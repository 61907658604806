import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";
import { PUBLISH_STATUS } from "../../../consts";

// @ts-ignore
import { TOUR_TYPES } from "tour-guide-shared-library";

// Mui
import { Box, Divider, FormLabel } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import RadioButtonWithInfo from "../../inputs/RadioButtonWithInfo";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";
import RichTextEditor from "../../richTextEditor/RichTextEditor";

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
}

const Summary = forwardRef(({ tour, tourId, clearFormError }: Props, ref) => {
  // #region States
  const initalFormState = {
    price: {
      label: DICTIONARY.createTour.fields.price.label,
      info: DICTIONARY.createTour.fields.price.info,
      value: tour.price >= 0 ? tour.price : DEFAULT_PRICE,
      isValid: true,
      required: true,
      validators: [
        {
          validate: (val: number) => VALIDATORS.number.isGreaterThanX(val, 0), // TEMP If set to "0" --> it means FREE tour --> No need to enter promocode
          errorMessage: DICTIONARY.createTour.fields.price.error.minPrice,
        },
      ],
    },
    publishStatus: {
      label: DICTIONARY.createTour.fields.publishStatus.label,
      info: DICTIONARY.createTour.fields.publishStatus.info,
      value: tour.publishStatus
        ? tour.publishStatus
        : PUBLISH_STATUS.draft.value,
      isValid: true,
      required: true,
      validators: [],
    },
    outdoorGame_wrongAnswerPenaltyTime: {
      label:
        DICTIONARY.createTour.fields.outdoorGame_wrongAnswerPenaltyTime.label,
      info: DICTIONARY.createTour.fields.outdoorGame_wrongAnswerPenaltyTime
        .info,
      value: tour.outdoorGame_wrongAnswerPenaltyTime
        ? tour.outdoorGame_wrongAnswerPenaltyTime
        : 10,
      isValid: true,
      required: false,
      validators: [],
    },
    outdoorGame_finishInformation: {
      label: DICTIONARY.createTour.fields.outdoorGame_finishInformation.label,
      info: DICTIONARY.createTour.fields.outdoorGame_finishInformation.info,
      value: HELPERS.richText.getRichTextFromDb(
        tour.outdoorGame_finishInformation
      ),
      isValid: true,
      required: false,
      validators: [],
    },
  };

  const [formState, setFormState] = useState(initalFormState);
  // #endregion

  //#region Functionality
  const handleInputChange = (event: any, payload?: any) => {
    const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
    let key: string = "";
    let val: any;

    switch (type) {
      case "text":
      case "textarea": {
        const { id, value } = event.target;
        key = id;
        val = value;
        break;
      }
      case "number": {
        const { id, value } = event.target;
        key = id;
        val = Number(value);
        break;
      }
      case "select": {
        const { name, value } = event.target;
        key = name;
        val = value;
        break;
      }
      case "radio": {
        const { name, value } = event.target;
        key = name;
        val = value;
        break;
      }
      case "file": {
        const { id, files } = event.target;
        key = id;
        val = files != null && files[0];
        break;
      }
      case "richText": {
        key = payload.id;
        val = event;
        break;
      }
      default: {
        break;
      }
    }

    setFormState((prevState: any) => {
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
          isValid: true,
        },
      };
    });

    // Clear errors
    clearFormError();
  };

  //#endregion

  //#region Submit and Validation
  const isFormValid = (formState: any) => {
    try {
      // Empty fields
      const { formState: formStateResponseEmptyFields, emptyFields } =
        HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
          ...formState,
        });
      if (emptyFields.length > 0) {
        setFormState(formStateResponseEmptyFields);

        const error =
          HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

        return {
          isValid: false,
          error,
        };
      }

      // Invaid fields
      const {
        formState: formStateResponseInvalidFields,
        formError: error,
      }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
        ...formState,
      });
      if (error) {
        setFormState(formStateResponseInvalidFields);

        return {
          isValid: false,
          error,
        };
      }

      return {
        isValid: true,
      };
    } catch (error) {
      HELPERS.localhost.isVerbose() && console.error(error);

      return {
        isValid: false,
        error: DICTIONARY.createTour.errors.generalError,
      };
    }
  };

  const prepareDataToSave = () => {
    let files: any = [];

    const parsedData = {
      price: formState.price.value,
      publishStatus: formState.publishStatus.value,
      // Outdoor game fields
      ...(tour.tourType === TOUR_TYPES.outdoorGame.value && {
        outdoorGame_wrongAnswerPenaltyTime:
          formState.outdoorGame_wrongAnswerPenaltyTime.value,
      }),
      ...(tour.tourType === TOUR_TYPES.outdoorGame.value && {
        outdoorGame_finishInformation: HELPERS.richText.prepareRichTextToDbSave(
          formState.outdoorGame_finishInformation.value
        ),
      }),
    };

    return {
      data: parsedData,
      files: files,
    };
  };

  const onSubmit = () => {
    // Validate fields
    const response = isFormValid(formState);

    if ("error" in response) {
      return response;
    }

    const parsedData = prepareDataToSave();

    return {
      ...response, // isValid = true
      ...parsedData, // data and files
    };
  };

  const onBack = () => {
    // Validate fields
    const response = isFormValid(formState);

    if ("error" in response) {
      return response;
    }

    const parsedData = prepareDataToSave();

    return {
      ...response, // isValid = true
      ...parsedData, // data and files
    };
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
    onBack,
  }));
  //#endregion

  return (
    <Fragment>
      {/* Header */}
      <SectionHeader title={DICTIONARY.createTour.sections.summary.label} />

      {/* Content */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: 1,
          mt: 2,
        }}
      >
        {/* Tour price */}
        <Box sx={{ gridColumn: "1 / 6" }} mb={1}>
          <NumberFieldWithInfo
            label={formState.price.label}
            value={formState.price.value}
            id={"price"}
            onChange={handleInputChange}
            required={formState.price.required}
            error={!formState.price.isValid}
            info={formState.price.info}
          />
        </Box>

        {/* Publish status radio buttons */}
        <Box sx={{ gridColumn: "span 12" }} mb={1}>
          <RadioButtonWithInfo
            label={formState.publishStatus.label}
            value={formState.publishStatus.value}
            id="publishStatus"
            onChange={(event: any) =>
              handleInputChange(event, { type: "radio" })
            }
            info={DICTIONARY.createTour.fields.publishStatus.info}
            options={publishStatusArr}
          />
        </Box>
      </Box>

      {/* Outdoor game */}
      {tour.tourType === TOUR_TYPES.outdoorGame.value && (
        <Fragment>
          <Divider sx={{ mb: 2 }}>Outdoor game</Divider>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(12, 1fr)",
              gap: 1,
            }}
          >
            {/* Wrong answer penalty time */}
            <Box sx={{ gridColumn: "1 / 6" }} mb={1}>
              <NumberFieldWithInfo
                label={formState.outdoorGame_wrongAnswerPenaltyTime.label}
                value={formState.outdoorGame_wrongAnswerPenaltyTime.value}
                id={"outdoorGame_wrongAnswerPenaltyTime"}
                onChange={handleInputChange}
                required={formState.outdoorGame_wrongAnswerPenaltyTime.required}
                error={!formState.outdoorGame_wrongAnswerPenaltyTime.isValid}
                info={formState.outdoorGame_wrongAnswerPenaltyTime.info}
              />
            </Box>

            {/* Finish game information */}
            <Box sx={{ gridColumn: "span 12" }} mb={1}>
              <FormLabel component="legend" sx={{ fontSize: 14 }}>
                {formState.outdoorGame_finishInformation.label}
              </FormLabel>
              <RichTextEditor
                /*
            // @ts-ignore */
                editorState={formState.outdoorGame_finishInformation.value}
                onEditorStateChange={(editorState: any) =>
                  handleInputChange(editorState, {
                    type: "richText",
                    id: "outdoorGame_finishInformation",
                  })
                }
              />
            </Box>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
});

export default Summary;

//#region Helpers
const DEFAULT_PRICE = 5;

const publishStatusArr = Object.keys(PUBLISH_STATUS).map((key: string) => ({
  ...PUBLISH_STATUS[key],
}));
//#endregion
