import { useState, useEffect } from "react";
import { DICTIONARY } from "../../utils";
import { tourApi } from "../../api";

// App components
import HighlightCardContainer from "./HighlightCardContainer";

// Mui
import { Box, Typography } from "@mui/material";

const CARD_HEIGHT = 80;

// Top tour first entrance
export default function TopTourCard() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>({});

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await tourApi.metadata.getAll();

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const parsedData = parseData(response);
      setData(parsedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Chart data
  const parseData = (rawData: any) => {
    try {
      let retVal = [];

      if (rawData && rawData.length > 0) {
        retVal = rawData.map((item: any) => {
          const { name, counter_firstTimeEntrances } = item;
          const parsedItem = {
            label: name,
            value: counter_firstTimeEntrances ?? 0,
          };

          return parsedItem;
        });

        retVal = sortEntities(retVal, "value");
      }

      return retVal[0];
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <HighlightCardContainer
      title={DICTIONARY.dashboard.cards.topTourEntrances.title}
      info={DICTIONARY.dashboard.cards.topTourEntrances.info}
      icon="tour"
      actions={[]}
      isLoading={isLoading}
      error={error}
      style={{
        height: `${CARD_HEIGHT + 80}px`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: CARD_HEIGHT,
        }}
      >
        <Typography sx={{ fontSize: 34, mb: 0.5 }}>{data.value}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 18, textAlign: "center" }}>
            {data.label}
          </Typography>
        </Box>
      </Box>
    </HighlightCardContainer>
  );
}

//#region Helpers
const sortEntities = (tours: any, key: string) => {
  function callback(a: any, b: any) {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
