import { useState } from "react";

// Mui
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

// App components
import InfoTooltip from "../shared/InfoTooltip";

type Props = {
  label: string;
  value: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
  error: boolean;
  info: string;
  disabled?: boolean;
};

export default function NumberFieldWithInfo({
  label,
  value,
  id,
  onChange,
  required,
  error,
  info,
  disabled,
}: Props) {
  const [stringValue, setStringValue] = useState(value.toString());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStringValue(() => {
      onChange(event);
      return event.target.value;
    });
  };

  return (
    <FormControl
      variant="outlined"
      size="small"
      margin="none"
      fullWidth
      error={error}
      required={required}
      disabled={disabled}
    >
      <InputLabel htmlFor="initialZoom">{label}</InputLabel>
      <OutlinedInput
        label={label}
        value={stringValue}
        id={id}
        name={id}
        onChange={handleChange}
        type="number"
        disabled={disabled}
        inputProps={{ type: "number" }}
        endAdornment={
          info !== "" && (
            <InputAdornment position="end">
              <InfoTooltip info={info} />
            </InputAdornment>
          )
        }
        sx={{
          paddingRight: 0.5,
        }}
      />
    </FormControl>
  );
}
