import React, { useState, useEffect, useCallback } from "react";
import {
  Route,
  Switch,
  withRouter,
  useHistory,
  Redirect,
} from "react-router-dom";
import { ROUTES } from "./utils";
import { adminApi } from "./api";
import { AuthContext, SnackbarProvider, SidebarProvider } from "./context";
import { auth } from "./config/firebase-config";

import theme from "./config/mui-theme";
import "./App.scss";

// date-fns
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// Firebase
import { startFirebaseApp } from "./config/firebase-config";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";

// App Pages
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";

// Dashboard
import Dashboard from "./pages/Dashboard";

// Tours
import Tours from "./pages/tour/Tours";
import Tour from "./pages/tour/Tour";
import CreateTour from "./pages/tour/CreateTour";
import ToursAnalytics from "./pages/tour/ToursAnalytics";
import Promocode from "./pages/tour/PromocodesManager"; // DEPRECATED

// Promocodes
import Promocodes from "./pages/promocode/Promocodes";
import CreatePromocode from "./pages/promocode/CreatePromocode";
import PromocodesAnalytics from "./pages/promocode/PromocodesAnalytics";
import OndemandPromocode from "./pages/promocode/OndemandPromocode";

// Guides
import Guides from "./pages/guide/Guides";
import CreateGuide from "./pages/guide/CreateGuide";

// Business Partners
import BusinessPartners from "./pages/businessPartner/BusinessPartners";
import CreateBusinessPartner from "./pages/businessPartner/CreateBusinessPartner";

// Seller
import Sellers from "./pages/seller/Sellers";
import CreateSeller from "./pages/seller/CreateSeller";

import NotFound from "./pages/NotFound";
import QrCodeGenerator from "./pages/QrCodeGenerator";

// App Components
import ElevationScroll from "./components/layout/ElevationScroll";
import AppHeader from "./components/layout/AppHeader";
import Sidebar from "./components/layout/Sidebar";
import Main from "./components/layout/Main";
import DrawerHeader from "./components/layout/DrawerHeader";
import AppLoader from "./components/loader/AppLoader";

startFirebaseApp();

function App() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  // #region User
  const [user, setUser] = useState({
    auth: null,
    data: null,
  });

  const handleUserUpdate = useCallback(
    ({ auth, data }) => {
      setUser({
        auth: auth ? auth : user.auth,
        data: data ? data : user.data,
      });
    },
    [user]
  );

  const signupUserIfNeeded = useCallback(async (uid: string, data: object) => {
    /* 
			This is neccesary for the case of user signup --> 
			firebase.auth().signups functions are not acting as promises, but as a stream,
			so after 'createUserWithEmailAndPassword' we immediatly getting caught in 'onAuthStateChanged'.
		*/
    await adminApi.create(uid, data);
    const adminData = await adminApi.get(uid);

    return adminData;
  }, []);
  // #endregion

  // #region Auth state management
  useEffect(() => {
    const firebaseListener = onAuthStateChanged(auth, async (user) => {
      try {
        setIsLoading(true);
        if (user && user.emailVerified) {
          // User is signed in
          let adminData: any = await adminApi.get(user.uid);

          // If needed - Add user to 'users' collection
          if (
            adminData.error !== undefined ||
            Object.keys(adminData).length === 0
          ) {
            adminData = await signupUserIfNeeded(user.uid, user);
          }

          handleUserUpdate({ auth: user, data: adminData });
          setIsLoading(false);

          const { pathname, search } = history.location;
          const currentPath = pathname + search;
          const path =
            currentPath === ROUTES.login.path ||
            currentPath === ROUTES.signup.path
              ? ROUTES.root.path
              : currentPath;
          history.push(path);

          return;
        } else {
          // User is signed out
          setIsLoading(false);
          handleUserUpdate({ auth: null, data: null });
          history.push(ROUTES.login.path);
        }
      } catch (e) {
        setIsLoading(false);
      }

      return () => {
        firebaseListener();
      };
    });
  }, []);
  //#endregion

  // #region Routes
  let routes;

  if (user.auth) {
    routes = (
      <Box sx={{ display: "flex" }}>
        <AuthContext.Provider value={{ user, handleUserUpdate }}>
          <SidebarProvider>
            <ElevationScroll>
              <AppHeader />
            </ElevationScroll>
            <Sidebar />
            <Main>
              <DrawerHeader />
              <Switch>
                {/* Dashboard */}
                <Route exact path={ROUTES.root.path} component={Dashboard} />
                {/* Tours */}
                <Route exact path={ROUTES.tours.path} component={Tours} />
                <Route
                  exact
                  path={ROUTES.tours.create.path}
                  component={CreateTour}
                />
                <Route
                  exact
                  path={ROUTES.tours.edit.path}
                  component={CreateTour}
                />
                <Route
                  exact
                  path={ROUTES.tours.analytics.path}
                  component={ToursAnalytics}
                />
                {/* DEPRECATED - ROUTES.tours.promocode.path */}
                <Route
                  path={ROUTES.tours.promocode.path}
                  component={Promocode}
                />
                {/* Tour */}
                <Route path={ROUTES.tours.tour.path} component={Tour} />
                {/* Promocodes */}
                <Route
                  exact
                  path={ROUTES.promocodes.path}
                  component={Promocodes}
                />
                <Route
                  exact
                  path={ROUTES.promocodes.create.path}
                  component={CreatePromocode}
                />
                <Route
                  exact
                  path={ROUTES.promocodes.edit.path}
                  component={CreatePromocode}
                />
                <Route
                  exact
                  path={ROUTES.promocodes.analytics.path}
                  component={PromocodesAnalytics}
                />
                <Route
                  exact
                  path={ROUTES.promocodes.ondemand.path}
                  component={OndemandPromocode}
                />
                {/* Guide */}
                <Route exact path={ROUTES.guides.path} component={Guides} />
                <Route
                  exact
                  path={ROUTES.guides.create.path}
                  component={CreateGuide}
                />
                <Route
                  exact
                  path={ROUTES.guides.edit.path}
                  component={CreateGuide}
                />
                {/* Business Partner */}
                <Route
                  exact
                  path={ROUTES.businessPartners.path}
                  component={BusinessPartners}
                />
                <Route
                  exact
                  path={ROUTES.businessPartners.create.path}
                  component={CreateBusinessPartner}
                />
                <Route
                  exact
                  path={ROUTES.businessPartners.edit.path}
                  component={CreateBusinessPartner}
                />
                {/* Seller */}
                <Route exact path={ROUTES.sellers.path} component={Sellers} />
                <Route
                  exact
                  path={ROUTES.sellers.create.path}
                  component={CreateSeller}
                />
                <Route
                  exact
                  path={ROUTES.sellers.edit.path}
                  component={CreateSeller}
                />
                {/* Other */}
                <Route
                  exact
                  path={ROUTES.qrCodeGenerator.path}
                  component={QrCodeGenerator}
                />
                <Route component={NotFound} />
                {/* <Route exact path={ROUTES.root.path}>
                  <Redirect exact to={ROUTES.tours.path} />
                </Route> */}
              </Switch>
            </Main>
          </SidebarProvider>
        </AuthContext.Provider>
      </Box>
    );
  } else {
    routes = (
      <Switch>
        <Route path={ROUTES.login.path} component={Login} />
        {/* <Route path={ROUTES.signup.path} component={Signup} /> */}
      </Switch>
    );
  }
  // #endregion

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider>
            {isLoading ? <AppLoader /> : routes}
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </React.Fragment>
  );
}

export default withRouter(App);
