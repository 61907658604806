const dashboard = {
  title: "Dashboard",
  emptyState: "The requested tour does not exist",
  cards: {
    topPromocode: {
      title: "Top promocode",
      info: "Most used promocode.",
    },
    topTourEntrances: {
      title: "Top tour",
      info: "The tour with the most 'first time' entrances.",
    },
    topGuide: {
      title: "Top guide",
      info: "The guide with the most 'show contact details' clicks.",
    },
  },
  charts: {
    // Usages of promocodes
    promocodeUsages: {
      title: "Promocodes usages",
      actions: {},
    },
  },
};

export default dashboard;
