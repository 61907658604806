const map = {
  preview: "Preview mode",
  outofmaxbounds:
    "Hey there, your current location is out of this tour bounds.",
  error:
    "There is an issue getting your location. Please reload the page or contact support.",
  longitude: "Lng",
  latitude: "Lat",
  zoom: "Zoom",
};

export default map;
