import React, {
  useState,
  FC,
  useContext,
  createContext,
  useEffect,
} from "react";
import { useIsMobileScreen } from "../hooks";

interface ISidebarContext {
  isSidebarOpen: boolean;
  isMobileScreen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const defaultState = {
  isSidebarOpen: true,
  isMobileScreen: false,
  handleDrawerOpen: () => null,
  handleDrawerClose: () => null,
};

const SidebarContext = createContext<ISidebarContext>(defaultState);

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider: FC = ({ children }) => {
  const isMobileScreen = useIsMobileScreen();

  useEffect(() => {
    if (isMobileScreen) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }, [isMobileScreen]);

  const [isSidebarOpen, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        handleDrawerOpen,
        handleDrawerClose,
        isMobileScreen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
