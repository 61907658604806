const sellers = {
  title: "Sellers",
  create: "Create new seller",
  emptyState: "No sellers yet",
  emptyStateFiltered: "No matching sellers",
  entityItem: {
    actions: {
      view: "View",
      edit: "Edit seller",
      delete: "Delete",
    },
  },
  table: {
    columns: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      countries: "Countries",
      location: "Location",
      createdBy: "Created by",
      createdAt: "Created at",
      updatedAt: "Updated at",
      actions: "Actions",
    },
  },
  deleteConfirmationDialog: {
    title: "Delete seller",
    body: "After deleting the seller you will no longer be able to edit it.",
    button: "Delete",
  },
};

export default sellers;
