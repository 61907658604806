import React, { createContext, useContext } from "react";

interface IAuthContext {
  user: any;
  handleUserUpdate: ({}: any) => void;
}

const defaultState = {
  user: {},
  handleUserUpdate: () => null,
};

export const AuthContext = createContext<IAuthContext>(defaultState);

export const useAuthContext = () => useContext(AuthContext);
