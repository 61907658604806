import React, { useState, useEffect, Fragment } from "react";
import { DICTIONARY, HELPERS } from "../../../../utils";
import { tourApi } from "../../../../api";

// App components
import Loader from "../../../loader/Loader";
import EmptyState from "../../../shared/EmptyState";
import InfoTooltip from "../../../shared/InfoTooltip";

// Mui
import { Box, Container, Typography, Button, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

type Props = {
  tourId: string;
};

function AdminPromocode({ tourId }: Props) {
  const [emptyState, setEmptyState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [promocodes, setPromocodes] = useState<any>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await tourApi.promocode.getSubcollection(
        tourId,
        "admin"
      );

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setEmptyState(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setEmptyState(DICTIONARY.promocodesManager.sections.admin.emptyState);
        return;
      }

      const parsedResponse = response.map((item: any) => {
        return {
          ...item,
          id: item.code,
          createdAt: HELPERS.date.formatDate(item.createdAt.toDate()),
        };
      });

      // Success
      setEmptyState("");
      setPromocodes(parsedResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Table
  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: DICTIONARY.promocodesManager.table.columns.code,
      width: 150,
    },
    {
      field: "createdAt",
      headerName: DICTIONARY.promocodesManager.table.columns.createdAt,
      width: 250,
    },
    {
      field: "count",
      headerName: DICTIONARY.promocodesManager.table.columns.count,
      width: 100,
    },
  ];
  //#endregion

  //#region Components
  const Header = () => {
    return (
      <Fragment>
        {/* Section header */}
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={1}
        >
          <Typography variant="h6" component="h1" sx={{ mr: 0.2, mb: 0 }}>
            {DICTIONARY.promocodesManager.sections.admin.title}
          </Typography>
          <InfoTooltip
            info={DICTIONARY.promocodesManager.sections.admin.info}
          />
        </Box>
      </Fragment>
    );
  };
  const Content = ({ promocodes }: any) => {
    return (
      <Fragment>
        {/* Section content */}
        <div style={{ height: 180, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={promocodes}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    );
  };
  //#endregion

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <Header />

          <Paper sx={{ p: 1 }}>
            {isLoading ? (
              <Loader py={4} />
            ) : emptyState ? (
              <EmptyState title={emptyState} icon="promocode" py={4} />
            ) : (
              <Content promocodes={promocodes} />
            )}
          </Paper>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default AdminPromocode;
