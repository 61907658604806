import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";

// Mui
import { Box, TextField, InputAdornment } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";

// Icons
import {
  Preview as PreviewIcon,
  Language as WebsiteIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedinIcon,
} from "@mui/icons-material";

interface Props {
  entityData: any;
  entityId: string;
  clearFormError: () => void;
  onPreviewIntroScreen: (response: any) => void;
}

const Social = forwardRef(
  (
    { entityData, entityId, clearFormError, onPreviewIntroScreen }: Props,
    ref
  ) => {
    // #region States
    const initalFormState = {
      website: {
        label: DICTIONARY.createGuide.fields.website.label,
        info: DICTIONARY.createGuide.fields.website.info,
        value: entityData.website ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      facebook: {
        label: DICTIONARY.createGuide.fields.facebook.label,
        info: DICTIONARY.createGuide.fields.facebook.info,
        value: entityData.facebook ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      instagram: {
        label: DICTIONARY.createGuide.fields.instagram.label,
        info: DICTIONARY.createGuide.fields.instagram.info,
        value: entityData.instagram ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      linkedin: {
        label: DICTIONARY.createGuide.fields.linkedin.label,
        info: DICTIONARY.createGuide.fields.linkedin.info,
        value: entityData.linkedin ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleGuidePagePreview = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        onPreviewIntroScreen(response);
        return;
      }

      const parsedData = prepareDataToSave();

      onPreviewIntroScreen({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createGuide.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let fileUrl: string = "";
      let files: any = [];

      const parsedData = {
        website: formState.website.value,
        facebook: formState.facebook.value,
        instagram: formState.instagram.value,
        linkedin: formState.linkedin.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createGuide.sections.social.label}
          actions={[
            {
              label: DICTIONARY.createGuide.buttons.introScreen,
              icon: <PreviewIcon />,
              onClick: handleGuidePagePreview,
            },
          ]}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 5,
          }}
        >
          {/* Website */}
          <Box sx={{ gridColumn: "1 / 10" }} mb={1}>
            <TextField
              label={formState.website.label}
              value={formState.website.value}
              id="website"
              name="website"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.website.required}
              error={!formState.website.isValid}
              fullWidth
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WebsiteIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Facebook */}
          <Box sx={{ gridColumn: "1 / 10" }} mb={1}>
            <TextField
              label={formState.facebook.label}
              value={formState.facebook.value}
              id="facebook"
              name="facebook"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.facebook.required}
              error={!formState.facebook.isValid}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Instagram */}
          <Box sx={{ gridColumn: "1 / 10" }} mb={1}>
            <TextField
              label={formState.instagram.label}
              value={formState.instagram.value}
              id="instagram"
              name="instagram"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.instagram.required}
              error={!formState.instagram.isValid}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InstagramIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Linkedin */}
          <Box sx={{ gridColumn: "1 / 10" }} mb={1}>
            <TextField
              label={formState.linkedin.label}
              value={formState.linkedin.value}
              id="linkedin"
              name="linkedin"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.linkedin.required}
              error={!formState.linkedin.isValid}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedinIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Social;
