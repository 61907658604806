import { useState, useEffect } from "react";
import { DICTIONARY, HELPERS } from "../../utils";
import { logEventApi } from "../../api";
import { parse } from "date-fns";

// App components
import ChartContainer from "./ChartContainer";

// Mui
import { Paper, Box, Typography } from "@mui/material";

// Recharts
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";

// Colors
import { grey } from "@mui/material/colors";

const CHART_HEIGHT = 400;

type Props = {
  tour: any;
};

export default function OutdoorGameFinishedLogChart({ tour }: Props) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  //#region Init
  const fetchData = async (tourId: string) => {
    try {
      setIsLoading(true);
      const queryFilterArr = [
        {
          field: "tourId",
          operator: "==",
          value: tourId,
        },
        {
          field: "event",
          operator: "==",
          value: "outdoor_game_finished",
        },
      ];

      const response = await logEventApi.getAll(queryFilterArr);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const parsedData = convertToChartData(response);
      setData(parsedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    if (tour && "id" in tour) {
      setError("");
      (async () => {
        fetchData(tour.id);
      })();
    } else {
      setError(DICTIONARY.general.chart.errors.selectTour);
    }
  }, [tour]);
  //#endregion

  //#region Chart data
  const convertToChartData = (rawData: any) => {
    try {
      let retVal: any[] = [];
      let datesMap: any = {};

      if (rawData && rawData.length > 0) {
        rawData.forEach((item: any) => {
          const { timestamp_client } = item;

          const date = HELPERS.date.formatDate(
            timestamp_client.toDate(),
            "dd/MM/yy"
          );

          if (date in datesMap) {
            datesMap[date] += 1;
          } else {
            datesMap[date] = 1;
          }
        });

        retVal = Object.keys(datesMap).map((key: any) => {
          return {
            date: key,
            count: datesMap[key],
          };
        });

        retVal = sortEntities(retVal, "date", {
          type: "timestamp",
          format: "dd/MM/yy",
        });
      }

      return retVal;
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <ChartContainer
      title={DICTIONARY.toursAnalytics.charts.outdoorGameFinished.title}
      actions={[]}
      isLoading={isLoading}
      error={error}
      style={{
        height: `${CHART_HEIGHT + 80}px`,
        flex: 1,
      }}
    >
      <ResponsiveContainer height={CHART_HEIGHT} width="100%">
        <BarChart
          data={data}
          margin={{ right: 10, top: 5, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize={10} interval={0} />
          <YAxis
            fontSize={12}
            interval="preserveStartEnd"
            allowDecimals={false}
          />
          <Tooltip content={<CustomTooltip payload={data} />} />
          <Legend />
          <Bar dataKey="count" name="Finished games" fill="#9ccc65" />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

//#region Helpers
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <Paper sx={{ p: 1 }}>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>Date:</Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.date}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Finished games:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.count}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

const sortEntities = (tours: any, key: string, options?: any) => {
  function callback(a: any, b: any) {
    // timestamp
    if (options && options.type === "timestamp") {
      a = parse(a[key], options.format, new Date());
      b = parse(b[key], options.format, new Date());

      return a - b;
    }

    // Other (number, string, ..)
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
