import localhost from "./localhost";
import auth from "./auth";
import mapbox from "./mapbox";
import firebase from "./firebase";
import createTour from "./createTour";
import general from "./general";
import device from "./device";
import date from "./date";
import country from "./country";
import richText from "./richText";
import form from "./form";

const HELPERS = {
  localhost,
  auth,
  mapbox,
  firebase,
  createTour,
  general,
  device,
  date,
  country,
  richText,
  form,
};

export default HELPERS;
