import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DICTIONARY, VALIDATORS, ROUTES, HELPERS } from "../../utils";
import { tourApi } from "../../api";
import { useAuthContext } from "../../context";
import { IMAGE_PLACEHOLDER } from "../../consts";

// Map
// @ts-ignore
import { IntroScreen, Map } from "tour-guide-shared-library";
// import Map from "../../components/map/Map";

// App components
import AppFooter from "../../components/layout/AppFooter";
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";

import TourDetails from "../../components/tour/createTour/TourDetails";
import Route from "../../components/tour/createTour/Route";
import TourPoints from "../../components/tour/createTour/TourPoints";
import SymbolPoints from "../../components/tour/createTour/SymbolPoints";
import ExtraInfo from "../../components/tour/createTour/ExtraInfo";
import Summary from "../../components/tour/createTour/Summary";

// Mui
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Box,
  Container,
  Typography,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Icons
import {
  NavigateBefore as BackIcon,
  NavigateNext as NextIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

const useStyles = makeStyles({});

const SECTIONS: any = {
  tourDetails: {
    label: DICTIONARY.createTour.sections.details.label,
    order: 0,
  },
  route: {
    label: DICTIONARY.createTour.sections.route.label,
    order: 1,
  },
  tourPoints: {
    label: DICTIONARY.createTour.sections.points.label,
    order: 2,
  },
  symbolPoints: {
    label: DICTIONARY.createTour.sections.symbolPoints.label,
    order: 3,
  },
  extraTourInfo: {
    label: DICTIONARY.createTour.sections.extraTourInfo.label,
    order: 4,
  },
  summary: {
    label: DICTIONARY.createTour.sections.summary.label,
    order: 5,
  },
};

const sectionsArr: any = Object.keys(SECTIONS).map((key: string) => ({
  key,
  label: SECTIONS[key].label,
  order: SECTIONS[key].order,
}));

const PREVIEW_MODES = {
  map: {
    value: "map",
    label: "Map",
  },
  introScreen: {
    value: "introScreen",
    label: "Intro screen",
  },
  tourInfo: {
    value: "tourInfo",
    label: "Tour info",
  },
};

function CreateTour() {
  const classes = useStyles();
  const history = useHistory();
  const { id: tourId }: any = useParams(); // in Edit mode, we will have 'id'
  const { user } = useAuthContext();

  // #region States and refs
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pageError, setPageError] = useState<string>("");
  const [tour, setTour] = useState<any>({});
  const [formError, setFormError] = useState("");
  const formErrorRef = useRef<any>(null);

  const [files, setFiles] = useState<any>([]); // all files along the wizard (coverImage, thumbnails, videos, audios)

  const tourDetailsRef = useRef<any>();
  const routeRef = useRef<any>();
  const tourPointsRef = useRef<any>();
  const symbolPointsRef = useRef<any>();
  const extraTourInfoRef = useRef<any>();
  const summaryRef = useRef<any>();
  // #endregion

  // #region Load data
  useEffect(() => {
    // Create mode
    if (!tourId) {
      setIsPageLoading(false);
      return;
    }

    (async () => {
      try {
        setIsPageLoading(true);
        console.log("Create Tour init A: ", new Date());
        const response = await tourApi.get(tourId);
        console.log("Create Tour init B: ", new Date());
        if (response.hasOwnProperty("error")) {
          setIsPageLoading(false);
          setPageError(DICTIONARY.createTour.emptyState);
          return;
        }

        // If entity doesnt exist
        if (Object.keys(response).length === 0) {
          setPageError(DICTIONARY.createTour.emptyState);
        }
        console.log(response);
        // Success
        setTour(response);
        setPreviewTour(response);
        setIsPageLoading(false);
      } catch (error) {
        console.error("Create Tour init error: ", new Date());
        setIsPageLoading(false);
      }
    })();
  }, [tourId]);
  //#endregion

  // #region Stepper
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    try {
      const refsMap: any = {
        tourDetails: tourDetailsRef,
        route: routeRef,
        tourPoints: tourPointsRef,
        symbolPoints: symbolPointsRef,
        extraTourInfo: extraTourInfoRef,
      };

      const selectedRef = refsMap[sectionsArr[activeStep].key];

      const response = selectedRef.current.onNext();

      if (response.isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        // Preview tour
        setPreviewTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...response.data,
          };

          return updatedTour;
        });

        // Tour
        setTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...response.data,
          };

          return updatedTour;
        });

        // Files
        if (response.files) {
          setFiles((prevState: []) => {
            const updatedFiles = [...prevState, ...response.files];

            return updatedFiles;
          });
        }
      } else {
        showFormError(response.error);
      }
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleBack = () => {
    try {
      const refsMap: any = {
        route: routeRef,
        tourPoints: tourPointsRef,
        symbolPoints: symbolPointsRef,
        extraTourInfo: extraTourInfoRef,
        summary: summaryRef,
      };

      const selectedRef = refsMap[sectionsArr[activeStep].key];

      if (!selectedRef) {
        return;
      }

      const response = selectedRef.current.onBack();

      if (response.isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        // Preview tour
        setPreviewTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...response.data,
          };

          return updatedTour;
        });

        setTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...response.data,
          };

          return updatedTour;
        });
      } else {
        showFormError(response.error);
      }
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };
  // #endregion

  // #region Error helpers
  const showFormError = (error: string) => {
    setFormError(error);

    // We need to short setTimeout, to first let 'formErrorRef' to be added to the DOM, so then we can scroll to it
    setTimeout(() => {
      formErrorRef.current.scrollIntoView({
        behavior: "smooth",
        top: formErrorRef.current.offsetTop,
      });
    }, 50);
  };

  const clearFormError = () => {
    setFormError("");
  };
  // #endregion

  // #region Preview functionality
  const [activePreviewMode, setActivePreviewMode] = useState(
    PREVIEW_MODES.map.value
  ); // map = the default mode
  const [previewTour, setPreviewTour] = useState<any>({
    route: "",
    routeColor: "",
    initialZoom: "",
    tourPoints: [],
  });

  const handleIntroScreenPreview = (response: any) => {
    try {
      if ("error" in response) {
        showFormError(response.error);
        return;
      }

      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });

      setActivePreviewMode(PREVIEW_MODES.introScreen.value);
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleCloseIntroScreen = () => {
    setActivePreviewMode(PREVIEW_MODES.map.value); // map = the default mode
  };

  const handleTourNamePreview = (response: any) => {
    try {
      if ("error" in response) {
        showFormError(response.error);
        return;
      }

      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleMapStylePreview = (mapStyle: any) => {
    setPreviewTour((prevState: any) => {
      const updatedTour = {
        ...prevState,
        mapStyle,
      };

      return updatedTour;
    });
  };

  const handleRoutePreview = (response: any) => {
    try {
      if ("error" in response) {
        showFormError(response.error);
        return;
      }

      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleTourPointPreview = (response: any) => {
    // Used when a tour point, symbol point gets change
    try {
      if ("error" in response) {
        showFormError(response.error);
        return;
      }

      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleExtraTourInfoPreview = (response: any) => {
    try {
      if ("error" in response) {
        showFormError(response.error);
        return;
      }

      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };

  const handleTourPointDelete = (response: any) => {
    try {
      setPreviewTour((prevState: any) => {
        const updatedTour = {
          ...prevState,
          ...response.data,
        };

        return updatedTour;
      });
    } catch (error) {
      showFormError(DICTIONARY.createTour.errors.generalError);
    }
  };
  // #endregion

  // #region Submit
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const uploadFile = async ({ file, type, fileId, tourId, suffix }: any) => {
    const uploadImageResponse = await tourApi.uploadFile({
      type,
      fileId,
      file,
      suffix,
      tourId,
      userId: user.auth.uid,
    });

    if (uploadImageResponse.hasOwnProperty("error")) {
      return {
        error: DICTIONARY.general.filesUpload.error.imageUploadError,
      };
    } else {
      return {
        fileId,
        suffix,
        downloadUrl: uploadImageResponse.downloadUrl,
      };
    }
  };

  const handleSubmit = async () => {
    try {
      const summaryResponse = summaryRef.current.onSubmit();

      // #region Step 0. Validate summaryResponse
      if (summaryResponse.isValid) {
        // Preview tour
        setPreviewTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...summaryResponse.data,
          };

          return updatedTour;
        });

        // Tour
        setTour((prevState: any) => {
          const updatedTour = {
            ...prevState,
            ...summaryResponse.data,
          };

          return updatedTour;
        });

        // Files
        if (summaryResponse.files) {
          setFiles((prevState: []) => {
            const updatedFiles = [...prevState, ...summaryResponse.files];

            return updatedFiles;
          });
        }
      } else {
        showFormError(summaryResponse.error);
        return;
      }
      // #endregion

      setIsSubmitLoading(true);

      // #region Step 1. Validations
      // TODO: Should I do validation here?
      // TODO: add all validations function from each section --> export validation function to helper file, and use it here also
      // #endregion

      let finalTourData = {
        ...tour,
        ...summaryResponse.data,
      };

      // #region Step 2. Replace "blob" with imagePlaceholder
      // Go over all image properties in tour data, and instead of "blob", use imagePlaceholder
      // After the create / edit --> the real files will be set (step 4)

      if (finalTourData.coverImage.startsWith("blob")) {
        finalTourData.coverImage = IMAGE_PLACEHOLDER;
      }

      finalTourData?.tourPoints.forEach((item: any) => {
        if (item?.thumbnailImage.startsWith("blob")) {
          item.thumbnailImage = IMAGE_PLACEHOLDER;
        }
      });

      finalTourData?.extraTourInfo?.steps?.forEach((item: any) => {
        if (item?.extraInfoStepImage.startsWith("blob")) {
          item.extraInfoStepImage = IMAGE_PLACEHOLDER;
        }
      });

      finalTourData?.tourPoints?.forEach((point: any) => {
        point?.additionalImages?.forEach((item: any) => {
          if (item?.url.startsWith("blob")) {
            item.url = IMAGE_PLACEHOLDER;
          }
        });
      });
      // #endregion

      // #region Step 3. Send request to create / edit tour
      let tourResponse: any = tourId
        ? await tourApi.edit(tourId, finalTourData)
        : await tourApi.create(finalTourData, user);

      if (tourResponse.hasOwnProperty("error")) {
        setIsSubmitLoading(false);
        setFormError(DICTIONARY.createTour.errors.generalError);
        return;
      }
      // #endregion

      // #region Step 4. Upload files if needed (coverImage, thumbnails, videos, audios)
      if (files.length > 0) {
        let filesPromises: any = [];

        for (let i = 0; i < files.length; i++) {
          const { file, fileId, type, suffix } = files[i];
          if (VALIDATORS.filesUpload.isValidDataFile(file, type)) {
            filesPromises.push(
              uploadFile({
                type,
                file,
                fileId,
                suffix,
                tourId: tourResponse.tourId,
              })
            );
          }
        }

        if (filesPromises.length === 0) {
          // TODO: Show error
        }

        const filesResponses = await Promise.all(filesPromises);

        const updatedTourWithFilesUrls = { ...finalTourData };

        filesResponses.forEach((response: any) => {
          if ("error" in response) {
            // TODO: Show error
          } else {
            switch (response.suffix) {
              case "coverImage": {
                updatedTourWithFilesUrls.coverImage = response.downloadUrl;
                break;
              }
              case "mapStyleCustomImage": {
                updatedTourWithFilesUrls.mapStyleCustomImage =
                  response.downloadUrl;
                break;
              }
              case "thumbnailImage":
              case "videoContent":
              case "audioContent": {
                const indexOfPoint =
                  updatedTourWithFilesUrls.tourPoints.findIndex(
                    (item: any) => item.uniqueId === response.fileId
                  ); // Find tour point
                updatedTourWithFilesUrls.tourPoints[indexOfPoint][
                  response.suffix
                ] = response.downloadUrl;
                break;
              }
              case "extraInfoStepImage": {
                const indexOfPoint =
                  updatedTourWithFilesUrls.extraTourInfo.steps.findIndex(
                    (item: any) => item.uniqueId === response.fileId
                  ); // Find tour point
                updatedTourWithFilesUrls.extraTourInfo.steps[indexOfPoint][
                  response.suffix
                ] = response.downloadUrl;
                break;
              }
              case "additionalImage": {
                const [pointId, additionalImageId] = response.fileId.split("-");
                const indexOfPoint =
                  updatedTourWithFilesUrls.tourPoints.findIndex(
                    (item: any) => item.uniqueId === pointId
                  );

                const indexOfadditionalImage =
                  updatedTourWithFilesUrls.tourPoints[indexOfPoint][
                    "additionalImages"
                  ].findIndex((item: any) => item.id === additionalImageId);

                updatedTourWithFilesUrls.tourPoints[indexOfPoint][
                  "additionalImages"
                ][indexOfadditionalImage].url = response.downloadUrl;
                break;
              }
              default: {
                break;
              }
            }
          }
        });

        const tourResponseAfterImageUpload = await tourApi.edit(
          tourResponse.tourId,
          updatedTourWithFilesUrls
        );

        if (tourResponseAfterImageUpload.hasOwnProperty("error")) {
          setIsSubmitLoading(false);
          setFormError(DICTIONARY.createTour.errors.updateTourPointImages);
          return;
        }
      }
      // #endregion

      // Success
      setIsSubmitLoading(false);
      history.push(ROUTES.tours.path);
    } catch (error) {
      setIsSubmitLoading(false);
      HELPERS.localhost.isVerbose() && console.error(error);
    }
  };
  // #endregion

  if (isPageLoading) return <Loader />;
  if (pageError !== "")
    return <EmptyState title={pageError} icon="tour" py={10} />;

  return (
    <React.Fragment>
      <Container
        sx={{
          marginTop: 3,
          marginBottom: 5,
        }}
      >
        {/* Page header */}
        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
          <IconButton
            size="small"
            onClick={() => history.push(ROUTES.tours.path)}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="h5" component="h1">
            {tourId
              ? DICTIONARY.createTour.title.edit
              : DICTIONARY.createTour.title.create}
          </Typography>
          {tourId && (
            <Typography sx={{ fontSize: 16, color: grey[700], mt: 0.4 }}>
              ({tour.name})
            </Typography>
          )}
        </Box>

        {/* Page content */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={3}
          mt={1}
        >
          {/* Left side - Stepper and form */}
          <Box flex="auto">
            {/* Sections stepper */}
            <Box
              position={{ xs: "static", sm: "sticky" }}
              sx={{
                top: "75px",
                mb: 2,
                mt: 1,
                zIndex: 2,
                outline: `16px solid ${grey[100]}`,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  zIndex: 3,
                  boxShadow:
                    "inset 0px -2px 1px -1px rgb(0 0 0 / 20%), inset 0px -1px 1px 0px rgb(0 0 0 / 14%), inset 0px 0px 3px 0px rgb(0 0 0 / 12%)",
                }}
              >
                <Stepper activeStep={activeStep} alternativeLabel>
                  {sectionsArr.map((step: any, index: number) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                      <Step key={step.key} {...stepProps}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Paper>
            </Box>

            {/* Form */}
            <Box component="form" noValidate>
              <Paper
                sx={{
                  px: 2,
                  pb: 2,
                }}
              >
                {/* Section content */}
                <Box
                  sx={
                    {
                      // pt: 1.6,
                    }
                  }
                >
                  {/* Tour Details */}
                  {activeStep === SECTIONS.tourDetails.order && (
                    <TourDetails
                      ref={tourDetailsRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                      onPreviewIntroScreen={handleIntroScreenPreview}
                      onPreviewTourName={handleTourNamePreview}
                    />
                  )}

                  {/* Route and map */}
                  {activeStep === SECTIONS.route.order && (
                    <Route
                      ref={routeRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                      onPreviewMapStyle={handleMapStylePreview}
                      onPreviewRoute={handleRoutePreview}
                    />
                  )}

                  {/* Tour points */}
                  {activeStep === SECTIONS.tourPoints.order && (
                    <TourPoints
                      ref={tourPointsRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                      onPreviewTourPoint={handleTourPointPreview}
                      onDeleteTourPoint={handleTourPointDelete}
                    />
                  )}

                  {/* Symbol points */}
                  {activeStep === SECTIONS.symbolPoints.order && (
                    <SymbolPoints
                      ref={symbolPointsRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                      onPreviewTourPoint={handleTourPointPreview}
                      onDeleteTourPoint={handleTourPointDelete}
                    />
                  )}

                  {/* Extra info */}
                  {activeStep === SECTIONS.extraTourInfo.order && (
                    <ExtraInfo
                      ref={extraTourInfoRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                      onPreviewExtraTourInfo={handleExtraTourInfoPreview}
                      onDeleteTourPoint={handleTourPointDelete}
                    />
                  )}

                  {/* Summary section */}
                  {activeStep === sectionsArr.length - 1 && (
                    <Summary
                      ref={summaryRef}
                      tour={tour}
                      tourId={tourId}
                      clearFormError={clearFormError}
                    />
                  )}
                </Box>
              </Paper>

              {/* Error message */}
              <Box
                id="form-error"
                ref={formErrorRef}
                flex={1}
                sx={{
                  mt: 2,
                  maxWidth: "md",
                }}
              >
                {formError && (
                  <Alert severity="error" variant="filled">
                    {formError}
                  </Alert>
                )}
              </Box>
            </Box>
            {/* End - Form */}
          </Box>

          {/* Right side - Mobile preview */}
          <Box
            // flex="1"
            position={{ xs: "static", sm: "sticky" }}
            sx={{
              minWidth: "300px",
              maxWidth: "300px",
              height: "560px",
              minHeight: "560px",
              border: "2px solid black",
              borderRadius: "10px",
              overflow: "hidden",
              top: "75px",
              mt: 1,
            }}
          >
            {activePreviewMode === PREVIEW_MODES.map.value && (
              <Map
                token={process.env.REACT_APP_MAPBOX_TOKEN}
                tour={previewTour}
                tourId={tourId}
                isPreviewMode={true}
                isDebugMode={false}
              />
            )}

            {activePreviewMode === PREVIEW_MODES.introScreen.value && (
              <IntroScreen
                token={process.env.REACT_APP_MAPBOX_TOKEN}
                tour={previewTour}
                tourId={tourId}
                onClose={handleCloseIntroScreen}
                onStartTour={handleCloseIntroScreen}
                isPreviewMode={true}
                style={{
                  height: "100%",
                }}
              />
            )}
          </Box>
        </Box>
      </Container>

      <AppFooter>
        <Container sx={{ height: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            gap={3}
            sx={{ height: "100%" }}
          >
            <Box flex="4" display="flex" justifyContent="space-between" pr={2}>
              <Button
                variant="contained"
                startIcon={<BackIcon />}
                onClick={handleBack}
                color="secondary"
                disabled={activeStep === 0 || formError.length > 0}
              >
                {DICTIONARY.createTour.buttons.back}
              </Button>

              {activeStep < sectionsArr.length - 1 && (
                <Button
                  variant="contained"
                  endIcon={<NextIcon />}
                  onClick={handleNext}
                  color="secondary"
                  disabled={formError.length > 0}
                >
                  {DICTIONARY.createTour.buttons.next}
                </Button>
              )}

              {/* Submit button */}
              {activeStep === sectionsArr.length - 1 && (
                <LoadingButton
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  loading={isSubmitLoading}
                  loadingPosition="start"
                  color="success"
                  disabled={isSubmitLoading || formError.length > 0}
                >
                  {DICTIONARY.createTour.buttons.save}
                </LoadingButton>
              )}
            </Box>
            {/* Dummy width  */}
            <Box
              flex="1"
              sx={{
                minWidth: "300px",
              }}
            ></Box>
          </Box>
        </Container>
      </AppFooter>
    </React.Fragment>
  );
}

export default CreateTour;
