import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";

// Mui
import { Box, TextField, FormLabel, InputAdornment } from "@mui/material";

// App components
import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ImageUploader from "../../inputs/ImageUploader";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import ChipsInput from "../../inputs/ChipsInput";
import AutocompleteSelectCountry from "../../inputs/AutocompleteSelectCountry";
import AutocompleteSelectLanguage from "../../inputs/AutocompleteSelectLanguage";

// Icons
import {
  Preview as PreviewIcon,
  Email as EmailIcon,
  PhoneIphone as PhoneIcon,
} from "@mui/icons-material";

interface Props {
  entityData: any;
  entityId: string;
  clearFormError: () => void;
  onPreviewIntroScreen: (response: any) => void;
  isEditMode: boolean;
}

const GuideDetails = forwardRef(
  (
    {
      entityData,
      entityId,
      clearFormError,
      onPreviewIntroScreen,
      isEditMode,
    }: Props,
    ref
  ) => {
    // #region States
    const initalFormState = {
      name: {
        label: DICTIONARY.createGuide.fields.name.label,
        info: DICTIONARY.createGuide.fields.name.info,
        value: entityData.name ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) =>
              VALIDATORS.string.isLengthInRange(val, 2, 40),
            errorMessage: DICTIONARY.createGuide.fields.name.error.length,
          },
        ],
      },
      email: {
        label: DICTIONARY.createGuide.fields.email.label,
        info: DICTIONARY.createGuide.fields.email.info,
        value: entityData.email ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) => VALIDATORS.email.isValid(val),
            errorMessage:
              DICTIONARY.createBusinessPartner.fields.email.error
                .badlyFormattedEmail,
          },
        ],
      },
      phone: {
        label: DICTIONARY.createGuide.fields.phone.label,
        info: DICTIONARY.createGuide.fields.phone.info,
        value: entityData.phone ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      avatar: {
        label: DICTIONARY.createGuide.fields.avatar.label,
        info: DICTIONARY.general.filesUpload.imageUploadInstructions,
        value: entityData.avatar ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (value: any) => {
              // Check if the value matches the value from the DB. if true --> the avatar wasnt change and its valid
              const coverImageDB = entityData.avatar;

              return (
                coverImageDB === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "image")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.imageType,
          },
        ],
      },
      description: {
        label: DICTIONARY.createGuide.fields.description.label,
        info: DICTIONARY.createGuide.fields.description.info,
        value: HELPERS.richText.getRichTextFromDb(entityData.description),
        isValid: true,
        required: false,
        validators: [],
      },
      countries: {
        label: DICTIONARY.createGuide.fields.countries.label,
        info: DICTIONARY.createGuide.fields.countries.info,
        value: entityData.countries ?? [],
        isValid: true,
        required: true,
        validators: [],
      },
      // nationality: {
      //   label: DICTIONARY.createGuide.fields.nationality.label,
      //   info: DICTIONARY.createGuide.fields.nationality.info,
      //   value: entityData.nationality ?? [],
      //   isValid: true,
      //   required: true,
      //   validators: [],
      // },
      spokenLanguages: {
        label: DICTIONARY.createGuide.fields.spokenLanguages.label,
        info: DICTIONARY.createGuide.fields.spokenLanguages.info,
        value: entityData.spokenLanguages ?? [],
        isValid: true,
        required: true,
        validators: [],
      },
      tags: {
        label: DICTIONARY.createGuide.fields.tags.label,
        info: DICTIONARY.createGuide.fields.tags.info,
        value: entityData.tags ?? [],
        isValid: true,
        required: false,
        validators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips":
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleGuidePagePreview = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        onPreviewIntroScreen(response);
        return;
      }

      const parsedData = prepareDataToSave();

      onPreviewIntroScreen({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createGuide.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let fileUrl: string = "";
      let files: any = [];

      // New file
      if (
        VALIDATORS.filesUpload.isValidDataFile(formState.avatar.value, "image")
      ) {
        files.push({
          type: "image",
          file: formState.avatar.value,
          fileId: entityId ? entityId : "",
          suffix: "avatar",
        });
        fileUrl = URL.createObjectURL(formState.avatar.value);
      } else {
        // file hasn't changed - it has the url from firebase storgae
        fileUrl = formState.avatar.value;
      }

      const parsedData = {
        name: formState.name.value,
        email: formState.email.value,
        phone: formState.phone.value,
        description: HELPERS.richText.prepareRichTextToDbSave(
          formState.description.value
        ),
        avatar: fileUrl,
        countries: formState.countries.value,
        // nationality: formState.nationality.value,
        spokenLanguages: formState.spokenLanguages.value,
        tags: formState.tags.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createGuide.sections.details.label}
          actions={[
            {
              label: DICTIONARY.createGuide.buttons.introScreen,
              icon: <PreviewIcon />,
              onClick: handleGuidePagePreview,
            },
          ]}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 5,
          }}
        >
          {/* Name */}
          <Box sx={{ gridRow: "1", gridColumn: "1 / 10" }} mb={1}>
            <TextField
              label={formState.name.label}
              value={formState.name.value}
              id="name"
              name="name"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.name.required}
              error={!formState.name.isValid}
              fullWidth
              autoFocus
            />
          </Box>

          {/* Email */}
          <Box sx={{ gridRow: "2", gridColumn: "1 / 6" }} mb={1}>
            <TextField
              label={formState.email.label}
              value={formState.email.value}
              id="email"
              name="email"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.email.required}
              error={!formState.email.isValid}
              disabled={isEditMode}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Phone */}
          <Box sx={{ gridRow: "2", gridColumn: "6 / 10" }} mb={1}>
            <TextField
              label={formState.phone.label}
              value={formState.phone.value}
              id="phone"
              name="phone"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.phone.required}
              error={!formState.phone.isValid}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Countries of operation */}
          <Box sx={{ gridRow: "3", gridColumn: "1 / 10" }} mb={1}>
            <AutocompleteSelectCountry
              label={formState.countries.label}
              info={formState.countries.info}
              id="countries"
              value={formState.countries.value}
              error={!formState.countries.isValid}
              onInputChange={handleInputChange}
              required={formState.countries.required}
              multiple={true}
              shouldShowCode={false}
              shouldShowPhone={false}
            />
          </Box>

          {/* Spoken languages */}
          <Box sx={{ gridRow: "4", gridColumn: "1 / 10" }} mb={1}>
            <AutocompleteSelectLanguage
              label={formState.spokenLanguages.label}
              info={formState.spokenLanguages.info}
              id="spokenLanguages"
              value={formState.spokenLanguages.value}
              error={!formState.spokenLanguages.isValid}
              onInputChange={handleInputChange}
              required={formState.spokenLanguages.required}
              multiple={true}
            />
          </Box>

          {/* Avatar */}
          <Box
            sx={{
              gridRow: {
                xs: "5",
                sm: "1 / 4",
              },
              gridColumn: {
                xs: "span 12",
                sm: "10 / 13",
              },
            }}
          >
            <ImageUploader
              label={formState.avatar.label}
              info={formState.avatar.info}
              id="avatar"
              value={formState.avatar.value}
              error={!formState.avatar.isValid}
              onInputChange={handleInputChange}
              placeholderType="avatar"
              imageContainerStyle={{
                borderRadius: "50%",
                height: "150px",
                width: "150px",
                margin: "auto",
              }}
            />
          </Box>

          {/* Description */}
          <Box sx={{ gridRow: "5", gridColumn: "span 12" }} mb={1}>
            <FormLabel component="legend" sx={{ fontSize: 14 }}>
              {formState.description.label}
            </FormLabel>
            <RichTextEditor
              /*
            // @ts-ignore */
              editorState={formState.description.value}
              onEditorStateChange={(editorState: any) =>
                handleInputChange(editorState, { type: "richText" })
              }
            />
          </Box>

          {/* Tags */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <ChipsInput
              label={formState.tags.label}
              info={formState.tags.info}
              id="tags"
              value={formState.tags.value}
              error={!formState.tags.isValid}
              onInputChange={handleInputChange}
              placeholder="Tag"
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default GuideDetails;
