//#region validations
const tourDetailsValidation = {
  handleEmptyFields: (formState: any, prefix?: string | number) => {
    let emptyFields: string[] = [];

    for (const field in formState) {
      const value = formState[field].value;
      const isRequired = formState[field].required;

      if (
        isRequired &&
        (value == null || (value !== undefined && value.length === 0))
      ) {
        formState[field].isValid = false;
        let error = prefix
          ? `${prefix}.${formState[field].label}`
          : formState[field].label;
        emptyFields.push(error);
      }
    }

    return {
      formState,
      emptyFields,
    };
  },
  handleInvalidFields: (formState: any, prefix?: string | number) => {
    for (const field in formState) {
      const validators = formState[field].validators;
      if (validators.length === 0) {
        continue;
      }

      // loop over each validator function of the field
      for (const validator of validators) {
        const validateCallback = validator.validate;
        if (
          typeof validateCallback === "function" &&
          !validateCallback(formState[field].value, formState)
        ) {
          formState[field].isValid = false;
          let formError =
            typeof validator.errorMessage === "function"
              ? validator.errorMessage(formState[field].value)
              : validator.errorMessage;
          formError = prefix ? `${prefix}.${formError}` : formError;

          return {
            formState,
            formError,
          };
        }
      }
    }

    return true;
  },
};

const tourPointsValidation = {
  handleEmptyFields: (tourPoints: any, shouldShowPointIndex: boolean) => {
    let emptyFields: string[] = [];

    for (let i = 0; i < tourPoints.length; i++) {
      let tourPoint = tourPoints[i];

      for (const field in tourPoint) {
        const value = tourPoint[field].value;
        const isRequired = tourPoint[field].required;

        if (
          isRequired &&
          (value == null || (value !== undefined && value.length === 0))
        ) {
          tourPoints[i][field].isValid = false;
          let error = shouldShowPointIndex
            ? `${i + 1}.${tourPoint[field].label}`
            : tourPoint[field].label;
          emptyFields.push(error);
        }
      }
    }

    return {
      tourPoints,
      emptyFields,
    };
  },
  handleInvalidFields: (tourPoints: any, shouldShowPointIndex: boolean) => {
    for (let i = 0; i < tourPoints.length; i++) {
      let tourPoint = tourPoints[i];

      for (const field in tourPoint) {
        const validators = tourPoint[field].validators;
        if (validators === undefined || validators.length === 0) {
          continue;
        }

        // loop over each validator function of the field
        for (let key of Object.keys(validators)) {
          const validator = validators[key];
          const validateCallback = validator.validate;

          if (
            typeof validateCallback === "function" &&
            !validateCallback(tourPoint[field].value, tourPoint)
          ) {
            tourPoints[i][field].isValid = false;
            let formError =
              typeof validator.errorMessage === "function"
                ? validator.errorMessage(tourPoint[field].value)
                : validator.errorMessage;
            formError = shouldShowPointIndex
              ? `${i + 1}.${formError}`
              : formError;

            return {
              tourPoints,
              formError,
            };
          }
        }
      }
    }

    return true;
  },
};
//#endregion

//#region Error message
const createEmptyFormFieldErrorMessage = (invalidFields: string[]) => {
  let commaSepareted = invalidFields.join(", ");
  var indexOfLastComma = commaSepareted.lastIndexOf(",");
  commaSepareted =
    indexOfLastComma > -1
      ? commaSepareted.substring(0, indexOfLastComma) +
        " &" +
        commaSepareted.substring(indexOfLastComma + 1)
      : commaSepareted;
  const formError = `Please fill ${commaSepareted} ${
    invalidFields.length === 1 ? "field" : "fields"
  }.`;

  return formError;
};
//#endregion

const exported = {
  tourDetailsValidation,
  tourPointsValidation,
  createEmptyFormFieldErrorMessage,
};

export default exported;
