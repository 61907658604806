import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, HELPERS, ROUTES } from "../../utils";
import { useAuthContext, useSidebarContext } from "../../context";
import { drawerWidth } from "../../consts";

// Firebase
import { auth } from "../../config/firebase-config";
import { signOut } from "firebase/auth";

// Mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  Divider,
  ListItemIcon,
  Box,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

// Icons
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";

// Images
import Logo from "../../assets/images/logo/logo-back-office.svg";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  isMobileScreen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== "open" && prop !== "isMobileScreen";
  },
})<AppBarProps>(({ theme, open, isMobileScreen }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    !isMobileScreen && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

export default function AppHeader() {
  const history = useHistory();
  const { user } = useAuthContext();
  const { isSidebarOpen, handleDrawerOpen, isMobileScreen } =
    useSidebarContext();

  //#region Logo
  const onLogoClick = () => {
    history.push(ROUTES.root.path);
  };
  //#endregion

  //#region Account Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //#endregion

  //#region Logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        HELPERS.localhost.isVerbose() && console.error(error);
      });
  };
  //#endregion

  return (
    <AppBar
      // position="fixed"
      open={isSidebarOpen}
      isMobileScreen={isMobileScreen}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 1,
              ...(isSidebarOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            src={Logo}
            alt="Tour Guide - Back Office"
            onClick={onLogoClick}
            sx={{
              height: "34px",
              cursor: "pointer",
            }}
          />
        </Box>

        <div>
          <IconButton
            // size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenuClick}
            color="inherit"
          >
            {/* <AccountCircleIcon /> */}
            <img
              src={user.data.avatar}
              alt={user.data.name}
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              sx: {
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              {DICTIONARY.general.appHeader.logout}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
