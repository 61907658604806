const cloneObject = (obj: any) => {
  let clone: any = {};

  for (let i in obj) {
    if (obj[i] != null && typeof obj[i] == "object")
      clone[i] = cloneObject(obj[i]);
    else clone[i] = obj[i];
  }

  return clone;
};

export default {
  cloneObject,
};
