import { Fragment, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { DICTIONARY } from "../../utils";
import { tourApi } from "../../api";

// App components
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";
import AutocompleteSelectTours from "../../components/inputs/AutocompleteSelectTours";
import OutdoorGameFeedbacks from "../../components/outdoorGame/analytics/OutdoorGameFeedbacks";

// Charts
import TourEntrancesChart from "../../components/charts/TourEntrancesChart";
import GeolocationPermissionBlockedLogByDateChart from "../../components/charts/GeolocationPermissionBlockedLogByDateChart";
import GeolocationPermissionBlockedLogByDeviceChart from "../../components/charts/GeolocationPermissionBlockedLogByDeviceChart";
import OutdoorGameFinishedLogChart from "../../components/charts/OutdoorGameFinishedLogChart";

// Mui
import { Container, Paper, Box, Typography, FormLabel } from "@mui/material";

function ToursAnalytics() {
  const { search } = useLocation();
  const history = useHistory();

  //#region Init
  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search);
      const id = query.get("id");
      const name = query.get("name");

      if (id && name) {
        setFormState((prevState: any) => {
          return {
            ...prevState,
            tour: {
              ...prevState.tour,
              value: {
                id,
                name,
              },
              isValid: true,
            },
          };
        });
      }
    }
  }, [search]);
  //#endregion

  //#region Select tour
  const initalFormState = {
    tour: {
      label: DICTIONARY.toursAnalytics.form.fields.tour.label,
      info: DICTIONARY.toursAnalytics.form.fields.tour.info,
      value: null,
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
  };

  const [formState, setFormState] = useState(initalFormState);

  const handleAutocompleteChange = async (event: any, payload?: any) => {
    const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
    let key: string = "";
    let val: any;

    switch (type) {
      case "autocomplete": {
        const { id, value } = payload;
        key = id;
        val = value;
        break;
      }
      default: {
        break;
      }
    }

    setFormState((prevState: any) => {
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
          isValid: true,
        },
      };
    });

    // Update query param
    if (val && val.hasOwnProperty("id") && val.hasOwnProperty("name")) {
      history.replace({ search: `?id=${val.id}&name=${val.name}` });
    } else {
      history.replace({ search: "" });
    }
  };
  //#endregion

  //#region Get data
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tourEntrancesData, setTourEntrancesData] = useState<any>({});
  const [counter_firstTimeEntrances, setCounter_firstTimeEntrances] = useState<
    number | null
  >(null);

  // const fetchTourEntrances = async (tourId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const query = {
  //       field: "tourId",
  //       operator: "==",
  //       value: tourId,
  //     };
  //     const response = await tourApi.entrance.getAll(query);

  //     if (response.hasOwnProperty("error")) {
  //       setIsLoading(false);
  //       setError(DICTIONARY.general.error);
  //       return;
  //     }

  //     if (response.length === 0) {
  //       setIsLoading(false);
  //       setError(DICTIONARY.general.chart.errors.noData);
  //       return;
  //     }

  //     // Success
  //     // const parsedData = parseData(response);
  //     // setData(parsedData);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     setError(DICTIONARY.general.error);
  //   }
  // };

  // const fetchTourMetadata = async (tourId: string) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await tourApi.metadata.get(tourId);

  //     if (response.hasOwnProperty("error")) {
  //       setIsLoading(false);
  //       setError(DICTIONARY.general.error);
  //       return;
  //     }

  //     if (response.length === 0) {
  //       setIsLoading(false);
  //       setError(DICTIONARY.general.chart.errors.noData);
  //       return;
  //     }

  //     // Success
  //     setCounter_firstTimeEntrances(response.counter_firstTimeEntrances);
  //     // const parsedData = parseData(response);
  //     // setData(parsedData);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     setError(DICTIONARY.general.error);
  //   }
  // };
  //#endregion

  return (
    <Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.toursAnalytics.title}
              </Typography>
            </Box>
          </Box>

          {/* Content */}
          <Paper sx={{ mt: 3, px: 2, py: 1.5 }}>
            {/* Select tour */}
            <Box sx={{ width: 1, mb: 2 }}>
              <FormLabel
                component="label"
                sx={{
                  display: "block",
                  fontSize: (theme) => theme.typography.pxToRem(14),
                  mb: 1,
                }}
              >
                {DICTIONARY.toursAnalytics.selectTour}
              </FormLabel>
              <AutocompleteSelectTours
                label={formState.tour.label}
                info={formState.tour.info}
                id="tour"
                value={formState.tour.value}
                error={false}
                onChange={handleAutocompleteChange}
                multiple={false}
              />
            </Box>
          </Paper>

          {isLoading ? (
            <Loader py={0} />
          ) : error !== "" ? (
            <EmptyState title={error} py={10} />
          ) : (
            <Box sx={{}}>
              {/* Tour entrances chart */}
              <TourEntrancesChart tour={formState?.tour?.value} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <GeolocationPermissionBlockedLogByDateChart
                  tour={formState?.tour?.value}
                />
                <GeolocationPermissionBlockedLogByDeviceChart
                  tour={formState?.tour?.value}
                />
              </Box>

              <Box sx={{ my: 2 }}>
                <OutdoorGameFinishedLogChart tour={formState?.tour?.value} />

                <OutdoorGameFeedbacks tour={formState?.tour?.value} />
              </Box>

              {/* counter_firstTimeEntrances: {counter_firstTimeEntrances} */}
            </Box>
          )}
        </Box>
      </Container>
    </Fragment>
  );
}

export default ToursAnalytics;
