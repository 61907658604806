import { Fragment, useState, useEffect } from "react";
import { DICTIONARY, HELPERS } from "../../../utils";
import { outdoorGameApi } from "../../../api";
import { parse } from "date-fns";

// App components
import Loader from "../../loader/Loader";
import EmptyState from "../../shared/EmptyState";

// Mui
import {
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

const CHART_HEIGHT = 400;

type Props = {
  tour: any;
};

export default function OutdoorGameFeedbacks({ tour }: Props) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [avgRating, setAvgRating] = useState<string>("-");
  const [generalComments, setGeneralComments] = useState<any[]>([]);

  //#region Init
  const fetchData = async (tourId: string) => {
    try {
      setIsLoading(true);
      const query = {
        field: "tourId",
        operator: "==",
        value: tourId,
      };

      const response = await outdoorGameApi.feedback.getAll(query);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const { avgRating, generalComments } = parseData(response);
      setAvgRating(avgRating);
      setGeneralComments(generalComments);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    if (tour && "id" in tour) {
      setError("");
      (async () => {
        fetchData(tour.id);
      })();
    } else {
      setError(DICTIONARY.general.chart.errors.selectTour);
    }
  }, [tour]);
  //#endregion

  //#region Chart data
  const parseData = (rawData: any) => {
    try {
      let avgRating: string = "-";
      let generalComments: any[] = [];

      if (rawData && rawData.length > 0) {
        let totalRating: number = 0;
        rawData.forEach((feedback: any) => {
          const { overallRating, generalComment, timestamp } = feedback;

          totalRating += overallRating;

          const date = HELPERS.date.formatDate(
            timestamp.toDate(),
            "dd/MM/yy HH:mm"
          );
          generalComments.push({
            date,
            comment: generalComment ? generalComment : "-",
            overallRating,
          });
        });

        avgRating = (totalRating / rawData.length).toFixed(1);
      }

      generalComments = sortEntities(generalComments, "date", {
        type: "timestamp",
        format: "dd/MM/yy HH:mm",
      });

      return {
        avgRating,
        generalComments,
      };
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <Paper
      id="chart-container"
      sx={{ mt: 3, p: 2, display: "flex", flexDirection: "column" }}
    >
      <Box
        id="chart-header-wrapper"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component="h3" sx={{ fontSize: 18 }}>
          Outdoor game feedbacks (AVG rank: {avgRating})
        </Typography>
      </Box>

      {/* Content */}
      <Box
        id="chart-content-wrapper"
        sx={{ flex: 1, display: "flex", justifyContent: "center" }}
      >
        {isLoading ? (
          <Box sx={{ mt: -4 }}>
            <Loader />
          </Box>
        ) : error !== "" ? (
          <Box sx={{}}>
            <EmptyState title={error} icon="tour" py={15} />
          </Box>
        ) : (
          <List sx={{ width: "100%", bgcolor: "background.paper" }} dir="auto">
            {generalComments.length > 0 &&
              generalComments.map((item: any, index: number) => (
                <Fragment key={index}>
                  <ListItem alignItems="flex-start" dir="auto">
                    <ListItemText
                      dir="auto"
                      primary={item.comment}
                      secondaryTypographyProps={{
                        component: "div",
                      }}
                      secondary={
                        <Fragment>
                          <Box sx={{ display: "block" }}>
                            <Typography
                              sx={{ display: "inline", fontSize: 13, mr: 1 }}
                              color="text.secondary"
                            >
                              Rank:
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: 13,
                                color: "black",
                              }}
                            >
                              {item.overallRating}
                            </Typography>
                          </Box>

                          <Box sx={{ display: "block" }}>
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: 13,
                                mr: 1,
                              }}
                              color="text.secondary"
                            >
                              Date:
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                fontSize: 13,
                                color: "black",
                              }}
                            >
                              {item.date}
                            </Typography>
                          </Box>
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" sx={{ ml: 1 }} />
                </Fragment>
              ))}
          </List>
        )}
      </Box>
    </Paper>
  );
}

//#region Helpers
const sortEntities = (tours: any, key: string, options?: any) => {
  function callback(a: any, b: any) {
    // timestamp
    if (options && options.type === "timestamp") {
      a = parse(a[key], options.format, new Date());
      b = parse(b[key], options.format, new Date());

      return b - a;
    }

    // Other (number, string, ..)
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
