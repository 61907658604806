// Map style images
import streets from "../assets/images/mapStyles/streets.png";
import monochrome from "../assets/images/mapStyles/monochrome.png";
import basic from "../assets/images/mapStyles/basic.png";
import outdoors from "../assets/images/mapStyles/outdoors.png";
import satelliteStreets from "../assets/images/mapStyles/satelliteStreets.jpg";
import blank from "../assets/images/mapStyles/blank.jpg";
import custom from "../assets/images/mapStyles/custom.png";

// Icons
import {
  LocalParking,
  Wc,
  DirectionsCar,
  AccessTime,
  HourglassEmpty,
  PlayCircle,
  Loop,
  AttachMoney,
  CreditCard,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  ThumbUpAlt,
  ThumbDownAlt,
  Flag,
  SportsSoccer,
  SportsBasketball,
  SportsTennis,
  FamilyRestroom,
  Male,
  Female,
  ImportContacts,
} from "@mui/icons-material";

export const MAP_STYLES: any = {
  streets: {
    label: "Streets",
    value: "streets",
    image: streets,
    url: "mapbox://styles/idopostelnik/ckut4syw8133917ql91zmqd6n",
  },
  basic: {
    label: "Basic",
    value: "basic",
    image: basic,
    url: "mapbox://styles/idopostelnik/ckts5to8o1s1v17s5pxi6kg7j",
  },
  outdoors: {
    label: "Outdoors",
    value: "outdoors",
    image: outdoors,
    url: "mapbox://styles/idopostelnik/ckuhghxps2p9g18lho1e7c3gj",
  },
  satelliteStreets: {
    label: "Satellite Streets",
    value: "satelliteStreets",
    image: satelliteStreets,
    url: "mapbox://styles/idopostelnik/ckuhgozib10ao19mwisjp7bdf",
  },
  monochrome: {
    label: "Monochrome",
    value: "monochrome",
    image: monochrome,
    url: "mapbox://styles/idopostelnik/ckut4ts2f2bps18mz6cdvs109",
  },
  blank: {
    label: "Blank",
    value: "blank",
    image: blank,
    url: "mapbox://styles/idopostelnik/ckuhgpa6k1qcq17p4evfsezv3",
  },
  custom: {
    label: "Custom",
    value: "custom",
    image: custom,
    url: "mapbox://styles/idopostelnik/ckuhgpa6k1qcq17p4evfsezv3",
  },
};

export const TOUR_GUIDING_COLORS: any = {
  green: {
    label: "Green",
    value: "green",
    hex: "#4caf50",
  },
  teal: {
    label: "Teal",
    value: "teal",
    hex: "#26a69a",
  },
  blue: {
    label: "Blue",
    value: "blue",
    hex: "#2196f3",
  },
  cyan: {
    label: "Cyan",
    value: "cyan",
    hex: "#00bcd4",
  },
  yellow: {
    label: "Yellow",
    value: "yellow",
    hex: "#ffee58",
  },
  orange: {
    label: "Orange",
    value: "orange",
    hex: "#ff9800",
  },
  red: {
    label: "Red",
    value: "red",
    hex: "#f44336",
  },
  pink: {
    label: "Pink",
    value: "pink",
    hex: "#e91e63",
  },
  purple: {
    label: "Purple",
    value: "purple",
    hex: "#9c27b0",
  },
  black: {
    label: "Black",
    value: "black",
    hex: "#000",
  },
  white: {
    label: "White",
    value: "white",
    hex: "#fff",
  },
};

export const GEOLOCATION_MODE: any = {
  default: {
    label: "Default",
    value: "default",
  },
  background: {
    label: "Run in background",
    value: "background",
  },
  disable: {
    label: "Disable",
    value: "disable",
  },
};

export const DISPLAY_TOUR_POINTS_ON_MAP: any = {
  display: {
    label: "Display",
    value: "display",
  },
  hide: {
    label: "Hide",
    value: "hide",
  },
};

export const ROUTE_DIRECTION_ARROW_OPTIONS: any = {
  none: {
    label: "None",
    value: "none",
  },
  straight: {
    label: "Straight",
    value: 0,
  },
  reverse: {
    label: "Reverse",
    value: 180,
  },
};

export const AUTO_ACTIVATE_TOUR_POINT: any = {
  yes: {
    label: "Yes",
    value: true,
  },
  no: {
    label: "No",
    value: false,
  },
};

export const TOUR_POINT_CONTENT_DISPLAY_OPTIONS: any = {
  drawer: {
    label: "Drawer",
    value: "drawer",
  },
  fullscreen: {
    label: "Full screen",
    value: "fullscreen",
  },
};

export const TOUR_POINT_CONTENT_TYPES: any = {
  none: {
    label: "None",
    value: "none",
  },
  audio: {
    label: "Audio",
    value: "audio",
  },
  video: {
    label: "Video",
    value: "video",
  },
  externalWebpage: {
    label: "External web page",
    value: "externalWebpage",
  },
  question: {
    label: "Question",
    value: "question",
  },
  orientation: {
    label: "Orientation",
    value: "orientation",
  },
};

export const QUESTIONS_TYPES: any = {
  multiple: {
    label: "Multiple choice",
    value: "multiple",
  },
  shortAnswer: {
    label: "Short answer",
    value: "shortAnswer",
  },
  displayAllAnswers: {
    label: "Display answers",
    value: "displayAllAnswers",
  },
};

export const ORIENTATION_TYPES: any = {
  point: {
    label: "Simple discovery point ",
    value: "point",
  },
  multipleQuestionPoint: {
    label: "Point + Multiple choice",
    value: "multipleQuestionPoint",
  },
  shortAnswerPoint: {
    label: "Point + Short answer",
    value: "shortAnswerPoint",
  },
  summary: {
    label: "Summary",
    value: "summary",
  },
};

export const SHOULD_DISPLAY_ANSWER_FEEDBACK: any = {
  yes: {
    label: "Yes",
    value: "yes",
  },
  no: {
    label: "No",
    value: "no",
  },
};

export const TOUR_GUIDING_ICONS: any = {
  // Icons are based on Material-UI icons
  parking: {
    label: "Parking",
    value: "parking",
    component: LocalParking,
  },
  restroom: {
    label: "Restroom",
    value: "restroom",
    component: Wc,
  },
  book: {
    label: "Book",
    value: "book",
    component: ImportContacts,
  },
  car: {
    label: "Car",
    value: "car",
    component: DirectionsCar,
  },
  time: {
    label: "Time",
    value: "time",
    component: AccessTime,
  },
  hourGlass: {
    label: "Hour glass",
    value: "hourGlass",
    component: HourglassEmpty,
  },
  play: {
    label: "Play",
    value: "play",
    component: PlayCircle,
  },
  loop: {
    label: "Loop",
    value: "loop",
    component: Loop,
  },
  money: {
    label: "Money",
    value: "money",
    component: AttachMoney,
  },
  creditCard: {
    label: "Credit card",
    value: "creditCard",
    component: CreditCard,
  },
  smile: {
    label: "Smile",
    value: "smile",
    component: SentimentSatisfiedAlt,
  },
  sad: {
    label: "Sad",
    value: "sad",
    component: SentimentVeryDissatisfied,
  },
  good: {
    label: "Good",
    value: "good",
    component: ThumbUpAlt,
  },
  bad: {
    label: "Bad",
    value: "bad",
    component: ThumbDownAlt,
  },
  flag: {
    label: "Flag",
    value: "flag",
    component: Flag,
  },
  soccerBall: {
    label: "Soccer ball",
    value: "soccerBall",
    component: SportsSoccer,
  },
  basketBall: {
    label: "Basket ball",
    value: "basketBall",
    component: SportsBasketball,
  },
  tennis: {
    label: "Tennis",
    value: "tennis",
    component: SportsTennis,
  },
  family: {
    label: "Family",
    value: "family",
    component: FamilyRestroom,
  },
  male: {
    label: "Male",
    value: "male",
    component: Male,
  },
  female: {
    label: "Female",
    value: "female",
    component: Female,
  },
};
