export const PUBLISH_STATUS: any = {
  draft: {
    label: "Draft",
    value: "draft",
  },
  public: {
    label: "Public",
    value: "public",
  },
};
