import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DICTIONARY, VALIDATORS, ROUTES, HELPERS } from "../../utils";
import { SNACKBAR_SEVERITY } from "../../consts";
import { useSnackbarContext } from "../../context";

// Firebase
import { auth } from "../../config/firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";

// Mui
import {
  Grid,
  Box,
  Paper,
  Container,
  Typography,
  Alert,
  Button,
  TextField,
  Link,
  Divider,
  AlertColor,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Icons
import {
  LockOpen as LockOpenIcon,
  Google as GoogleIcon,
  Security as SecurityIcon,
} from "@mui/icons-material";

// Images
import Logo from "../../assets/images/logo/logo-back-office-login.svg";

export default function Login() {
  const { handleSnackbarDisplay } = useSnackbarContext();
  const mounted = useRef(false);
  const userRef = useRef({});

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  //#region Identification
  const [identificationCode, setIdentificationCode] = useState<string>("");
  const [identificationCodeErrorMessage, setIdentificationCodeErrorMessage] =
    useState<string>("");
  const [isIdentificationCodeValid, setIsIdentificationCodeValid] =
    useState<boolean>(false);

  const handleIdentificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIdentificationCode(event.target.value);
    setIdentificationCodeErrorMessage("");
  };

  const handleSubmitIdentificationCode = () => {
    try {
      if (identificationCode === process.env.REACT_APP_IDENTIFICATION_CODE) {
        setIsIdentificationCodeValid(true);
      } else {
        setIdentificationCodeErrorMessage(
          DICTIONARY.auth.identification.form.error.invalid
        );
      }
    } catch (error) {
      setIdentificationCodeErrorMessage(
        DICTIONARY.auth.identification.form.error.generalError
      );
    }
  };
  //#endregion

  //#region Form
  const initalFormState = {
    email: {
      label: DICTIONARY.auth.form.field.email,
      value: "",
      isValid: true,
      required: true,
      validators: [
        {
          validate: (val: string) => VALIDATORS.email.isValid(val),
          errorMessage: DICTIONARY.general.form.badlyFormattedEmail,
        },
      ],
    },
    password: {
      label: DICTIONARY.auth.form.field.password,
      value: "",
      isValid: true,
      validators: [],
    },
  };

  const [formState, setFormState] = useState(initalFormState);
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [
    shouldShowEmailNotVerifiedMessage,
    setShouldShowEmailNotVerifiedMessage,
  ] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id: key, value: val } = event.target;

    setFormState((prevState: any) => {
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
          isValid: true,
        },
      };
    });
    setFormError("");
  };
  //#endregion

  //#region Submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const { email, password } = formState;
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
          // Signed in
          userRef.current = userCredential.user;

          if (!userCredential.user.emailVerified) {
            const severity = SNACKBAR_SEVERITY.warning as AlertColor;
            const message = DICTIONARY.auth.form.error.emailNotVerified;
            const autoHideDuration = 5000; // close automatically after 5 sec
            handleSnackbarDisplay(severity, message, autoHideDuration);

            mounted.current && setShouldShowEmailNotVerifiedMessage(true);
          }
        })
        .catch((error) => {
          let errorMessage;
          const errorCode = error.code;

          switch (errorCode) {
            case "auth/invalid-email":
              errorMessage =
                DICTIONARY.auth.form.error.incorrectEmailOrPassword; // badly formatted email
              break;
            case "auth/user-not-found":
              errorMessage =
                DICTIONARY.auth.form.error.incorrectEmailOrPassword; // email doesnt exist in the system
              break;
            case "auth/wrong-password":
              errorMessage =
                DICTIONARY.auth.form.error.incorrectEmailOrPassword;
              break;
            default:
              errorMessage =
                DICTIONARY.auth.form.error.incorrectEmailOrPassword;
              break;
          }
          HELPERS.localhost.isVerbose() &&
            console.error(`Login error: ${error.message}`);

          setFormError(errorMessage);
          setIsLoading(false);
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleContinueWithGoogle = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();
      HELPERS.auth.googleSignin();
    } catch (error) {}
  };
  //#endregion

  //#region Email verification
  const resendEmailVerification = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    try {
      event.preventDefault();
      const isSentSuccessfully = await HELPERS.auth.emailVerificationCallback(
        userRef.current
      );

      if (isSentSuccessfully) {
        setShouldShowEmailNotVerifiedMessage(false);
      }
    } catch (e) {
      setShouldShowEmailNotVerifiedMessage(false);
      // TODO: handle here error of 'too many requests'..
    }
  };
  //#endregion

  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            style={{ height: "120px" }}
            alt="Tour Guide - Back Office"
          />

          {/* Step 1 - Identification*/}
          {isIdentificationCodeValid === false && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                component="h1"
                sx={{
                  marginTop: 2,
                  fontSize: 20,
                }}
              >
                {DICTIONARY.auth.identification.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                  width: 1,
                }}
              >
                {/* Identification Code */}
                <TextField
                  label={DICTIONARY.auth.identification.form.field.code}
                  value={identificationCode}
                  id="identification"
                  name="identification"
                  onChange={handleIdentificationCodeChange}
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  color="secondary"
                />

                <LoadingButton
                  onClick={handleSubmitIdentificationCode}
                  loading={isLoading}
                  loadingPosition="start"
                  startIcon={<SecurityIcon />}
                  variant="contained"
                  disabled={isLoading || formError.length > 0}
                  color="secondary"
                  sx={{
                    px: 3,
                  }}
                >
                  {DICTIONARY.auth.identification.button.submit}
                </LoadingButton>
              </Box>

              {/* identification Code Error Message */}
              {identificationCodeErrorMessage && (
                <Box sx={{ my: 1, width: 1 }}>
                  <Alert severity="error">
                    {identificationCodeErrorMessage}
                  </Alert>
                </Box>
              )}
            </Box>
          )}

          {/* Step 2 - Login */}
          {isIdentificationCodeValid === true && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                sx={{
                  marginTop: 2,
                  fontSize: 20,
                }}
              >
                {DICTIONARY.auth.title.login}
              </Typography>

              {/* Form */}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {/* Email */}
                <TextField
                  label={formState.email.label}
                  value={formState.email.value}
                  id="email"
                  name="email"
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  autoComplete="email"
                />

                {/* Password */}
                <TextField
                  label={formState.password.label}
                  value={formState.password.value}
                  id="password"
                  name="password"
                  onChange={handleInputChange}
                  margin="dense"
                  required
                  fullWidth
                  type="password"
                  size="small"
                  autoComplete="current-password"
                />

                {/* Submit button */}
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ mt: 1, mb: 2 }}
                >
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<LockOpenIcon />}
                    variant="contained"
                    disabled={isLoading || formError.length > 0}
                  >
                    {DICTIONARY.auth.button.login}
                  </LoadingButton>
                </Box>

                {/* Error message */}
                {formError && (
                  <Box sx={{ mb: 2 }}>
                    <Alert severity="error">{formError}</Alert>
                  </Box>
                )}

                {/* Email not verified message */}
                {shouldShowEmailNotVerifiedMessage && (
                  <Box sx={{ mb: 2 }}>
                    <Alert
                      severity="warning"
                      action={
                        <Button size="small" onClick={resendEmailVerification}>
                          {DICTIONARY.auth.form.error.resendEmailVerification}
                        </Button>
                      }
                    >
                      {DICTIONARY.auth.form.error.emailNotVerified}
                    </Alert>
                  </Box>
                )}
              </Box>
              {/* End - Form */}

              {/* Forgot Password line */}
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                {/* <Grid item>
              <Link
                component={RouterLink}
                to={ROUTES.signup.path}
                variant="body2"
              >
                {DICTIONARY.auth.button.dontHaveAccount}
                <span> {DICTIONARY.auth.button.signup}</span>
              </Link>
            </Grid> */}
              </Grid>

              {/* Google auth */}
              <Divider sx={{ width: "100%", my: 2 }}>Or</Divider>

              <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleContinueWithGoogle}
              >
                {DICTIONARY.auth.button.google}
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
