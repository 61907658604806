import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";

// Mui
import { Box } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import UserCredsDetails from "../../user/UserCredsDetails";

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode?: boolean;
  entityId?: string;
}

const Summary = forwardRef(
  ({ entityData, clearFormError, isEditMode, entityId }: Props, ref) => {
    // #region States
    const initalFormState = {};

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "radio": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    //#endregion

    //#region Submit and Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };

    const prepareDataToSave = () => {
      let files: any = [];

      let parsedData = {};

      // Send password only in Create mode
      if (!isEditMode) {
        parsedData = {
          password: password,
        };
      }

      return {
        data: parsedData,
        files: files,
      };
    };

    const onSubmit = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onSubmit,
      onBack,
    }));
    //#endregion

    // #region Password
    const [password, setPassword] = useState<string | null>(
      !isEditMode ? HELPERS.auth.createNewUserPassword() : null
    );
    // #endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader title={DICTIONARY.createTour.sections.summary.label} />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 2,
          }}
        >
          <Box sx={{ gridRow: "1", gridColumn: "1 / 6" }} mb={1}>
            <UserCredsDetails
              name={entityData.name}
              email={entityData.email}
              id={entityId ? entityId : null}
              password={isEditMode ? null : password}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Summary;
