import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";

// Mui
import {
  Box,
  Typography,
  Alert,
  TextField,
  Divider,
  IconButton,
} from "@mui/material";

// App components
import EmptyState from "../../shared/EmptyState";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import SelectColor from "../../inputs/SelectColor";
import SelectIcon from "../../inputs/SelectIcon";
import TextFieldWithInfo from "../../inputs/TextFieldWithInfo";

// Icons
import {
  Delete as DeleteIcon,
  AddLocation as AddTourPointIcon,
  Preview as PreviewIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
  onPreviewTourPoint: (response: any) => void;
  onDeleteTourPoint: (response: any) => void;
}

const SymbolPoints = forwardRef(
  (
    {
      tour,
      tourId,
      clearFormError,
      onPreviewTourPoint,
      onDeleteTourPoint,
    }: Props,
    ref
  ) => {
    // #region States
    const containerRef = useRef<any>(null);

    const symbolPointsTemplate = {
      icon: {
        label: DICTIONARY.createTour.fields.symbolPoints.icon.label,
        info: DICTIONARY.createTour.fields.symbolPoints.icon.info,
        value: "",
        isValid: true,
        required: true,
        validators: [],
      },
      iconColor: {
        label: DICTIONARY.createTour.fields.symbolPoints.iconColor.label,
        info: DICTIONARY.createTour.fields.symbolPoints.iconColor.info,
        value: "",
        isValid: true,
        required: true,
        validators: [],
      },
      label: {
        label: DICTIONARY.createTour.fields.symbolPoints.label.label,
        info: DICTIONARY.createTour.fields.symbolPoints.label.info,
        value: "",
        isValid: true,
        required: false,
        validators: [],
      },
      coordinates: {
        label: DICTIONARY.createTour.fields.symbolPoints.coordinates.label,
        info: DICTIONARY.createTour.fields.symbolPoints.coordinates.info,
        value: "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) =>
              VALIDATORS.coordinates.isValidCoordinates(val),
            errorMessage:
              DICTIONARY.createTour.fields.symbolPoints.coordinates.error
                .invalid,
          },
        ],
      },
    };

    const [tourPointsState, setTourPointsState] = useState<any>([]);
    const [previewTourPointError, setPreviewTourPointError] =
      useState<string>("");
    const [previewTourPointErrorIndex, setPreviewTourPointErrorIndex] =
      useState<number>(-1);
    //#endregion

    // #region Load data
    useEffect(() => {
      // Edit mode - load tour 'tourPointsState' and 'previewTour'
      if (tour?.symbolPoints?.length > 0) {
        try {
          // Set tour points
          let tourPointsFromDB: any = [];

          tour.symbolPoints.forEach((item: any) => {
            let clonedSymbolPointsTemplate: any =
              HELPERS.general.cloneObject(symbolPointsTemplate);

            clonedSymbolPointsTemplate.icon.value = item.icon;
            clonedSymbolPointsTemplate.iconColor.value = item.iconColor;
            clonedSymbolPointsTemplate.label.value = item.label;
            clonedSymbolPointsTemplate.coordinates.value = item.coordinates;

            clonedSymbolPointsTemplate.uniqueId = item.uniqueId;
            tourPointsFromDB.push(clonedSymbolPointsTemplate);
          });

          setTourPointsState(tourPointsFromDB);
        } catch (error) {}
      }
    }, [tour]);
    //#endregion

    //#region Functionality
    const handleTourPointInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      const [uniqueId, field] = key.split("-");

      setTourPointsState((prevState: any) => {
        const indexOfPoint = prevState.findIndex(
          (item: any) => item.uniqueId === uniqueId
        );
        let newState = [...prevState];
        newState[indexOfPoint][field] = {
          ...newState[indexOfPoint][field],
          value: val,
          isValid: true,
        };

        return newState;
      });

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };

    const handleAddTourPoint = () => {
      let updatedTourPoints = [...tourPointsState];
      updatedTourPoints.push({
        uniqueId: HELPERS.firebase.generateId(),
        ...symbolPointsTemplate,
      });
      setTourPointsState(updatedTourPoints);

      setTimeout(() => {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          top: containerRef.current.offsetBottom,
        });
      }, 50);
    };

    const handleTourPointPreview = (uniqueId: string) => {
      const indexOfPointToValidate = tourPointsState.findIndex(
        (item: any) => item.uniqueId === uniqueId
      );

      if (indexOfPointToValidate === -1) {
        return;
      }

      const tourPointToValidate = {
        ...tourPointsState[indexOfPointToValidate],
      };

      const shouldShowPointIndex = false;
      const response = isTourPointValid(
        [tourPointToValidate],
        indexOfPointToValidate,
        tourPointsState,
        shouldShowPointIndex
      );

      if ("error" in response) {
        // Show error message for the spesific point
        setPreviewTourPointError(response.error);
        setPreviewTourPointErrorIndex(indexOfPointToValidate);
        return;
      }

      const parsedData = prepareDataToSave(tourPointsState);

      onPreviewTourPoint({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };

    const handleDeleteTourPoint = (uniqueId: string) => {
      let updatedTourPointsState = [...tourPointsState];
      const indexOfPoint = tourPointsState.findIndex(
        (item: any) => item.uniqueId === uniqueId
      );
      if (indexOfPoint === -1) {
        return;
      }

      updatedTourPointsState.splice(indexOfPoint, 1);

      // Update tour points state

      setTourPointsState(updatedTourPointsState);

      // Update preview tour
      const parsedData = prepareDataToSave(updatedTourPointsState);

      onDeleteTourPoint({
        ...parsedData, // data and files
      });

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };
    // #endregion

    //#region Validation
    const isFormValid = (tourPointsState: any) => {
      try {
        // Empty fields
        const shouldShowPointIndex = true;
        const { tourPoints: tourPointsResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourPointsValidation.handleEmptyFields(
            [...tourPointsState],
            shouldShowPointIndex
          );
        if (emptyFields.length > 0) {
          setTourPointsState(tourPointsResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          tourPoints: tourPointsResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourPointsValidation.handleInvalidFields(
          [...tourPointsState],
          shouldShowPointIndex
        );
        if (error) {
          setTourPointsState(tourPointsResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };

    const isTourPointValid = (
      tourPointToValidate: any,
      indexOfPointToValidate: number,
      originalTourPointsState: any,
      shouldShowPointIndex: boolean
    ) => {
      try {
        // Empty fields
        const { tourPoints: tourPointsResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourPointsValidation.handleEmptyFields(
            [...tourPointToValidate],
            shouldShowPointIndex
          );
        if (emptyFields.length > 0) {
          let updatedTourPointsState = [...originalTourPointsState];
          updatedTourPointsState[indexOfPointToValidate] =
            tourPointsResponseEmptyFields[0]; // Since we validate only 1 tour point --> it will always be the first in 'tourPointsResponseEmptyFields'
          setTourPointsState(updatedTourPointsState);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          tourPoints: tourPointsResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourPointsValidation.handleInvalidFields(
          [...tourPointToValidate],
          shouldShowPointIndex
        );
        if (error) {
          let updatedTourPointsState = [...originalTourPointsState];
          updatedTourPointsState[indexOfPointToValidate] =
            tourPointsResponseInvalidFields[0];
          setTourPointsState(updatedTourPointsState);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };
    // #endregion

    //#region Submit
    const prepareDataToSave = (tourPointsState: any) => {
      let parsedFormState: any = [];
      let files: any = [];

      tourPointsState.forEach((item: any) => {
        parsedFormState.push({
          uniqueId: item.uniqueId,
          icon: item.icon.value,
          iconColor: item.iconColor.value,
          label: item.label.value,
          coordinates: item.coordinates.value,
        });
      });

      return {
        data: {
          symbolPoints: parsedFormState,
        },
        files: files,
      };
    };

    const onNext = () => {
      // If no sympbol points were created at all
      if (tourPointsState.length === 0) {
        return {
          isValid: true,
          data: {
            symbolPoints: [],
          },
          files: [],
        };
      }

      // Validate fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave(tourPointsState);

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // If no sympbol points were created at all
      if (tourPointsState.length === 0) {
        return {
          isValid: true,
          data: {
            symbolPoints: [],
          },
          files: [],
        };
      }

      // Validate fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave(tourPointsState);

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createTour.sections.symbolPoints.label}
          actions={[
            {
              label: DICTIONARY.createTour.buttons.addSymbolPoint,
              icon: <AddTourPointIcon />,
              color: "primary",
              onClick: handleAddTourPoint,
            },
          ]}
        />

        {/* Content */}
        {tourPointsState.length > 0 ? (
          tourPointsState.map((item: any, index: number) => (
            <React.Fragment key={item.uniqueId}>
              <Box
                ref={containerRef}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(14, 1fr)",
                  gap: 1,
                  pt: 2,
                  pb: 4,
                }}
              >
                {/* Index */}
                <Box sx={{ gridRow: "1", gridColumn: "span 1" }}>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.5,
                      fontSize: 14,
                      height: "28px",
                      width: "28px",
                      lineHeight: "28px",
                      borderRadius: "50%",
                      border: `1px solid ${grey[400]}`,
                      textAlign: "center",
                    }}
                    component="h2"
                  >
                    {index + 1}
                  </Typography>
                </Box>

                {/* Icon */}
                <Box sx={{ gridRow: "1", gridColumn: "2 / 6" }}>
                  <SelectIcon
                    label={tourPointsState[index].icon.label}
                    value={tourPointsState[index].icon.value}
                    id={`${item.uniqueId}-icon`}
                    name={`${item.uniqueId}-icon`}
                    onChange={(event: any) =>
                      handleTourPointInputChange(event, { type: "select" })
                    }
                    required={tourPointsState[index].icon.required}
                    error={!tourPointsState[index].icon.isValid}
                  />
                </Box>

                <Box sx={{ gridRow: "1", gridColumn: "6 / 10" }}>
                  <SelectColor
                    label={tourPointsState[index].iconColor.label}
                    value={tourPointsState[index].iconColor.value}
                    id={`${item.uniqueId}-iconColor`}
                    name={`${item.uniqueId}-iconColor`}
                    onChange={(event: any) =>
                      handleTourPointInputChange(event, { type: "select" })
                    }
                    required={tourPointsState[index].iconColor.required}
                    error={!tourPointsState[index].iconColor.isValid}
                  />
                </Box>

                {/* Label  */}
                <Box sx={{ gridRow: "1", gridColumn: "10 / 14" }} mb={1}>
                  <TextFieldWithInfo
                    label={tourPointsState[index].label.label}
                    value={tourPointsState[index].label.value}
                    id={`${item.uniqueId}-label`}
                    onChange={handleTourPointInputChange}
                    required={tourPointsState[index].label.required}
                    error={!tourPointsState[index].label.isValid}
                    info={tourPointsState[index].label.info}
                  />
                </Box>

                {/* coordinates  */}
                <Box sx={{ gridRow: "2", gridColumn: "2 / 14" }}>
                  <TextField
                    label={tourPointsState[index].coordinates.label}
                    value={tourPointsState[index].coordinates.value}
                    id={`${item.uniqueId}-coordinates`}
                    onChange={handleTourPointInputChange}
                    margin="none"
                    required={tourPointsState[index].coordinates.required}
                    fullWidth
                    size="small"
                    error={!tourPointsState[index].coordinates.isValid}
                  />
                </Box>

                {/* Actions */}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ gridRow: "1", gridColumn: "14 / 15" }}
                  mb={1}
                >
                  {/* Preview Route button */}
                  <IconButton
                    aria-label="preview"
                    color="secondary"
                    size="small"
                    onClick={() => handleTourPointPreview(item.uniqueId)}
                  >
                    <PreviewIcon color="info" />
                  </IconButton>

                  {/* Delete point button */}
                  <IconButton
                    aria-label="delete"
                    color="error"
                    size="small"
                    onClick={() => handleDeleteTourPoint(item.uniqueId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>

                {/* Preview tour point error message */}
                {previewTourPointError && previewTourPointErrorIndex === index && (
                  <Box sx={{ gridColumn: "2 / 14" }}>
                    <Alert severity="error" variant="filled">
                      {previewTourPointError}
                    </Alert>
                  </Box>
                )}
              </Box>

              {index < tourPointsState.length - 1 && <Divider sx={{}} />}
            </React.Fragment>
          ))
        ) : (
          <EmptyState
            title={DICTIONARY.createTour.sections.symbolPoints.emptyState.title}
            subtitle={
              DICTIONARY.createTour.sections.symbolPoints.emptyState.subtitle
            }
            icon="symbol"
            py={4}
          />
        )}
      </Fragment>
    );
  }
);

export default SymbolPoints;
