import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, HELPERS, ROUTES } from "../../utils";
import { COUNTRY_LIST } from "../../consts";

// Components
import BusinessPartnerItemMenu from "./BusinessPartnerItemMenu";

// Mui
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IconButton, Paper, Box } from "@mui/material";

// Icons
import { MoreVert as MoreIcon } from "@mui/icons-material";

// Colors
import { blue } from "@mui/material/colors";

interface Props {
  data: any[];
  onItemUpdate: () => Promise<void>;
}

export default function BusinessPartnersTable({ data, onItemUpdate }: Props) {
  const history = useHistory();
  const [parsedData, setParsedData] = useState<any[]>(data);

  //#region Init
  useEffect(() => {
    const parsedData = data.map((item) => {
      return {
        ...item,
        operatedByName: item.operatedBy.name,
      };
    });

    setParsedData(parsedData);
  }, [data]);
  //#endregion

  //#region View tour
  const handleView = (id: any) => {
    history.push(`${ROUTES.tours.path}/${id}`);
  };
  //#endregion

  //#region Columns
  const getParsedDate = (value: any) => {
    try {
      return HELPERS.date.formatDate(value.toDate(), "dd/MM/yy");
    } catch (error) {
      return "-";
    }
  };

  const getParsedCountry = (value: any) => {
    try {
      if (value && value.length > 0) {
        let retVal: any = [];

        value.forEach((item: string) => {
          retVal.push(COUNTRY_LIST[item]?.label || item);
        });

        return retVal.join(", ");
      }
      return COUNTRY_LIST[value]?.label || value;
    } catch (error) {}
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: DICTIONARY.businessPartners.table.columns.name,
      flex: 1,
    },
    {
      field: "email",
      headerName: DICTIONARY.businessPartners.table.columns.email,
      flex: 1,
    },
    // {
    //   field: "phone",
    //   headerName: DICTIONARY.businessPartners.table.columns.phone,
    //   flex: 1,
    // },
    {
      field: "operatedByName",
      headerName: DICTIONARY.businessPartners.table.columns.operatedBy,
      flex: 1,
    },
    {
      field: "countries",
      headerName: DICTIONARY.businessPartners.table.columns.countries,
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedCountry(params.value);
      },
      flex: 1,
    },
    {
      field: "location",
      headerName: DICTIONARY.businessPartners.table.columns.location,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: DICTIONARY.tours.table.columns.createdAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: DICTIONARY.tours.table.columns.updatedAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "actions",
      headerName: DICTIONARY.tours.table.columns.actions,
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation(); // don't select this row after clicking
              handleMenuClick(event, params.row);
            }}
          >
            <MoreIcon />
          </IconButton>
        );
      },
    },
  ];
  //#endregion

  //#region Menu
  const [guideData, setGuideData] = useState<any>(data);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    guideData: any
  ) => {
    setGuideData(guideData);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //#endregion

  const handleTourDataUpdate = () => {
    onItemUpdate();
  };

  return (
    <Fragment>
      <Paper sx={{ height: "74vh", width: "100%", p: 1 }}>
        <DataGrid
          rows={parsedData}
          columns={columns}
          disableColumnFilter
          // onSelectionModelChange={handleView}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              sx: {
                "&.MuiDataGrid-toolbarContainer": {
                  // justifyContent: "flex-end",
                  px: 1,
                  pt: 1,

                  "& .MuiButton-root": {
                    color: blue[700],
                    mr: 1,
                    "&:hover": {
                      backgroundColor: blue[50],
                    },
                  },
                },
              },
            },
          }}
        />
      </Paper>

      {/* Guide item menu */}
      <BusinessPartnerItemMenu
        data={guideData}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onItemUpdate={onItemUpdate}
      />
    </Fragment>
  );
}
