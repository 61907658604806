// Firebase
import { db, storage, functions } from "../config/firebase-config";
import {
  collection,
  doc,
  query,
  where,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

// utils
import { HELPERS } from "../utils";

const tour = {
  get: async function (id: string) {
    try {
      const docRef = doc(db, "tours", id);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw Error;
      }

      return docSnap.data();
    } catch (e) {
      const error = `Error getting tour data with id: ${id}`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  getAll: async function (queryFilter?: any) {
    try {
      let retVal: any = [];
      const toursRef = collection(db, "tours");
      let querySnapshot;

      if (queryFilter) {
        const q = query(
          toursRef,
          where(queryFilter.field, queryFilter.operator, queryFilter.value)
        );
        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(toursRef);
      }

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();
        retVal.push({
          id: doc.id,
          ...data,
        });
      });

      return retVal;
    } catch (e) {
      const error = `Error getting tours data`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  create: async function (data: any, user: any) {
    try {
      const docRef = await addDoc(collection(db, "tours"), {
        ...data,
        createdAt: Timestamp.fromDate(new Date()),
        deletedAt: null,
        createdBy: {
          id: user.auth.uid,
          name: user.data.name,
          avatar: user.data.avatar,
        },
      });

      return {
        success: "true",
        tourId: docRef.id,
      };
    } catch (e) {
      const error = `Error creating tour`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  edit: async function (id: string, data: any) {
    try {
      const docRef = await doc(db, "tours", id);

      await updateDoc(docRef, {
        ...data,
        updatedAt: Timestamp.fromDate(new Date()),
      });

      return {
        success: "true",
        tourId: id,
      };
    } catch (e) {
      const error = `Error editing tour`;
      HELPERS.localhost.isVerbose() && console.error(e);
      return {
        error,
      };
    }
  },
  delete: async function (id: string) {
    try {
      const docRef = await doc(db, "tours", id);

      await updateDoc(docRef, {
        deletedAt: Timestamp.fromDate(new Date()),
      });

      return true;
    } catch (e) {
      const error = `Error deleting tour`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  uploadFile: async function ({ fileId, file, suffix, tourId, userId }: any) {
    try {
      const path = `tours/${tourId}/${fileId}-${suffix}`;
      const fileRef = ref(storage, path);

      let metadata = {
        customMetadata: {
          uploadedBy: userId,
          tourId,
        },
      };

      const snapshot = await uploadBytes(fileRef, file, metadata);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      return {
        success: "true",
        downloadUrl,
      };
    } catch (e) {
      const error = `Error uploading tour point file`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  promocode: {
    get: async function (tourId: string) {
      // GET promocode metadata
      try {
        const docRef = doc(db, "tours_promocode", tourId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw Error;
        }

        return docSnap.data();
      } catch (e) {
        const error = `Error getting promocodes of tour: ${tourId}`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
    getSubcollection: async function (tourId: string, subcollection: string) {
      // GET subcollection promocodes of tour {tourId}
      try {
        let retVal: any = [];
        const docRef = collection(
          db,
          `tours_promocode/${tourId}/${subcollection}`
        );
        let querySnapshot;

        querySnapshot = await getDocs(docRef);

        querySnapshot.forEach((doc) => {
          let data: any = doc.data();
          retVal.push(data);
        });

        return retVal;
      } catch (e) {
        const error = `Error getting "${subcollection}" promocodes of tour: ${tourId}`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
    createDemoPromocode: async function (tourId: string, data: any) {
      // DEPRECATED
      try {
        const createDemoPromocode = httpsCallable(
          functions,
          "createDemoPromocode"
        );
        const response = await createDemoPromocode({
          tourId,
          ...data,
        });

        return response;
      } catch (error) {
        return {
          error,
        };
      }
    },
    createReusablePromocode: async function (tourId: string, data: any) {
      // DEPRECATED
      try {
        const createReusablePromocode = httpsCallable(
          functions,
          "createReusablePromocode"
        );
        const response = await createReusablePromocode({
          tourId,
          ...data,
        });

        return response;
      } catch (error) {
        return {
          error,
        };
      }
    },
    createSingleusePromocode: async function (tourId: string, data: any) {
      // DEPRECATED
      try {
        const createSingleusePromocode = httpsCallable(
          functions,
          "createSingleusePromocode"
        );
        const response = await createSingleusePromocode({
          tourId,
          ...data,
        });

        return response;
      } catch (error) {
        return {
          error,
        };
      }
    },
  },
  metadata: {
    get: async function (id: string) {
      try {
        const docRef = doc(db, "tours_metadata", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw Error;
        }

        return docSnap.data();
      } catch (e) {
        const error = `Error getting tour_metadata with id: ${id}`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
    getAll: async function (queryFilter?: any) {
      try {
        let retVal: any = [];
        const toursRef = collection(db, "tours_metadata");
        let querySnapshot;

        if (queryFilter) {
          const q = query(
            toursRef,
            where(queryFilter.field, queryFilter.operator, queryFilter.value)
          );
          querySnapshot = await getDocs(q);
        } else {
          querySnapshot = await getDocs(toursRef);
        }

        querySnapshot.forEach((doc) => {
          let data: any = doc.data();
          retVal.push({
            id: doc.id,
            ...data,
          });
        });

        return retVal;
      } catch (e) {
        const error = `Error getting tours_metadata data`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
  },
  entrance: {
    getAll: async function (queryFilter?: any) {
      try {
        let retVal: any = [];
        const toursRef = collection(db, "tours_entrances");
        let querySnapshot;

        if (queryFilter) {
          const q = query(
            toursRef,
            where(queryFilter.field, queryFilter.operator, queryFilter.value)
          );
          querySnapshot = await getDocs(q);
        } else {
          querySnapshot = await getDocs(toursRef);
        }

        querySnapshot.forEach((doc) => {
          let data: any = doc.data();
          retVal.push({
            id: doc.id,
            ...data,
          });
        });

        return retVal;
      } catch (e) {
        const error = `Error getting tours_metadata data`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
  },
};

export default tour;
