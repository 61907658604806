import { useState, Fragment, useRef } from "react";
import { DICTIONARY } from "../../utils";

import { CopyToClipboard } from "react-copy-to-clipboard";

// Mui
import { TextField, Box, IconButton, Popover, Typography } from "@mui/material";

// Icons
import { Link as LinkIcon } from "@mui/icons-material";

type Props = {
  link: string;
};

export default function CopyPwaLink({ link }: Props) {
  const containerRef = useRef(null);

  //#region Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  //#endregion

  const handleClickCopy = () => {
    handlePopoverOpen(containerRef.current);
  };

  return (
    <Fragment>
      <Box mb={1} display="flex" justifyContent="center" alignItems="center">
        <TextField
          hiddenLabel
          id="copy-pwa-link"
          value={link}
          size="small"
          disabled={true}
          fullWidth
          variant="outlined"
          // InputProps={{ classes: { disabled: classes.invitationLink } }}
        />

        <CopyToClipboard text={link} onCopy={handleClickCopy}>
          <IconButton
            aria-label="copy"
            onMouseLeave={handlePopoverClose}
            ref={containerRef}
          >
            <LinkIcon />
          </IconButton>
        </CopyToClipboard>
      </Box>

      {/* "Copied" message */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            p: 1,
          },
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{DICTIONARY.general.copyPwaLink.copied}</Typography>
      </Popover>
    </Fragment>
  );
}
