import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { PUBLISH_STATUS, COUNTRY_LIST } from "../../consts";

// Mui
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";

// App components
import TourItemMenu from "./TourItemMenu";

// Icons
import {
  MoreVert as MoreIcon,
  Visibility as PublishIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

type Props = {
  data: any;
  onItemUpdate: () => Promise<void>;
};

export default function TourItem({ data, onItemUpdate }: Props) {
  const history = useHistory();
  const [tourData, setTourData] = useState(data);

  const handleTourDataUpdate = (updatedTourData: any) => {
    onItemUpdate();
  };

  //#region Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //#endregion

  //#region View tour
  const handleView = () => {
    history.push(`${ROUTES.tours.path}/${tourData.id}`);
  };
  //#endregion

  return (
    <Fragment>
      <Card>
        <CardHeader
          sx={{
            "&.MuiCardHeader-root": {
              width: 1,
              justifyContent: "space-between",
            },
            "& .MuiCardHeader-content": {
              width: "calc(100% - 50px)",
            },
            "& .MuiCardHeader-action": {
              width: "40px",
              mt: -1,
            },
          }}
          action={
            <IconButton aria-label="menu" onClick={handleMenuClick}>
              <MoreIcon />
            </IconButton>
          }
          title={
            <Box display="flex" sx={{}}>
              <Typography
                variant="body1"
                component="h2"
                sx={{
                  mr: 1,
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={handleView}
              >
                {tourData.name}
              </Typography>
              {tourData.publishStatus === PUBLISH_STATUS.public.value ? (
                <PublishIcon color="success" />
              ) : (
                <HideIcon color="error" />
              )}
            </Box>
          }
          subheader={
            <Box display="flex" onClick={handleView}>
              <Typography
                variant="body2"
                component="h2"
                color={grey[500]}
                sx={{ mr: 0.5, cursor: "pointer" }}
              >
                {DICTIONARY.tours.tourItem.details.guide}:
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                color={grey[700]}
                sx={{ cursor: "pointer" }}
              >
                {tourData.guide?.name || tourData.createdBy.name}
              </Typography>
            </Box>
          }
        />
        <CardMedia
          component="img"
          height="194"
          image={tourData.coverImage}
          alt={tourData.name}
          onClick={handleView}
          sx={{ cursor: "pointer" }}
        />
        <CardContent onClick={handleView} sx={{ cursor: "pointer" }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {DICTIONARY.tours.tourItem.details.country}:{" "}
            {COUNTRY_LIST[tourData.country]?.label || tourData.country}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {DICTIONARY.tours.tourItem.details.location}: {tourData.location}
          </Typography>
        </CardContent>

        {/* <CardActions disableSpacing>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
        </CardActions> */}
      </Card>

      {/* Tour item menu */}
      <TourItemMenu
        tourData={tourData}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onTourDataUpdate={handleTourDataUpdate}
        onItemUpdate={onItemUpdate}
      />
    </Fragment>
  );
}
