import React, { useState, FC, useContext } from "react";
import { SNACKBAR_SEVERITY } from "../consts";

import { Snackbar, Alert, AlertColor } from "@mui/material";

interface ISnackbarContext {
  handleSnackbarDisplay: (
    severity: AlertColor,
    message: string,
    autoHideDuration: number | null
  ) => void;
}

const defaultState = {
  handleSnackbarDisplay: (
    severity: AlertColor,
    message: string,
    autoHideDuration: number | null
  ) => null,
};

const SnackbarContext = React.createContext<ISnackbarContext>(defaultState);

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarProvider: FC = ({ children }) => {
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(
    SNACKBAR_SEVERITY.info
  ); // error, warning, info, success
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [autoHideDuration, setAutoHideDuration] = useState<number | null>(5000);

  const handleSnackbarDisplay = (
    severity: AlertColor,
    message: string,
    autoHideDuration: number | null
  ) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setShouldShowSnackbar(true);
    setAutoHideDuration(autoHideDuration);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent) => {
    setShouldShowSnackbar(false);
  };

  return (
    <SnackbarContext.Provider
      value={{
        handleSnackbarDisplay,
      }}
    >
      {children}

      <Snackbar
        open={shouldShowSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity as AlertColor}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
