export const LOCALE_LIST: any = {
  en: {
    label: "English",
    value: "en",
    countryCode: "US",
  },
  he: {
    label: "Hebrew",
    value: "he",
    countryCode: "IL",
  },
};
