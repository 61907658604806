import { ReactChild, ReactChildren, cloneElement } from "react";

// App components
import Loader from "../loader/Loader";
import EmptyState from "../shared/EmptyState";
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import { Card, CardContent, CardHeader, Box } from "@mui/material";

// Icons
import {
  ErrorOutline as DefaultIcon,
  LocationOn as TourIcon,
  Receipt as PromocodeIcon,
  Tour as SymbolIcon,
  Person as PersonIcon,
  Accessibility as GuideIcon,
  People as SellerIcon,
  Store as BusinessPartnerIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";

const ICONS_MAP: any = {
  default: <DefaultIcon />,
  tour: <TourIcon />,
  promocode: <PromocodeIcon />,
  guide: <GuideIcon />,
  seller: <SellerIcon />,
  businessPartner: <BusinessPartnerIcon />,
  symbol: <SymbolIcon />,
  person: <PersonIcon />,
  dashboard: <DashboardIcon />,
};

type Props = {
  title: string;
  info?: string;
  icon?: any;
  actions?: any;
  isLoading: boolean;
  error: string;
  style?: any;
  children: ReactChild | ReactChildren;
};

export default function HighlightCardContainer({
  title,
  info,
  icon,
  actions,
  isLoading,
  error,
  style,
  children,
}: Props) {
  //#region Icon
  const Icon = () => {
    return cloneElement(ICONS_MAP[icon], {
      color: "action",
      // sx: { fontSize: 40 },
    });
  };
  //#endregion

  return (
    <Card
      id="highlight-card-container"
      sx={{ display: "flex", flexDirection: "column", ...style }}
    >
      {/* Header */}
      <CardHeader
        id="highlight-card-header-wrapper"
        avatar={<Icon />}
        component="div"
        title={
          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            {title}
            {info && <InfoTooltip info={info} />}
          </Box>
        }
        sx={{
          "&.MuiCardHeader-root": {
            pb: 0,
          },
          "& .MuiCardHeader-title": {
            fontSize: 18,
          },
          "& .MuiCardHeader-avatar": {
            mr: 1.5,
          },
        }}
      />

      {/* Content */}
      <CardContent
        id="highlight-card-content-wrapper"
        sx={{ flex: 1, display: "flex", justifyContent: "center" }}
      >
        {isLoading ? (
          <Loader py={0} />
        ) : error !== "" ? (
          <EmptyState title={error} py={0} icon={icon} />
        ) : (
          children
        )}
      </CardContent>
    </Card>
  );
}
