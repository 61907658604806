import { Fragment, useState, useRef } from "react";
import { DICTIONARY } from "../utils";

// Components
import QrCode from "../components/shared/QrCode";

// Mui
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  FormLabel,
  Alert,
  Paper,
} from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

function QrCodeGenerator() {
  //#region Form
  const [inputValue, setInputValue] = useState<string>("");
  const [formError, setFormError] = useState("");
  const [qrValue, setQrValue] = useState<string>("");
  const [isInputValueChanged, setIsInputValueChanged] =
    useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setInputValue(value);
    setIsInputValueChanged(true);

    setFormError("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setQrValue(`?search=${inputValue}`);

      setIsInputValueChanged(false);
    } catch (error) {}
  };
  //#endregion
  return (
    <Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.qrCodeGenerator.title}
              </Typography>
            </Box>
          </Box>

          {/* Content */}
          <Paper sx={{ mt: 2, px: 3, py: 3 }}>
            <FormLabel
              component="label"
              sx={{
                display: "block",
                fontSize: (theme) => theme.typography.pxToRem(14),
                mb: 1,
                whiteSpace: "pre-line",
                // "&.MuiFormLabel-root": {
                //   whiteSpace: "pre-line",
                // },
              }}
            >
              {DICTIONARY.qrCodeGenerator.fields.searchValue.info}
            </FormLabel>

            <Box sx={{ mt: 3 }}>
              {/* Form */}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 1,
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TextField
                  label={DICTIONARY.qrCodeGenerator.fields.searchValue.label}
                  value={inputValue}
                  id="inputValue"
                  name="inputValue"
                  onChange={handleInputChange}
                  margin="none"
                  size="small"
                  required={true}
                  error={formError !== ""}
                  autoFocus
                  sx={{
                    width: "400px",
                  }}
                />

                {/* Submit button */}
                <Button
                  type="submit"
                  variant="contained"
                  // size="small"
                  color="secondary"
                  disabled={formError.length > 0}
                >
                  {DICTIONARY.qrCodeGenerator.buttons.generate}
                </Button>

                {/* Error message */}
                {formError && (
                  <Box sx={{ mb: 2 }}>
                    <Alert severity="error">{formError}</Alert>
                  </Box>
                )}
              </Box>

              {/* Qr code */}
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "360px",
                }}
              >
                {/* <Typography>{qrValue}</Typography> */}
                {qrValue !== "" && isInputValueChanged === false ? (
                  <QrCode
                    qrValue={qrValue}
                    qrFileName={`search_${inputValue}`}
                  />
                ) : (
                  <Box>
                    <Typography sx={{ color: grey[700] }}>
                      {DICTIONARY.qrCodeGenerator.emptyState}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Fragment>
  );
}

export default QrCodeGenerator;
