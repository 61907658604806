import { useState, Fragment } from "react";

import { userApi } from "../../api";
import { DICTIONARY, HELPERS } from "../../utils";

// App components
import UserCredsDetails from "./UserCredsDetails";

// Mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Icons
import { Password as PasswordIcon } from "@mui/icons-material";

const INITIAL_PASSWORD = "********";

interface PublishStatusDialogProps {
  open: boolean;
  data: any;
  onClose: (data: any) => void;
}

export default function UserCredsDialog({
  open,
  data,
  onClose,
}: PublishStatusDialogProps) {
  // #region Password
  const [password, setPassword] = useState<string>(INITIAL_PASSWORD);

  const handleCreateNewPassword = () => {
    const password = HELPERS.auth.createNewUserPassword();

    setPassword(password);
  };
  // #endregion

  //#region Dialog
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitLoading(true);

      let response = await userApi.updatePassword(data.id, {
        password: password,
      });

      if (!response.hasOwnProperty("error")) {
        onClose({
          isSubmitedSuccessfully: true,
        });
      }
      setIsSubmitLoading(false);
    } catch (error) {
      setIsSubmitLoading(false);
    }
  };
  //#endregion

  return (
    <Fragment>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>{DICTIONARY.auth.userCredsDialog.title}</DialogTitle>

        <DialogContent dividers>
          <UserCredsDetails
            name={data.name}
            email={data.email}
            id={data.id}
            password={password}
            onCreatePassword={handleCreateNewPassword}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            {DICTIONARY.general.button.cancel}
          </Button>

          {/* Submit button */}
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitLoading}
            loadingPosition="start"
            startIcon={<PasswordIcon />}
            variant="contained"
            disabled={isSubmitLoading || password === INITIAL_PASSWORD}
          >
            {DICTIONARY.auth.userCredsDialog.buttons.update}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
