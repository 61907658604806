import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { tourApi } from "../../api";

// Map
// @ts-ignore
import { IntroScreen, Map } from "tour-guide-shared-library";
// import Map from "../../components/map/Map";

// Mui
import { Box } from "@mui/material";

// App components
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";

function Tour() {
  const history = useHistory();
  const { id: tourId }: any = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tour, setTour] = useState({});
  const [showTourIntro, setShowTourIntro] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await tourApi.get(tourId);

        if (response.hasOwnProperty("error")) {
          setIsLoading(false);
          setError(DICTIONARY.general.error);
          return;
        }

        // Success
        setTour(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [tourId]);

  const handleCloseIntroScreen = () => {
    history.push(ROUTES.tours.path);
  };

  const handleStartTour = () => {
    setShowTourIntro(false);
  };

  const handleTriggerSnackbar = ({
    value,
    title,
    severity,
    message,
    autoHideDuration,
  }: any) => {
    console.log(`-- handleTriggerSnackbar: ${title}, Message: ${message}`);

    return;
  };

  const Content = () => (
    <Box
      id="tour-content"
      sx={{
        marginBottom: "-40px", // because main has padding-bottom of 40px
      }}
    >
      {showTourIntro ? (
        <IntroScreen
          token={process.env.REACT_APP_MAPBOX_TOKEN}
          if="tour-intro-screen"
          tour={tour}
          onClose={handleCloseIntroScreen}
          onStartTour={handleStartTour}
        />
      ) : (
        <Map
          token={process.env.REACT_APP_MAPBOX_TOKEN}
          tour={tour}
          tourId={tourId}
          onTriggerSnackbar={handleTriggerSnackbar}
        />
      )}
    </Box>
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <EmptyState title={DICTIONARY.tour.emptyState} icon="tour" py={20} />
      ) : (
        <Content />
      )}
    </React.Fragment>
  );
}

export default Tour;
