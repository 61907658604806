// Firebase
import { db, storage, functions } from "../config/firebase-config";
import {
  collection,
  doc,
  query,
  where,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

// utils
import { HELPERS } from "../utils";
import { PROMOCODE_PATTERNS } from "../consts";

const promocode = {
  get: async function (id: string) {
    try {
      let retVal;
      const docRef = doc(db, "promocodes", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        retVal = docSnap.data();
      } else {
        retVal = {};
        HELPERS.localhost.isVerbose() &&
          console.log(`No such promocode document with id: ${id}`);
      }

      return retVal;
    } catch (e) {
      const error = `Error getting promocode data with id: ${id}`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  getAll: async function (queryFilter?: any) {
    try {
      let retVal: any = [];
      const toursRef = collection(db, "promocodes");
      let querySnapshot;

      if (queryFilter) {
        const q = query(
          toursRef,
          where(queryFilter.field, queryFilter.operator, queryFilter.value)
        );
        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(toursRef);
      }

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();
        retVal.push({
          id: doc.id,
          ...data,
        });
      });

      return retVal;
    } catch (e) {
      const error = `Error getting promocodes data`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  create: async function (data: any, user: any) {
    try {
      await setDoc(doc(db, "promocodes", data.code), {
        ...data,
        createdAt: Timestamp.fromDate(new Date()),
        deletedAt: null,
        createdBy: {
          id: user.auth.uid,
          name: user.data.name,
          avatar: user.data.avatar,
        },
      });

      return {
        success: "true",
        id: data.code,
      };
    } catch (e) {
      const error = `Error creating promocode`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  edit: async function (id: string, data: any) {
    try {
      const docRef = await doc(db, "promocodes", id);

      await updateDoc(docRef, {
        ...data,
        updatedAt: Timestamp.fromDate(new Date()),
      });

      return {
        success: "true",
        id: id,
      };
    } catch (e) {
      const error = `Error editing promocode`;
      HELPERS.localhost.isVerbose() && console.error(e);
      return {
        error,
      };
    }
  },
  delete: async function (id: string) {
    try {
      const docRef = await doc(db, "promocodes", id);

      await updateDoc(docRef, {
        deletedAt: Timestamp.fromDate(new Date()),
      });

      return true;
    } catch (e) {
      const error = `Error deleting promocode`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  uploadFile: async function ({ fileId, file, suffix, id, userId }: any) {
    try {
      const path = `promocodes/${id}/${fileId}-${suffix}`;
      const fileRef = ref(storage, path);

      let metadata = {
        customMetadata: {
          uploadedBy: userId,
          id,
        },
      };

      const snapshot = await uploadBytes(fileRef, file, metadata);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      return {
        success: "true",
        downloadUrl,
      };
    } catch (e) {
      const error = `Error uploading promocode file`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  ondemand: {
    generate: async function ({ eligibleTours }: any) {
      try {
        const generateOndemandPromocode = httpsCallable(
          functions,
          "generateOndemandPromocode"
        );
        const ONDEMAND_PROMOCODE_LENGTH = 5;

        const data = {
          eligibleTours,
          length: ONDEMAND_PROMOCODE_LENGTH,
          pattern: PROMOCODE_PATTERNS.digits.value,
        };

        const response = await generateOndemandPromocode(data);

        return response;
      } catch (error) {
        return {
          error,
        };
      }
    },
  },
};

export default promocode;
