const createBusinessPartner = {
  title: {
    create: "Create new business partner",
    edit: "Edit business partner",
  },
  sections: {
    details: {
      label: "Business partner details",
      emptyState: "",
    },
    operator: {
      label: "Operator",
      emptyState: "",
    },
    tours: {
      label: "Tours",
      emptyState: "No tours yet",
    },
    summary: {
      label: "Summary",
      emptyState: "",
    },
  },
  buttons: {
    next: "Next",
    back: "Back",
    save: "Save",
  },
  fields: {
    name: {
      label: "Name",
      info: "",
      error: {
        length:
          "Business partner name must contain between 2 and 40 characters.",
      },
    },
    email: {
      label: "Email",
      info: "",
      error: {
        badlyFormattedEmail: "The email address is badly formatted.",
      },
    },
    phone: {
      label: "Phone",
      info: "",
      error: {},
    },
    nationality: {
      label: "Nationality",
      info: "",
      error: {},
    },
    countries: {
      label: "Countries of operation",
      info: "",
      error: {},
    },
    description: {
      label: "Description",
      info: "",
      error: {},
    },
    website: {
      label: "Website",
      info: "",
      error: {},
    },
    location: {
      label: "location",
      info: "",
      error: {},
    },
    operatorType: {
      label: "Operator type",
      info: "Which role is defined to be incharge of the business partner.",
      error: {},
    },
    operatedBy: {
      // name, id
      label: "Operatored by",
      info: "",
      error: {
        required: "Business parner must have an operator.",
      },
    },
  },
  errors: {
    generalError: `Couldn't create business partner. Please try again or contact support.`,
    generalErrorEdit: `Couldn't edit business partner. Please try again or contact support.`,
  },
  emptyState: "The requested business partner does not exist",
};

export default createBusinessPartner;
