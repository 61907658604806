import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { businessPartnerApi } from "../../api";
import { COUNTRY_LIST } from "../../consts";

// Mui
import { Box, Container, Typography, Button } from "@mui/material";

// App components
import BusinessPartnersTable from "../../components/businessPartner/BusinessPartnersTable";

import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";
import SearchBox from "../../components/shared/SearchBox";

function BusinessPartners() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const originalTours = useRef<any>([]);
  const [parsedBusinessPartners, setParsedBusinessPartners] = useState<any[]>(
    []
  );

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const query = {
        field: "deletedAt",
        operator: "==",
        value: null,
      };
      const response = await businessPartnerApi.getAll(query);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      // Success
      originalTours.current = sortEntities(response);
      setParsedBusinessPartners(originalTours.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Search
  const [searchValue, setSearchValue] = useState<string | null>("");
  const handleSearchValueChange = (searchValue: string | null) => {
    setSearchValue(() => {
      let filteredTours: any = filterEntities(
        originalTours.current,
        searchValue
      );
      filteredTours = sortEntities(filteredTours);

      setParsedBusinessPartners(filteredTours);

      return searchValue;
    });
  };
  //#endregion

  const TableView = () => {
    return (
      <Box sx={{ mt: 3 }}>
        <BusinessPartnersTable
          data={parsedBusinessPartners}
          onItemUpdate={fetchData}
        />
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.businessPartners.title}
              </Typography>

              {/* Search */}
              <Box
                sx={{
                  width: "440px",
                }}
              >
                <SearchBox
                  searchValue={searchValue}
                  onChange={handleSearchValueChange}
                  placeholder="Search business partner"
                />
              </Box>
            </Box>

            {/* Right side */}
            <Box
              sx={{
                maxWidth: "600px",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Action */}
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={ROUTES.businessPartners.create.path}
              >
                {DICTIONARY.businessPartners.create}
              </Button>
            </Box>
          </Box>

          {isLoading ? (
            <Loader />
          ) : error ? (
            <EmptyState
              title={DICTIONARY.businessPartners.emptyState}
              icon="businessPartner"
              py={10}
            />
          ) : (
            <TableView />
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default BusinessPartners;

//#region Helpers
const sortEntities = (tours: any) => {
  function callback(a: any, b: any) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};

const filterEntities = (tours: any[], filteredValue: string | null) => {
  function callback(item: any, filteredValue: string) {
    try {
      const isIncludedInWord = (item: string) =>
        item.toLocaleLowerCase().includes(filteredValue);

      const isIncludedInCountries = (countries: [] | undefined) => {
        if (countries && countries.length > 0) {
          let filteredCountries = countries.filter((item) =>
            isIncludedInWord(COUNTRY_LIST[item]?.label || item)
          );
          return filteredCountries.length > 0;
        } else {
          return false;
        }
      };

      if (
        isIncludedInWord(item.name) ||
        isIncludedInWord(item.email) ||
        isIncludedInWord(item.location) ||
        isIncludedInCountries(item.countries) ||
        isIncludedInWord(item.operatedBy.name)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  try {
    if (filteredValue === undefined) {
      return false;
    }

    const parsedFilteredValue: string =
      filteredValue?.toLocaleLowerCase() || "";

    let filteredTours = tours.filter((item: any) =>
      callback(item, parsedFilteredValue)
    );

    return filteredTours;
  } catch (error) {
    return tours;
  }
};
//#endregion
