const toursAnalytics = {
  title: "Tour analytics",
  emptyState: "The requested tour does not exist",
  selectTour: "Select tour",
  form: {
    fields: {
      tour: {
        label: "Tour",
        info: "",
      },
    },
  },
  cards: {
    topPromocode: {
      title: "Top promocode",
      info: "Most used promocode.",
    },
    topTourEntrances: {
      title: "Top tour",
      info: "The tour with the most 'first time' entrances.",
    },
    topGuide: {
      title: "Top guide",
      info: "The guide with the most 'show contact details' clicks.",
    },
  },
  charts: {
    // Usages of promocodes
    tourEntrances: {
      title: "First-time entrances - by date",
      actions: {},
    },
    geolocationPermissionBlockedByDate: {
      title: "Geolocation permission blocked - by date",
      actions: {},
    },
    geolocationPermissionBlockedByDevice: {
      title: "Geolocation permission blocked - by device",
      actions: {},
    },
    outdoorGameFinished: {
      title: "Finished outdoor games - by date",
      actions: {},
    },
  },
};

export default toursAnalytics;
