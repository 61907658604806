import React from "react";
import { COUNTRY_LIST } from "../../consts";

// Mui
import { TextField, Autocomplete, Box } from "@mui/material";

type Props = {
  label: string;
  info: string;
  id: string;
  value: any;
  error: boolean;
  required: boolean;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    payload: any
  ) => void;
  multiple?: boolean;
  shouldShowCode?: boolean;
  shouldShowPhone?: boolean;
};

const AutocompleteSelectCountry = ({
  label,
  info,
  id,
  value,
  error,
  required,
  onInputChange,
  multiple = false,
  shouldShowCode = false,
  shouldShowPhone = false,
}: Props) => {
  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      fullWidth
      options={Object.keys(COUNTRY_LIST)}
      autoHighlight
      getOptionLabel={(code) => {
        return COUNTRY_LIST[code]?.label || "";
      }}
      renderOption={(props, code) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${
              COUNTRY_LIST[code]?.countryCode?.toLowerCase() ||
              code?.toLowerCase()
            }.png`}
            srcSet={`https://flagcdn.com/w40/${
              COUNTRY_LIST[code]?.countryCode?.toLowerCase() ||
              code?.toLowerCase()
            }.png 2x`}
            alt=""
          />
          {COUNTRY_LIST[code]?.label} {shouldShowCode && `(${code})`}{" "}
          {shouldShowPhone && `+${COUNTRY_LIST[code]?.phone}`}
        </Box>
      )}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        onInputChange(event, {
          type: "autocomplete",
          id: id,
          value: newValue,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          required={required}
          error={error}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          sx={{
            "& .MuiChip-root": {
              height: "22px",
              "& .MuiChip-deleteIcon": {
                fontSize: 18,
              },
            },
          }}
        />
      )}
    />
  );
};

export default AutocompleteSelectCountry;
