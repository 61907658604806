const ondemandPromocode = {
  title: "On-demand promocode",
  buttons: {
    generate: "Generate code",
  },
  steps: {
    // 0
    generate: {
      label: "Choose the tour that being applied for the code",
    },
    // 1
    displayCode: {
      generatedCode: "Generated code",
      download: "Download QR",
      new: "Genetate new code",
      createdAt: "Created at",
      eligibleTours: "Eligible tour",
    },
  },
  fields: {
    eligibleToursMode: {
      label: "What tours does this promocode apply for?",
      info: "Default - the promocode code is eligible for all the tours in the system. \n \n Selected - The promocode will work only on the selected tours.",
    },
    eligibleTours: {
      label: "Eligible tour",
      info: "",
    },
  },
  errors: {
    generalError: `Couldn't create promocode. Please try again or contact support.`,
  },
  emptyState: "The requested promocode does not exist",
};

export default ondemandPromocode;
