const createSeller = {
  title: {
    create: "Create new seller",
    edit: "Edit seller",
  },
  sections: {
    details: {
      label: "Seller details",
      emptyState: "",
    },
    summary: {
      label: "Summary",
      emptyState: "",
    },
  },
  buttons: {
    next: "Next",
    back: "Back",
    save: "Save",
  },
  fields: {
    name: {
      label: "Name",
      info: "",
      error: {
        length: "Seller name must contain between 2 and 40 characters.",
      },
    },
    email: {
      label: "Email",
      info: "",
      error: {
        badlyFormattedEmail: "The email address is badly formatted.",
      },
    },
    phone: {
      label: "Phone",
      info: "",
      error: {},
    },
    nationality: {
      label: "Nationality",
      info: "",
      error: {},
    },
    countries: {
      label: "Countries of operation",
      info: "",
      error: {},
    },
    description: {
      label: "Description",
      info: "",
      error: {},
    },
    website: {
      label: "Website",
      info: "",
      error: {},
    },
    location: {
      label: "location",
      info: "",
      error: {},
    },
  },
  errors: {
    generalError: `Couldn't create seller. Please try again or contact support.`,
    generalErrorEdit: `Couldn't edit seller. Please try again or contact support.`,
  },
  emptyState: "The requested seller does not exist",
};

export default createSeller;
