const general = {
  sidebar: {
    dashboard: {
      label: "Dashboard",
    },
    sections: {
      tours: {
        label: "Tours",
        children: {
          tours: "Tours",
          analytics: "Analytics",
        },
      },
      promocodes: {
        label: "Promocodes",
        children: {
          promocodes: "Promocodes",
          analytics: "Analytics",
          ondemand: "On-demand",
        },
      },
      roles: {
        label: "Roles",
        children: {
          guides: "Guides",
          sellers: "Sellers",
          businessPartners: "Business partners",
        },
      },
      other: {
        label: "Other",
        children: {
          qrCodeGenerator: "QR Code generator",
        },
      },
    },
  },
  button: {
    submit: "Submit",
    cancel: "Cancel",
    delete: "Delete",
    remove: "Remove",
    generate: "Generate",
  },
  appHeader: {
    myAccount: "My account",
    contactUs: "Contact us",
    logout: "Logout",
  },
  banners: {},
  form: {
    badlyFormattedEmail: "The email address is badly formatted.",
  },
  filesUpload: {
    imageUploadInstructions: "Supported formats: JPG, PNG. Up to 1 MB.",
    videoUploadInstructions: "Supported formats: MP4. Up to 5 MB.",
    audioUploadInstructions: "Supported formats: MP3. Up to 5 MB.",
    error: {
      imageType: "File must be of an image file type.",
      videoType: "File must be of a video file type.",
      audioType: "File must be of a audio file type.",
      imageUploadError: `Couldn't upload image. Please try again or contact support.`,
      videoUploadError: `Couldn't upload video. Please try again or contact support.`,
      audioUploadError: `Couldn't upload audio. Please try again or contact support.`,
    },
  },
  copyPwaLink: {
    title: "Copy link",
    button: "Copy",
    copied: "Copied",
  },
  error: "General error",
  chart: {
    errors: {
      noData: "No data yet.",
      selectTour: "No tour was selected",
    },
  },
};

export default general;
