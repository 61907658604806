import { auth } from "../../config/firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { HELPERS } from "../../utils";

const googleSignin = async () => {
  const provider = new GoogleAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // The signed-in user info.
      const user = result.user;
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

const emailVerificationCallback = (user: any) => {
  return new Promise((resolve, reject) => {
    let continueUrl = HELPERS.localhost.isLocalhost()
      ? "http://localhost:3000/"
      : "https://back-office.tour-guiding.com/";

    const actionCodeSettings = {
      url: continueUrl,
      handleCodeInApp: true,
    };

    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        // Email verification sent!
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createNewUserPassword = () => {
  // Relevant for businessPartners, guides and sellers
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const passwordLength = 8;
  let password = "";

  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }

  return password;
};

const exported = {
  googleSignin,
  emailVerificationCallback,
  createNewUserPassword,
};

export default exported;
