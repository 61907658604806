import { Fragment, useState, useImperativeHandle, forwardRef } from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";
import {
  MAP_STYLES,
  GEOLOCATION_MODE,
  DISPLAY_TOUR_POINTS_ON_MAP,
  ROUTE_DIRECTION_ARROW_OPTIONS,
} from "../../../consts";
import {
  TOUR_GUIDING_COLORS,
  // @ts-ignore
} from "tour-guide-shared-library";

// Mui
import { Box, TextField } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import MapStyleSwitcher from "../../inputs/MapStyleSwitcher";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";
import SelectColor from "../../inputs/SelectColor";
import SelectWithInfo from "../../inputs/SelectWithInfo";
import RadioButtonWithInfo from "../../inputs/RadioButtonWithInfo";
import ImageUploader from "../../inputs/ImageUploader";

// Icons
import { Preview as PreviewIcon } from "@mui/icons-material";

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
  onPreviewMapStyle: (mapStyle: any) => void;
  onPreviewRoute: (response: any) => void;
}

const Route = forwardRef(
  (
    { tour, tourId, clearFormError, onPreviewMapStyle, onPreviewRoute }: Props,
    ref
  ) => {
    // #region States
    const initalFormState = {
      route: {
        label: DICTIONARY.createTour.fields.route.label,
        info: DICTIONARY.createTour.fields.route.info,
        value: tour.route ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) =>
              VALIDATORS.coordinates.isValidGeoJsonCoordinates(val),
            errorMessage: DICTIONARY.createTour.fields.route.error.invalid,
          },
        ],
      },
      routeColor: {
        label: DICTIONARY.createTour.fields.routeColor.label,
        info: DICTIONARY.createTour.fields.routeColor.info,
        value: tour.routeColor ?? DEFAULT_ROUTE_COLOR,
        isValid: true,
        required: true,
        validators: [],
      },
      initialZoom: {
        label: DICTIONARY.createTour.fields.initialZoom.label,
        info: DICTIONARY.createTour.fields.initialZoom.info,
        value: tour.initialZoom ?? DEFAULT_ZOOM,
        isValid: true,
        required: false,
        validators: [
          {
            validate: (val: number) => VALIDATORS.number.isNumber(val),
            errorMessage: DICTIONARY.createTour.fields.initialZoom.error.number,
          },
        ],
      },
      routeArrowDirection: {
        label: DICTIONARY.createTour.fields.routeArrowDirection.label,
        info: DICTIONARY.createTour.fields.routeArrowDirection.info,
        value: tour.routeArrowDirection ?? DEFAULT_ROUTE_ARROW_DIRECTION,
        isValid: true,
        required: false,
        validators: [],
      },
      mapStyle: {
        label: DICTIONARY.createTour.fields.mapStyle.label,
        info: DICTIONARY.createTour.fields.mapStyle.info,
        value: tour.mapStyle ?? mapStylesArr[0].value,
        isValid: true,
        required: true,
        validators: [],
      },
      mapStyleCustomImage: {
        label: DICTIONARY.createTour.fields.mapStyleCustomImage.label,
        info: DICTIONARY.general.filesUpload.imageUploadInstructions,
        value: tour.mapStyleCustomImage ?? "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, formState: any) => {
              if (formState.mapStyle.value === MAP_STYLES.custom.value) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.mapStyleCustomImage.error.required,
          },
          {
            validate: (value: any, formState: any) => {
              // Check if the value matches the value from the DB. if true --> the videoContent wasnt change and its valid
              return (
                formState.mapStyleCustomImage.value === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "image")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.videoType,
          },
        ],
      },
      geolocationMode: {
        label: DICTIONARY.createTour.fields.geolocationMode.label,
        info: DICTIONARY.createTour.fields.geolocationMode.info,
        value: tour.geolocationMode ?? GEOLOCATION_MODE.default.value,
        isValid: true,
        required: true,
        validators: [],
      },
      displayTourPointsOnMap: {
        label: DICTIONARY.createTour.fields.displayTourPointsOnMap.label,
        info: DICTIONARY.createTour.fields.displayTourPointsOnMap.info,
        value:
          tour.displayTourPointsOnMap ??
          DISPLAY_TOUR_POINTS_ON_MAP.display.value,
        isValid: true,
        required: true,
        validators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "radio": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleMapStyleChange = (value: string | null) => {
      setFormState((prevState: any) => {
        return {
          ...prevState,
          mapStyle: {
            ...prevState.mapStyle,
            value,
            isValid: true,
          },
        };
      });

      onPreviewMapStyle(value);

      // Clear errors
      clearFormError();
    };

    const handleRoutePreview = () => {
      const response = isFormValid(formState);

      if ("error" in response) {
        onPreviewRoute(response);
        return;
      }

      const parsedData = prepareDataToSave();

      onPreviewRoute({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };
    //#endregion

    //#region Submit and Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };

    const prepareDataToSave = () => {
      let mapStyleCustomImageFileUrl: string = "";
      let files: any = [];

      // New file
      if (
        VALIDATORS.filesUpload.isValidDataFile(
          formState.mapStyleCustomImage.value,
          "image"
        )
      ) {
        files.push({
          type: "image",
          file: formState.mapStyleCustomImage.value,
          fileId: tourId ? tourId : "",
          suffix: "mapStyleCustomImage",
        });
        mapStyleCustomImageFileUrl = URL.createObjectURL(
          formState.mapStyleCustomImage.value
        );
      } else {
        // file hasn't changed - it has the url from firebase storgae
        mapStyleCustomImageFileUrl = formState.mapStyleCustomImage.value;
      }

      const parsedData = {
        mapStyle: formState.mapStyle.value,
        mapStyleCustomImage: mapStyleCustomImageFileUrl,
        route: formState.route.value,
        routeColor: formState.routeColor.value,
        initialZoom: formState.initialZoom.value,
        routeArrowDirection: formState.routeArrowDirection.value,
        geolocationMode: formState.geolocationMode.value,
        displayTourPointsOnMap: formState.displayTourPointsOnMap.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createTour.sections.route.label}
          actions={[
            {
              label: DICTIONARY.createTour.buttons.route,
              icon: <PreviewIcon />,
              onClick: handleRoutePreview,
            },
          ]}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 2.5,
          }}
        >
          {/* Map style */}
          <Box sx={{ gridColumn: "1 / 10" }} mb={1}>
            <MapStyleSwitcher
              initialMapStyle={formState.mapStyle.value}
              onMapStyleChange={handleMapStyleChange}
            />
          </Box>

          {/* Tour coverImage */}
          {formState.mapStyle.value === "custom" && (
            <Box
              sx={{
                // gridRow: 2,
                gridColumn: "10 / 13",
                maxHeight: "50px",
                maxWidth: "150px",
                mt: 2,
                mb: 2,
                ml: "auto",
              }}
            >
              <ImageUploader
                label={formState.mapStyleCustomImage.label}
                info={formState.mapStyleCustomImage.info}
                id="mapStyleCustomImage"
                value={formState.mapStyleCustomImage.value}
                error={!formState.mapStyleCustomImage.isValid}
                onInputChange={handleInputChange}
              />
            </Box>
          )}

          {/* Route coordinates */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <TextField
              label={formState.route.label}
              value={formState.route.value}
              id="route"
              name="route"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.route.required}
              error={!formState.route.isValid}
              fullWidth
            />
          </Box>

          {/* Route color */}
          <Box sx={{ gridColumn: "1 / 5" }} mb={1}>
            <SelectColor
              label={formState.routeColor.label}
              value={formState.routeColor.value}
              id="route-color"
              name="routeColor"
              onChange={(event: any) =>
                handleInputChange(event, { type: "select" })
              }
              required={formState.routeColor.required}
              error={!formState.routeColor.isValid}
            />
          </Box>

          {/* Initial zoom */}
          <Box sx={{ gridColumn: "5 / 9" }} mb={1}>
            <NumberFieldWithInfo
              label={formState.initialZoom.label}
              value={formState.initialZoom.value}
              id={"initialZoom"}
              onChange={handleInputChange}
              required={formState.initialZoom.required}
              error={!formState.initialZoom.isValid}
              info={formState.initialZoom.info}
            />
          </Box>

          {/* Route arrow */}
          <Box sx={{ gridColumn: "9 / 13" }} mb={1}>
            <SelectWithInfo
              label={formState.routeArrowDirection.label}
              value={formState.routeArrowDirection.value}
              id="routeArrowDirection"
              onChange={(event: any) => {
                handleInputChange(event, { type: "select" });
              }}
              required={formState.routeArrowDirection.required}
              error={!formState.routeArrowDirection.isValid}
              info={formState.routeArrowDirection.info}
              options={routeArrowOptionsArr}
            />
          </Box>

          {/* Display geolocation */}
          <Box sx={{ gridColumn: "1 / 7" }} mb={1}>
            <RadioButtonWithInfo
              label={formState.geolocationMode.label}
              value={formState.geolocationMode.value}
              id="geolocationMode"
              onChange={(event: any) =>
                handleInputChange(event, { type: "radio" })
              }
              info={formState.geolocationMode.info}
              options={geolocationModeArr}
            />
          </Box>

          {/* Display tour points on map */}
          <Box sx={{ gridColumn: "1 / 7" }} mb={1}>
            <RadioButtonWithInfo
              label={formState.displayTourPointsOnMap.label}
              value={formState.displayTourPointsOnMap.value}
              id="displayTourPointsOnMap"
              onChange={(event: any) =>
                handleInputChange(event, { type: "radio" })
              }
              info={DICTIONARY.createTour.fields.displayTourPointsOnMap.info}
              options={displayTourPointsOnMapArr}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Route;

//#region Helpers
const DEFAULT_ZOOM = 12;
const DEFAULT_ROUTE_COLOR = TOUR_GUIDING_COLORS.orange.value;
const DEFAULT_ROUTE_ARROW_DIRECTION = ROUTE_DIRECTION_ARROW_OPTIONS.none.value;

const mapStylesArr = Object.keys(MAP_STYLES).map((key: string) => ({
  value: key,
  ...MAP_STYLES[key],
}));

const geolocationModeArr = Object.keys(GEOLOCATION_MODE).map((key: string) => ({
  ...GEOLOCATION_MODE[key],
}));

const displayTourPointsOnMapArr = Object.keys(DISPLAY_TOUR_POINTS_ON_MAP).map(
  (key: string) => ({
    ...DISPLAY_TOUR_POINTS_ON_MAP[key],
  })
);

const routeArrowOptionsArr = Object.keys(ROUTE_DIRECTION_ARROW_OPTIONS).map(
  (key: string) => ({
    ...ROUTE_DIRECTION_ARROW_OPTIONS[key],
  })
);
//#endregion
