const ROUTES = {
  root: {
    path: "/",
  },
  login: {
    path: "/login",
  },
  signup: {
    path: "/signup",
  },
  // Tours
  tours: {
    path: "/tours",
    tour: {
      path: "/tours/:id",
    },
    create: {
      path: "/tours/create",
    },
    edit: {
      path: "/tours/:id/edit",
    },
    analytics: {
      path: "/tours/analytics",
    },
    promocode: {
      // DEPRECATED
      path: "/tours/:id/promocode",
    },
  },
  // Promocodes
  promocodes: {
    path: "/promocodes",
    create: {
      path: "/promocodes/create",
    },
    edit: {
      path: "/promocodes/:id/edit",
    },
    analytics: {
      path: "/promocodes/analytics",
    },
    ondemand: {
      path: "/promocodes/ondemand",
    },
  },
  // Guides
  guides: {
    path: "/guides",
    create: {
      path: "/guides/create",
    },
    edit: {
      path: "/guides/:id/edit",
    },
  },
  // Business partners
  businessPartners: {
    path: "/business-partners",
    create: {
      path: "/business-partners/create",
    },
    edit: {
      path: "/business-partners/:id/edit",
    },
  },
  // Sellers
  sellers: {
    path: "/sellers",
    create: {
      path: "/sellers/create",
    },
    edit: {
      path: "/sellers/:id/edit",
    },
  },
  // Other
  qrCodeGenerator: {
    path: "/qr-code-generator",
  },
};

export default ROUTES;
