export const PROMOCODE_TYPES: any = {
  // admin: {
  //   label: 'Admin',
  //   value: 'admin',
  // },
  demo: {
    label: "Demo",
    value: "demo",
  },
  reusable: {
    label: "Reusable",
    value: "reusable",
  },
  singleuse: {
    label: "Single use",
    value: "singleuse",
  },
};

export const DISCOUNT_UNITS: any = {
  percentage: {
    label: "Percentage (%)",
    value: "percentage",
  },
  currency: {
    label: "Currency ($)",
    value: "currency",
  },
};

export const PROMOCODE_PATTERN_TYPES: any = {
  digits: {
    label: "Digits",
    value: "digits",
  },
  all: {
    label: "Digits and letters",
    value: "all",
  },
};

export const PROMOCODE_SOURCE_TYPES: any = {
  autoGenerated: {
    label: "Auto generated",
    value: "autoGenerated",
  },
  custom: {
    label: "Custom",
    value: "custom",
  },
};

export const PROMOCODE_PATTERNS = {
  digits: {
    value: "digits",
    label: "Digits",
    options: "0123456789",
  },
  all: {
    value: "all",
    label: "Digits and letters",
    options: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
  },
};

export const PROMOCODE_OWNER_TYPES: any = {
  businessPartner: {
    label: "Business partner",
    value: "businessPartner",
  },
  seller: {
    label: "Seller",
    value: "seller",
  },
  sellerOperator: {
    label: "Seller (operator)",
    value: "sellerOperator",
  },
  guide: {
    label: "Guide",
    value: "guide",
  },
  guideOperator: {
    label: "Guide (operator)",
    value: "guideOperator",
  },
  admin: {
    label: "Admin",
    value: "admin",
  },
  adminOperator: {
    label: "Admin (operator)",
    value: "adminOperator",
  },
  unknown: {
    label: "-",
    value: "unknown",
  },
};

export const PROMOCODE_STATUS: any = {
  active: {
    label: "Active",
    value: "active",
  },
  inactive: {
    label: "Inactive",
    value: "inactive",
  },
};
