import { DICTIONARY } from "../../utils";

//#region validations
const basic = {
  handleEmptyFields: (formState: any, prefix?: string | number) => {
    let emptyFields: string[] = [];

    for (const field in formState) {
      const { label, value, isRequired } = formState[field];

      if (isRequired(formState) && isValueEmpty(value)) {
        formState[field].isValid = false;
        let error = prefix ? `${prefix}.${label}` : label;
        emptyFields.push(error);
      }
    }

    return {
      formState,
      emptyFields,
    };
  },
  handleInvalidFields: async (formState: any, prefix?: string | number) => {
    for (const key in formState) {
      const { value, customValidators } = formState[key];
      if (customValidators.length === 0) {
        continue;
      }
      let formError = "";

      // loop over each validator function of the field
      for (let i = 0; i < customValidators.length; i++) {
        try {
          const { callback, errorMessage } = customValidators[i];

          const isValid = await callback(value, formState); // callback can be async function

          if (!isValid) {
            formState[key].isValid = false;
            formError =
              typeof errorMessage === "function"
                ? errorMessage(value)
                : errorMessage;
            formError = prefix ? `${prefix}.${formError}` : formError;

            return {
              formState,
              formError,
            };
          }
        } catch (e) {
          formState[key].isValid = false;
          formError = DICTIONARY.general.error;

          return {
            formState,
            formError,
          };
        }
      }
    }

    return true;
  },
};

const list = {
  handleEmptyFields: (formState: any, shouldShowPointIndex: boolean) => {
    let emptyFields: string[] = [];

    for (let i = 0; i < formState.length; i++) {
      let item = formState[i];

      for (const field in item) {
        const { label, value, isRequired, innerPropertiesToCheck } =
          item[field];

        if (
          isRequired &&
          isRequired(item) &&
          isValueEmpty(value, innerPropertiesToCheck)
        ) {
          formState[i][field].isValid = false;
          let error = shouldShowPointIndex ? `${i + 1}.${label}` : label;
          emptyFields.push(error);
        }
      }
    }

    return {
      formState,
      emptyFields,
    };
  },
  handleInvalidFields: (formState: any, shouldShowPointIndex: boolean) => {
    for (let i = 0; i < formState.length; i++) {
      let item = formState[i];

      for (const key in item) {
        const { value, customValidators } = item[key];
        if (customValidators === undefined || customValidators.length === 0) {
          continue;
        }

        // loop over each validator function of the field
        for (let i = 0; i < customValidators.length; i++) {
          try {
            const { callback, errorMessage } = customValidators[i];
            const isValid = callback(value, item);

            if (!isValid) {
              formState[i][key].isValid = false;
              let formError =
                typeof errorMessage === "function"
                  ? errorMessage(value)
                  : errorMessage;
              formError = shouldShowPointIndex
                ? `${i + 1}.${formError}`
                : formError;

              return {
                formState,
                formError,
              };
            }
          } catch (e) {
            formState[i][key].isValid = false;
            let formError = DICTIONARY.general.error;

            return {
              formState,
              formError,
            };
          }
        }
      }
    }

    return true;
  },
};
//#endregion

//#region Error message
const createEmptyFormFieldErrorMessage = (invalidFields: string[]) => {
  let commaSepareted = invalidFields.join(", ");
  var indexOfLastComma = commaSepareted.lastIndexOf(",");
  commaSepareted =
    indexOfLastComma > -1
      ? commaSepareted.substring(0, indexOfLastComma) +
        " &" +
        commaSepareted.substring(indexOfLastComma + 1)
      : commaSepareted;
  const formError = `Please fill ${commaSepareted} ${
    invalidFields.length === 1 ? "field" : "fields"
  }.`;

  return formError;
};
//#endregion

const exported = {
  basic,
  list,
  createEmptyFormFieldErrorMessage,
};

export default exported;

//#region Helpers
const isValueEmpty = (value: string, innerPropertiesToCheck?: string[]) => {
  const check = (value: string) =>
    value == null ||
    value === "" ||
    (value !== undefined && value.length === 0);

  if (innerPropertiesToCheck && innerPropertiesToCheck.length > 0) {
    for (let i = 0; i < innerPropertiesToCheck.length; i++) {
      const property: any = innerPropertiesToCheck[i];
      if (check(value[property])) {
        return true;
      }
    }
  }
  return check(value);
};

//#endregion
