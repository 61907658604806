import React, { Fragment } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { useSidebarContext } from "../../context";
import { drawerWidth } from "../../consts";

// App components
import DrawerHeader from "./DrawerHeader";

// Mui
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";

// Icons
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  LocationOn as ToursIcon,
  People as SellerIcon,
  Store as BusinessPartnerIcon,
  Accessibility as GuidesIcon,
  Receipt as PromocodeIcon,
  Dashboard as DashboardIcon,
  BarChart as AnalyticsIcon,
  AddCircleOutline as GenerateOndemandPromocode,
  QrCode2 as QrCodeIcon,
} from "@mui/icons-material";

// Colors
import { blue, grey } from "@mui/material/colors";

const useStyles = makeStyles({
  activeItem: {
    color: `${blue[800]} !important`,
    transition: "0.1s ease",
    "& .MuiListItem-root": {
      background: `${blue[50]} !important`,
    },
  },
  link: {
    color: grey[800],
    textDecoration: "none",
  },
});

const MENU_ITEMS: any = {
  dashboard: {
    label: DICTIONARY.general.sidebar.dashboard.label,
    key: "dashboard",
    to: ROUTES.root.path,
    icon: <DashboardIcon />,
  },
  tours: {
    label: DICTIONARY.general.sidebar.sections.tours.label,
    key: "tours",
    children: [
      {
        key: "tours",
        to: ROUTES.tours.path,
        label: DICTIONARY.general.sidebar.sections.tours.children.tours,
        icon: <ToursIcon />,
      },
      {
        key: "analytics",
        to: ROUTES.tours.analytics.path,
        label: DICTIONARY.general.sidebar.sections.tours.children.analytics,
        icon: <AnalyticsIcon />,
      },
    ],
  },
  promocodes: {
    label: DICTIONARY.general.sidebar.sections.promocodes.label,
    key: "promocodes",
    children: [
      {
        key: "promocodes",
        to: ROUTES.promocodes.path,
        label:
          DICTIONARY.general.sidebar.sections.promocodes.children.promocodes,
        icon: <PromocodeIcon />,
      },
      {
        key: "analytics",
        to: ROUTES.promocodes.analytics.path,
        label:
          DICTIONARY.general.sidebar.sections.promocodes.children.analytics,
        icon: <AnalyticsIcon />,
      },
      {
        key: "ondemand",
        to: ROUTES.promocodes.ondemand.path,
        label: DICTIONARY.general.sidebar.sections.promocodes.children.ondemand,
        icon: <GenerateOndemandPromocode />,
      },
    ],
  },
  roles: {
    label: DICTIONARY.general.sidebar.sections.roles.label,
    key: "roles",
    children: [
      {
        key: "guides",
        to: ROUTES.guides.path,
        label: DICTIONARY.general.sidebar.sections.roles.children.guides,
        icon: <GuidesIcon />,
      },
      {
        key: "sellers",
        to: ROUTES.sellers.path,
        label: DICTIONARY.general.sidebar.sections.roles.children.sellers,
        icon: <SellerIcon />,
      },
      {
        key: "businessPartners",
        to: ROUTES.businessPartners.path,
        label:
          DICTIONARY.general.sidebar.sections.roles.children.businessPartners,
        icon: <BusinessPartnerIcon />,
      },
    ],
  },
  other: {
    label: DICTIONARY.general.sidebar.sections.other.label,
    key: "roles",
    children: [
      {
        key: "qrCodeGenerator",
        to: ROUTES.qrCodeGenerator.path,
        label:
          DICTIONARY.general.sidebar.sections.other.children.qrCodeGenerator,
        icon: <QrCodeIcon />,
      },
    ],
  },
};

export default function AppHeader() {
  const classes = useStyles();
  const { isSidebarOpen, handleDrawerClose, isMobileScreen } =
    useSidebarContext();
  const theme = useTheme();
  const history = useHistory();

  const getExactPath = (sidebarItemPath: string) => {
    try {
      let retVal = false;

      // If the current location includes the main route of the "sidebarItemPath" (like "tours" or "guides", etc.)
      // And it holds the words "create" or "edit" --> also set the main route as active
      let parsedSidebarItemPath = sidebarItemPath.substring(1);
      const pathname = history?.location?.pathname;
      let pathnameArr = pathname.split("/");
      if (pathnameArr[0] === "") {
        pathnameArr.shift();
      }

      if (
        pathnameArr[0] === parsedSidebarItemPath &&
        (pathnameArr.includes("create") || pathnameArr.includes("edit"))
      ) {
        retVal = false;
      } else {
        retVal = true;
      }
      return retVal;
    } catch (error) {
      return true;
    }
  };

  const SidebarList = () => (
    <Fragment>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ overflow: "auto" }}>
        {Object.keys(MENU_ITEMS).map((key: string) =>
          MENU_ITEMS[key].children && MENU_ITEMS[key].children.length > 0 ? (
            <Fragment key={key}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {MENU_ITEMS[key].label}
                  </ListSubheader>
                }
              >
                {MENU_ITEMS[key].children.map((item: any) => (
                  <NavLink
                    to={item.to}
                    exact={getExactPath(item.to)}
                    activeClassName={classes.activeItem}
                    className={classes.link}
                    key={item.key}
                    onClick={isMobileScreen ? handleDrawerClose : () => {}}
                  >
                    <ListItem button disableRipple>
                      <ListItemIcon sx={{ color: "inherit" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
              <Divider />
            </Fragment>
          ) : (
            <Fragment key={key}>
              <NavLink
                to={MENU_ITEMS[key].to}
                exact={getExactPath(MENU_ITEMS[key].to)}
                activeClassName={classes.activeItem}
                className={classes.link}
                onClick={isMobileScreen ? handleDrawerClose : () => {}}
              >
                <ListItem button disableRipple>
                  <ListItemIcon sx={{ color: "inherit" }}>
                    {MENU_ITEMS[key].icon}
                  </ListItemIcon>
                  <ListItemText primary={MENU_ITEMS[key].label} />
                </ListItem>
              </NavLink>
              <Divider />
            </Fragment>
          )
        )}
      </Box>
    </Fragment>
  );

  return (
    <Fragment>
      {isMobileScreen ? (
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="temporary"
          anchor="left"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={handleDrawerClose}
          open={isSidebarOpen}
        >
          <SidebarList />
        </Drawer>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={isSidebarOpen}
        >
          <SidebarList />
        </Drawer>
      )}
    </Fragment>
  );
}
