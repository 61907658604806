import React, { ReactChildren, ReactChild } from "react";
import { useSidebarContext } from "../../context";
import { drawerWidth } from "../../consts";

// Mui
import { useTheme } from "@mui/material/styles";
import { Paper } from "@mui/material";

type Props = {
  children: ReactChild | ReactChildren;
};

export default function AppFooter({ children }: Props) {
  const { isSidebarOpen } = useSidebarContext();
  const theme = useTheme();

  return (
    <Paper
      square
      variant="outlined"
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "60px",
        paddingLeft: isSidebarOpen ? `${drawerWidth}px` : 0,
        transition: theme.transitions.create("padding-left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(isSidebarOpen && {
          transition: theme.transitions.create("padding-left", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
    >
      {children}
    </Paper>
  );
}
