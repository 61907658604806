import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";
import { PROMOCODE_PATTERNS, DISCOUNT_UNITS } from "../../../consts";
import { promocodeApi } from "../../../api";

// Mui
import { Box, Divider, FormLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import TextFieldWithInfo from "../../inputs/TextFieldWithInfo";
import SelectWithInfo from "../../inputs/SelectWithInfo";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";

// Colors
import { grey } from "@mui/material/colors";

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode: boolean;
}

const Details = forwardRef(
  ({ entityData, clearFormError, isEditMode }: Props, ref) => {
    // #region States
    const initalFormState = {
      description: {
        label: DICTIONARY.createPromocode.fields.description.label,
        info: DICTIONARY.createPromocode.fields.description.info,
        value: entityData.description ?? "",
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
      code: {
        label: DICTIONARY.createPromocode.fields.code.label,
        info: DICTIONARY.createPromocode.fields.code.info,
        value: entityData.code ?? "",
        isValid: true,
        isRequired: () => true,
        customValidators: [
          {
            callback: (val: string, formState: any) => {
              return VALIDATORS.string.isLengthEqual(val, 8);
            },
            errorMessage: DICTIONARY.createPromocode.fields.code.error.length,
          },
          {
            callback: (val: string) => {
              for (let i = 0; i < val.length; i++) {
                const char = val[i];
                if (PROMOCODE_PATTERNS.all.options.indexOf(char) === -1) {
                  return false;
                }
              }

              return true;
            },
            errorMessage:
              DICTIONARY.createPromocode.fields.code.error.invalidChar,
          },
          {
            callback: async (val: string) => {
              if (isEditMode) {
                return true;
              }

              const response = await promocodeApi.get(val);
              if (
                Object.keys(response).length === 0 &&
                !response.hasOwnProperty("error")
              ) {
                return true;
              } else {
                return false;
              }
            },
            errorMessage:
              DICTIONARY.createPromocode.fields.code.error.alreadyExist,
          },
        ],
      },
      expirationDate: {
        label: DICTIONARY.createPromocode.fields.expirationDate.label,
        info: DICTIONARY.createPromocode.fields.expirationDate.info,
        // value: entityData?.expirationDate?.toDate() ?? null,
        value:
          entityData?.expirationDate == null
            ? null
            : entityData.expirationDate instanceof Date
            ? entityData.expirationDate
            : entityData.expirationDate.toDate(),
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
      discountUnit: {
        label: DICTIONARY.createPromocode.fields.discountUnit.label,
        info: DICTIONARY.createPromocode.fields.discountUnit.info,
        value: entityData.discountUnit ?? "",
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
      discountValue: {
        label: DICTIONARY.createPromocode.fields.discountValue.label,
        info: DICTIONARY.createPromocode.fields.discountValue.info,
        value: entityData.discountValue ?? "",
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips":
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        case "date": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Validation
    const isFormValid = async (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.form.basic.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.form.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = await HELPERS.form.basic.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createPromocode.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit
    const prepareDataToSave = () => {
      let files: any = [];

      const parsedData = {
        description: formState.description.value,
        code: formState.code.value,
        expirationDate: formState.expirationDate.value,
        discountUnit: formState.discountUnit.value,
        discountValue: formState.discountValue.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = async () => {
      const response = await isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createPromocode.sections.details.label}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 3,
          }}
        >
          {/* Code  */}
          <Box sx={{ gridColumn: "1 / 5" }} mb={1}>
            <TextFieldWithInfo
              label={formState.code.label}
              value={formState.code.value}
              id="code"
              onChange={handleInputChange}
              required={formState.code.isRequired()}
              error={!formState.code.isValid}
              info={formState.code.info}
              disabled={isEditMode}
              autoFocus
            />
          </Box>

          {/* Description */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <TextFieldWithInfo
              label={formState.description.label}
              value={formState.description.value}
              id="description"
              onChange={handleInputChange}
              required={formState.description.isRequired()}
              error={!formState.description.isValid}
              info={formState.description.info}
              multiline
              rows={3}
            />
          </Box>

          {/* End users details */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <Divider />
          </Box>
          <Box sx={{ gridColumn: "span 12" }} mb={2}>
            <FormLabel
              component="legend"
              sx={{ fontSize: 16, color: grey[900] }}
            >
              {DICTIONARY.createPromocode.sections.details.endUser}
            </FormLabel>
          </Box>

          {/* Discount unit */}
          <Box sx={{ gridColumn: "1 / 5" }} mb={1}>
            <SelectWithInfo
              label={formState.discountUnit.label}
              value={formState.discountUnit.value}
              id="discountUnit"
              onChange={(event: any) => {
                handleInputChange(event, { type: "select" });
              }}
              required={formState.discountUnit.isRequired()}
              error={!formState.discountUnit.isValid}
              info={formState.discountUnit.info}
              options={discountUnitsArr}
            />
          </Box>

          {/* Discount value */}
          <Box sx={{ gridColumn: "5 / 9" }} mb={1}>
            <NumberFieldWithInfo
              label={formState.discountValue.label}
              value={formState.discountValue.value}
              id="discountValue"
              onChange={handleInputChange}
              required={formState.discountValue.isRequired()}
              error={!formState.discountValue.isValid}
              info={formState.discountValue.info}
            />
          </Box>

          {/* Expiration Date */}
          <Box sx={{ gridColumn: "9 / 13" }}>
            <DatePicker
              label={formState.expirationDate.label}
              value={formState.expirationDate.value}
              onChange={(newValue: any) =>
                handleInputChange(newValue, {
                  type: "date",
                  id: "expirationDate",
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required={formState.expirationDate.isRequired()}
                  error={!formState.expirationDate.isValid}
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Details;

//#region Helpers
const discountUnitsArr = Object.keys(DISCOUNT_UNITS).map((key: string) => ({
  ...DISCOUNT_UNITS[key],
}));
//#endregion
