// DEPRECATED
import React from "react";
import { DICTIONARY, VALIDATORS } from "../../../../utils";
import { useForm } from "../../../../hooks";
import { tourApi } from "../../../../api";
import { PROMOCODE_SOURCE_TYPES } from "../../../../consts";

// App components
import TextFieldWithInfo from "../../../inputs/TextFieldWithInfo";

// Mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  IconButton,
  Alert,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LoadingButton, DatePicker } from "@mui/lab";

// Icons
import { Save as SaveIcon, Close as CloseIcon } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: (isSubmited: boolean) => void;
  tourId: string;
}
// DEPRECATED
export default function DemoPromocodeDialog({ open, onClose, tourId }: Props) {
  //#region Form
  const initalFormState: any = {
    description: {
      label:
        DICTIONARY.promocodesManager.sections.demo.form.fields.description
          .label,
      info: DICTIONARY.promocodesManager.sections.demo.form.fields.description
        .info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    expirationDate: {
      label:
        DICTIONARY.promocodesManager.sections.demo.form.fields.expirationDate
          .label,
      info: DICTIONARY.promocodesManager.sections.demo.form.fields
        .expirationDate.info,
      value: null,
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    codeSourceType: {
      label:
        DICTIONARY.promocodesManager.sections.demo.form.fields.codeSourceType
          .label,
      info: DICTIONARY.promocodesManager.sections.demo.form.fields
        .codeSourceType.info,
      value: PROMOCODE_SOURCE_TYPES.autoGenerated.value,
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    customCode: {
      label:
        DICTIONARY.promocodesManager.sections.demo.form.fields.customCode.label,
      info: DICTIONARY.promocodesManager.sections.demo.form.fields.customCode
        .info,
      value: "",
      isValid: true,
      isRequired: (formState: any) => {
        // depands on codeSourceType
        const isCustomCodeOptionSelected =
          formState.codeSourceType.value ===
          PROMOCODE_SOURCE_TYPES.custom.value;

        return isCustomCodeOptionSelected;
      },
      customValidators: [
        {
          callback: (val: string, formState: any) => {
            const isCustomCodeOptionSelected =
              formState.codeSourceType.value ===
              PROMOCODE_SOURCE_TYPES.custom.value;
            if (isCustomCodeOptionSelected) {
              return VALIDATORS.string.isLengthEqual(val, 8);
            } else {
              return true;
            }
          },
          errorMessage:
            DICTIONARY.promocodesManager.sections.demo.form.fields.customCode
              .errors.length,
        },
      ],
    },
  };

  const onSubmit = async () => {
    const data = {
      description: formState.description.value,
      expirationDate: formState.expirationDate.value,
      customCode: formState.customCode.value,
    };

    const response = await tourApi.promocode.createDemoPromocode(tourId, data);

    return response;
  };

  const {
    formState,
    formError,
    isSubmitLoading,
    handleInputChange,
    handleSubmit,
    handleCancel,
  } = useForm({ initalFormState, onSubmit, onClose });
  //#endregion

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%" } }} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {DICTIONARY.promocodesManager.sections.demo.form.title}

        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 14,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Form */}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <DialogContent
          dividers
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 2,
            mt: 1,
          }}
        >
          {/* Description */}
          <Box sx={{ gridRow: "1", gridColumn: "span 2" }}>
            <TextFieldWithInfo
              label={formState.description.label}
              value={formState.description.value}
              id="description"
              onChange={handleInputChange}
              required={formState.description.isRequired()}
              error={!formState.description.isValid}
              info={formState.description.info}
              autoFocus
            />
          </Box>

          {/* Expiration Date */}
          <Box sx={{ gridRow: "2", gridColumn: "1 / 2" }}>
            <DatePicker
              label={formState.expirationDate.label}
              value={formState.expirationDate.value}
              onChange={(newValue: any) =>
                handleInputChange(newValue, {
                  type: "date",
                  id: "expirationDate",
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required={formState.expirationDate.isRequired()}
                  error={!formState.expirationDate.isValid}
                  fullWidth
                />
              )}
            />
          </Box>

          {/* Custom code - Radio buttons */}
          <Box sx={{ gridRow: "3", gridColumn: "span 2" }}>
            <FormControl
              component="fieldset"
              required={formState.codeSourceType.isRequired()}
              error={!formState.codeSourceType.isValid}
            >
              <FormLabel component="legend">
                {formState.codeSourceType.label}
              </FormLabel>
              <RadioGroup
                aria-label={formState.codeSourceType.label}
                name="codeSourceType"
                value={formState.codeSourceType.value}
                onChange={(event: any) =>
                  handleInputChange(event, { type: "radio" })
                }
              >
                <FormControlLabel
                  value={PROMOCODE_SOURCE_TYPES.autoGenerated.value}
                  control={<Radio />}
                  label={PROMOCODE_SOURCE_TYPES.autoGenerated.label}
                />
                <FormControlLabel
                  value={PROMOCODE_SOURCE_TYPES.custom.value}
                  control={<Radio />}
                  label={PROMOCODE_SOURCE_TYPES.custom.label}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* Custom code */}
          {formState.codeSourceType.value ===
            PROMOCODE_SOURCE_TYPES.custom.value && (
            <Box sx={{ gridRow: "4", gridColumn: "1 / 2" }}>
              <TextFieldWithInfo
                label={formState.customCode.label}
                value={formState.customCode.value}
                id="customCode"
                onChange={handleInputChange}
                required={formState.customCode.isRequired(formState)}
                error={!formState.customCode.isValid}
                info={formState.customCode.info}
                autoFocus
              />
            </Box>
          )}

          {formError && (
            <Box sx={{ mb: 1, gridColumn: "span 2" }}>
              <Alert severity="error">{formError}</Alert>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            {DICTIONARY.general.button.cancel}
          </Button>

          {/* Submit button */}
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={isSubmitLoading || formError !== ""}
          >
            {DICTIONARY.general.button.generate}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
