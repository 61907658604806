import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, HELPERS, ROUTES } from "../../utils";
import { PUBLISH_STATUS, COUNTRY_LIST } from "../../consts";

// Components
import TourItemMenu from "./TourItemMenu";

// Mui
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IconButton, Paper, Box } from "@mui/material";

// Icons
import {
  MoreVert as MoreIcon,
  Visibility as PublishIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";

// Colors
import { blue } from "@mui/material/colors";

interface Props {
  data: any[];
  onItemUpdate: () => Promise<void>;
}

export default function ToursTable({ data, onItemUpdate }: Props) {
  const history = useHistory();
  const [parsedData, setParsedData] = useState<any[]>(data);

  //#region Init
  useEffect(() => {
    const parsedData = data.map((item) => {
      return {
        ...item,
        createdByName: item.createdBy.name,
        guideName: item.guide?.name || item.createdBy.name,
        // createdAt: HELPERS.date.formatDate(item.createdAt.toDate()),
        // updatedAt: HELPERS.date.formatDate(item.updatedAt.toDate()),
      };
    });

    setParsedData(parsedData);
  }, [data]);
  //#endregion

  //#region View tour
  const handleView = (id: any) => {
    history.push(`${ROUTES.tours.path}/${id}`);
  };
  //#endregion

  //#region Parse values
  const getParsedDate = (value: any) => {
    try {
      return HELPERS.date.formatDate(value.toDate(), "dd/MM/yy");
    } catch (error) {
      return "-";
    }
  };

  const getParsedCountry = (value: any) => {
    try {
      return COUNTRY_LIST[value]?.label || value;
    } catch (error) {}
  };
  //#endregion

  //#region Columns
  const columns: GridColDef[] = [
    {
      field: "coverImage",
      headerName: DICTIONARY.tours.table.columns.coverImage,
      width: 120,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.value}
          sx={{ width: "100px", height: "40px", objectFit: "cover" }}
        />
      ),
    },
    {
      field: "name",
      headerName: DICTIONARY.tours.table.columns.name,
      flex: 2,
    },
    {
      field: "country",
      headerName: DICTIONARY.tours.table.columns.country,
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedCountry(params.value);
      },
      flex: 1,
    },
    {
      field: "location",
      headerName: DICTIONARY.tours.table.columns.location,
      flex: 1,
    },
    {
      field: "guideName",
      headerName: DICTIONARY.tours.table.columns.guide,
      flex: 1,
    },
    {
      field: "createdByName",
      headerName: DICTIONARY.tours.table.columns.createdBy,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: DICTIONARY.tours.table.columns.createdAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: DICTIONARY.tours.table.columns.updatedAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "publishStatus",
      headerName: DICTIONARY.tours.table.columns.publishStatus,
      width: 80,
      renderCell: (params: any) => {
        return params.row.publishStatus === PUBLISH_STATUS.public.value ? (
          <PublishIcon color="success" sx={{ mx: "auto" }} />
        ) : (
          <HideIcon color="error" sx={{ mx: "auto" }} />
        );
      },
    },
    {
      field: "actions",
      headerName: DICTIONARY.tours.table.columns.actions,
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation(); // don't select this row after clicking
              handleMenuClick(event, params.row);
            }}
          >
            <MoreIcon />
          </IconButton>
        );
      },
    },
  ];
  //#endregion

  //#region Menu
  const [tourData, setTourData] = useState<any>(data);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    tourData: any
  ) => {
    setTourData(tourData);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //#endregion

  const handleTourDataUpdate = () => {
    onItemUpdate();
  };

  return (
    <Fragment>
      <Paper sx={{ height: "74vh", width: "100%", p: 1 }}>
        <DataGrid
          rows={parsedData}
          columns={columns}
          disableColumnFilter
          onSelectionModelChange={handleView}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              sx: {
                "&.MuiDataGrid-toolbarContainer": {
                  // justifyContent: "flex-end",
                  px: 1,
                  pt: 1,

                  "& .MuiButton-root": {
                    color: blue[700],
                    mr: 1,
                    "&:hover": {
                      backgroundColor: blue[50],
                    },
                  },
                },
              },
            },
          }}
        />
      </Paper>

      {/* Tour item menu */}
      <TourItemMenu
        tourData={tourData}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onTourDataUpdate={handleTourDataUpdate}
        onItemUpdate={onItemUpdate}
      />
    </Fragment>
  );
}
