import { useState } from "react";
import { DICTIONARY } from "../utils";

interface IInputState {
  label: string;
  value: string;
  info: string;
  isValid: boolean;
  isRequired: (formState?: any) => boolean;
  customValidators: {
    callback: (value: any, formState?: any) => any;
    errorMessage: string;
  }[];
}

interface IFormState {
  [key: string]: IInputState;
}

interface IFormHookProps {
  initalFormState: any;
  onSubmit: () => Promise<any>;
  onClose?: (isSubmited: boolean) => void;
}

export const useForm = ({
  initalFormState,
  onSubmit,
  onClose,
}: IFormHookProps) => {
  // States
  const [formState, setFormState] = useState<IFormState>(initalFormState);
  const [formError, setFormError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleInputChange = (event: any, payload?: any) => {
    const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
    let key: string = "";
    let val: any;

    switch (type) {
      case "text":
      case "textarea": {
        const { id, value } = event.target;
        key = id;
        val = value;
        break;
      }
      case "number": {
        const { id, value } = event.target;
        key = id;
        val = Number(value);
        break;
      }
      case "select": {
        const { name, value } = event.target;
        key = name;
        val = value;
        break;
      }
      case "checkbox": {
        const { name, checked } = event.target;
        key = name;
        val = checked;
        break;
      }
      case "radio": {
        const { name, value } = event.target;
        key = name;
        val = value;
        break;
      }
      case "autocomplete": {
        key = payload.name;
        val = payload.value;
        break;
      }
      case "file": {
        const { id, files } = event.target;
        key = id;
        val = files != null && files[0];
        break;
      }
      case "date": {
        key = payload.id;
        val = event;
        break;
      }
      default: {
        break;
      }
    }

    setFormState((prevState: any) => {
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
          isValid: true,
        },
      };
    });

    // Clear errors
    setFormError("");
  };

  const validateForm = () => {
    //#region Helpers
    const isValueEmpty = (value: string) =>
      value === "" || value == null || value === undefined;
    //#endregion

    try {
      let formError = "";
      let requiredValuesAccumalativeError: string[] = [];

      // 1.A Required fields - Check
      for (let key in formState) {
        const { label, value, isRequired } = formState[key];

        // 1.A Required fields - Check
        if (isRequired(formState) && isValueEmpty(value)) {
          requiredValuesAccumalativeError.push(`"${label}"`);
          formState[key].isValid = false;
        }
      }

      // 1. B Required fields - Error message
      if (requiredValuesAccumalativeError.length > 0) {
        if (requiredValuesAccumalativeError.length === 1) {
          formError = `${requiredValuesAccumalativeError[0]} is required.`;
        } else {
          let errorStr = requiredValuesAccumalativeError.join(", ");
          const lastCommaIndex = errorStr.lastIndexOf(",");
          errorStr =
            errorStr.substring(0, lastCommaIndex) +
            " and " +
            errorStr.substring(lastCommaIndex + 1);

          formError = `${errorStr} are required.`;
        }

        setFormState(formState); // Set form fields as invalid in needed
        return formError;
      }

      // 2.A Custom validators - Check
      for (let key in formState) {
        const { value, customValidators } = formState[key];

        if (customValidators.length > 0) {
          for (let i = 0; i < customValidators.length; i++) {
            try {
              const { callback, errorMessage } = customValidators[i];
              const isValid = callback(value, formState);

              if (!isValid) {
                formState[key].isValid = false;
                setFormState(formState); // Set form fields as invalid in needed

                formError = errorMessage;
                return formError;
              }
            } catch (e) {
              formState[key].isValid = false;
              setFormState(formState); // Set form fields as invalid in needed

              formError = DICTIONARY.general.error;
              return formError;
            }
          }
        }
      }

      return ""; // Validation has succeeded
    } catch (error) {
      return DICTIONARY.general.error;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitLoading(true);

      // Validate
      const formError: any = validateForm();
      if (formError !== "") {
        setFormError(formError);
        setIsSubmitLoading(false);
        return;
      }

      // Submit form

      const response: any = await onSubmit();

      if (
        response.hasOwnProperty("error") ||
        response?.data.hasOwnProperty("error")
      ) {
        const errorMessage =
          response.error || response.data.error || DICTIONARY.general.error;
        setIsSubmitLoading(false);
        setFormError(errorMessage);
        return;
      }

      setIsSubmitLoading(false);
      setFormState(initalFormState);
      onClose && onClose(true);
    } catch (error) {
      setFormError(DICTIONARY.general.error);
    }
  };

  const handleCancel = () => {
    setFormState(initalFormState);
    setFormError("");
    onClose && onClose(false);
  };

  return {
    formState,
    formError,
    isSubmitLoading,
    handleInputChange,
    handleSubmit,
    handleCancel,
  };
};
