import { format } from "date-fns";

// Firebase
import { Timestamp } from "../../config/firebase-config";

const formatDate = (date: Date, Pattern = "LLLL dd, yyyy") => {
  try {
    return format(date, Pattern);
  } catch (error) {
    return "-";
  }
};

const parseTimestamp = (clientTimestamp: any) => {
  // Parsing timestamp that was creaded by: "admin.firestore.Timestamp.now()" in a firebase function
  try {
    const seconds = clientTimestamp._seconds || clientTimestamp.seconds;
    const nanosecs =
      clientTimestamp._nanoseconds || clientTimestamp.nanoseconds;

    return new Timestamp(seconds, nanosecs);
  } catch (e) {
    return null;
  }
};

const exported = {
  formatDate,
  parseTimestamp,
};

export default exported;
