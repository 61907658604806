import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { tourApi } from "../../api";
import { VIEW_STRUCTURE_OPTIONS } from "../../consts";

// Mui
import { Box, Container, Typography, Button } from "@mui/material";

// App components
import TourItem from "../../components/tour/TourItem";
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";
import SearchBox from "../../components/shared/SearchBox";
import ViewStructureToggle from "../../components/shared/ViewStructureToggle";
import ToursTable from "../../components/tour/ToursTable";

function Tours() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const originalTours = useRef<any>([]);
  const [parsedTours, setParsedTours] = useState<any[]>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const query = {
        field: "deletedAt",
        operator: "==",
        value: null,
      };
      const response = await tourApi.getAll(query);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      // Success
      originalTours.current = sortTours(response);
      setParsedTours(originalTours.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region View structure
  const [viewStructure, setViewStructure] = useState<string | null>(
    VIEW_STRUCTURE_OPTIONS.table.value
  );
  const handleViewButtonClick = (newViewStructure: string | null) => {
    setViewStructure(newViewStructure);
  };
  //#endregion

  //#region Search
  const [searchValue, setSearchValue] = useState<string | null>("");
  const handleSearchValueChange = (searchValue: string | null) => {
    setSearchValue(() => {
      let filteredTours: any = filterTours(originalTours.current, searchValue);
      filteredTours = sortTours(filteredTours);

      setParsedTours(filteredTours);

      return searchValue;
    });
  };
  //#endregion

  const GridView = () => {
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={2}
        sx={{ marginTop: 3 }}
      >
        {parsedTours.length === 0 ? (
          <Box
            gridColumn={{
              xs: "span 12",
            }}
          >
            <EmptyState
              title={DICTIONARY.tours.emptyStateFiltered}
              icon="tour"
              py={10}
            />
          </Box>
        ) : (
          parsedTours.map((item: any) => (
            <Box
              key={item.id}
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 3",
              }}
            >
              <TourItem data={item} onItemUpdate={fetchData} />
            </Box>
          ))
        )}
      </Box>
    );
  };

  const TableView = () => {
    return (
      <Box sx={{ mt: 3 }}>
        <ToursTable data={parsedTours} onItemUpdate={fetchData} />
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.tours.title}
              </Typography>

              {/* Search tour */}
              <Box
                sx={{
                  width: "440px",
                }}
              >
                <SearchBox
                  searchValue={searchValue}
                  onChange={handleSearchValueChange}
                />
              </Box>
            </Box>

            {/* Right side */}
            <Box
              sx={{
                maxWidth: "600px",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Change view */}
              <ViewStructureToggle
                viewStructure={viewStructure}
                onButtonClick={handleViewButtonClick}
              />
              {/* Create new tour */}
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={ROUTES.tours.create.path}
              >
                {DICTIONARY.tours.create}
              </Button>
            </Box>
          </Box>

          {isLoading ? (
            <Loader />
          ) : error ? (
            <EmptyState
              title={DICTIONARY.tours.emptyState}
              icon="tour"
              py={10}
            />
          ) : viewStructure === VIEW_STRUCTURE_OPTIONS.table.value ? (
            <TableView />
          ) : (
            <GridView />
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default Tours;

//#region Helpers
const sortTours = (tours: any) => {
  function callback(a: any, b: any) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};

const filterTours = (tours: any[], filteredValue: string | null) => {
  function callback(item: any, filteredValue: string) {
    try {
      const isIncludedInWord = (item: string) =>
        item.toLocaleLowerCase().includes(filteredValue);

      const isIncludedInTags = (tags: [] | undefined) => {
        if (tags && tags.length > 0) {
          let filteredTags = tags.filter((tag) => isIncludedInWord(tag));
          return filteredTags.length > 0;
        } else {
          return false;
        }
      };

      if (
        isIncludedInWord(item.name) ||
        isIncludedInWord(item.country) ||
        isIncludedInWord(item.location) ||
        isIncludedInTags(item.tags)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  try {
    if (filteredValue === undefined) {
      return false;
    }

    const parsedFilteredValue: string =
      filteredValue?.toLocaleLowerCase() || "";

    let filteredTours = tours.filter((item: any) =>
      callback(item, parsedFilteredValue)
    );

    return filteredTours;
  } catch (error) {
    return tours;
  }
};
//#endregion
