const auth = {
  // Login and Signup pages
  title: {
    login: "Log in",
    signup: "Sign up",
  },
  form: {
    field: {
      email: "Email",
      name: "Name",
      password: "Password",
    },
    error: {
      badlyFormattedEmail: "The email address is badly formatted.",
      incorrectEmailOrPassword: "Incorrect email or password.",
      emailInUse: "The email address is already in use by another account.",
      weakPassword: "Password should be at least 6 characters.",
      nameLength: "Name must contain a least 2 characters.",
      generalError: "Error. Please try again or contact support.",
      emailNotVerified: "Email has not been verified yet.", // Add button to resend email
      resendEmailVerification: "Resend",
    },
  },
  button: {
    login: "Log in",
    signup: "Sign up",
    google: "Continue with Google",
    forgotPassword: "Forgot password?",
    dontHaveAccount: `Don't have an account?`,
    alreadyHaveAccount: `Already have an account?`,
  },
  snackbar: {
    emailVerification: "Check your email for verification",
    resetPassowrd: "An email with reset instructions has been sent",
  },
  forgotPassword: {
    title: "Forgot password",
    subtitle: "Fill in your email to reset your password",
    form: {
      field: {
        email: "Email",
      },
      error: {
        generalError: `Couldn't send a password reset email. Please try again or contact support.`,
        badlyFormattedEmail: "The email address is badly formatted.",
      },
    },
  },
  identification: {
    title: "Identification",
    form: {
      field: {
        code: "Code",
      },
      error: {
        generalError: "Error. Please try again or contact support.",
        invalid: "Invalid identification code.",
      },
    },
    button: {
      submit: "Submit",
    },
  },
  userCredsDialog: {
    title: "User credentials",
    text: {
      name: "Name",
      email: "Email",
      userId: "User ID",
    },
    buttons: {
      update: "Update user password",
      createPassword: "Create new password",
    },
    fields: {
      password: {
        label: "Password",
        info: "Please copy the password and hand it over to the user.",
        error: {},
      },
    },
  },
};

export default auth;
