import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, HELPERS, ROUTES } from "../../utils";
import { PUBLISH_STATUS } from "../../consts";

// Components
import GuideItemMenu from "./GuideItemMenu";

// Mui
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IconButton, Paper, Box } from "@mui/material";

// Icons
import {
  MoreVert as MoreIcon,
  Visibility as PublishIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";

// Colors
import { blue, grey } from "@mui/material/colors";

interface Props {
  data: any[];
  onItemUpdate: () => Promise<void>;
}

export default function GuidesTable({ data, onItemUpdate }: Props) {
  const history = useHistory();
  const [parsedData, setParsedData] = useState<any[]>(data);

  //#region Init
  useEffect(() => {
    const parsedData = data.map((item) => {
      return {
        ...item,
        // createdByName: item.createdBy.name,
        // createdAt: HELPERS.date.formatDate(item.createdAt.toDate()),
        // updatedAt: HELPERS.date.formatDate(item.updatedAt.toDate()),
      };
    });

    setParsedData(parsedData);
  }, [data]);
  //#endregion

  //#region View tour
  const handleView = (id: any) => {
    history.push(`${ROUTES.tours.path}/${id}`);
  };
  //#endregion

  //#region Columns
  const getParsedDate = (value: any) => {
    try {
      return HELPERS.date.formatDate(value.toDate(), "dd/MM/yy");
    } catch (error) {
      return "-";
    }
  };

  const columns: GridColDef[] = [
    {
      field: "avatar",
      headerName: DICTIONARY.guides.table.columns.avatar,
      width: 80,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.value}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: `1px solid ${grey[300]}`,
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: DICTIONARY.guides.table.columns.name,
      flex: 1,
    },
    {
      field: "email",
      headerName: DICTIONARY.guides.table.columns.email,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: DICTIONARY.tours.table.columns.createdAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "updatedAt",
      headerName: DICTIONARY.tours.table.columns.updatedAt,
      type: "date",
      valueFormatter: (params: GridValueFormatterParams) => {
        return getParsedDate(params.value);
      },
      flex: 1,
    },
    {
      field: "actions",
      headerName: DICTIONARY.tours.table.columns.actions,
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation(); // don't select this row after clicking
              handleMenuClick(event, params.row);
            }}
          >
            <MoreIcon />
          </IconButton>
        );
      },
    },
  ];
  //#endregion

  //#region Menu
  const [guideData, setGuideData] = useState<any>(data);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    guideData: any
  ) => {
    setGuideData(guideData);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //#endregion

  const handleTourDataUpdate = () => {
    onItemUpdate();
  };

  return (
    <Fragment>
      <Paper sx={{ height: "74vh", width: "100%", p: 1 }}>
        <DataGrid
          rows={parsedData}
          columns={columns}
          disableColumnFilter
          // onSelectionModelChange={handleView}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              sx: {
                "&.MuiDataGrid-toolbarContainer": {
                  // justifyContent: "flex-end",
                  px: 1,
                  pt: 1,

                  "& .MuiButton-root": {
                    color: blue[700],
                    mr: 1,
                    "&:hover": {
                      backgroundColor: blue[50],
                    },
                  },
                },
              },
            },
          }}
        />
      </Paper>

      {/* Guide item menu */}
      <GuideItemMenu
        data={guideData}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onTourDataUpdate={handleTourDataUpdate}
        onItemUpdate={onItemUpdate}
      />
    </Fragment>
  );
}
