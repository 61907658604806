import { useRef } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  Entity,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./richTextEditor.css";

type Props = {
  editorState: any;
  onEditorStateChange: any;
};

const RichTextEditor = ({ editorState, onEditorStateChange }: Props) => {
  // const [editorState, setEditorState] = useState(EditorState.createEmpty()); // the editorState is coming as a prop
  const containerRef = useRef<any>(null);

  const _toggleInlineStyle = (inlineStyle: string) => {
    let newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

    // setEditorState(newState);
    onEditorStateChange(newState);
  };

  const _toggleBlockType = (blockType: string) => {
    let newState = RichUtils.toggleBlockType(editorState, blockType);

    // setEditorState(newState);
    onEditorStateChange(newState);
  };

  const _addLink = () => {
    try {
      let linkUrl = window.prompt("Add link that starts with https://");

      const selection = editorState.getSelection();

      const entityKey = Entity.create("LINK", "MUTABLE", {
        url: linkUrl,
        style: {
          background: "red",
        },
      });

      let newState = RichUtils.toggleLink(editorState, selection, entityKey);

      // setEditorState(newState);
      onEditorStateChange(newState);
    } catch (error) {}
  };

  const _removeLink = () => {
    try {
      const selection = editorState.getSelection();
      const newState = RichUtils.toggleLink(editorState, selection, null);

      // setEditorState(newState);
      onEditorStateChange(newState);
    } catch (error) {}
  };

  return (
    <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={_toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={_toggleInlineStyle}
      />
      <LinkControl
        editorState={editorState}
        onAddLink={_addLink}
        onRemoveLink={_removeLink}
      />
      <div className="RichEditor-editor">
        <Editor
          editorState={editorState}
          onChange={onEditorStateChange}
          placeholder="Tell a story..."
          spellCheck={true}
          ref={containerRef}
        />
      </div>
    </div>
  );
};

export default RichTextEditor;

//#region Rich text button control
const StyleButton = ({ active, label, onToggle, style }: any) => {
  const _onToggle = (e: any) => {
    e.preventDefault();
    onToggle(style);
  };

  let className = "RichEditor-styleButton";
  if (active) {
    className += " RichEditor-activeButton";
  }

  return (
    <span className={className} onClick={_onToggle}>
      {label}
    </span>
  );
};
//#endregion

//#region Inline style controls (bold, italic, ..)
var INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];

const InlineStyleControls = ({ editorState, onToggle }: any) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type: any) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
//#endregion

//#region Block types controls (h1, h2, ..)
const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
];

const BlockStyleControls = ({ editorState, onToggle }: any) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
//#endregion

//#region Link control (a href)

const LinkControl = ({ editorState, onAddLink, onRemoveLink }: any) => {
  return (
    <div className="RichEditor-controls">
      <StyleButton key="add-link" label="Add link" onToggle={onAddLink} />
      <StyleButton
        key="remove-link"
        label="Remove link"
        onToggle={onRemoveLink}
      />
    </div>
  );
};
//#endregion
