// Firebase
import { functions } from "../config/firebase-config";
import { httpsCallable } from "firebase/functions";
import { db } from "../config/firebase-config";
import { doc, setDoc, Timestamp } from "firebase/firestore";

// NOTE: The user methogs are relevant for businessPartners, guides and sellers
const user = {
  create: async function (data: any, adminData: any, role: string) {
    try {
      // 1. Create the user auth
      const createUser = httpsCallable(functions, "createUser");
      const response: any = await createUser({
        role,
        ...data,
      });

      // If user auth created successfully
      if (response?.data?.uid) {
        // 2. Create User record with "userType" field
        await setDoc(doc(db, "users", response.data.uid), {
          // Uset role
          role,
          // Other meta details
          createdAt: Timestamp.fromDate(new Date()),
          deletedAt: null,
          createdBy: {
            id: adminData.auth.uid,
            name: adminData.data.name,
            avatar: adminData.data.avatar,
          },
        });
      }
      return response;
    } catch (error) {
      return {
        error,
      };
    }
  },
  updatePassword: async function (uid: string, data: any) {
    try {
      const updateUserPassword = httpsCallable(functions, "updateUserPassword");
      const response = await updateUserPassword({
        uid,
        ...data,
      });

      return response;
    } catch (error) {
      return {
        error,
      };
    }
  },
};

export default user;
