import React, { useState, useEffect } from "react";
import { tourApi } from "../../api";
import { PUBLISH_STATUS } from "../../consts";

// Mui
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Checkbox,
  Typography,
  Chip,
  ListItem,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Colors
import { grey } from "@mui/material/colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface EnitityI {
  id: string;
  name: string;
}

type Props = {
  label: string;
  info: string;
  id: string;
  value: EnitityI[] | EnitityI | null;
  error: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, payload: any) => void;
  multiple?: boolean;
};

const AutocompleteSelectTours = ({
  label,
  info,
  id,
  value,
  error,
  disabled = false,
  onChange,
  multiple = true,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly EnitityI[]>([]);
  const loading = open && options.length === 0;

  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        let response = [];

        response = await tourApi.getAll();

        if (response) {
          response = response.filter((item: any) => {
            if (item.publishStatus === PUBLISH_STATUS.public.value) {
              return true;
            } else {
              return false;
            }
          });

          response = response.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
        }

        if (active) {
          let options = [];

          if (multiple) {
            options.push({
              id: "all",
              name: "All",
            });
          }
          options = [...options, ...response];

          setOptions(options);
        }
      } catch (error) {}
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = (event: any, selectedValues: any | null) => {
    if (multiple && Array.isArray(value)) {
      let retVal: readonly EnitityI[] = [...selectedValues];

      // Find "all" option in selectedValues
      const allIndex = selectedValues.findIndex(
        (option: any) => option.id === "all"
      );

      // Find "all" option in selectedValues
      const allIndexPrevState =
        value && value.findIndex((option: any) => option.id === "all");

      // "all" is not selected now, but was selected in the prevState (=value)
      if (allIndex === -1 && allIndexPrevState > -1) {
        retVal = []; // none is selected
      } else if (allIndex > -1 && allIndex === selectedValues.length - 1) {
        // If "all" is in the selectedValues And it is the last option to be chosen
        retVal = options; // Set all options
      } else {
        // "all" may be selected, but not all tours are selected --> change "all" from being selected
        retVal = selectedValues.filter((option: any) => option.id !== "all");
      }

      onChange(event, { type: "autocomplete", id: id, value: retVal });
    } else {
      onChange(event, { type: "autocomplete", id: id, value: selectedValues });
    }
  };

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      limitTags={4}
      fullWidth
      size="small"
      open={open}
      disabled={disabled}
      disableCloseOnSelect
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      // Options (list)
      options={options}
      filterOptions={(options) => {
        const filteredOptions = options.filter((option) => {
          const parsedInput = inputValue.toLowerCase();

          if (
            option.id.toLowerCase().includes(parsedInput) ||
            option.name.toLowerCase().includes(parsedInput)
          ) {
            return true;
          } else {
            return false;
          }
        });
        return filteredOptions;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem
            {...props}
            key={option.id}
            sx={{
              borderBottom:
                option.id === "all" ? `1px solid ${grey[400]}` : "none",
            }}
          >
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                sx={{
                  width: 14,
                  height: 14,
                  mr: 2,
                }}
                size="small"
              />
            )}

            <Typography>{option.name}</Typography>
            {option.id !== "all" && (
              <Typography sx={{ fontSize: 12, color: grey[700], ml: 0.5 }}>
                ({option.id})
              </Typography>
            )}
          </ListItem>
        );
      }}
      // Value
      value={value}
      onChange={(event: any, newValue: any | null) => {
        // setValue(newValue);
        handleChange(event, newValue);

        // if only one option is being selected --> close the dropdown after this
        if (multiple === false) {
          setOpen(false);
        }
      }}
      // Input
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            margin="none"
            label={label}
            error={error}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      // Tags (chips)
      renderTags={(tagValue, getTagProps) => {
        const filteredTags = tagValue.filter(
          (option: any) => option.id !== "all"
        );

        return filteredTags.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.name} size="small" />
        ));
      }}
    />
  );
};

export default AutocompleteSelectTours;
