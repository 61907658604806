const isLocalhost = () => {
  try {
    if (window.location.hostname === "localhost") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const safeLocalStorage = () => {
  try {
    return localStorage ? localStorage : window;
  } catch (e) {
    return window;
  }
};

const isVerbose = () => {
  // @ts-ignore
  return safeLocalStorage().isUnderDevelopment === "true";
};

export default {
  isLocalhost,
  safeLocalStorage,
  isVerbose,
};
