import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";
import { OPERATORS_OF_BP } from "../../../consts";
import { guideApi, sellerApi, adminApi } from "../../../api";

// Mui
import { Box } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import SelectWithInfo from "../../inputs/SelectWithInfo";
import AutocompleteSelectEntity from "../../inputs/AutocompleteSelectEntity";

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode?: boolean;
}

const Operator = forwardRef(
  ({ entityData, clearFormError, isEditMode }: Props, ref) => {
    // #region States
    const initalFormState = {
      operatorType: {
        label: DICTIONARY.createBusinessPartner.fields.operatorType.label,
        info: DICTIONARY.createBusinessPartner.fields.operatorType.info,
        value: entityData.operatorType ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      operatedBy: {
        label: DICTIONARY.createBusinessPartner.fields.operatedBy.label,
        info: DICTIONARY.createBusinessPartner.fields.operatedBy.info,
        value: entityData.operatedBy ?? { name: "", id: "" },
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: any) => val.name !== "",
            errorMessage:
              DICTIONARY.createBusinessPartner.fields.operatedBy.error.required,
          },
        ],
      },
    };

    const [formState, setFormState] = useState(initalFormState);

    const getRelevantEntityApi = (operatorType: string) => {
      let callback = null;

      switch (operatorType) {
        case OPERATORS_OF_BP.guideOperator.value: {
          callback = guideApi.getAll;
          break;
        }
        case OPERATORS_OF_BP.sellerOperator.value: {
          callback = sellerApi.getAll;
          break;
        }
        case OPERATORS_OF_BP.adminOperator.value: {
          callback = adminApi.getAll;
          break;
        }
        default: {
          break;
        }
      }

      return callback;
    };

    const [operatedByApiCallback, setOperatedByApiCallback] = useState<any>(
      getRelevantEntityApi(initalFormState.operatorType.value)
    );
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips":
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        const newState = { ...prevState };

        if (key === "operatorType") {
          newState.operatedBy.value = { name: "", id: "" };
          setOperatedByApiCallback(null);
          const callback = getRelevantEntityApi(val);
          setOperatedByApiCallback(callback);
        }

        return {
          ...newState,
          [key]: {
            ...newState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createBusinessPartner.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let fileUrl: string = "";
      let files: any = [];

      const parsedData = {
        operatorType: formState.operatorType.value,
        operatedBy: formState.operatedBy.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createBusinessPartner.sections.operator.label}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 3,
          }}
        >
          {/* Operator Type */}
          <Box sx={{ gridColumn: "1 / 7" }} mb={1}>
            <SelectWithInfo
              label={formState.operatorType.label}
              value={formState.operatorType.value}
              id="operatorType"
              onChange={(event: any) => {
                handleInputChange(event, { type: "select" });
              }}
              required={formState.operatorType.required}
              error={!formState.operatorType.isValid}
              info={formState.operatorType.info}
              options={operatorsArr}
            />
          </Box>

          <Box sx={{ gridColumn: "7 / 13" }} mb={1}>
            <AutocompleteSelectEntity
              label={formState.operatedBy.label}
              info={formState.operatedBy.info}
              id="operatedBy"
              value={formState.operatedBy.value}
              error={!formState.operatedBy.isValid}
              disabled={formState.operatorType.value === ""}
              onInputChange={handleInputChange}
              apiCallback={() => operatedByApiCallback}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Operator;

//#region Helpers
const operatorsArr = Object.keys(OPERATORS_OF_BP).map((key: string) => ({
  ...OPERATORS_OF_BP[key],
}));
//#endregion
