import { useState, useEffect } from "react";
import { DICTIONARY, HELPERS } from "../../utils";
import { logEventApi } from "../../api";
import { parse } from "date-fns";

// App components
import ChartContainer from "./ChartContainer";

// Mui
import { Paper, Box, Typography } from "@mui/material";

// Recharts
import { ResponsiveContainer, PieChart, Pie, Tooltip } from "recharts";

// Colors
import { grey } from "@mui/material/colors";

// User agent parser
import { UAParser } from "ua-parser-js";
const parser = new UAParser();

const CHART_HEIGHT = 400;

type Props = {
  tour: any;
};

export default function GeolocationPermissionBlockedLogByDeviceChart({
  tour,
}: Props) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  //#region Init
  const fetchData = async (tourId: string) => {
    try {
      setIsLoading(true);
      const queryFilterArr = [
        {
          field: "tourId",
          operator: "==",
          value: tourId,
        },
        {
          field: "event",
          operator: "==",
          value: "geolocation_permission_blocked",
        },
      ];

      const response = await logEventApi.getAll(queryFilterArr);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const parsedData = convertToChartData(response);
      setData(parsedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    if (tour && "id" in tour) {
      setError("");
      (async () => {
        fetchData(tour.id);
      })();
    } else {
      setError(DICTIONARY.general.chart.errors.selectTour);
    }
  }, [tour]);
  //#endregion

  //#region Chart data
  const convertToChartData = (rawData: any) => {
    try {
      let retVal: any[] = [];
      let devicessMap: any = {};

      if (rawData && rawData.length > 0) {
        rawData.forEach((item: any) => {
          const { userAgent, deviceUid } = item;

          parser.setUA(userAgent);
          let result = parser.getResult();
          let deviceKey = `${result.device.vendor || "Unknown"} \n ${
            result.os.name || "Unknown"
          } \n ${result.browser.name || "Unknown"}`;

          // deviceUid is more unique than userAgent (started to collect deviceUid on April 19th)
          const uid = deviceUid || userAgent.replace(/\D+/g, "");

          if (deviceKey in devicessMap) {
            if (uid in devicessMap[deviceKey]) {
              devicessMap[deviceKey][uid] += 1;
            } else {
              devicessMap[deviceKey][uid] = 1;
            }
          } else {
            devicessMap[deviceKey] = {};
            devicessMap[deviceKey][uid] = 1;
          }
        });

        retVal = Object.keys(devicessMap).map((deviceKey: any) => {
          return {
            deviceKey: deviceKey,
            count: Object.keys(devicessMap[deviceKey]).length || 0,
          };
        });

        retVal = sortEntities(retVal, "count");
      }

      return retVal;
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <ChartContainer
      title={
        DICTIONARY.toursAnalytics.charts.geolocationPermissionBlockedByDevice
          .title
      }
      actions={[]}
      isLoading={isLoading}
      error={error}
      style={{
        height: `${CHART_HEIGHT + 80}px`,
        flex: 1,
      }}
    >
      <ResponsiveContainer height={CHART_HEIGHT} width="100%">
        <PieChart margin={{ right: 10, top: 5, left: 0, bottom: 5 }}>
          <Pie
            dataKey="count"
            isAnimationActive={false}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={110}
            fill="#00838f"
            label
          />
          <Tooltip content={<CustomTooltip payload={data} />} />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

//#region Helpers
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <Paper sx={{ p: 1 }}>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography
            sx={{ color: grey[600], fontSize: 12, whiteSpace: "pre-line" }}
          >
            {`Device: \n OS: \n Browser:`}
          </Typography>
          <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
            {payload[0].payload.deviceKey}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Count:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.count}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

const sortEntities = (tours: any, key: string, options?: any) => {
  function callback(a: any, b: any) {
    // timestamp
    if (options && options.type === "timestamp") {
      a = parse(a[key], options.format, new Date());
      b = parse(b[key], options.format, new Date());

      return a - b;
    }

    // Other (number, string, ..)
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
