import React, { Fragment, useState } from "react";
import { DICTIONARY } from "../../utils";
import { MAP_STYLES } from "../../consts";

// Mui
import { styled } from "@mui/material/styles";
import {
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
  Tooltip,
} from "@mui/material";

// Colors
import { green, grey } from "@mui/material/colors";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
  },
  "& .MuiToggleButtonGroup-grouped": {
    backgroundColor: "white",
    border: `1px solid ${grey[400]} !important`,
    borderRadius: `4px !important`,
    "&.Mui-disabled": {},
    "&:not(:first-of-type)": {},
    "&:first-of-type": {},
  },
  "& .MuiToggleButton-root": {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: green[400],
      border: `2px solid ${green[500]} !important`,
    },
  },
}));

type MapStyleSwitcherProps = {
  initialMapStyle: string;
  onMapStyleChange: (value: string | null) => void;
};

export default function MapStyleSwitcher({
  initialMapStyle,
  onMapStyleChange,
}: MapStyleSwitcherProps) {
  const [selectedMapStyle, setSelectedMapStyle] = useState<any>(
    initialMapStyle ? initialMapStyle : mapStylesArr[0].value
  );

  const handleMapStyleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMapStyle: string | null
  ) => {
    setSelectedMapStyle(newMapStyle);
    onMapStyleChange(newMapStyle);
  };

  return (
    <Fragment>
      <FormLabel
        component="label"
        sx={{
          display: "block",
          fontSize: (theme) => theme.typography.pxToRem(14),
        }}
      >
        {DICTIONARY.createTour.fields.mapStyle.label}
      </FormLabel>
      <StyledToggleButtonGroup
        value={selectedMapStyle}
        exclusive
        onChange={handleMapStyleChange}
        aria-label="Selected map style"
        size="small"
        color="primary"
      >
        {mapStylesArr.map((item: any) => (
          <ToggleButton
            key={item.value}
            value={item.value}
            aria-label={item.label}
            sx={{ width: "54px", height: "54px" }}
          >
            <Tooltip title={item.label} arrow>
              <img
                src={item.image}
                alt={item.label}
                style={{ minWidth: "44px", height: "44px", objectFit: "cover" }}
              />
            </Tooltip>
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Fragment>
  );
}

//#region Helpers
const mapStylesArr = Object.keys(MAP_STYLES).map((key: string) => ({
  value: key,
  ...MAP_STYLES[key],
}));
//#endregion
