import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";
import { PROMOCODE_STATUS } from "../../../consts";

// Mui
import { Box } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import RadioButtonWithInfo from "../../inputs/RadioButtonWithInfo";

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode: boolean;
}

const Summary = forwardRef(
  ({ entityData, clearFormError, isEditMode }: Props, ref) => {
    // #region States
    const initalFormState = {
      status: {
        label: DICTIONARY.createPromocode.fields.status.label,
        info: DICTIONARY.createPromocode.fields.status.info,
        value: entityData.status
          ? entityData.status
          : PROMOCODE_STATUS.active.value,
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "radio": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips":
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        case "date": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.form.basic.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.form.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.form.basic.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createPromocode.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let files: any = [];

      const parsedData = {
        status: formState.status.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onSubmit = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // Validate fields
      const response = isFormValid(formState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onSubmit,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createPromocode.sections.summary.label}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 3,
          }}
        >
          {/* Publish status radio buttons */}
          <Box sx={{ gridColumn: "1 / 8" }} mb={1}>
            <RadioButtonWithInfo
              label={formState.status.label}
              value={formState.status.value}
              id="status"
              onChange={(event: any) =>
                handleInputChange(event, { type: "radio" })
              }
              info={DICTIONARY.createPromocode.fields.status.info}
              options={promocodeStatusArr}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default Summary;

//#region Helpers
const promocodeStatusArr = Object.keys(PROMOCODE_STATUS).map((key: string) => ({
  ...PROMOCODE_STATUS[key],
}));
//#endregion
