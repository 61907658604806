import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { blue, teal, amber, grey } from "@mui/material/colors";

/*
  Material UI default breakpoints:
    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
*/

declare module "@mui/material/styles" {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}

let theme = createTheme({
  palette: {
    background: {
      default: grey[100],
    },
    primary: {
      main: amber[600],
      contrastText: "#fff",
    },
    secondary: {
      main: blue[800],
      contrastText: "#fff",
    },
    success: {
      main: teal["600"],
      contrastText: "#fff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    poster: {
      // test
      color: "red",
    },
  },
});

export default theme;
