import general from "./general";
import auth from "./auth";
import map from "./map";

// Dashboard
import dashboard from "./dashboard";

// Tour
import tours from "./tour/tours";
import tour from "./tour/tour";
import createTour from "./tour/createTour";
import promocodesManager from "./tour/promocodesManager";
import toursAnalytics from "./tour/toursAnalytics";

// Promocodes
import promocodes from "./promocode/promocodes";
import createPromocode from "./promocode/createPromocode";
import promocodesAnalytics from "./promocode/promocodesAnalytics";
import ondemandPromocode from "./promocode/ondemandPromocode";

// Guide
import guides from "./guide/guides";
import createGuide from "./guide/createGuide";

// Business partners
import businessPartners from "./businessPartner/businessPartners";
import createBusinessPartner from "./businessPartner/createBusinessPartner";

// Sellers
import sellers from "./seller/sellers";
import createSeller from "./seller/createSeller";

// Other
import qrCodeGenerator from "./qrCodeGenerator";

const DICTIONARY = {
  general,
  auth,
  map,
  dashboard,
  // Tours
  tours,
  tour,
  createTour,
  toursAnalytics,
  // Promocodes
  promocodes,
  createPromocode,
  promocodesAnalytics,
  ondemandPromocode,
  // Guides
  guides,
  createGuide,
  // Business partners
  businessPartners,
  createBusinessPartner,
  // Sellers
  sellers,
  createSeller,
  promocodesManager, // under tour - DEPRECATED
  // Other
  qrCodeGenerator,
};

export default DICTIONARY;
