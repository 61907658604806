import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { tourApi } from "../../api";

// Mui
import {
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";

// App components
import ConfirmationDialog from "../dialog/ConfirmationDialog";
import SimpleDialog from "../dialog/SimpleDialog";
import CopyPwaLink from "../shared/CopyPwaLink";
import QrCode from "../shared/QrCode";
import PublishStatusDialog from "./PublishStatusDialog";

// Icons
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Share as ShareIcon,
  Visibility as PublishIcon,
  Receipt as PromocodeIcon,
  QrCode2 as QrCodeIcon,
  BarChart as AnalyticsIcon,
} from "@mui/icons-material";

type Props = {
  tourData: any;
  anchorEl: any;
  handleCloseMenu: () => void;
  onTourDataUpdate: (updatedTourData: any) => void;
  onItemUpdate: () => Promise<void>;
};

export default function TourItemMenu({
  tourData,
  anchorEl,
  handleCloseMenu,
  onTourDataUpdate,
  onItemUpdate,
}: Props) {
  const history = useHistory();

  //#region Edit tour
  const handleEdit = () => {
    history.push(`${ROUTES.tours.path}/${tourData.id}/edit`);
  };
  //#endregion

  //#region tour analytics
  const handleGoToAnalytics = () => {
    history.push({
      pathname: ROUTES.tours.analytics.path,
      search: `?id=${tourData.id}&name=${tourData.name}`,
      // state: {
      //   id: tourData.id,
      //   name: tourData.name,
      // },
    });
  };
  //#endregion

  //#region manage promocode
  const handleManagePromocode = () => {
    history.push(`${ROUTES.tours.path}/${tourData.id}/promocode`);
  };
  //#endregion

  //#region Publish tour
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const onPublishListItemClick = () => {
    handleCloseMenu();
    setOpenPublishModal(true);
  };

  const handlePublish = async (data: any) => {
    let response = await tourApi.edit(tourData.id, {
      publishStatus: data.publishStatus,
    });

    return response;
  };

  const handleClosePublishModal = (data: any) => {
    setOpenPublishModal(false);

    if (data.isSubmitedSuccessfully) {
      onTourDataUpdate({
        ...tourData,
        publishStatus: data.publishStatus,
      });
    }
  };
  //#endregion

  //#region Copy PWA tour link
  const [openCopyPwaLinkModal, setOpenCopyPwaLinkModal] = useState(false);

  const onCopyPwaLinkItemClick = () => {
    handleCloseMenu();
    setOpenCopyPwaLinkModal(true);
  };

  const handleCloseCopyPwaLinkModal = (isSubmitedSuccessfully: boolean) => {
    setOpenCopyPwaLinkModal(false);
  };
  //#endregion

  //#region Tour QR code
  const [openQrCodeModal, setOpenQrCodeModalModal] = useState(false);

  const handleQrCodeItemClick = () => {
    handleCloseMenu();
    setOpenQrCodeModalModal(true);
  };

  const handleCloseQrCodeModal = (isSubmitedSuccessfully: boolean) => {
    setOpenQrCodeModalModal(false);
  };
  //#endregion

  //#region Delete tour
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onDeleteListItemClick = () => {
    handleCloseMenu();
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    let response = await tourApi.delete(tourData.id);
    return response;
  };

  const handleCloseDeleteModal = (isSubmitedSuccessfully: boolean) => {
    setOpenDeleteModal(false);

    // If submited successfully --> update tour data state in the UI (without fetching the updated data fron the DB)
    if (isSubmitedSuccessfully) {
      onItemUpdate();
    }
  };
  //#endregion

  return (
    <Fragment>
      {/* Tour item menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Edit */}
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{DICTIONARY.tours.tourItem.actions.edit}</ListItemText>
        </MenuItem>

        {/* Promocode */}
        {/* <MenuItem onClick={handleManagePromocode}>
          <ListItemIcon>
            <PromocodeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.promocode}
          </ListItemText>
        </MenuItem> */}

        {/* Publish / Hide */}
        <MenuItem onClick={onPublishListItemClick}>
          <ListItemIcon>
            <PublishIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.publish}
          </ListItemText>
        </MenuItem>

        <Divider />

        {/* Analytics */}
        <MenuItem onClick={handleGoToAnalytics}>
          <ListItemIcon>
            <AnalyticsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.analytics}
          </ListItemText>
        </MenuItem>

        <Divider />
        {/* Copy PWA (end-user) link */}
        <MenuItem
          onClick={onCopyPwaLinkItemClick}
          // disabled={tourData.publishStatus !== "public"}
        >
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.copyLink}
          </ListItemText>
        </MenuItem>

        {/* QR code */}
        <MenuItem onClick={handleQrCodeItemClick}>
          <ListItemIcon>
            <QrCodeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.qrCode}
          </ListItemText>
        </MenuItem>

        {/* Delete */}
        <Divider />
        <MenuItem onClick={onDeleteListItemClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.tours.tourItem.actions.delete}
          </ListItemText>
        </MenuItem>
      </Menu>

      {/* Publish dialog */}
      {openPublishModal && (
        <PublishStatusDialog
          open={openPublishModal}
          title={DICTIONARY.tours.publishStatusDialog.title}
          value={tourData.publishStatus}
          onSubmit={handlePublish}
          onClose={handleClosePublishModal}
        />
      )}

      {/* Copy link dialog */}
      {openCopyPwaLinkModal && (
        <SimpleDialog
          open={openCopyPwaLinkModal}
          title={DICTIONARY.general.copyPwaLink.title}
          onClose={handleCloseCopyPwaLinkModal}
        >
          <CopyPwaLink
            link={`${process.env.REACT_APP_PWA_BASE_URL}/tours/${tourData.id}`}
          />
        </SimpleDialog>
      )}

      {/* QR code */}
      {openQrCodeModal && (
        <SimpleDialog
          open={openQrCodeModal}
          title={`${tourData.name} _ ${DICTIONARY.tours.qrCodeDialog.title}`}
          onClose={handleCloseQrCodeModal}
        >
          <QrCode qrValue={`tours/${tourData.id}`} qrFileName={tourData.name} />
        </SimpleDialog>
      )}

      {/* Delete dialog */}
      {openDeleteModal && (
        <ConfirmationDialog
          open={openDeleteModal}
          title={DICTIONARY.tours.deleteConfirmationDialog.title}
          onSubmit={handleDelete}
          onClose={handleCloseDeleteModal}
          submitButtonText={DICTIONARY.tours.deleteConfirmationDialog.button}
          submitButtonIcon={<DeleteIcon />}
          submitButtonColor="error"
        >
          <Typography variant="body2">
            {DICTIONARY.tours.deleteConfirmationDialog.body}
          </Typography>
        </ConfirmationDialog>
      )}
    </Fragment>
  );
}
