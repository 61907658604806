import React, { useState, useEffect, Fragment, useRef } from "react";
import { DICTIONARY, HELPERS } from "../../../../utils";
import { tourApi } from "../../../../api";
import { CSVLink } from "react-csv";
import { useSnackbarContext } from "../../../../context";
import { SNACKBAR_SEVERITY } from "../../../../consts";

// App components
import Loader from "../../../loader/Loader";
import EmptyState from "../../../shared/EmptyState";
import InfoTooltip from "../../../shared/InfoTooltip";
import CreateSingleusePromocode from "../create/CreateSingleusePromocode";

// Mui
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Divider,
  AlertColor,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";

// Icons
import {
  Create as CreateIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";

type Props = {
  tourId: string;
  tourName: string;
};

function SingleusePromocodes({ tourId, tourName }: Props) {
  const { handleSnackbarDisplay } = useSnackbarContext();

  const [emptyState, setEmptyState] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [promocodes, setPromocodes] = useState<any>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await tourApi.promocode.get(tourId);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setEmptyState(
          DICTIONARY.promocodesManager.sections.singleuse.emptyState
        );
        return;
      }

      if (!response.hasOwnProperty("meta_singleuse_total")) {
        setIsLoading(false);
        setEmptyState(
          DICTIONARY.promocodesManager.sections.singleuse.emptyState
        );
        return;
      }

      const parsedResponse = [
        {
          id: "meta",
          total: response.meta_singleuse_total,
          used: response.meta_singleuse_used ? response.meta_singleuse_used : 0,
          remained: response.meta_singleuse_used
            ? response.meta_singleuse_total - response.meta_singleuse_used
            : response.meta_singleuse_total,
        },
      ];

      // Success
      setEmptyState("");
      setPromocodes(parsedResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Table
  const columns: GridColDef[] = [
    {
      field: "total",
      headerName: DICTIONARY.promocodesManager.table.columns.total,
      width: 150,
    },
    {
      field: "used",
      headerName: DICTIONARY.promocodesManager.table.columns.used,
      width: 150,
    },
    {
      field: "remained",
      headerName: DICTIONARY.promocodesManager.table.columns.remained,
      width: 150,
    },
  ];
  //#endregion

  //#region Download CSV
  const downloadCsvEl = useRef<any>(null);
  const [isDownloadCsvLoading, setIsDownloadCsvLoading] =
    useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  const headers = [
    { label: "Code", key: "code" },
    { label: "Description", key: "description" },
    { label: "Count", key: "count" },
    { label: "Created at", key: "createdAt" },
    { label: "Days to expiration", key: "daysToExpiration" },
    { label: "Discount unit", key: "discountUnit" },
    { label: "Discount value", key: "discountValue" },
  ];

  const showSnackbarError = () => {
    const severity = SNACKBAR_SEVERITY.warning as AlertColor;
    const message =
      DICTIONARY.promocodesManager.sections.singleuse.errors.downloadCsv;
    const autoHideDuration = 5000; // close automatically after 5 sec
    handleSnackbarDisplay(severity, message, autoHideDuration);
  };

  const fetchSinglueusePromocodesData = async () => {
    try {
      setIsDownloadCsvLoading(true);

      const response = await tourApi.promocode.getSubcollection(
        tourId,
        "singleuse"
      );

      if (response.hasOwnProperty("error")) {
        setIsDownloadCsvLoading(false);
        showSnackbarError();
        return;
      }

      if (response.length === 0) {
        setIsDownloadCsvLoading(false);
        showSnackbarError();
        return;
      }

      const parsedResponse = response.map((item: any) => {
        const {
          code,
          description,
          count,
          createdAt,
          daysToExpiration,
          discountUnit,
          discountValue,
        } = item;
        return {
          code,
          description,
          count,
          createdAt: HELPERS.date.formatDate(createdAt.toDate()),
          daysToExpiration,
          discountUnit,
          discountValue,
        };
      });

      const csvString = parsedResponse;
      // const csvString = [
      //   [...Object.keys(parsedResponse[0])],
      //   ...parsedResponse.map((item:any) => {
      //     return Object.values(item);
      //   })
      // ];

      // Success
      setIsDownloadCsvLoading(false);
      return csvString;
    } catch (error) {
      setIsDownloadCsvLoading(false);
      showSnackbarError();
      return;
    }
  };

  const handleDownloadCsv = async () => {
    try {
      const data = await fetchSinglueusePromocodesData();

      if (!data) {
        return;
      }

      setData(() => {
        setTimeout(() => {
          downloadCsvEl.current.link.click();
        });
        return data;
      });
    } catch (error) {}
  };
  //#endregion

  //#region Components
  const Header = () => {
    return (
      <Fragment>
        {/* Section header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          {/* Title */}
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" component="h1" sx={{ mr: 0.2, mb: 0 }}>
              {DICTIONARY.promocodesManager.sections.singleuse.title}
            </Typography>
            <InfoTooltip
              info={DICTIONARY.promocodesManager.sections.singleuse.info}
            />
          </Box>

          {/* Action */}
          <Box display="flex" alignItems="center">
            {/* Generate button */}
            <Button
              startIcon={<CreateIcon />}
              color="secondary"
              onClick={handleOpenCreatePromocodeModal}
            >
              {DICTIONARY.promocodesManager.sections.singleuse.buttons.generate}
            </Button>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            {/* Download CSV */}
            <LoadingButton
              startIcon={<DownloadIcon />}
              color="secondary"
              loading={isDownloadCsvLoading}
              loadingPosition="start"
              onClick={handleDownloadCsv}
              disabled={promocodes.length === 0}
            >
              {DICTIONARY.promocodesManager.sections.singleuse.buttons.download}
            </LoadingButton>

            <CSVLink
              filename={`Singleuse promocodes - ${tourName} - ${new Date().toDateString()}.csv`}
              data={data}
              headers={headers}
              ref={downloadCsvEl}
            />
          </Box>
        </Box>
      </Fragment>
    );
  };
  const Content = ({ promocodes }: any) => {
    return (
      <Fragment>
        {/* Section content */}
        <div style={{ height: 180, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={promocodes}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    );
  };
  //#endregion

  //#region Create code modal
  const [openCreatePromocodeModal, setOpenCreatePromocodeModal] =
    useState(false);

  const handleOpenCreatePromocodeModal = () => {
    setOpenCreatePromocodeModal(true);
  };

  const handleCloseCreatePromocodeModal = async (
    isSubmitedSuccessfully: boolean
  ) => {
    setOpenCreatePromocodeModal(false);

    if (isSubmitedSuccessfully) {
      await fetchData();
    }
  };
  //#endregion

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <Header />

          <Paper sx={{ p: 1 }}>
            {isLoading ? (
              <Loader py={4} />
            ) : emptyState ? (
              <EmptyState title={emptyState} icon="promocode" py={4} />
            ) : (
              <Content promocodes={promocodes} />
            )}
          </Paper>
        </Box>
      </Container>

      {/* Dialogs */}
      <CreateSingleusePromocode
        open={openCreatePromocodeModal}
        onClose={handleCloseCreatePromocodeModal}
        tourId={tourId}
      />
    </React.Fragment>
  );
}

export default SingleusePromocodes;
