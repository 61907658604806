import md5 from "md5";

// Firebase
import { db, storage } from "../config/firebase-config";
import {
  collection,
  doc,
  query,
  where,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { userApi } from "./index";

// utils
import { HELPERS } from "../utils";
import { USER_ROLES } from "../consts";

const seller = {
  get: async function (uid: string) {
    try {
      let retVal;
      const docRef = doc(db, "sellers", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        retVal = docSnap.data();
      } else {
        retVal = {};
        HELPERS.localhost.isVerbose() &&
          console.log(`No such seller document with uid: ${uid}`);
      }

      return retVal;
    } catch (e) {
      const error = `Error getting seller data with uid: ${uid}`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  getAll: async function (queryFilter?: any) {
    try {
      let retVal: any = [];
      const toursRef = collection(db, "sellers");
      let querySnapshot;

      if (queryFilter) {
        const q = query(
          toursRef,
          where(queryFilter.field, queryFilter.operator, queryFilter.value)
        );
        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(toursRef);
      }

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();
        retVal.push({
          id: doc.id,
          ...data,
        });
      });

      return retVal;
    } catch (e) {
      const error = `Error getting sellers data`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  create: async function (data: any, adminData: any, password: string) {
    try {
      // 1. Create the Auth user for this business partner
      const createdUserResponse: any = await userApi.create(
        {
          email: data.email,
          password: password,
          displayName: data.name,
          photoURL: `https://gravatar.com/avatar/${md5(
            data.email
          )}?d=identicon`,
        },
        adminData,
        USER_ROLES.seller.value // userRole
      );

      // If user auth created successfully
      if (createdUserResponse?.data?.uid) {
        // 2. Create the seller record
        await setDoc(doc(db, "sellers", createdUserResponse.data.uid), {
          ...data,
          createdAt: Timestamp.fromDate(new Date()),
          deletedAt: null,
          createdBy: {
            id: adminData.auth.uid,
            name: adminData.data.name,
            avatar: adminData.data.avatar,
          },
        });
      } else {
        throw Error;
      }

      return {
        success: "true",
        entityId: createdUserResponse.data.uid,
      };
    } catch (e) {
      const error = `Error creating seller`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  edit: async function (id: string, data: any) {
    try {
      const docRef = await doc(db, "sellers", id);

      await updateDoc(docRef, {
        ...data,
        updatedAt: Timestamp.fromDate(new Date()),
      });

      return {
        success: "true",
        guideId: id,
      };
    } catch (e) {
      const error = `Error editing seller`;
      HELPERS.localhost.isVerbose() && console.error(e);
      return {
        error,
      };
    }
  },
  delete: async function (id: string) {
    try {
      const docRef = await doc(db, "sellers", id);

      await updateDoc(docRef, {
        deletedAt: Timestamp.fromDate(new Date()),
      });

      return true;
    } catch (e) {
      const error = `Error deleting seller`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  uploadFile: async function ({ fileId, file, suffix, guideId, userId }: any) {
    try {
      const path = `sellers/${guideId}/${fileId}-${suffix}`;
      const fileRef = ref(storage, path);

      let metadata = {
        customMetadata: {
          uploadedBy: userId,
          guideId,
        },
      };

      const snapshot = await uploadBytes(fileRef, file, metadata);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      return {
        success: "true",
        downloadUrl,
      };
    } catch (e) {
      const error = `Error uploading seller file`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
};

export default seller;
