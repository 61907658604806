import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";
import { guideApi } from "../../../api";
import { IMAGE_AVATAR_PLACEHOLDER } from "../../../consts";

// Mui
import { Box, TextField, IconButton, FormLabel } from "@mui/material";

// App components
import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ImageUploader from "../../inputs/ImageUploader";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import ChipsInput from "../../inputs/ChipsInput";
import SelectTourType from "../../inputs/SelectTourType";
import AutocompleteSelectLanguage from "../../inputs/AutocompleteSelectLanguage";
import AutocompleteSelectCountry from "../../inputs/AutocompleteSelectCountry";
import AutocompleteSelectEntity from "../../inputs/AutocompleteSelectEntity";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";

// Icons
import { Preview as PreviewIcon } from "@mui/icons-material";

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
  onPreviewIntroScreen: (response: any) => void;
  onPreviewTourName: (response: any) => void;
}

const TourDetails = forwardRef(
  (
    {
      tour,
      tourId,
      clearFormError,
      onPreviewIntroScreen,
      onPreviewTourName,
    }: Props,
    ref
  ) => {
    // #region States
    const initalFormState = {
      name: {
        label: DICTIONARY.createTour.fields.name.label,
        info: DICTIONARY.createTour.fields.name.info,
        value: tour.name ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) =>
              VALIDATORS.string.isLengthInRange(val, 3, 50),
            errorMessage: DICTIONARY.createTour.fields.name.error.length,
          },
        ],
      },
      description: {
        label: DICTIONARY.createTour.fields.description.label,
        info: DICTIONARY.createTour.fields.description.info,
        value: HELPERS.richText.getRichTextFromDb(tour.description),
        isValid: true,
        required: false,
        validators: [],
      },
      country: {
        label: DICTIONARY.createTour.fields.country.label,
        info: DICTIONARY.createTour.fields.country.info,
        value: tour.country ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      location: {
        label: DICTIONARY.createTour.fields.location.label,
        info: DICTIONARY.createTour.fields.location.info,
        value: tour.location ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      tourType: {
        label: DICTIONARY.createTour.fields.tourType.label,
        info: DICTIONARY.createTour.fields.tourType.info,
        value: tour.tourType ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      language: {
        label: DICTIONARY.createTour.fields.language.label,
        info: DICTIONARY.createTour.fields.language.info,
        value: tour.language ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      durationHour: {
        label: DICTIONARY.createTour.fields.durationHour.label,
        info: DICTIONARY.createTour.fields.durationHour.info,
        value: tour.durationHour ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      lengthKm: {
        label: DICTIONARY.createTour.fields.lengthKm.label,
        info: DICTIONARY.createTour.fields.lengthKm.info,
        value: tour.lengthKm ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
      coverImage: {
        label: DICTIONARY.createTour.fields.coverImage.label,
        info: DICTIONARY.general.filesUpload.imageUploadInstructions,
        value: tour.coverImage ?? "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (value: any) => {
              // Check if the value matches the value from the DB. if true --> the coverImage wasnt change and its valid
              const coverImageDB = tour.coverImage;

              return (
                coverImageDB === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "image")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.imageType,
          },
        ],
      },
      tags: {
        label: DICTIONARY.createTour.fields.tags.label,
        info: DICTIONARY.createTour.fields.tags.info,
        value: tour.tags ?? [],
        isValid: true,
        required: false,
        validators: [],
      },
      guide: {
        label: DICTIONARY.createTour.fields.guide.label,
        info: DICTIONARY.createTour.fields.guide.info,
        value: tour.guide ?? { name: "", id: "" },
        isValid: true,
        required: true,
        validators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        case "chips":
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleIntroScreenPreview = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        onPreviewIntroScreen(response);
        return;
      }

      const parsedData = prepareDataToSave();

      onPreviewIntroScreen({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };

    const handleTourNamePreview = () => {
      const response = isFormValid({
        name: formState.name,
      });

      if ("error" in response) {
        onPreviewTourName(response);
        return;
      }

      const parsedData = prepareDataToSave();

      onPreviewTourName({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let fileUrl: string = "";
      let files: any = [];

      // New file
      if (
        VALIDATORS.filesUpload.isValidDataFile(
          formState.coverImage.value,
          "image"
        )
      ) {
        files.push({
          type: "image",
          file: formState.coverImage.value,
          fileId: tourId ? tourId : "",
          suffix: "coverImage",
        });
        fileUrl = URL.createObjectURL(formState.coverImage.value);
      } else {
        // file hasn't changed - it has the url from firebase storgae
        fileUrl = formState.coverImage.value;
      }

      const parsedData = {
        name: formState.name.value,
        description: HELPERS.richText.prepareRichTextToDbSave(
          formState.description.value
        ),
        country: formState.country.value,
        location: formState.location.value,
        tourType: formState.tourType.value,
        language: formState.language.value,
        durationHour: formState.durationHour.value,
        lengthKm: formState.lengthKm.value,
        coverImage: fileUrl,
        tags: formState.tags.value,
        guide: formState.guide.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createTour.sections.details.label}
          actions={[
            {
              label: DICTIONARY.createTour.buttons.introScreen,
              icon: <PreviewIcon />,
              onClick: handleIntroScreenPreview,
            },
          ]}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 5,
          }}
        >
          {/* Name */}
          <Box sx={{ gridRow: "1", gridColumn: "1 / 8" }} mb={1}>
            <TextField
              label={formState.name.label}
              value={formState.name.value}
              id="name"
              name="name"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.name.required}
              error={!formState.name.isValid}
              fullWidth
              autoFocus
            />
          </Box>

          {/* Preview tour name */}
          <Box
            sx={{ gridRow: "1", gridColumn: "8 / 9", justifySelf: "center" }}
            mb={1}
          >
            <IconButton
              aria-label="route-preview"
              onClick={handleTourNamePreview}
            >
              <PreviewIcon color="info" />
            </IconButton>
          </Box>

          {/* Country */}
          <Box sx={{ gridRow: "2", gridColumn: "1 / 5" }} mb={1}>
            <AutocompleteSelectCountry
              label={formState.country.label}
              info={formState.country.info}
              id="country"
              value={formState.country.value}
              error={!formState.country.isValid}
              onInputChange={handleInputChange}
              required={formState.country.required}
              multiple={false}
              shouldShowCode={false}
              shouldShowPhone={false}
            />
            {/* <TextField
              label={formState.country.label}
              value={formState.country.value}
              id="country"
              name="country"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.country.required}
              error={!formState.country.isValid}
              fullWidth
            /> */}
          </Box>

          {/* Location */}
          <Box sx={{ gridRow: "2", gridColumn: "5 / 9" }} mb={1}>
            <TextField
              label={formState.location.label}
              value={formState.location.value}
              id="location"
              name="location"
              onChange={handleInputChange}
              margin="none"
              size="small"
              required={formState.location.required}
              error={!formState.location.isValid}
              fullWidth
            />
          </Box>

          {/* Tour type */}
          <Box sx={{ gridRow: "3", gridColumn: "1 / 5" }} mb={1}>
            <SelectTourType
              label={formState.tourType.label}
              value={formState.tourType.value}
              id="tourType"
              name="tourType"
              onChange={(event: any) =>
                handleInputChange(event, { type: "select" })
              }
              required={formState.tourType.required}
              error={!formState.tourType.isValid}
            />
          </Box>

          {/* Tour language */}
          <Box sx={{ gridRow: "3", gridColumn: "5 / 9" }} mb={1}>
            <AutocompleteSelectLanguage
              label={formState.language.label}
              info={formState.language.info}
              id="language"
              value={formState.language.value}
              error={!formState.language.isValid}
              onInputChange={handleInputChange}
              required={formState.language.required}
            />
          </Box>

          {/* Duration */}
          <Box sx={{ gridRow: "4", gridColumn: "1 / 5" }} mb={1}>
            <NumberFieldWithInfo
              label={formState.durationHour.label}
              value={formState.durationHour.value}
              id="durationHour"
              onChange={handleInputChange}
              required={formState.durationHour.required}
              error={!formState.durationHour.isValid}
              info={formState.durationHour.info}
            />
          </Box>

          {/* Length */}
          <Box sx={{ gridRow: "4", gridColumn: "5 / 9" }} mb={1}>
            <NumberFieldWithInfo
              label={formState.lengthKm.label}
              value={formState.lengthKm.value}
              id="lengthKm"
              onChange={handleInputChange}
              required={formState.lengthKm.required}
              error={!formState.lengthKm.isValid}
              info={formState.lengthKm.info}
            />
          </Box>

          {/* Tour coverImage */}
          <Box
            sx={{
              gridRow: {
                xs: "4",
                sm: "1 / 4",
              },
              gridColumn: {
                xs: "span 12",
                sm: "9 / 13",
              },
            }}
          >
            <ImageUploader
              label={formState.coverImage.label}
              info={formState.coverImage.info}
              id="coverImage"
              value={formState.coverImage.value}
              error={!formState.coverImage.isValid}
              onInputChange={handleInputChange}
              imageContainerStyle={{
                width: "96%",
                margin: "auto",
              }}
            />
          </Box>

          {/* Guide */}
          <Box sx={{ gridRow: "5", gridColumn: "1 / 9" }} mb={1}>
            <AutocompleteSelectEntity
              label={formState.guide.label}
              info={formState.guide.info}
              id="guide"
              value={formState.guide.value}
              error={!formState.guide.isValid}
              onInputChange={handleInputChange}
              apiCallback={guideApi.getAll}
              additionalOptions={[
                {
                  id: "none",
                  name: "None",
                  // avatar: IMAGE_AVATAR_PLACEHOLDER,
                },
              ]}
            />
          </Box>

          {/* Description */}
          <Box sx={{ gridRow: "6", gridColumn: "span 12" }} mb={1}>
            <FormLabel component="legend" sx={{ fontSize: 14 }}>
              {formState.description.label}
            </FormLabel>
            <RichTextEditor
              /*
            // @ts-ignore */
              editorState={formState.description.value}
              onEditorStateChange={(editorState: any) =>
                handleInputChange(editorState, { type: "richText" })
              }
            />
          </Box>

          {/* Tags */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <ChipsInput
              label={formState.tags.label}
              info={formState.tags.info}
              id="tags"
              value={formState.tags.value}
              error={!formState.tags.isValid}
              onInputChange={handleInputChange}
              placeholder="Tag"
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default TourDetails;
