import { ReactChild, ReactChildren } from "react";

// App components
import Loader from "../loader/Loader";
import EmptyState from "../shared/EmptyState";

// Mui
import { Box, Typography, Paper, Button } from "@mui/material";

type Props = {
  title: string;
  actions?: any;
  isLoading: boolean;
  error: string;
  style?: any;
  children: ReactChild | ReactChildren;
};

export default function ChartContainer({
  title,
  actions,
  isLoading,
  error,
  style,
  children,
}: Props) {
  return (
    <Paper
      id="chart-container"
      sx={{ mt: 3, p: 2, display: "flex", flexDirection: "column", ...style }}
    >
      <Box
        id="chart-header-wrapper"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component="h3" sx={{ fontSize: 18 }}>
          {title}
        </Typography>

        {actions &&
          actions.length > 0 &&
          actions.map((action: any) => (
            <Button variant="contained" size="small">
              Action item
            </Button>
          ))}
      </Box>

      {/* Content */}
      <Box
        id="chart-content-wrapper"
        sx={{ flex: 1, display: "flex", justifyContent: "center" }}
      >
        {isLoading ? (
          <Box sx={{ mt: -4 }}>
            <Loader />
          </Box>
        ) : error !== "" ? (
          <Box sx={{}}>
            <EmptyState title={error} icon="tour" py={15} />
          </Box>
        ) : (
          children
        )}
      </Box>
    </Paper>
  );
}
