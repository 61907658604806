// roles who can be charged of business partner
export const OPERATORS_OF_BP: any = {
  guideOperator: {
    label: "Guide",
    value: "guideOperator",
  },
  sellerOperator: {
    label: "Seller",
    value: "sellerOperator",
  },
  adminOperator: {
    label: "Admin",
    value: "adminOperator",
  },
};

export const USER_ROLES: any = {
  businessPartner: {
    label: "Business partner",
    value: "businessPartner",
  },
  seller: {
    label: "Seller",
    value: "seller",
  },
  guide: {
    label: "Guide",
    value: "guide",
  },
  admin: {
    label: "Admin",
    value: "admin",
  },
};
