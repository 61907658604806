// @ts-ignore
import { TOUR_GUIDING_ICONS } from "tour-guide-shared-library";

// Mui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

type Props = {
  label: string;
  value: string;
  id: string;
  name: string;
  onChange: (event: any) => any;
  required: boolean;
  error: boolean;
};

export default function SelectIcon({
  label,
  value,
  id,
  name,
  onChange,
  required,
  error,
}: Props) {
  return (
    <FormControl
      fullWidth
      size="small"
      margin="none"
      required={required}
      error={error}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        name={name}
        label={label}
        value={value}
        onChange={(event) => onChange(event)}
        required={required}
        error={error}
        fullWidth
      >
        {symbolPointsArr.map((item: any) => {
          const IconComponent = item.component;

          return (
            <MenuItem
              key={item.value}
              value={item.value}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                color: grey[700],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  component="span"
                  mr={1}
                  sx={{
                    display: "inline-block",
                    height: "18px",
                    width: "18px",
                  }}
                >
                  <IconComponent
                    sx={{
                      height: "18px",
                      width: "18px",
                      lineHeight: "18px",
                      color: grey[900],
                    }}
                  />
                </Box>
                <Typography variant="body1" sx={{ display: "inline-block" }}>
                  {item.label}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

//#region Helpers
const symbolPointsArr = Object.keys(TOUR_GUIDING_ICONS).map((key: string) => ({
  ...TOUR_GUIDING_ICONS[key],
}));
//#endregion
