import React, { cloneElement } from "react";

// Mui
import { Box, Typography } from "@mui/material";

// Icons
import {
  ErrorOutline as DefaultIcon,
  LocationOn as TourIcon,
  Receipt as PromocodeIcon,
  Tour as SymbolIcon,
  Person as PersonIcon,
  Accessibility as GuideIcon,
  People as SellerIcon,
  Store as BusinessPartnerIcon,
  Dashboard as DashboardIcon,
} from "@mui/icons-material";

const ICONS_MAP: any = {
  default: <DefaultIcon />,
  tour: <TourIcon />,
  promocode: <PromocodeIcon />,
  guide: <GuideIcon />,
  seller: <SellerIcon />,
  businessPartner: <BusinessPartnerIcon />,
  symbol: <SymbolIcon />,
  person: <PersonIcon />,
  dashboard: <DashboardIcon />,
};

type Props = {
  title?: string;
  subtitle?: string;
  icon?: string;
  py?: number;
};

export default function EmptyState({
  title,
  subtitle,
  icon = "default",
  py = 0,
}: Props) {
  const Icon = () => {
    return cloneElement(ICONS_MAP[icon], {
      color: "action",
      sx: { fontSize: 40 },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={py}
    >
      <Icon />
      <Typography variant="body1" sx={{ mt: 0.5 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: 13 }}>{subtitle}</Typography>
    </Box>
  );
}
