import React, { useState, ReactChild, ReactChildren } from "react";
import { DICTIONARY } from "../../utils";

// Mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  onSubmit: () => Promise<any>;
  onClose: (isSubmited: boolean) => void;
  submitButtonText: string;
  submitButtonIcon: JSX.Element;
  submitButtonColor?:
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | undefined;
  children: ReactChild | ReactChildren;
}

export default function ConfirmationDialog({
  open,
  title,
  submitButtonText,
  submitButtonIcon,
  submitButtonColor,
  onSubmit,
  onClose,
  children,
}: ConfirmationDialogProps) {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    const response = await onSubmit();
    setIsSubmitLoading(false);

    if (!response.hasOwnProperty("error")) {
      onClose(true);
    }
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="inherit">
          {DICTIONARY.general.button.cancel}
        </Button>

        {/* Submit button */}
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitLoading}
          loadingPosition="start"
          startIcon={submitButtonIcon}
          variant="contained"
          disabled={isSubmitLoading}
          color={submitButtonColor}
        >
          {submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
