// Mui
import { Paper, InputBase, Divider, IconButton } from "@mui/material";

// Icons
import { Search as SearchIcon } from "@mui/icons-material";

type Props = {
  searchValue: string | null;
  onChange: (value: string | null) => void;
  placeholder?: string;
};

export default function SearchBox({
  searchValue,
  onChange,
  placeholder = "Search tour",
}: Props) {
  //#region Functionality
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  //#endregion

  return (
    <Paper
      elevation={1}
      sx={{
        p: "0px 2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <IconButton sx={{ p: 1, pr: 0.5 }} aria-label="search" disableRipple>
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <InputBase
        sx={{ ml: 1.5, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": "search" }}
        fullWidth
        value={searchValue}
        onChange={handleChange}
        dir="auto"
      />
    </Paper>
  );
}
