// Mui
import { Tooltip, IconButton } from "@mui/material";

// Colors
import { blue } from "@mui/material/colors";

// Icons
import { Info as InfoIcon } from "@mui/icons-material";

type Props = {
  info: string;
  style?: any;
  ariaLabel?: string;
};

export default function InfoTooltip({ info, style = {}, ariaLabel }: Props) {
  return (
    <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{info}</div>} arrow>
      <IconButton
        aria-label={ariaLabel || "tooltip"}
        edge="end"
        size="small"
        sx={{
          cursor: "auto",
          ...style,
        }}
      >
        <InfoIcon sx={{ width: "16px", height: "16px", color: blue[300] }} />
      </IconButton>
    </Tooltip>
  );
}
