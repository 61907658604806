const filesUpload = {
  isValidDataFile: (file: any, type: string) => {
    // type = [image / video / audio]
    try {
      if (typeof file === "object" && file !== "" && file != null) {
        return file.type.startsWith(`${type}/`);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  isValidFileUrl: (fileUrl: string) => {
    try {
      const local =
        "http://localhost:9199/v0/b/tour-guide-hub.appspot.com/o/tours";
      const prod =
        "https://firebasestorage.googleapis.com/v0/b/tour-guide-hub.appspot.com/o/tours";

      if (
        typeof fileUrl === "string" &&
        (fileUrl.startsWith(local) || fileUrl.startsWith(prod))
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
};

export default filesUpload;
