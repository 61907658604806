import { Fragment, useState } from "react";
import QRCode from "qrcode.react";
import { DICTIONARY, HELPERS } from "../../utils";
import { promocodeApi } from "../../api";

// App components
import AutocompleteSelectTours from "../../components/inputs/AutocompleteSelectTours";

// Mui
import {
  Container,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
  FormLabel,
  Divider,
  Link,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// Colors
import { grey } from "@mui/material/colors";

const STEPS = {
  0: "generate",
  1: "displayCode",
};

function OndemandPromocode() {
  // #region States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [step, setStep] = useState<string>(STEPS[0]);
  const [qrValue, setQrValue] = useState<string>("");

  // Response
  const [generatedCode, setGeneratedCode] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [eligibleTours, setEligibleTours] = useState<[]>([]);

  const initalFormState = {
    eligibleTours: {
      label: DICTIONARY.ondemandPromocode.fields.eligibleTours.label,
      info: DICTIONARY.ondemandPromocode.fields.eligibleTours.info,
      value: null,
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
  };

  const [formState, setFormState] = useState(initalFormState);
  // #endregion

  //#region Validation
  const isFormValid = (formState: any) => {
    try {
      // Empty fields
      const { formState: formStateResponseEmptyFields, emptyFields } =
        HELPERS.form.basic.handleEmptyFields({
          ...formState,
        });
      if (emptyFields.length > 0) {
        setFormState(formStateResponseEmptyFields);

        const error =
          HELPERS.form.createEmptyFormFieldErrorMessage(emptyFields);

        return {
          isValid: false,
          error,
        };
      }

      // Invaid fields
      const {
        formState: formStateResponseInvalidFields,
        formError: error,
      }: any = HELPERS.form.basic.handleInvalidFields({
        ...formState,
      });
      if (error) {
        setFormState(formStateResponseInvalidFields);

        return {
          isValid: false,
          error,
        };
      }

      return {
        isValid: true,
      };
    } catch (error) {
      HELPERS.localhost.isVerbose() && console.error(error);

      return {
        isValid: false,
        error: DICTIONARY.createPromocode.errors.generalError,
      };
    }
  };

  const clearFormError = () => {
    setFormError("");
  };
  //#endregion

  //#region Functionality
  const handleAutocompleteChange = (event: any, payload?: any) => {
    const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
    let key: string = "";
    let val: any;

    switch (type) {
      case "autocomplete": {
        const { id, value } = payload;
        key = id;
        val = value;
        break;
      }
      default: {
        break;
      }
    }

    setFormState((prevState: any) => {
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
          isValid: true,
        },
      };
    });

    // Clear errors
    clearFormError();
  };

  const handleGenerate = async () => {
    try {
      // 0. Clear error
      clearFormError();

      // 1. Validation
      const isValidResponse = isFormValid(formState); // Validate fields

      if ("error" in isValidResponse) {
        setFormError(isValidResponse.error);
        return;
      }

      // 2. Generate code
      setIsLoading(true);

      const data = {
        eligibleTours:
          formState.eligibleTours.value != null
            ? [formState.eligibleTours.value]
            : [], // NOTE: used here inside array - to support later use of multuple tours
      };
      const response: any = await promocodeApi.ondemand.generate(data);

      if (response?.data?.success === true) {
        const { code, eligibleTours, createdAt } = response.data;
        setGeneratedCode(code);

        if (eligibleTours.length > 0) {
          let parsedEligibleTours = [];
          const indexOfAll = eligibleTours.findIndex(
            (item: any) => item.id === "all"
          );
          if (indexOfAll > -1) {
            parsedEligibleTours = [];
          } else {
            parsedEligibleTours = eligibleTours.map((item: any) => item.name);
          }

          setEligibleTours(parsedEligibleTours);
        }

        const parsedCreadedAt: any = HELPERS.date.parseTimestamp(createdAt);

        setCreatedAt(
          HELPERS.date.formatDate(
            parsedCreadedAt.toDate(),
            "LLLL dd, yyyy - HH:mm"
          )
        );

        //
        const qrValue = prepareQRCode(code, eligibleTours) || "";
        setQrValue(qrValue);
      }
      setIsLoading(false);
      setStep(STEPS[1]);
    } catch (error) {
      console.error("Error handleGenerate: ", error);
      setFormError(DICTIONARY.ondemandPromocode.errors.generalError);
      setIsLoading(false);
    }
  };

  const handleNewCode = () => {
    // Clear eligibleTours
    setFormState(initalFormState);

    // Clear errors (if any)
    clearFormError();

    // Move back to step 0
    setStep(STEPS[0]);
  };
  //#endregion

  //#region QR code
  const prepareQRCode = (code: string, eligibleTours: any[]) => {
    // Designated to a spesific tour - go directly to tour page
    try {
      let retVal = "";
      let BASE_URL = process.env.REACT_APP_PWA_BASE_URL;

      const indexOfAll = eligibleTours.findIndex(
        (item: any) => item.id === "all"
      );
      if (indexOfAll > -1 || eligibleTours.length === 0) {
        retVal = `${BASE_URL}?promocode=${code}&tours=all`;
      } else if (eligibleTours.length === 1) {
        retVal = `${BASE_URL}/tours/${eligibleTours[0].id}?promocode=${code}`;
      } else {
        const toursIds = eligibleTours.map((item: any) => item.id);
        let toursIdsObj: any = {};
        toursIds.forEach((id: string, index: number) => {
          toursIdsObj[`tour_${index}`] = id;
        });

        const toursQueryParam = new URLSearchParams(toursIdsObj).toString();

        retVal = `${BASE_URL}?promocode=${code}&${toursQueryParam}`;
      }

      return retVal;
    } catch (error) {
      setFormError(DICTIONARY.ondemandPromocode.errors.generalError);
    }
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas: any = document.getElementById("qr-gen");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `qr_code_${createdAt}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  //#endregion

  return (
    <Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.ondemandPromocode.title}
              </Typography>
            </Box>
          </Box>

          {/* Content */}
          <Paper sx={{ mt: 3, px: 2, py: 1.5 }}>
            {/* 1st step - generate code */}
            {step === STEPS[0] && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 5,
                }}
              >
                <Box sx={{ width: "400px", mb: 2 }}>
                  <FormLabel
                    component="label"
                    sx={{
                      display: "block",
                      fontSize: (theme) => theme.typography.pxToRem(14),
                      mb: 1,
                    }}
                  >
                    {DICTIONARY.ondemandPromocode.steps.generate.label}
                  </FormLabel>
                  <AutocompleteSelectTours
                    label={formState.eligibleTours.label}
                    info={formState.eligibleTours.info}
                    id="eligibleTours"
                    value={formState.eligibleTours.value}
                    error={!formState.eligibleTours.isValid}
                    onChange={handleAutocompleteChange}
                    multiple={false}
                  />
                </Box>

                <LoadingButton
                  variant="contained"
                  size="large"
                  color="info"
                  onClick={handleGenerate}
                  loading={isLoading}
                  sx={{
                    borderRadius: "50%",
                    width: "160px",
                    height: "160px",
                    fontSize: 24,
                    lineHeight: "28px",
                  }}
                  loadingIndicator={
                    <CircularProgress color="inherit" size={26} />
                  }
                >
                  {DICTIONARY.ondemandPromocode.buttons.generate}
                </LoadingButton>
              </Box>
            )}

            {/* Step 2 - Show code */}
            {step === STEPS[1] && qrValue !== "" && formError === "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mt: 2,
                }}
              >
                {/* Created at */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.2,
                    width: "200px",
                    mb: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: 12, color: grey[500] }}>
                    {DICTIONARY.ondemandPromocode.steps.displayCode.createdAt}:
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>{createdAt}</Typography>
                </Box>

                {/* Eligibale tours */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.2,
                    width: "200px",
                    mb: 2,
                  }}
                >
                  <Typography sx={{ fontSize: 12, color: grey[500] }}>
                    {
                      DICTIONARY.ondemandPromocode.steps.displayCode
                        .eligibleTours
                    }
                    :
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>
                    {eligibleTours.length === 0
                      ? "All tours"
                      : eligibleTours.join(", ")}
                  </Typography>
                </Box>

                <Divider flexItem />

                {/* The code and QRcode */}
                <Box
                  sx={{
                    mt: 1,
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1.5,
                  }}
                >
                  <Typography sx={{ fontSize: 34, letterSpacing: "6px" }}>
                    {generatedCode}
                  </Typography>

                  {/* <Link href={qrValue}>Link</Link> */}

                  <QRCode
                    id="qr-gen"
                    value={qrValue}
                    size={200}
                    level={"M"}
                    // includeMargin={true}
                  />
                </Box>

                <Divider flexItem />

                {/* Actions */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mt: 3,
                    mb: 2,
                  }}
                >
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={downloadQRCode}
                  >
                    {DICTIONARY.ondemandPromocode.steps.displayCode.download}
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleNewCode}
                  >
                    {DICTIONARY.ondemandPromocode.steps.displayCode.new}
                  </Button>
                </Box>
              </Box>
            )}

            {/* Error */}
            {formError !== "" && (
              <Box
                id="form-error"
                flex={1}
                sx={{
                  width: "400px",
                  mx: "auto",
                  mb: 3,
                }}
              >
                {formError && (
                  <Alert severity="error" variant="filled">
                    {formError}
                  </Alert>
                )}
              </Box>
            )}
          </Paper>
        </Box>
      </Container>
    </Fragment>
  );
}

export default OndemandPromocode;
