import React from "react";

// App components
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

type Props = {
  label: string;
  value: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: boolean;
  info: string;
  options: any;
};

export default function RadioButtonWithInfo({
  label,
  value,
  id,
  onChange,
  required = false,
  error = false,
  info,
  options,
}: Props) {
  return (
    <Box>
      <FormControl
        component="fieldset"
        required={required}
        error={error}
        sx={{ mr: 0.5 }}
      >
        <FormLabel component="legend">
          {label}
          <InfoTooltip
            info={info}
            style={{
              mt: -0.2,
            }}
          />
        </FormLabel>
        <RadioGroup
          aria-label="publish-status"
          name={id}
          value={value}
          onChange={onChange}
        >
          {options.map((item: any) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<Radio />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
