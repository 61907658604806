const createGuid = {
  title: {
    create: "Create new guide",
    edit: "Edit guide",
  },
  sections: {
    details: {
      label: "Guide details",
      emptyState: "",
    },
    social: {
      label: "Social",
      emptyState: "",
    },
    summary: {
      label: "Summary",
      emptyState: "",
    },
  },
  buttons: {
    next: "Next",
    back: "Back",
    save: "Save",
    preview: "Preview",
    introScreen: "Preview guide page",
    route: "Preview",
  },
  fields: {
    name: {
      label: "Name",
      info: "",
      error: {
        length: "Guide name must contain between 2 and 40 characters.",
      },
    },
    email: {
      label: "Email",
      info: "",
      error: {
        badlyFormattedEmail: "The email address is badly formatted.",
      },
    },
    phone: {
      label: "Phone",
      info: "",
      error: {},
    },
    avatar: {
      label: "Avatar",
      info: "",
      error: {},
    },
    nationality: {
      label: "Nationality",
      info: "",
      error: {},
    },
    countries: {
      label: "Countries of operation",
      info: "",
      error: {},
    },
    description: {
      label: "Description",
      info: "",
      error: {},
    },
    tags: {
      label: "Tags",
      info: "Add tags that describe best your guide to help users discover it by searching the words in the app. \n You can add multiple tags and use any multiuple languages. \n Press 'Enter' To add the tag.",
      error: {},
    },
    spokenLanguages: {
      label: "Spoken languages",
      info: "",
      error: {},
    },
    website: {
      label: "Website",
      info: "",
      error: {},
    },
    facebook: {
      label: "Facebook",
      info: "",
      error: {},
    },
    instagram: {
      label: "Instagram",
      info: "",
      error: {},
    },
    linkedin: {
      label: "Linkedin",
      info: "",
      error: {},
    },
  },
  errors: {
    generalError: `Couldn't create tour. Please try again or contact support.`,
    generalErrorEdit: `Couldn't edit tour. Please try again or contact support.`,
    updateGuideImage: `The guide saved successfully, but we couldn't save guide image. Please try again or contact support.`,
  },
  emptyState: "The requested guide does not exist",
};

export default createGuid;
