import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DICTIONARY, VALIDATORS, ROUTES, HELPERS } from "../../utils";
import { businessPartnerApi } from "../../api";
import { useAuthContext } from "../../context";

// App components
import AppFooter from "../../components/layout/AppFooter";
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";

import BusinessPartnerDetails from "../../components/businessPartner/createBusinessPartner/BusinessPartnerDetails";
import Operator from "../../components/businessPartner/createBusinessPartner/Operator";
import Tours from "../../components/businessPartner/createBusinessPartner/Tours";
import Summary from "../../components/businessPartner/createBusinessPartner/Summary";

// Mui
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Box,
  Container,
  Typography,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Icons
import {
  NavigateBefore as BackIcon,
  NavigateNext as NextIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

const useStyles = makeStyles({});

const SECTIONS: any = {
  entityDetails: {
    label: DICTIONARY.createBusinessPartner.sections.details.label,
    order: 0,
  },
  operator: {
    label: DICTIONARY.createBusinessPartner.sections.operator.label,
    order: 1,
  },
  tours: {
    label: DICTIONARY.createBusinessPartner.sections.tours.label,
    order: 2,
  },
  summary: {
    label: DICTIONARY.createBusinessPartner.sections.summary.label,
    order: 2,
  },
};

const sectionsArr: any = Object.keys(SECTIONS).map((key: string) => ({
  key,
  label: SECTIONS[key].label,
  order: SECTIONS[key].order,
}));

function CreateBusinessPartner() {
  const classes = useStyles();
  const history = useHistory();
  const { id: entityId }: any = useParams(); // in Edit mode, we will have 'id'
  const { user } = useAuthContext();

  // #region States and refs
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pageError, setPageError] = useState<string>("");
  const [entityData, setEntityData] = useState<any>({});
  const originalEntityDataRef = useRef<any>({}); // Used for comparison between original data to the new submitted data
  const [formError, setFormError] = useState("");
  const formErrorRef = useRef<any>(null);

  const [files, setFiles] = useState<any>([]); // all files along the wizard (avatar)

  const detailsRef = useRef<any>();
  const operatorRef = useRef<any>();
  const toursRef = useRef<any>();
  const summaryRef = useRef<any>();
  // #endregion

  // #region Load data
  useEffect(() => {
    // Create mode
    if (!entityId) {
      setIsPageLoading(false);
      return;
    }

    (async () => {
      try {
        setIsPageLoading(true);

        const response = await businessPartnerApi.get(entityId);

        if (response.hasOwnProperty("error")) {
          setIsPageLoading(false);
          setPageError(DICTIONARY.createBusinessPartner.emptyState);
          return;
        }

        // If entity doesnt exist
        if (Object.keys(response).length === 0) {
          setPageError(DICTIONARY.createBusinessPartner.emptyState);
        }
        // console.log(response);
        // Success
        originalEntityDataRef.current = response;
        setEntityData(response);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    })();
  }, [entityId]);
  //#endregion

  // #region Stepper
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    try {
      const refsMap: any = {
        entityDetails: detailsRef,
        operator: operatorRef,
        tours: toursRef,
      };

      const selectedRef = refsMap[sectionsArr[activeStep].key];

      const response = selectedRef.current.onNext();

      if (response.isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        // Guide
        setEntityData((prevState: any) => {
          const updatedGuide = {
            ...prevState,
            ...response.data,
          };

          return updatedGuide;
        });

        // Files
        if (response.files) {
          setFiles((prevState: []) => {
            const updatedFiles = [...prevState, ...response.files];

            return updatedFiles;
          });
        }
      } else {
        showFormError(response.error);
      }
    } catch (error) {
      showFormError(DICTIONARY.createBusinessPartner.errors.generalError);
    }
  };

  const handleBack = () => {
    try {
      const refsMap: any = {
        operator: operatorRef,
        tours: toursRef,
        summary: summaryRef,
      };

      const selectedRef = refsMap[sectionsArr[activeStep].key];

      if (!selectedRef) {
        return;
      }

      const response = selectedRef.current.onBack();

      if (response.isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        // Guide
        setEntityData((prevState: any) => {
          const updatedGuide = {
            ...prevState,
            ...response.data,
          };

          return updatedGuide;
        });
      } else {
        showFormError(response.error);
      }
    } catch (error) {
      showFormError(DICTIONARY.createBusinessPartner.errors.generalError);
    }
  };
  // #endregion

  // #region Error helpers
  const showFormError = (error: string) => {
    setFormError(error);

    // We need to short setTimeout, to first let 'formErrorRef' to be added to the DOM, so then we can scroll to it
    setTimeout(() => {
      formErrorRef.current.scrollIntoView({
        behavior: "smooth",
        top: formErrorRef.current.offsetTop,
      });
    }, 50);
  };

  const clearFormError = () => {
    setFormError("");
  };
  // #endregion

  // #region Submit
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const summaryResponse = summaryRef.current.onSubmit();

      // #region Step 0. Validate summaryResponse
      if (summaryResponse.isValid) {
        // Guide
        setEntityData((prevState: any) => {
          const updatedGuide = {
            ...prevState,
            // ...summaryResponse.data,
          };

          return updatedGuide;
        });

        // Files
        if (summaryResponse.files) {
          setFiles((prevState: []) => {
            const updatedFiles = [...prevState, ...summaryResponse.files];

            return updatedFiles;
          });
        }
      } else {
        showFormError(summaryResponse.error);
        return;
      }
      // #endregion

      setIsSubmitLoading(true);
      // #region Step 1. Validations
      // TODO: Should I do validation here?
      // TODO: add all validations function from each section --> export validation function to helper file, and use it here also
      // #endregion

      // #region Step 2. Send request to create / edit tour
      // NOTE: I dont add "summaryResponse.data" to "finalEntityData" because it includes the password value
      // Instead, I'm passing password as a separate argument.
      const finalEntityData = {
        ...entityData,
        // ...summaryResponse.data,
      };

      // Check if the operator has changed
      let operatorChangedDetails = {};
      if (
        originalEntityDataRef?.current?.operatedBy?.id !== undefined &&
        originalEntityDataRef?.current?.operatedBy?.id !==
          finalEntityData?.operatedBy?.id
      ) {
        operatorChangedDetails = {
          new_id: finalEntityData.operatedBy.id,
          new_name: finalEntityData.operatedBy.name,
          new_operatorType: finalEntityData.operatorType,
          old_id: originalEntityDataRef.current.operatedBy.id,
          old_name: originalEntityDataRef.current.operatedBy.name,
          old_operatorType: originalEntityDataRef.current.operatorType,
        };
      }

      let entityResponse: any = entityId
        ? await businessPartnerApi.edit(
            entityId,
            finalEntityData,
            operatorChangedDetails
          )
        : await businessPartnerApi.create(
            finalEntityData,
            user,
            summaryResponse.data.password
          );

      if (entityResponse.hasOwnProperty("error")) {
        setIsSubmitLoading(false);
        setFormError(DICTIONARY.createBusinessPartner.errors.generalError);
        return;
      }
      // #endregion

      // Success
      setIsSubmitLoading(false);
      history.push(ROUTES.businessPartners.path);
    } catch (error) {
      setIsSubmitLoading(false);
      HELPERS.localhost.isVerbose() && console.error(error);
    }
  };
  // #endregion

  const handleCloseBusinessPartnerPage = () => {
    //
  };

  if (isPageLoading) return <Loader />;
  if (pageError !== "")
    return <EmptyState title={pageError} icon="businessPartner" py={10} />;

  return (
    <React.Fragment>
      <Container
        sx={{
          marginTop: 3,
          marginBottom: 5,
        }}
        maxWidth="md"
      >
        {/* Page header */}
        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
          <IconButton
            size="small"
            onClick={() => history.push(ROUTES.businessPartners.path)}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="h5" component="h1">
            {entityId
              ? DICTIONARY.createBusinessPartner.title.edit
              : DICTIONARY.createBusinessPartner.title.create}
          </Typography>
          {entityId && (
            <Typography sx={{ fontSize: 16, color: grey[700], mt: 0.4 }}>
              ({entityData.name})
            </Typography>
          )}
        </Box>

        {/* Page content */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={3}
          mt={1}
        >
          {/* Left side - Stepper and form */}
          <Box flex="auto">
            {/* Sections stepper */}
            <Box
              position={{ xs: "static", sm: "sticky" }}
              sx={{
                top: "75px",
                mb: 2,
                mt: 1,
                zIndex: 2,
                outline: `16px solid ${grey[100]}`,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  zIndex: 3,
                  boxShadow:
                    "inset 0px -2px 1px -1px rgb(0 0 0 / 20%), inset 0px -1px 1px 0px rgb(0 0 0 / 14%), inset 0px 0px 3px 0px rgb(0 0 0 / 12%)",
                }}
              >
                <Stepper activeStep={activeStep} alternativeLabel>
                  {sectionsArr.map((step: any, index: number) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                      <Step key={step.key} {...stepProps}>
                        <StepLabel>{step.label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Paper>
            </Box>

            {/* Form */}
            <Box component="form" noValidate>
              <Paper
                sx={{
                  px: 2,
                  pb: 2,
                }}
              >
                {/* Section content */}
                <Box>
                  {/* Tour Details */}
                  {activeStep === SECTIONS.entityDetails.order && (
                    <BusinessPartnerDetails
                      ref={detailsRef}
                      entityData={entityData}
                      clearFormError={clearFormError}
                      isEditMode={entityId != null}
                    />
                  )}

                  {/* Operator */}
                  {activeStep === SECTIONS.operator.order && (
                    <Operator
                      ref={operatorRef}
                      entityData={entityData}
                      clearFormError={clearFormError}
                      isEditMode={entityId != null}
                    />
                  )}

                  {/* Tours */}
                  {activeStep === SECTIONS.tours.order && (
                    <Tours
                      ref={toursRef}
                      entityData={entityData}
                      clearFormError={clearFormError}
                      isEditMode={entityId != null}
                    />
                  )}

                  {/* Summary section */}
                  {activeStep === sectionsArr.length - 1 && (
                    <Summary
                      ref={summaryRef}
                      entityData={entityData}
                      clearFormError={clearFormError}
                      isEditMode={entityId != null}
                      entityId={entityId != null ? entityId : null}
                    />
                  )}
                </Box>
              </Paper>

              {/* Error message */}
              <Box
                id="form-error"
                ref={formErrorRef}
                flex={1}
                sx={{
                  mt: 2,
                  maxWidth: "md",
                }}
              >
                {formError && (
                  <Alert severity="error" variant="filled">
                    {formError}
                  </Alert>
                )}
              </Box>
            </Box>
            {/* End - Form */}
          </Box>
        </Box>
      </Container>

      <AppFooter>
        <Container sx={{ height: "100%" }} maxWidth="md">
          <Box
            display="flex"
            alignItems="center"
            gap={3}
            sx={{ height: "100%" }}
          >
            <Box flex="4" display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                startIcon={<BackIcon />}
                onClick={handleBack}
                color="secondary"
                disabled={activeStep === 0 || formError.length > 0}
              >
                {DICTIONARY.createBusinessPartner.buttons.back}
              </Button>

              {activeStep < sectionsArr.length - 1 && (
                <Button
                  variant="contained"
                  endIcon={<NextIcon />}
                  onClick={handleNext}
                  color="secondary"
                  disabled={formError.length > 0}
                >
                  {DICTIONARY.createBusinessPartner.buttons.next}
                </Button>
              )}

              {/* Submit button */}
              {activeStep === sectionsArr.length - 1 && (
                <LoadingButton
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                  loading={isSubmitLoading}
                  loadingPosition="start"
                  color="success"
                  disabled={isSubmitLoading || formError.length > 0}
                >
                  {DICTIONARY.createBusinessPartner.buttons.save}
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Container>
      </AppFooter>
    </React.Fragment>
  );
}

export default CreateBusinessPartner;
