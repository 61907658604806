import React, { Fragment, useState, useEffect, useRef } from "react";
import { DICTIONARY, ROUTES } from "../../utils";
import { promocodeApi } from "../../api";
import { PROMOCODE_OWNER_TYPES } from "../../consts";

// App components
import ChartContainer from "./ChartContainer";

// Mui
import { Paper, Box, Typography } from "@mui/material";

// Recharts
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";

// Colors
import { grey } from "@mui/material/colors";

const CHART_HEIGHT = 400;

export default function PromocodesChart() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const query = {
        field: "deletedAt",
        operator: "==",
        value: null,
      };
      const response = await promocodeApi.getAll(query);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const parsedData = convertToChartData(response);
      setData(parsedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Chart data
  const convertToChartData = (rawData: any) => {
    try {
      let retVal = [];

      if (rawData && rawData.length > 0) {
        retVal = rawData.map((item: any) => {
          const { code, totalCount, owners } = item;
          const parsedItem = {
            code: code,
            count: totalCount ?? 0,
            owner: owners[0]?.owner.name || "-",
            ownerType: owners[0]?.type || "-",
          };

          return parsedItem;
        });

        // TEMP: Filter out my promocode (IDOADMIN)
        // And filter out promocodes with less than 2 uses
        retVal = retVal.filter(
          (item: any) => item.code !== "IDOADMIN" && item.count > 2
        );

        retVal = sortEntities(retVal, "count");
      }
      return retVal;
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <ChartContainer
      title={DICTIONARY.dashboard.charts.promocodeUsages.title}
      actions={[]}
      isLoading={isLoading}
      error={error}
      style={{
        height: `${CHART_HEIGHT + 80}px`,
      }}
    >
      <ResponsiveContainer height={CHART_HEIGHT} width="100%">
        <BarChart
          data={data}
          margin={{ right: 10, top: 5, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="code" fontSize={10} interval={0} />
          <YAxis
            fontSize={12}
            interval="preserveStartEnd"
            allowDecimals={false}
          />
          <Tooltip content={<CustomTooltip payload={data} />} />
          <Legend />
          <Bar dataKey="count" name="Total count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

//#region Helpers
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <Paper sx={{ p: 1 }}>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>Code:</Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.code}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Owner:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.owner}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Owner type:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {PROMOCODE_OWNER_TYPES[payload[0].payload.ownerType].label}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Total count:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.count}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

const sortEntities = (tours: any, key: string) => {
  function callback(a: any, b: any) {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
