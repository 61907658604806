import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import { useSidebarContext } from "../../context";
import { drawerWidth } from "../../consts";

const StyledMain = styled("main", {
  shouldForwardProp: (prop) => {
    return prop !== "open" && prop !== "isMobileScreen";
  },
})<{ open?: boolean; isMobileScreen?: boolean }>(
  ({ theme, open, isMobileScreen }) => ({
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1,
    // height: "100vh",
    paddingBottom: theme.spacing(5),
    // position: 'relative',
    // top: '60px',
    // left: 0,
    // right: 0,
    // bottom: 0,
    // overflow: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!isMobileScreen && { marginLeft: `-${drawerWidth}px` }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Main: FC = ({ children }) => {
  const { isSidebarOpen, isMobileScreen } = useSidebarContext();

  return (
    <StyledMain open={isSidebarOpen} isMobileScreen={isMobileScreen}>
      {children}
    </StyledMain>
  );
};

export default Main;
