const Link = ({ entityKey, contentState, children }: any) => {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
    <a style={{ color: "#1976d2" }} href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default Link;
