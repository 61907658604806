// Firebase
import { db, storage, functions } from "../config/firebase-config";
import {
  collection,
  doc,
  query,
  where,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

// utils
import { HELPERS } from "../utils";

const outdoorGame = {
  feedback: {
    getAll: async function (queryFilter?: any) {
      try {
        let retVal: any = [];
        const toursRef = collection(db, "outdoor_game_feedbacks");
        let querySnapshot;

        if (queryFilter) {
          const q = query(
            toursRef,
            where(queryFilter.field, queryFilter.operator, queryFilter.value)
          );
          querySnapshot = await getDocs(q);
        } else {
          querySnapshot = await getDocs(toursRef);
        }

        querySnapshot.forEach((doc) => {
          let data: any = doc.data();
          retVal.push({
            id: doc.id,
            ...data,
          });
        });

        return retVal;
      } catch (e) {
        const error = `Error getting outdoor game feedbacks data`;
        HELPERS.localhost.isVerbose() && console.error(error);
        return {
          error,
        };
      }
    },
  },
};

export default outdoorGame;
