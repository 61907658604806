import React from "react";
import { DICTIONARY, VALIDATORS } from "../../../../utils";
import { useForm } from "../../../../hooks";
import { tourApi } from "../../../../api";
import { DISCOUNT_UNITS, PROMOCODE_PATTERN_TYPES } from "../../../../consts";

// App components
import TextFieldWithInfo from "../../../inputs/TextFieldWithInfo";
import NumberFieldWithInfo from "../../../inputs/NumberFieldWithInfo";

// Mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Icons
import { Save as SaveIcon, Close as CloseIcon } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: (isSubmited: boolean) => void;
  tourId: string;
}

// DEPRECATED
export default function CreateSingleusePromocode({
  open,
  onClose,
  tourId,
}: Props) {
  //#region Form
  const initalFormState: any = {
    description: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields.description
          .label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields
        .description.info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    daysToExpiration: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields
          .daysToExpiration.label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields
        .daysToExpiration.info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    discountUnit: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields.discountUnit
          .label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields
        .discountUnit.info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    discountValue: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields
          .discountValue.label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields
        .discountValue.info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    promocodePattern: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields
          .promocodePattern.label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields
        .promocodePattern.info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [],
    },
    amount: {
      label:
        DICTIONARY.promocodesManager.sections.singleuse.form.fields.amount
          .label,
      info: DICTIONARY.promocodesManager.sections.singleuse.form.fields.amount
        .info,
      value: "",
      isValid: true,
      isRequired: () => true,
      customValidators: [
        {
          callback: (val: number) => VALIDATORS.number.isSmallerThanX(val, 500),
          errorMessage:
            DICTIONARY.promocodesManager.sections.singleuse.form.fields.amount
              .errors.limit,
        },
      ],
    },
  };

  const onSubmit = async () => {
    const data = {
      description: formState.description.value,
      daysToExpiration: formState.daysToExpiration.value,
      discountUnit: formState.discountUnit.value,
      discountValue: formState.discountValue.value,
      promocodePattern: formState.promocodePattern.value,
      amount: formState.amount.value,
    };

    const response = await tourApi.promocode.createSingleusePromocode(
      tourId,
      data
    );

    return response;
  };

  const {
    formState,
    formError,
    isSubmitLoading,
    handleInputChange,
    handleSubmit,
    handleCancel,
  } = useForm({ initalFormState, onSubmit, onClose });

  //#endregion

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { width: "80%" } }} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {DICTIONARY.promocodesManager.sections.singleuse.form.title}

        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 14,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Form */}
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <DialogContent
          dividers
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 2,
            mt: 1,
          }}
        >
          {/* Description */}
          <Box sx={{ gridRow: "1", gridColumn: "span 2" }}>
            <TextFieldWithInfo
              label={formState.description.label}
              value={formState.description.value}
              id="description"
              onChange={handleInputChange}
              required={formState.description.isRequired()}
              error={!formState.description.isValid}
              info={formState.description.info}
              autoFocus
            />
          </Box>

          {/* Days to expiry */}
          <Box sx={{ gridRow: "2", gridColumn: "span 1" }}>
            <NumberFieldWithInfo
              label={formState.daysToExpiration.label}
              value={formState.daysToExpiration.value}
              id="daysToExpiration"
              onChange={handleInputChange}
              required={formState.daysToExpiration.isRequired()}
              error={!formState.daysToExpiration.isValid}
              info={formState.daysToExpiration.info}
            />
          </Box>

          {/* Discount unit */}
          <Box sx={{ gridRow: "3", gridColumn: "1 / 2" }}>
            <FormControl
              fullWidth
              size="small"
              margin="none"
              required={formState.discountUnit.isRequired()}
              error={!formState.discountUnit.isValid}
            >
              <InputLabel id="discountUnit">
                {formState.discountUnit.label}
              </InputLabel>
              <Select
                labelId="discountUnit"
                name="discountUnit"
                label={formState.discountUnit.label}
                value={formState.discountUnit.value}
                onChange={(event: any) =>
                  handleInputChange(event, { type: "select" })
                }
                fullWidth
              >
                {discountUnitsArr.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Discount value */}
          <Box sx={{ gridRow: "3", gridColumn: "2 / 3" }}>
            <TextField
              label={formState.discountValue.label}
              value={formState.discountValue.value}
              id="discountValue"
              name="discountValue"
              onChange={handleInputChange}
              margin="none"
              size="small"
              fullWidth
              required={formState.discountValue.isRequired()}
              error={!formState.discountValue.isValid}
              type="number"
              inputProps={{ type: "number" }}
            />
          </Box>

          {/* Promocode pattern */}
          <Box sx={{ gridRow: "4", gridColumn: "1 / 2" }}>
            <FormControl
              fullWidth
              size="small"
              margin="none"
              required={formState.promocodePattern.isRequired()}
              error={!formState.promocodePattern.isValid}
            >
              <InputLabel id="promocodePattern">
                {formState.promocodePattern.label}
              </InputLabel>
              <Select
                labelId="promocodePattern"
                name="promocodePattern"
                label={formState.promocodePattern.label}
                value={formState.promocodePattern.value}
                onChange={(event: any) =>
                  handleInputChange(event, { type: "select" })
                }
                fullWidth
              >
                {promocodePatternArr.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Amount */}
          <Box sx={{ gridRow: "5", gridColumn: "1 / 2" }}>
            <NumberFieldWithInfo
              label={formState.amount.label}
              value={formState.amount.value}
              id="amount"
              onChange={handleInputChange}
              required={formState.amount.isRequired()}
              error={!formState.amount.isValid}
              info={formState.amount.info}
            />
          </Box>

          {formError && (
            <Box sx={{ mb: 1, gridRow: "6", gridColumn: "span 2" }}>
              <Alert severity="error">{formError}</Alert>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="inherit">
            {DICTIONARY.general.button.cancel}
          </Button>

          {/* Submit button */}
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            disabled={isSubmitLoading || formError !== ""}
          >
            {DICTIONARY.general.button.generate}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

//#region Helpers
const discountUnitsArr = Object.keys(DISCOUNT_UNITS).map((key: string) => ({
  ...DISCOUNT_UNITS[key],
}));

const promocodePatternArr = Object.keys(PROMOCODE_PATTERN_TYPES).map(
  (key: string) => ({
    ...PROMOCODE_PATTERN_TYPES[key],
  })
);

//#endregion
