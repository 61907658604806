import React from "react";

// Mui
import { Box, CircularProgress } from "@mui/material";

type Props = {
  py?: number;
};

export default function Loader({ py = 20 }: Props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" py={py}>
      <CircularProgress color="primary" size={30} />
    </Box>
  );
}
