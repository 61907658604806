// Firebase
import { db } from "../../config/firebase-config";
import { doc, collection } from "firebase/firestore";

const generateId = () => {
  return doc(collection(db, "temp")).id;
};

export default {
  generateId,
};
