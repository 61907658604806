import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { PUBLISH_STATUS } from "../../consts";

// Mui
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";

// App components
import GuideItemMenu from "./GuideItemMenu";

// Icons
import {
  MoreVert as MoreIcon,
  Visibility as PublishIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

type Props = {
  data: any;
  onItemUpdate: () => Promise<void>;
};

export default function GuideItem({ data, onItemUpdate }: Props) {
  const history = useHistory();
  const [guideData, setGuideData] = useState(data);

  const handleTourDataUpdate = (updatedTourData: any) => {
    onItemUpdate();
  };

  //#region Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //#endregion

  return (
    <Fragment>
      <Card sx={{ display: "flex", py: 2, pl: 2, pr: 1, height: "100%" }}>
        <CardMedia
          component="img"
          image={guideData.avatar}
          alt={guideData.name}
          sx={{
            height: "60px",
            width: "60px",
            minHeight: "60px",
            minWidth: "60px",
            borderRadius: "50%",
            border: `1px solid ${grey[200]}`,
          }}
        />
        <CardHeader
          sx={{
            pl: 2,
            pr: 1,
            py: 1,
            justifyContent: "space-between",
            width: "100%",
          }}
          action={
            <IconButton aria-label="menu" onClick={handleMenuClick}>
              <MoreIcon />
            </IconButton>
          }
          title={
            <Box display="flex">
              <Typography variant="body1" component="h2">
                {guideData.name}
              </Typography>
            </Box>
          }
          subheader={
            <Box display="flex">
              <Typography component="p" color={grey[700]} sx={{ fontSize: 12 }}>
                {guideData.email}
              </Typography>
            </Box>
          }
        />
      </Card>

      {/* Guide item menu */}
      <GuideItemMenu
        data={guideData}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        onTourDataUpdate={handleTourDataUpdate}
        onItemUpdate={onItemUpdate}
      />
    </Fragment>
  );
}
