import {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  Fragment,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";
import {
  TOUR_POINT_CONTENT_TYPES,
  TOUR_POINT_CONTENT_DISPLAY_OPTIONS,
  QUESTIONS_TYPES,
  ORIENTATION_TYPES,
  AUTO_ACTIVATE_TOUR_POINT,
} from "../../../consts";

// Mui
import {
  Box,
  Typography,
  Alert,
  TextField,
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormLabel,
  Collapse,
  Button,
} from "@mui/material";

// App components
import ExpandMore from "../../shared/ExpandMore";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ImageUploader from "../../inputs/ImageUploader";
import VideoUploader from "../../inputs/VideoUploader";
import AudioUploader from "../../inputs/AudioUploader";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";
import SelectWithInfo from "../../inputs/SelectWithInfo";
import InfoTooltip from "../../shared/InfoTooltip";

// Questions
import MultipleChoice from "./questions/MultipleChoice";
import ShortAnswer from "./questions/ShortAnswer";

// Icons
import {
  Delete as DeleteIcon,
  AddLocation as AddTourPointIcon,
  Preview as PreviewIcon,
} from "@mui/icons-material";

// Colors
import { grey, blue } from "@mui/material/colors";

// Lodash
var cloneDeep = require("lodash.clonedeep");

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
  onPreviewTourPoint: (response: any) => void;
  onDeleteTourPoint: (response: any) => void;
}

const TourPoints = forwardRef(
  (
    {
      tour,
      tourId,
      clearFormError,
      onPreviewTourPoint,
      onDeleteTourPoint,
    }: Props,
    ref
  ) => {
    //#region Refs
    const containerRef = useRef<any>(null);
    const multipleChoiceRef = useRef<any>({});
    const shortAnswerQuestionRef = useRef<any>({});
    //#endregion

    // #region States
    const [collapseTimeout, setCollapseTimeout] = useState<number>(
      DEFAULT_COLLAPSE_TIMEOUT
    );

    const tourPointsTemplate: any = {
      title: {
        label: DICTIONARY.createTour.fields.tourPoints.title.label,
        info: DICTIONARY.createTour.fields.tourPoints.title.info,
        value: "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) => VALIDATORS.string.isMinLength(val, 3),
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.title.error.length,
          },
        ],
      },
      thumbnailImage: {
        label: DICTIONARY.createTour.fields.tourPoints.thumbnailImage.label,
        info: DICTIONARY.general.filesUpload.imageUploadInstructions,
        value: "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              // Check if the value matches the value from the DB. if true --> the thumbnailImage wasnt change and its valid
              return (
                tourPoint.thumbnailImage.value === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "image")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.imageType,
          },
        ],
      },
      moreDetails: {
        label: DICTIONARY.createTour.fields.tourPoints.moreDetails.label,
        info: DICTIONARY.createTour.fields.tourPoints.moreDetails.info,
        value: HELPERS.richText.getRichTextFromDb(""),
        isValid: true,
        required: false,
        validators: [],
      },
      additionalImages: {
        label: DICTIONARY.createTour.fields.tourPoints.additionalImages.label,
        info: DICTIONARY.createTour.fields.tourPoints.additionalImages.info,
        value: [],
        validators: [],
      },
      // Point coordinates
      coordinates: {
        label: DICTIONARY.createTour.fields.tourPoints.coordinates.label,
        info: DICTIONARY.createTour.fields.tourPoints.coordinates.info,
        value: "",
        isValid: true,
        required: true,
        validators: [
          {
            validate: (val: string) =>
              VALIDATORS.coordinates.isValidCoordinates(val),
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.coordinates.error.invalid,
          },
        ],
      },
      autoActivateTourPoint: {
        label:
          DICTIONARY.createTour.fields.tourPoints.autoActivateTourPoint.label,
        info: DICTIONARY.createTour.fields.tourPoints.autoActivateTourPoint
          .info,
        value: AUTO_ACTIVATE_TOUR_POINT.yes.value,
        isValid: true,
        required: true,
        validators: [],
      },
      pointActivationRadius: {
        label:
          DICTIONARY.createTour.fields.tourPoints.pointActivationRadius.label,
        info: DICTIONARY.createTour.fields.tourPoints.pointActivationRadius
          .info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.autoActivateTourPoint.value ===
                AUTO_ACTIVATE_TOUR_POINT.yes.value
              ) {
                return VALIDATORS.number.isGreaterThanX(value, 10);
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.pointActivationRadius
                .error.min,
          },
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.autoActivateTourPoint.value ===
                AUTO_ACTIVATE_TOUR_POINT.yes.value
              ) {
                return value !== "";
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.pointActivationRadius
                .error.required,
          },
        ],
      },
      // Point content
      contentType: {
        label: DICTIONARY.createTour.fields.tourPoints.contentType.label,
        info: DICTIONARY.createTour.fields.tourPoints.contentType.info,
        value: "",
        isValid: true,
        required: true,
        validators: [],
      },
      displayTypeOnOpen: {
        label: DICTIONARY.createTour.fields.tourPoints.displayTypeOnOpen.label,
        info: DICTIONARY.createTour.fields.tourPoints.displayTypeOnOpen.info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                [
                  TOUR_POINT_CONTENT_TYPES.video.value,
                  TOUR_POINT_CONTENT_TYPES.audio.value,
                ].indexOf(tourPoint.contentType.value) > -1
              ) {
                return value !== "";
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.displayTypeOnOpen.error
                .required,
          },
        ],
      },
      // Content options
      videoContent: {
        label: DICTIONARY.createTour.fields.tourPoints.videoContent.label,
        info: DICTIONARY.general.filesUpload.videoUploadInstructions,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.contentType.value ===
                TOUR_POINT_CONTENT_TYPES.video.value
              ) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.videoContent.error
                .required,
          },
          {
            validate: (value: any, tourPoint: any) => {
              // Check if the value matches the value from the DB. if true --> the videoContent wasnt change and its valid
              return (
                tourPoint.videoContent.value === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "video")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.videoType,
          },
        ],
      },
      audioContent: {
        label: DICTIONARY.createTour.fields.tourPoints.audioContent.label,
        info: DICTIONARY.general.filesUpload.audioUploadInstructions,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.contentType.value ===
                TOUR_POINT_CONTENT_TYPES.audio.value
              ) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.audioContent.error
                .required,
          },
          {
            validate: (value: any, tourPoint: any) => {
              // Check if the value matches the value from the DB. if true --> the audioContent wasnt change and its valid
              return (
                tourPoint.audioContent.value === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "audio")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.audioType,
          },
        ],
      },
      externalWebpage: {
        label: DICTIONARY.createTour.fields.tourPoints.externalWebpage.label,
        info: DICTIONARY.createTour.fields.tourPoints.externalWebpage.info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.contentType.value ===
                TOUR_POINT_CONTENT_TYPES.externalWebpage.value
              ) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.externalWebpage.error
                .required,
          },
          {
            validate: (value: any, tourPoint: any) => {
              // Check if the value matches the value from the DB. if true --> the url wasnt change and its valid
              return (
                tourPoint.externalWebpage.value === value ||
                VALIDATORS.general.isValidUrl(value)
              );
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.externalWebpage.error
                .invalid,
          },
        ],
      },
      questionType: {
        label: DICTIONARY.createTour.fields.tourPoints.questionType.label,
        info: DICTIONARY.createTour.fields.tourPoints.questionType.info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.contentType.value ===
                TOUR_POINT_CONTENT_TYPES.question.value
              ) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.questionType.error
                .required,
          },
        ],
      },
      question: {}, // Question -or- Orinetation content
      orientationType: {
        label: DICTIONARY.createTour.fields.tourPoints.orientationType.label,
        info: DICTIONARY.createTour.fields.tourPoints.orientationType.info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.contentType.value ===
                TOUR_POINT_CONTENT_TYPES.orientation.value
              ) {
                return value !== undefined && value !== "" && value !== null;
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints.orientationType.error
                .required,
          },
        ],
      },
      orientationPointValidRadiusRange: {
        label:
          DICTIONARY.createTour.fields.tourPoints
            .orientationPointValidRadiusRange.label,
        info: DICTIONARY.createTour.fields.tourPoints
          .orientationPointValidRadiusRange.info,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (tourPoint.orientationPointValidRadiusRange.value !== "") {
                return VALIDATORS.number.isGreaterThanX(value, 5);
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.tourPoints
                .orientationPointValidRadiusRange.error.min,
          },
        ],
      },
      orientationPointDirections: {
        label:
          DICTIONARY.createTour.fields.tourPoints.orientationPointDirections
            .label,
        info: DICTIONARY.createTour.fields.tourPoints.orientationPointDirections
          .info,
        value: HELPERS.richText.getRichTextFromDb(""),
        isValid: true,
        required: true,
        validators: [],
      },
    };

    const [tourPointsState, setTourPointsState] = useState([
      {
        uniqueId: HELPERS.firebase.generateId(),
        ...tourPointsTemplate,
        expanded: true,
      },
    ]);
    const [previewTourPointError, setPreviewTourPointError] =
      useState<string>("");
    const [previewTourPointErrorIndex, setPreviewTourPointErrorIndex] =
      useState<number>(-1);
    //#endregion

    // #region Load data
    useEffect(() => {
      // Edit mode - load tour 'tourPointsState' and 'previewTour'
      if (tour?.tourPoints?.length > 0) {
        try {
          // Set tour points
          let parsedTourPoints: any = [];

          tour.tourPoints.forEach((item: any) => {
            let clonedTourPointsTemplate: any =
              HELPERS.general.cloneObject(tourPointsTemplate);

            // Id
            clonedTourPointsTemplate.uniqueId = item.uniqueId;

            clonedTourPointsTemplate.title.value = item.title;
            clonedTourPointsTemplate.thumbnailImage.value = item.thumbnailImage;
            clonedTourPointsTemplate.contentType.value = item.contentType;
            clonedTourPointsTemplate.displayTypeOnOpen.value =
              item.displayTypeOnOpen;
            clonedTourPointsTemplate.videoContent.value = item.videoContent;
            clonedTourPointsTemplate.audioContent.value = item.audioContent;
            clonedTourPointsTemplate.externalWebpage.value =
              item.externalWebpage;
            clonedTourPointsTemplate.moreDetails.value =
              HELPERS.richText.getRichTextFromDb(item.moreDetails);
            clonedTourPointsTemplate.coordinates.value = item.coordinates;
            clonedTourPointsTemplate.autoActivateTourPoint.value =
              item.autoActivateTourPoint;
            clonedTourPointsTemplate.pointActivationRadius.value =
              item.pointActivationRadius;

            // Additional images
            const parsedAdditionalImages = item.additionalImages.map(
              (image: any) => {
                const parsedImage = {
                  id: image.id || "",
                  url: image.url || "",
                  label: image.label || "",
                  link: image.link || "",
                };

                return parsedImage;
              }
            );
            clonedTourPointsTemplate.additionalImages.value =
              parsedAdditionalImages ?? [];

            // Question
            clonedTourPointsTemplate.questionType.value = item.questionType;
            clonedTourPointsTemplate.question = item.question ?? {};

            // Orientation
            clonedTourPointsTemplate.orientationType.value =
              item.orientationType;
            clonedTourPointsTemplate.orientationPointValidRadiusRange.value =
              item.orientationPointValidRadiusRange ?? "";
            clonedTourPointsTemplate.orientationPointDirections.value =
              HELPERS.richText.getRichTextFromDb(
                item.orientationPointDirections
              );

            parsedTourPoints.push({
              ...clonedTourPointsTemplate,
              expanded: true,
            });
          });

          setTourPointsState(parsedTourPoints);
        } catch (error) {}
      }
    }, [tour]);
    //#endregion

    // #region Functionality
    const handleTourPointInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      const [uniqueId, field] = key.split("-");

      // Additional images - require special treatment.. too complicated, I know...
      if (field === "additionalImages") {
        const [uniqueId, field, type, additionalImageId] = key.split("-"); // type = url / label

        setTourPointsState((prevState: any) => {
          const indexOfPoint = prevState.findIndex(
            (item: any) => item.uniqueId === uniqueId
          );
          let newState = [...prevState];

          const indexOfadditionalImage = newState[indexOfPoint][
            "additionalImages"
          ].value.findIndex((item: any) => item.id === additionalImageId);

          newState[indexOfPoint]["additionalImages"].value[
            indexOfadditionalImage
          ] = {
            ...newState[indexOfPoint]["additionalImages"].value[
              indexOfadditionalImage
            ],
            [type]: val,
          };

          return newState;
        });
      } else {
        setTourPointsState((prevState: any) => {
          const indexOfPoint = prevState.findIndex(
            (item: any) => item.uniqueId === uniqueId
          );
          let newState = [...prevState];
          newState[indexOfPoint][field] = {
            ...newState[indexOfPoint][field],
            value: val,
            isValid: true,
          };

          return newState;
        });
      }

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };

    const handleAddTourPoint = () => {
      let updatedTourPoints = [...tourPointsState];
      updatedTourPoints.push({
        uniqueId: HELPERS.firebase.generateId(),
        ...tourPointsTemplate,
        expanded: true,
      });
      setTourPointsState(updatedTourPoints);

      setTimeout(() => {
        containerRef.current.lastChild.scrollIntoView({
          behavior: "smooth",
          top: containerRef.current.offsetBottom,
        });
      }, 50);
    };

    const handleTourPointPreview = (uniqueId: string) => {
      try {
        const indexOfPointToValidate = tourPointsState.findIndex(
          (item: any) => item.uniqueId == uniqueId
        );

        if (indexOfPointToValidate === -1) {
          return;
        }

        // If needed - Question validation
        let questionResponse: any =
          validateTourPointQuestionBeforePreviewPoint(uniqueId);

        if (
          Object.keys(questionResponse).length > 0 &&
          "error" in questionResponse[uniqueId]
        ) {
          // Show error message for the spesific point
          setPreviewTourPointError(questionResponse[uniqueId].error);
          setPreviewTourPointErrorIndex(indexOfPointToValidate);
          return;
        }

        const tourPointToValidate = {
          ...tourPointsState[indexOfPointToValidate],
        };

        const shouldShowPointIndex = false;
        const response = isTourPointValid(
          [tourPointToValidate],
          indexOfPointToValidate,
          tourPointsState,
          shouldShowPointIndex
        );

        if ("error" in response) {
          // Show error message for the spesific point
          setPreviewTourPointError(response.error);
          setPreviewTourPointErrorIndex(indexOfPointToValidate);
          return;
        }

        const parsedData = prepareDataToSave(tourPointsState, questionResponse);

        onPreviewTourPoint({
          ...response, // isValid = true
          ...parsedData, // data and files
        });
      } catch (error) {}
    };

    const handleDeleteTourPoint = (uniqueId: string) => {
      let updatedTourPointsState = [...tourPointsState];
      const indexOfPoint = tourPointsState.findIndex(
        (item: any) => item.uniqueId == uniqueId
      );
      if (indexOfPoint === -1) {
        return;
      }

      updatedTourPointsState.splice(indexOfPoint, 1);

      // Update tour points state

      setTourPointsState(updatedTourPointsState);

      // Update preview tour
      const parsedData = prepareDataToSave(updatedTourPointsState);

      onDeleteTourPoint({
        ...parsedData, // data and files
      });

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };

    const handleExpandClick = (uniqueId: string) => {
      const indexOfPointToValidate = tourPointsState.findIndex(
        (item: any) => item.uniqueId === uniqueId
      );

      if (indexOfPointToValidate === -1) {
        return;
      }

      setCollapseTimeout(DEFAULT_COLLAPSE_TIMEOUT);

      setTourPointsState((prevState: any) => {
        const updatedState = [...prevState];
        updatedState[indexOfPointToValidate].expanded =
          !updatedState[indexOfPointToValidate].expanded;

        return updatedState;
      });
    };

    const handleTourPointButtonIndexClick = (index: number) => {
      try {
        const tourPointsChildren = containerRef.current.children;
        if (index > -1 && tourPointsChildren[index] != undefined) {
          const y =
            tourPointsChildren[index].getBoundingClientRect().top +
            window.scrollY -
            250;
          window.scroll({
            top: y,
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };
    // #endregion

    //#region Additional images
    const handleAddAdditionalImage = (uniqueId: string) => {
      try {
        const indexOfPoint = tourPointsState.findIndex(
          (item: any) => item.uniqueId === uniqueId
        );
        if (indexOfPoint === -1) {
          return;
        }

        let updatedTourPoints = [...tourPointsState];
        updatedTourPoints[indexOfPoint].additionalImages?.value?.push({
          url: "",
          label: "",
          link: "", // TODO: currently not supported in tour-guide-shared-library - need to think about it more
          id: HELPERS.firebase.generateId(),
        });

        setTourPointsState(updatedTourPoints);

        // Clear errors
        clearFormError();
      } catch (error) {}
    };

    const handleDeleteAdditionalImage = (
      uniqueId: string,
      additionalImageId: number
    ) => {
      try {
        const indexOfPoint = tourPointsState.findIndex(
          (item: any) => item.uniqueId === uniqueId
        );
        if (indexOfPoint === -1) {
          return;
        }

        let updatedTourPoints = [...tourPointsState];

        const indexOfadditionalImage = updatedTourPoints[indexOfPoint][
          "additionalImages"
        ].value.findIndex((item: any) => item.id === additionalImageId);

        if (indexOfadditionalImage > -1) {
          updatedTourPoints[indexOfPoint].additionalImages.value.splice(
            indexOfadditionalImage,
            1
          );
        }

        setTourPointsState(updatedTourPoints);

        // Clear errors
        clearFormError();
      } catch (error) {}

      // Clear errors
      clearFormError();
    };
    //#endregion

    // #region Validation
    const isFormValid = (tourPointsState: any) => {
      try {
        // Empty fields
        const shouldShowPointIndex = true;
        const { tourPoints: tourPointsResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourPointsValidation.handleEmptyFields(
            [...tourPointsState],
            shouldShowPointIndex
          );
        if (emptyFields.length > 0) {
          setTourPointsState(tourPointsResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          tourPoints: tourPointsResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourPointsValidation.handleInvalidFields(
          [...tourPointsState],
          shouldShowPointIndex
        );
        if (error) {
          setTourPointsState(tourPointsResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };

    const isTourPointValid = (
      tourPointToValidate: any,
      indexOfPointToValidate: number,
      originalTourPointsState: any,
      shouldShowPointIndex: boolean
    ) => {
      try {
        // Empty fields
        const { tourPoints: tourPointsResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourPointsValidation.handleEmptyFields(
            [...tourPointToValidate],
            shouldShowPointIndex
          );
        if (emptyFields.length > 0) {
          let updatedTourPointsState = [...originalTourPointsState];
          updatedTourPointsState[indexOfPointToValidate] =
            tourPointsResponseEmptyFields[0]; // Since we validate only 1 tour point --> it will always be the first in 'tourPointsResponseEmptyFields'
          setTourPointsState(updatedTourPointsState);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          tourPoints: tourPointsResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourPointsValidation.handleInvalidFields(
          [...tourPointToValidate],
          shouldShowPointIndex
        );
        if (error) {
          let updatedTourPointsState = [...originalTourPointsState];
          updatedTourPointsState[indexOfPointToValidate] =
            tourPointsResponseInvalidFields[0];
          setTourPointsState(updatedTourPointsState);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };

    const validateTourPointQuestionBeforePreviewPoint = (uniqueId: string) => {
      let questionResponseObj: any = {};

      // Multiple choice
      if (multipleChoiceRef.current[uniqueId]) {
        questionResponseObj[uniqueId] =
          multipleChoiceRef.current[uniqueId].onQuestionValidate();

        if ("error" in questionResponseObj[uniqueId]) {
          return questionResponseObj;
        }
      }

      // Short answer
      if (shortAnswerQuestionRef.current[uniqueId]) {
        questionResponseObj[uniqueId] =
          shortAnswerQuestionRef.current[uniqueId].onQuestionValidate();

        if ("error" in questionResponseObj[uniqueId]) {
          return questionResponseObj;
        }
      }

      return questionResponseObj;
    };
    // #endregion

    // #region Submit
    const prepareDataToSave = (
      tourPointsState: any,
      questionResponseObj?: any
    ) => {
      let parsedTourPoints: any = [];
      let files: any = [];

      tourPointsState.forEach((item: any) => {
        let tourPointContentType = item.contentType.value;
        const itemUniqueId = item.uniqueId;

        //#region Files
        // thumbnailImage file
        let thumbnailImageUrl: string = item.thumbnailImage.value;

        if (
          VALIDATORS.filesUpload.isValidDataFile(
            item.thumbnailImage.value,
            "image"
          )
        ) {
          files.push({
            type: "image",
            file: item.thumbnailImage.value,
            fileId: itemUniqueId,
            suffix: "thumbnailImage",
          });
          thumbnailImageUrl = URL.createObjectURL(item.thumbnailImage.value);
        }

        // videoContent file
        let videoContentFileUrl: string = item.videoContent.value;

        if (tourPointContentType === "video") {
          if (
            VALIDATORS.filesUpload.isValidDataFile(
              item.videoContent.value,
              tourPointContentType
            )
          ) {
            files.push({
              type: "video",
              file: item.videoContent.value,
              fileId: itemUniqueId,
              suffix: "videoContent",
            });
            videoContentFileUrl = URL.createObjectURL(item.videoContent.value);
          }
        }

        // audioContent file
        let audioContentFileUrl: string = item.audioContent.value;

        if (tourPointContentType === "audio") {
          if (
            VALIDATORS.filesUpload.isValidDataFile(
              item.audioContent.value,
              tourPointContentType
            )
          ) {
            files.push({
              type: "audio",
              file: item.audioContent.value,
              fileId: itemUniqueId,
              suffix: "audioContent",
            });
            audioContentFileUrl = URL.createObjectURL(item.audioContent.value);
          }
        }

        // Additional Images (if exist)
        let additionalImageUrls: any[] = [];
        item.additionalImages.value.length > 0 &&
          item.additionalImages.value.forEach((item: any) => {
            const { url, label, link, id } = item;

            // New image
            if (VALIDATORS.filesUpload.isValidDataFile(url, "image")) {
              files.push({
                type: "image",
                file: url,
                fileId: `${itemUniqueId}-${id}`,
                suffix: `additionalImage`,
              });

              additionalImageUrls.push({
                url: URL.createObjectURL(url),
                label,
                link,
                id,
              });
            }

            // Already existed image
            if (VALIDATORS.filesUpload.isValidFileUrl(url)) {
              additionalImageUrls.push({
                url,
                label,
                link,
                id,
              });
            }
          });
        //#endregion

        // Set display type according to contentType
        let displayTypeOnOpen;
        if (
          [
            TOUR_POINT_CONTENT_TYPES.none.value,
            TOUR_POINT_CONTENT_TYPES.question.value,
            TOUR_POINT_CONTENT_TYPES.orientation.value,
          ].indexOf(item.contentType.value) > -1
        ) {
          displayTypeOnOpen =
            TOUR_POINT_CONTENT_DISPLAY_OPTIONS.fullscreen.value;
        } else {
          displayTypeOnOpen = item.displayTypeOnOpen.value;
        }

        parsedTourPoints.push({
          uniqueId: itemUniqueId,
          title: item.title.value,
          moreDetails: HELPERS.richText.prepareRichTextToDbSave(
            item.moreDetails.value
          ),
          coordinates: item.coordinates.value,
          autoActivateTourPoint: item.autoActivateTourPoint.value,
          pointActivationRadius: item.pointActivationRadius.value,
          thumbnailImage: thumbnailImageUrl,
          contentType: item.contentType.value,
          displayTypeOnOpen: displayTypeOnOpen,
          videoContent: videoContentFileUrl,
          audioContent: audioContentFileUrl,
          externalWebpage:
            item.contentType.value ===
            TOUR_POINT_CONTENT_TYPES.externalWebpage.value
              ? item.externalWebpage.value
              : "",
          questionType:
            item.contentType.value === TOUR_POINT_CONTENT_TYPES.question.value
              ? item.questionType.value
              : "",
          question:
            questionResponseObj && questionResponseObj[itemUniqueId]
              ? questionResponseObj[itemUniqueId]
              : {},
          orientationType:
            item.contentType.value ===
            TOUR_POINT_CONTENT_TYPES.orientation.value
              ? item.orientationType.value
              : "",
          orientationPointValidRadiusRange:
            item.orientationPointValidRadiusRange.value,
          orientationPointDirections: HELPERS.richText.prepareRichTextToDbSave(
            item.orientationPointDirections.value
          ),
          additionalImages: additionalImageUrls,
        });
      });

      return {
        data: {
          tourPoints: parsedTourPoints,
        },
        files: files,
      };
    };

    const validateTourPointQuestionBeforeNextAndBack = () => {
      // It serves for question and orination types (when a question is selected)
      let questionResponseObj: any = {};

      // Multiple choice
      for (const uniqueId in multipleChoiceRef.current) {
        if (multipleChoiceRef.current[uniqueId]) {
          const questionResponse =
            multipleChoiceRef.current[uniqueId].onQuestionValidate();

          questionResponseObj[uniqueId] = questionResponse;
        }
      }

      // Short answer
      for (const uniqueId in shortAnswerQuestionRef.current) {
        if (shortAnswerQuestionRef.current[uniqueId]) {
          const questionResponse =
            shortAnswerQuestionRef.current[uniqueId].onQuestionValidate();

          questionResponseObj[uniqueId] = questionResponse;
        }
      }

      return questionResponseObj;
    };

    const expandAllTourPoints = () => {
      setCollapseTimeout(0); // No timeout for collapse --> expand all points immediatly
      setTourPointsState((prevState: any) => {
        const updatedState = [...prevState];
        updatedState.forEach((point: any) => {
          point.expanded = true;
        });

        return updatedState;
      });
    };

    const onNext = () => {
      // Expand all points before moving forward
      expandAllTourPoints();

      // If multipleChoice question exists, validate it
      const questionResponseObj = validateTourPointQuestionBeforeNextAndBack();

      for (const uniqueId in questionResponseObj) {
        if ("error" in questionResponseObj[uniqueId]) {
          return questionResponseObj[uniqueId];
        }
      }

      // Validate tour points fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      let parsedData = prepareDataToSave(tourPointsState, questionResponseObj);

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // Expand all points before moving back
      expandAllTourPoints();

      // If multipleChoice question exists, validate it
      const questionResponseObj = validateTourPointQuestionBeforeNextAndBack();

      for (const uniqueId in questionResponseObj) {
        if ("error" in questionResponseObj[uniqueId]) {
          return questionResponseObj[uniqueId];
        }
      }

      // Validate fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      let parsedData = prepareDataToSave(tourPointsState, questionResponseObj);

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    // #endregion

    function onDragEnd(result: any) {
      const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = cloneDeep(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const quotes = reorder(
        tourPointsState,
        result.source.index,
        result.destination.index
      );

      setTourPointsState(quotes);
    }

    return (
      <Box id="tour-points-main-container" sx={{ pb: 4 }}>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createTour.sections.points.label}
          actions={[
            {
              label: DICTIONARY.createTour.buttons.addTourPoint,
              icon: <AddTourPointIcon />,
              color: "primary",
              onClick: handleAddTourPoint,
            },
          ]}
        >
          {/* Tour points indexses buttons */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "4px",
                    overflow: "auto",
                    background: snapshot.isDraggingOver ? blue[50] : "white",
                  }}
                >
                  {tourPointsState &&
                    tourPointsState.map((item, index) => (
                      <Draggable
                        draggableId={item.uniqueId}
                        index={index}
                        key={item.uniqueId}
                      >
                        {(provided, snapshot) => (
                          <Box
                            // react-beautiful-dnd
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            //
                            sx={{
                              border: `1px solid ${blue[500]}`,
                              backgroundColor: snapshot.isDragging
                                ? blue[100]
                                : "white",
                              borderRadius: "4px",
                              height: "30px",
                              width: "36px",
                              px: 0.5,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 12,
                              color: grey[800],
                            }}
                            onClick={() =>
                              handleTourPointButtonIndexClick(index)
                            }
                          >
                            {index + 1}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </SectionHeader>

        <Box ref={containerRef} id="tour-points-container">
          {/* Content */}
          {tourPointsState &&
            tourPointsState.map((item, index) => (
              <Box
                key={item.uniqueId}
                id={`${index}-tour-point-container`}
                sx={{ mt: 1 }}
              >
                <Box
                  id={`${index}-point-container-visible-section`}
                  // ref={containerRef}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(14, 1fr)",
                    gap: 1,
                    pt: 5,
                  }}
                >
                  {/* Index + Epanded icon */}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ gridRow: "1", gridColumn: "span 1" }}
                    mb={1}
                  >
                    <ExpandMore
                      expand={tourPointsState[index].expanded}
                      onClick={() => handleExpandClick(item.uniqueId)}
                      aria-expanded={tourPointsState[index].expanded}
                      aria-label="show more"
                    />
                    <Typography
                      sx={{
                        fontSize: 20,
                        height: "28px",
                        width: "28px",
                        lineHeight: "28px",
                        // borderRadius: "50%",
                        // border: `1px solid ${grey[400]}`,
                        textAlign: "center",
                      }}
                      component="h2"
                    >
                      {index + 1}
                    </Typography>
                  </Box>

                  {/* Title  */}
                  <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                    <TextField
                      label={tourPointsState[index].title.label}
                      value={tourPointsState[index].title.value}
                      id={`${item.uniqueId}-title`}
                      onChange={handleTourPointInputChange}
                      margin="none"
                      required={tourPointsState[index].title.required}
                      fullWidth
                      size="small"
                      error={!tourPointsState[index].title.isValid}
                      autoFocus={
                        !tourPointsState[index].title.value &&
                        index === tourPointsState.length - 1
                      }
                    />
                  </Box>

                  {/* Thumbnail Image */}
                  <Box
                    sx={{
                      gridRow: "1 / 3",
                      gridColumn: "11 / 14",
                      maxHeight: "90px",
                    }}
                    mb={1}
                  >
                    <ImageUploader
                      label={tourPointsState[index].thumbnailImage.label}
                      info={tourPointsState[index].thumbnailImage.info}
                      id={`${item.uniqueId}-thumbnailImage`}
                      value={tourPointsState[index].thumbnailImage.value}
                      error={!tourPointsState[index].thumbnailImage.isValid}
                      onInputChange={handleTourPointInputChange}
                    />
                  </Box>

                  {/* coordinates  */}
                  <Box sx={{ gridColumn: "2 / 11" }}>
                    <TextField
                      label={tourPointsState[index].coordinates.label}
                      value={tourPointsState[index].coordinates.value}
                      id={`${item.uniqueId}-coordinates`}
                      onChange={handleTourPointInputChange}
                      margin="none"
                      required={tourPointsState[index].coordinates.required}
                      fullWidth
                      size="small"
                      error={!tourPointsState[index].coordinates.isValid}
                    />
                  </Box>

                  {/* Actions */}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ gridRow: "1", gridColumn: "14 / 15" }}
                    mb={1}
                  >
                    {/* Preview Route button */}
                    <IconButton
                      aria-label="preview"
                      color="secondary"
                      size="small"
                      onClick={() => handleTourPointPreview(item.uniqueId)}
                    >
                      <PreviewIcon color="info" />
                    </IconButton>

                    {/* Delete point button */}
                    <IconButton
                      aria-label="delete"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteTourPoint(item.uniqueId)}
                      disabled={index === 0 && tourPointsState.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Collapse
                  in={tourPointsState[index].expanded}
                  timeout={collapseTimeout}
                  // unmountOnExit
                  sx={{
                    "& .MuiCollapse-wrapperInner": {
                      display: "grid",
                      gridTemplateColumns: "repeat(14, 1fr)",
                      gap: 1,
                      pl: 1.2,
                      pr: 3,
                      mt: 2,
                    },
                  }}
                >
                  {/* Auto activation */}
                  <Box sx={{ gridColumn: "2 / 6" }}>
                    <SelectWithInfo
                      label={tourPointsState[index].autoActivateTourPoint.label}
                      value={tourPointsState[index].autoActivateTourPoint.value}
                      id={`${item.uniqueId}-autoActivateTourPoint`}
                      onChange={(event: any) =>
                        handleTourPointInputChange(event, { type: "select" })
                      }
                      required={
                        tourPointsState[index].autoActivateTourPoint.required
                      }
                      error={
                        !tourPointsState[index].autoActivateTourPoint.isValid
                      }
                      info={tourPointsState[index].autoActivateTourPoint.info}
                      options={autoActivateTourPointArr}
                    />
                  </Box>

                  {/* Point Actiovation Radius */}
                  {tourPointsState[index].autoActivateTourPoint.value ===
                    AUTO_ACTIVATE_TOUR_POINT.yes.value && (
                    <Box sx={{ gridColumn: "6 / 11" }}>
                      <NumberFieldWithInfo
                        label={
                          tourPointsState[index].pointActivationRadius.label
                        }
                        value={
                          tourPointsState[index].pointActivationRadius.value
                        }
                        id={`${item.uniqueId}-pointActivationRadius`}
                        onChange={handleTourPointInputChange}
                        required={
                          tourPointsState[index].pointActivationRadius.required
                        }
                        error={
                          !tourPointsState[index].pointActivationRadius.isValid
                        }
                        info={tourPointsState[index].pointActivationRadius.info}
                      />
                    </Box>
                  )}

                  {/* More details */}
                  <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                    <FormLabel
                      component="legend"
                      sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}
                      required={tourPointsState[index].moreDetails.required}
                    >
                      {tourPointsState[index].moreDetails.label}
                    </FormLabel>
                    <RichTextEditor
                      /*
                // @ts-ignore */
                      editorState={tourPointsState[index].moreDetails.value}
                      onEditorStateChange={(editorState: any) =>
                        handleTourPointInputChange(editorState, {
                          type: "richText",
                          id: `${item.uniqueId}-moreDetails`,
                        })
                      }
                    />
                  </Box>

                  {/* Content type */}
                  <Box sx={{ gridColumn: "2 / 6" }}>
                    <FormControl fullWidth size="small" margin="none">
                      <InputLabel id={`${item.uniqueId}-contentType`}>
                        {tourPointsState[index].contentType.label}
                      </InputLabel>
                      <Select
                        labelId={`${item.uniqueId}-contentType`}
                        name={`${item.uniqueId}-contentType`}
                        label={tourPointsState[index].contentType.label}
                        value={tourPointsState[index].contentType.value}
                        onChange={(event: any) =>
                          handleTourPointInputChange(event, { type: "select" })
                        }
                        required={tourPointsState[index].contentType.required}
                        error={!tourPointsState[index].contentType.isValid}
                        fullWidth
                      >
                        {tourPointContentTypesArr.map((item: any) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  {/* "Display type" select - displayed for 'video' and 'audio' */}
                  {[
                    TOUR_POINT_CONTENT_TYPES.video.value,
                    TOUR_POINT_CONTENT_TYPES.audio.value,
                  ].indexOf(tourPointsState[index].contentType.value) > -1 &&
                    tourPointsState[index].contentType.value !== "" && (
                      <Box sx={{ gridColumn: "6 / 11" }} mb={1}>
                        <FormControl fullWidth size="small" margin="none">
                          <InputLabel id={`${item.uniqueId}-displayTypeOnOpen`}>
                            {tourPointsState[index].displayTypeOnOpen.label}
                          </InputLabel>
                          <Select
                            labelId={`${item.uniqueId}-displayTypeOnOpen`}
                            name={`${item.uniqueId}-displayTypeOnOpen`}
                            label={
                              tourPointsState[index].displayTypeOnOpen.label
                            }
                            value={
                              tourPointsState[index].displayTypeOnOpen.value
                            }
                            onChange={(event: any) =>
                              handleTourPointInputChange(event, {
                                type: "select",
                              })
                            }
                            required={
                              tourPointsState[index].displayTypeOnOpen.required
                            }
                            error={
                              !tourPointsState[index].displayTypeOnOpen.isValid
                            }
                            fullWidth
                          >
                            {tourPointContentDisplayOptionsArr.map(
                              (item: any) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    )}

                  {/* Video content */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.video.value && (
                    <Box
                      sx={{
                        gridColumn: "2 / 10",
                        mt: 4,
                        maxHeight: "215px",
                      }}
                    >
                      <VideoUploader
                        label={tourPointsState[index].videoContent.label}
                        info={tourPointsState[index].videoContent.info}
                        id={`${item.uniqueId}-videoContent`}
                        value={tourPointsState[index].videoContent.value}
                        error={!tourPointsState[index].videoContent.isValid}
                        onInputChange={handleTourPointInputChange}
                      />
                    </Box>
                  )}

                  {/* Audio content */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.audio.value && (
                    <Box
                      sx={{
                        gridColumn: "2 / 10",
                        mt: 4,
                        maxHeight: "215px",
                      }}
                    >
                      <AudioUploader
                        label={tourPointsState[index].audioContent.label}
                        info={tourPointsState[index].audioContent.info}
                        id={`${item.uniqueId}-audioContent`}
                        value={tourPointsState[index].audioContent.value}
                        error={!tourPointsState[index].audioContent.isValid}
                        onInputChange={handleTourPointInputChange}
                      />
                    </Box>
                  )}

                  {/* External webpage */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.externalWebpage.value && (
                    <Box sx={{ gridColumn: "2 / 11" }} my={1}>
                      <TextField
                        label={tourPointsState[index].externalWebpage.label}
                        value={tourPointsState[index].externalWebpage.value}
                        id={`${item.uniqueId}-externalWebpage`}
                        onChange={handleTourPointInputChange}
                        margin="none"
                        required={
                          tourPointsState[index].externalWebpage.required
                        }
                        fullWidth
                        size="small"
                        error={!tourPointsState[index].externalWebpage.isValid}
                      />
                    </Box>
                  )}

                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* ----------------------------------------------------- Question ----------------------------------------------------- */}
                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* Question type - displayed for 'question' */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.question.value && (
                    <Box sx={{ gridColumn: "6 / 11" }} mb={1}>
                      <FormControl fullWidth size="small" margin="none">
                        <InputLabel id={`${item.uniqueId}-questionType`}>
                          {tourPointsState[index].questionType.label}
                        </InputLabel>
                        <Select
                          labelId={`${item.uniqueId}-questionType`}
                          name={`${item.uniqueId}-questionType`}
                          label={tourPointsState[index].questionType.label}
                          value={tourPointsState[index].questionType.value}
                          onChange={(event: any) =>
                            handleTourPointInputChange(event, {
                              type: "select",
                            })
                          }
                          required={
                            tourPointsState[index].questionType.required
                          }
                          error={!tourPointsState[index].questionType.isValid}
                          fullWidth
                        >
                          {questionsTypesArr.map((item: any) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}

                  {/* Question - Multiple choice */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.question.value &&
                    tourPointsState[index].questionType.value ===
                      QUESTIONS_TYPES.multiple.value && (
                      <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                        <MultipleChoice
                          ref={(el) =>
                            (multipleChoiceRef.current[item.uniqueId] = el)
                          }
                          data={tourPointsState[index].question}
                          tourPointIndex={index}
                          clearFormError={clearFormError}
                        />
                      </Box>
                    )}

                  {/* Question - Short ansewr question */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.question.value &&
                    tourPointsState[index].questionType.value ===
                      QUESTIONS_TYPES.shortAnswer.value && (
                      <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                        <ShortAnswer
                          ref={(el) =>
                            (shortAnswerQuestionRef.current[item.uniqueId] = el)
                          }
                          data={tourPointsState[index].question}
                          tourPointIndex={index}
                          clearFormError={clearFormError}
                        />
                      </Box>
                    )}

                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* ----------------------------------------------------- Orientation -------------------------------------------------- */}
                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* Orientation options */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.orientation.value && (
                    <Box sx={{ gridColumn: "6 / 11" }} mb={1}>
                      <FormControl fullWidth size="small" margin="none">
                        <InputLabel id={`${item.uniqueId}-orientationType`}>
                          {tourPointsState[index].orientationType.label}
                        </InputLabel>
                        <Select
                          labelId={`${item.uniqueId}-orientationType`}
                          name={`${item.uniqueId}-orientationType`}
                          label={tourPointsState[index].orientationType.label}
                          value={tourPointsState[index].orientationType.value}
                          onChange={(event: any) =>
                            handleTourPointInputChange(event, {
                              type: "select",
                            })
                          }
                          required={
                            tourPointsState[index].orientationType.required
                          }
                          error={
                            !tourPointsState[index].orientationType.isValid
                          }
                          fullWidth
                        >
                          {orientationsTypesArr.map((item: any) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}

                  {/* orientation Point Valid Radius Range - to all orientation points except summary  */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.orientation.value &&
                    tourPointsState[index].orientationType.value !==
                      ORIENTATION_TYPES.summary.value && (
                      <Fragment>
                        {/* Radius of validation */}
                        <Box sx={{ gridColumn: "2 / 11" }}>
                          <NumberFieldWithInfo
                            label={
                              tourPointsState[index]
                                .orientationPointValidRadiusRange.label
                            }
                            value={
                              tourPointsState[index]
                                .orientationPointValidRadiusRange.value
                            }
                            id={`${item.uniqueId}-orientationPointValidRadiusRange`}
                            onChange={handleTourPointInputChange}
                            required={
                              tourPointsState[index]
                                .orientationPointValidRadiusRange.required
                            }
                            error={
                              !tourPointsState[index]
                                .orientationPointValidRadiusRange.isValid
                            }
                            info={
                              tourPointsState[index]
                                .orientationPointValidRadiusRange.info
                            }
                          />
                        </Box>

                        {/* Directions to the point */}
                        <Box sx={{ gridColumn: "2 / 11" }}>
                          <FormLabel component="legend" sx={{ fontSize: 14 }}>
                            {
                              tourPointsState[index].orientationPointDirections
                                .label
                            }
                            <InfoTooltip
                              info={
                                tourPointsState[index]
                                  .orientationPointDirections.info
                              }
                            />
                          </FormLabel>
                          <RichTextEditor
                            /*
                            // @ts-ignore */
                            editorState={
                              tourPointsState[index].orientationPointDirections
                                .value
                            }
                            onEditorStateChange={(editorState: any) =>
                              handleTourPointInputChange(editorState, {
                                type: "richText",
                                id: `${item.uniqueId}-orientationPointDirections`,
                              })
                            }
                          />
                        </Box>
                      </Fragment>
                    )}

                  {/* Orientation Question - Multiple choice + point discovery */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.orientation.value &&
                    tourPointsState[index].orientationType.value ===
                      ORIENTATION_TYPES.multipleQuestionPoint.value && (
                      <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                        <MultipleChoice
                          ref={(el) =>
                            (multipleChoiceRef.current[item.uniqueId] = el)
                          }
                          data={tourPointsState[index].question}
                          tourPointIndex={index}
                          clearFormError={clearFormError}
                        />
                      </Box>
                    )}

                  {/* Orientation Question - Short ansewr question + point discovery */}
                  {tourPointsState[index].contentType.value ===
                    TOUR_POINT_CONTENT_TYPES.orientation.value &&
                    tourPointsState[index].orientationType.value ===
                      ORIENTATION_TYPES.shortAnswerPoint.value && (
                      <Box sx={{ gridColumn: "2 / 11" }} mb={1}>
                        <ShortAnswer
                          ref={(el) =>
                            (shortAnswerQuestionRef.current[item.uniqueId] = el)
                          }
                          data={tourPointsState[index].question}
                          tourPointIndex={index}
                          clearFormError={clearFormError}
                        />
                      </Box>
                    )}

                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* -------------------------------------------------- Additional Images ----------------------------------------------- */}
                  {/* -------------------------------------------------------------------------------------------------------------------- */}
                  {/* Additional Image - Header */}
                  <Divider
                    sx={{
                      gridColumn: "2 / 11",
                      mb: 1,
                    }}
                  />
                  <Box
                    sx={{
                      gridColumn: "2 / 11",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // mb: 2,
                    }}
                  >
                    <FormLabel component="legend" sx={{ fontSize: 14 }}>
                      {tourPointsState[index].additionalImages.label}
                      <InfoTooltip
                        info={tourPointsState[index].additionalImages.info}
                      />
                    </FormLabel>
                    {/* Add another answer button */}
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleAddAdditionalImage(item.uniqueId)}
                    >
                      {
                        DICTIONARY.createTour.fields.tourPoints.additionalImages
                          .button.add
                      }
                    </Button>
                  </Box>

                  {/* Additional Image - List */}
                  {tourPointsState[index].additionalImages.value.length > 0 ? (
                    tourPointsState[index].additionalImages.value?.map(
                      (image: any, imageImdex: number) => (
                        <Fragment key={image.id}>
                          <Box
                            sx={{
                              gridColumn: "2 / 6",
                              maxHeight: "90px",
                              my: 2.5,
                            }}
                          >
                            <ImageUploader
                              label={`${
                                DICTIONARY.createTour.fields.tourPoints
                                  .additionalImages.image.url
                              } (${imageImdex + 1})`}
                              info={
                                tourPointsState[index].additionalImages.info
                              }
                              id={`${item.uniqueId}-additionalImages-url-${image.id}`}
                              value={image.url}
                              error={false}
                              onInputChange={handleTourPointInputChange}
                            />
                          </Box>

                          {/* Additional image label and link */}
                          <Box sx={{ gridColumn: "6 / 10", mt: 3.2 }}>
                            <TextField
                              label={`${
                                DICTIONARY.createTour.fields.tourPoints
                                  .additionalImages.image.label
                              } (${imageImdex + 1})`}
                              value={image.label}
                              id={`${item.uniqueId}-additionalImages-label-${image.id}`}
                              onChange={handleTourPointInputChange}
                              margin="none"
                              fullWidth
                              error={false}
                              size="small"
                              sx={{ mb: 1 }}
                            />

                            <TextField
                              label={`${
                                DICTIONARY.createTour.fields.tourPoints
                                  .additionalImages.image.link
                              } (${imageImdex + 1})`}
                              value={image.link}
                              id={`${item.uniqueId}-additionalImages-link-${image.id}`}
                              onChange={handleTourPointInputChange}
                              margin="none"
                              fullWidth
                              error={false}
                              size="small"
                            />
                          </Box>

                          {/* Actions */}
                          <Box
                            display="flex"
                            alignSelf="flex-start"
                            justifyContent="flex-end"
                            sx={{ gridColumn: "10 / 11" }}
                          >
                            {/* Delete additional image button */}
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() =>
                                handleDeleteAdditionalImage(
                                  item.uniqueId,
                                  image.id
                                )
                              }
                              color="error"
                              sx={{
                                p: 0,
                                mt: 3,
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Fragment>
                      )
                    )
                  ) : (
                    <Box
                      sx={{
                        gridColumn: "2 / 11",
                        mb: 1,
                      }}
                    >
                      <Typography color="text.secondary" fontSize={12}>
                        {
                          DICTIONARY.createTour.fields.tourPoints
                            .additionalImages.emptyState
                        }
                      </Typography>
                    </Box>
                  )}
                </Collapse>

                {/* Preview tour point error message */}
                {previewTourPointError && previewTourPointErrorIndex === index && (
                  <Box sx={{ gridColumn: "2 / 14", mt: 2 }}>
                    <Alert severity="error" variant="filled">
                      {previewTourPointError}
                    </Alert>
                  </Box>
                )}

                {index < tourPointsState.length - 1 && (
                  <Divider sx={{ height: (theme) => theme.spacing(4) }} />
                )}
              </Box>
            ))}
        </Box>
      </Box>
    );
  }
);

export default TourPoints;

//#region Helpers
const autoActivateTourPointArr = Object.keys(AUTO_ACTIVATE_TOUR_POINT).map(
  (key: string) => ({
    ...AUTO_ACTIVATE_TOUR_POINT[key],
  })
);

const tourPointContentTypesArr = Object.keys(TOUR_POINT_CONTENT_TYPES).map(
  (key: string) => ({
    ...TOUR_POINT_CONTENT_TYPES[key],
  })
);

const tourPointContentDisplayOptionsArr = Object.keys(
  TOUR_POINT_CONTENT_DISPLAY_OPTIONS
).map((key: string) => ({
  ...TOUR_POINT_CONTENT_DISPLAY_OPTIONS[key],
}));

const questionsTypesArr = Object.keys(QUESTIONS_TYPES).map((key: string) => ({
  ...QUESTIONS_TYPES[key],
}));

const orientationsTypesArr = Object.keys(ORIENTATION_TYPES).map(
  (key: string) => ({
    ...ORIENTATION_TYPES[key],
  })
);

const DEFAULT_COLLAPSE_TIMEOUT = 200;
//#endregion
