import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DICTIONARY } from "../../utils";
import { tourApi } from "../../api";

// App components
import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";

import AdminPromocode from "../../components/tour/createTourPromocode/sections/AdminPromocode";
import DemoPromocodes from "../../components/tour/createTourPromocode/sections/DemoPromocodes";
import ReusablePromocodes from "../../components/tour/createTourPromocode/sections/ReusablePromocodes";
import SingleusePromocodes from "../../components/tour/createTourPromocode/sections/SingleusePromocodes";

// Mui
import { Box, Container, Typography } from "@mui/material";

/* -------------------------------- DEPRECATED -------------------------------- */
function PromocodesManager() {
  const { id: tourId }: any = useParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tour, setTour] = useState<any>({});

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await tourApi.get(tourId);

        if (response.hasOwnProperty("error")) {
          setIsLoading(false);
          setError(DICTIONARY.general.error);
          return;
        }

        // Success
        setTour(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [tourId]);

  const Content = () => (
    <Container>
      <Box
        sx={{
          marginTop: 3,
        }}
      >
        {/* Page header */}
        <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
          {/* Title */}
          <Typography variant="h5" component="h1" sx={{ mr: 1 }}>
            {DICTIONARY.promocodesManager.title}
          </Typography>
          <Typography variant="body1" component="h1">
            ({tour.name})
          </Typography>
        </Box>

        <AdminPromocode tourId={tourId} />

        <DemoPromocodes tourId={tourId} />

        <ReusablePromocodes tourId={tourId} />

        <SingleusePromocodes tourId={tourId} tourName={tour.name} />
      </Box>
    </Container>
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <EmptyState title={DICTIONARY.tour.emptyState} icon="tour" py={20} />
      ) : (
        <Content />
      )}
    </React.Fragment>
  );
}

export default PromocodesManager;
