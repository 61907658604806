const number = {
  isNumber: (val: number) => {
    try {
      return typeof Number(val) === "number";
    } catch (error) {
      return false;
    }
  },
  isSmallerThanX: (val: number, x: number) => {
    try {
      return val <= x;
    } catch (error) {
      return false;
    }
  },
  isGreaterThanX: (val: number, x: number) => {
    try {
      return val >= x;
    } catch (error) {
      return false;
    }
  },
};

export default number;
