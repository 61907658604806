export * from "./map";
export * from "./promocodes";
export * from "./tour";
export * from "./tours";
export * from "./country";
export * from "./language";
export * from "./roles";
export * from "./locale";

// General
export const SNACKBAR_SEVERITY = {
  error: "error",
  warning: "warning",
  info: "info",
  success: "success",
};

export const IMAGE_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/tour-guide-hub.appspot.com/o/placeholders%2Fimage-placeholder.jpg?alt=media&token=ba682dda-9b3a-4926-bee3-38edc7296b5e";
// export const RESIZED_IMAGE_SUFFIX = '_400x400';

export const IMAGE_AVATAR_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/tour-guide-hub.appspot.com/o/placeholders%2Favatar-placeholder.png?alt=media&token=93db5e93-9c96-4269-85fb-fad643f0ef82";

export const VIDEO_POSTER =
  "https://firebasestorage.googleapis.com/v0/b/tour-guide-hub.appspot.com/o/placeholders%2Fvideo-poster.jpg?alt=media&token=f36a51f4-177a-4bbf-95cc-7efc1658e3b1";

export const drawerWidth = 240;
