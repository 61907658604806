import { initializeApp, getApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
  Timestamp,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const startFirebaseApp = () => firebaseApp;
let db = getFirestore();
let auth = getAuth();
let storage = getStorage();
let functions = getFunctions(getApp());
// const analytics = getAnalytics(firebaseApp);

// Running emulator locally
if (
  window.location.hostname === "localhost" &&
  process.env.REACT_APP_EMULATOR === "true"
) {
  console.log("*** EMULATOR ***");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export {
  startFirebaseApp,
  firebaseApp,
  db,
  auth,
  storage,
  functions,
  Timestamp,
};
