// Icons
import { List as TableIcon, GridView as GridIcon } from "@mui/icons-material";

export const VIEW_STRUCTURE_OPTIONS: any = {
  table: {
    label: "Table",
    value: "table",
    icon: TableIcon,
  },
  grid: {
    label: "Grid",
    value: "grid",
    icon: GridIcon,
  },
};
