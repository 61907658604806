const qrCodeGenerator = {
  title: "QR code generator",
  buttons: {
    generate: "Generate code",
    download: "Download QR",
  },
  fields: {
    locale: {
      label: "Selected app language",
      info: "*Default language - English.",
    },
    searchValue: {
      label: "Search value",
      info: "Create a QR code for a desired search value in the users app. \n It can be a country, location, part of a tour name or a tag.",
    },
  },
  errors: {
    generalError: `Couldn't create promocode. Please try again or contact support.`,
  },
  emptyState: "The QR code will be presented here",
};

export default qrCodeGenerator;
