// Mui
import { Box, Typography } from "@mui/material";

function NotFound() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "400px",
      }}
    >
      <Typography variant="h5" component="h1">
        Page not found
      </Typography>
    </Box>
  );
}

export default NotFound;
