const getBounds = (route: [][]) => {
  try {
    const latitudeArr: any = route.map((item: any) => item[0]);
    const longitudeArr: any = route.map((item: any) => item[1]);

    const minLatitude: number = Math.min(...latitudeArr);
    const maxLatitude: number = Math.max(...latitudeArr);

    const minLongitude: number = Math.min(...longitudeArr);
    const maxLongitude: number = Math.max(...longitudeArr);

    const southWest = [minLongitude, minLatitude];
    const northEast = [maxLongitude, maxLatitude];
    return [southWest, northEast];
  } catch (error) {
    return;
  }
};

const getCenter = (bounds: any) => {
  try {
    const longitudeCenter = bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2;
    const latitudeCenter = bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2;

    return [longitudeCenter, latitudeCenter];
  } catch (error) {
    return;
  }
};

export default {
  getBounds,
  getCenter,
};
