const isLatitudeValid = (latitude: number) => Math.abs(latitude) <= 90;

const isLongitudeValid = (longitude: number) => Math.abs(longitude) <= 180;

const isValidCoordinates = (str: string) => {
  try {
    let coordinates: any = JSON.parse(str);

    if (coordinates.length != 2) {
      return false;
    }

    if (
      !(isLatitudeValid(coordinates[0]) && isLongitudeValid(coordinates[1]))
    ) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

const isValidGeoJsonCoordinates = (str: string) => {
  try {
    let arr: any = JSON.parse(str);

    if (arr.length === 0) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      let coordinates = arr[i];

      if (coordinates.length != 2) {
        return false;
      }

      if (
        !(isLatitudeValid(coordinates[0]) && isLongitudeValid(coordinates[1]))
      ) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};

const coordinates = {
  isLatitudeValid,
  isLongitudeValid,
  isValidCoordinates,
  isValidGeoJsonCoordinates,
};

export default coordinates;
