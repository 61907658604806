import React, { useState, useEffect, Fragment } from "react";
import { DICTIONARY, HELPERS } from "../../../../utils";
import { tourApi } from "../../../../api";

// App components
import Loader from "../../../loader/Loader";
import EmptyState from "../../../shared/EmptyState";
import InfoTooltip from "../../../shared/InfoTooltip";
import CreateDemoPromocode from "../create/CreateDemoPromocode";

// Mui
import { Box, Container, Typography, Button, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// Icons
import { Create as CreateIcon } from "@mui/icons-material";

type Props = {
  tourId: string;
};

function DemoPromocodes({ tourId }: Props) {
  const [emptyState, setEmptyState] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [promocodes, setPromocodes] = useState<any>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await tourApi.promocode.getSubcollection(tourId, "demo");

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setEmptyState(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setEmptyState(DICTIONARY.promocodesManager.sections.demo.emptyState);
        return;
      }

      const parsedResponse = response.map((item: any) => {
        return {
          ...item,
          id: item.code,
          createdAt: HELPERS.date.formatDate(item.createdAt.toDate()),
          expirationDate: HELPERS.date.formatDate(item.expirationDate.toDate()),
        };
      });

      // Success
      setEmptyState("");
      setPromocodes(parsedResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Table
  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: DICTIONARY.promocodesManager.table.columns.code,
      width: 150,
    },
    {
      field: "description",
      headerName: DICTIONARY.promocodesManager.table.columns.description,
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: DICTIONARY.promocodesManager.table.columns.createdAt,
      flex: 2,
    },
    {
      field: "expirationDate",
      headerName: DICTIONARY.promocodesManager.table.columns.expirationDate,
      flex: 2,
    },
    {
      field: "count",
      headerName: DICTIONARY.promocodesManager.table.columns.count,
      width: 100,
    },
  ];
  //#endregion

  //#region Components
  const Header = () => {
    return (
      <Fragment>
        {/* Section header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          {/* Title */}
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mb={1}
          >
            <Typography variant="h6" component="h1" sx={{ mr: 0.2, mb: 0 }}>
              {DICTIONARY.promocodesManager.sections.demo.title}
            </Typography>
            <InfoTooltip
              info={DICTIONARY.promocodesManager.sections.demo.info}
            />
          </Box>

          {/* Action */}
          <Button
            startIcon={<CreateIcon />}
            color="secondary"
            onClick={handleOpenCreatePromocodeModal}
          >
            {DICTIONARY.promocodesManager.sections.demo.buttons.generate}
          </Button>
        </Box>
      </Fragment>
    );
  };
  const Content = ({ promocodes }: any) => {
    return (
      <Fragment>
        {/* Section content */}
        <div style={{ height: 300, width: "100%" }}>
          <DataGrid
            columns={columns}
            rows={promocodes}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    );
  };
  //#endregion

  //#region Create code modal
  const [openCreatePromocodeModal, setOpenCreatePromocodeModal] =
    useState(false);

  const handleOpenCreatePromocodeModal = () => {
    setOpenCreatePromocodeModal(true);
  };

  const handleCloseCreatePromocodeModal = async (
    isSubmitedSuccessfully: boolean
  ) => {
    setOpenCreatePromocodeModal(false);

    if (isSubmitedSuccessfully) {
      await fetchData();
    }
  };
  //#endregion

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          <Header />

          <Paper sx={{ p: 1 }}>
            {isLoading ? (
              <Loader py={4} />
            ) : emptyState ? (
              <EmptyState title={emptyState} icon="promocode" py={4} />
            ) : (
              <Content promocodes={promocodes} />
            )}
          </Paper>
        </Box>
      </Container>

      {/* Dialogs */}
      <CreateDemoPromocode
        open={openCreatePromocodeModal}
        onClose={handleCloseCreatePromocodeModal}
        tourId={tourId}
      />
    </React.Fragment>
  );
}

export default DemoPromocodes;
