const createPromocode = {
  title: {
    create: "Create new promocode",
    edit: "Edit promocode",
  },
  sections: {
    details: {
      label: "Promocode details",
      emptyState: "",
      endUser: "End-user details",
    },
    owners: {
      label: "Owners",
      emptyState: "No owners yet",
      type: "Type",
    },
    tours: {
      label: "Tours",
      emptyState: "No tours yet",
    },
    summary: {
      label: "Summary",
      emptyState: "",
    },
  },
  buttons: {
    next: "Next",
    back: "Back",
    save: "Save",
    addOwner: "Add owner",
  },
  fields: {
    description: {
      label: "Description",
      info: "Some general details about the code. \n - Who is it for? \n - Is it for demo usage? \n etc.",
      error: {},
    },
    code: {
      label: "Code",
      info: "Code should be consist of 8 CAPITAL letters and/or digits.",
      error: {
        length: "The code must be consist of 8 chars.",
        invalidChar: "The code has invalid char.",
        alreadyExist: "The code is already exist in the system",
      },
    },
    expirationDate: {
      label: "Expiration date",
      info: "",
      error: {},
    },
    discountUnit: {
      label: "Discount unit",
      info: "If set to 'Percentage' - the discount will be deducted from the tour price by % operation. \n \n If set to 'Currency' - the discount will be deducted from the tour price by subtraction.",
      error: {},
    },
    discountValue: {
      label: "Discount value",
      info: "The numerical value of the discount. \n The value 100 with '%' unit will set a free tour.",
      error: {},
    },
    owners: {
      type: {
        label: "Type",
        info: "",
        error: {},
      },
      owner: {
        label: "Name",
        info: "",
        error: {
          required: "Must have an owner.",
        },
      },
      profitDistribution: {
        label: "Profit distribution (%)",
        info: "Defined in '%'. \n How much much profit will the owner get from the total price of the tour.",
        error: {},
      },
      limit: {
        label: "Limit ($)",
        info: "Set a limit to prevent the discount value (that being calculated by percentage) from being bigger than it should.",
        error: {},
      },
    },
    eligibleToursMode: {
      label: "What tours does this promocode apply for?",
      info: "Default - the promocode code is eligible for all the tours in the system. \n \n Selected - The promocode will work only on the selected tours.",
    },
    eligibleTours: {
      label: "Eligible tour",
      info: "",
    },
    status: {
      label: "Status",
      info: "Active - the promocode is valid to use by users. \n Inactive - the promocode will give an 'invalid' message when trying to use it.",
      error: {},
    },
  },
  errors: {
    generalError: `Couldn't create promocode. Please try again or contact support.`,
    generalErrorEdit: `Couldn't edit promocode. Please try again or contact support.`,
  },
  emptyState: "The requested promocode does not exist",
};

export default createPromocode;
