// App components
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";

type Props = {
  label: string;
  value: string;
  id: string;
  onChange: (event: any) => void;
  required: boolean;
  error: boolean;
  info: string;
  disabled?: boolean;
  options: any;
};

export default function SelectWithInfo({
  label,
  value,
  id,
  onChange,
  required,
  error,
  info,
  disabled,
  options,
}: Props) {
  return (
    <FormControl
      fullWidth
      size="small"
      margin="none"
      error={error}
      disabled={disabled}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        name={id}
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        error={error}
        disabled={disabled}
        fullWidth
        endAdornment={
          info !== "" && (
            <InputAdornment position="end">
              <InfoTooltip info={info} />
            </InputAdornment>
          )
        }
        sx={{
          paddingRight: 0.5,
          "& .MuiSelect-icon": {
            right: info !== "" ? 22 : "none",
          },
        }}
      >
        {options.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
