import React, { useState, useEffect } from "react";

// Mui
import { TextField, Autocomplete, CircularProgress } from "@mui/material";

interface EnitityI {
  id: string;
  name: string;
}

type Props = {
  label: string;
  info: string;
  id: string;
  value: EnitityI;
  error: boolean;
  required?: boolean;
  disabled?: boolean;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    payload: any
  ) => void;
  apiCallback: any;
  additionalOptions?: any;
};

const AutocompleteSelectEntity = ({
  label,
  info,
  id,
  value,
  error,
  required,
  disabled = false,
  onInputChange,
  apiCallback,
  additionalOptions,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly EnitityI[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        if (!apiCallback) {
          setOptions([]);
        }

        let response = [];
        response = await apiCallback();

        response =
          response &&
          response.map((item: any) => {
            return {
              id: item.id,
              name: item.name,
              ...(item.avatar && { avatar: item.avatar }),
            };
          });

        if (active) {
          let options = [];
          if (additionalOptions) {
            options = [...additionalOptions, ...response];
          } else {
            options = [...response];
          }
          setOptions(options);
        }
      } catch (error) {}
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id}
      fullWidth
      open={open}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        // setValue(newValue);
        onInputChange(event, { type: "autocomplete", id: id, value: newValue });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          margin="none"
          label={label}
          error={error}
          disabled={disabled}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteSelectEntity;
