import general from "./general";
import string from "./string";
import number from "./number";
import email from "./email";
import coordinates from "./coordinates";
import filesUpload from "./filesUpload";

const VALIDATORS = {
  general,
  string,
  number,
  email,
  coordinates,
  filesUpload,
};

export default VALIDATORS;
