import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { promocodeApi } from "../../api";

// Mui
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

// App components
import PromocodeStatusDialog from "./PromocodeStatusDialog";

// Icons
import {
  Edit as EditIcon,
  CheckCircleOutline as ActiveIcon,
} from "@mui/icons-material";

type Props = {
  data: any;
  anchorEl: any;
  handleCloseMenu: () => void;
  onItemUpdate: () => Promise<void>;
};

export default function PromocodeItemMenu({
  data,
  anchorEl,
  handleCloseMenu,
  onItemUpdate,
}: Props) {
  const history = useHistory();

  //#region Edit tour
  const handleEdit = () => {
    history.push(`${ROUTES.promocodes.path}/${data.id}/edit`);
  };
  //#endregion

  //#region Promocode status
  const [openStatusModal, setOpenStatusModal] = useState(false);

  const onStatusListItemClick = () => {
    handleCloseMenu();
    setOpenStatusModal(true);
  };

  const handleStatus = async (payload: any) => {
    let response = await promocodeApi.edit(data.id, {
      status: payload.status,
    });

    return response;
  };

  const handleCloseStatusModal = (data: any) => {
    setOpenStatusModal(false);

    if (data.isSubmitedSuccessfully) {
      onItemUpdate();
    }
  };
  //#endregion
  return (
    <Fragment>
      {/* Item menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Edit */}
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.promocodes.entityItem.actions.edit}
          </ListItemText>
        </MenuItem>

        {/* Promocode status */}
        <MenuItem onClick={onStatusListItemClick}>
          <ListItemIcon>
            <ActiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.promocodes.entityItem.actions.status}
          </ListItemText>
        </MenuItem>
      </Menu>

      {/* Status dialog */}
      {openStatusModal && (
        <PromocodeStatusDialog
          open={openStatusModal}
          title={DICTIONARY.promocodes.statusDialog.title}
          value={data.status}
          onSubmit={handleStatus}
          onClose={handleCloseStatusModal}
        />
      )}
    </Fragment>
  );
}
