const guides = {
  title: "Guides",
  create: "Create new guide",
  emptyState: "No guides yet",
  emptyStateFiltered: "No matching guides",
  guideItem: {
    details: {
      country: "Country",
      location: "Location",
      description: "Description",
    },
    actions: {
      view: "View",
      edit: "Edit guide",
      updateUserPassword: "Update user password",
      copyLink: "Copy guide link",
      delete: "Delete",
    },
  },
  table: {
    columns: {
      avatar: "Avatar",
      name: "Name",
      email: "Email",
      country: "Country",
      location: "Location",
      createdBy: "Created by",
      createdAt: "Created at",
      updatedAt: "Updated at",
      publishStatus: "Status",
      actions: "Actions",
    },
  },
  deleteConfirmationDialog: {
    title: "Delete guide",
    body: "Deleting the guide will remove it from the Back-office app and from the Users app. You will no longer be able to edit it.",
    button: "Delete",
  },
  copyPwaLink: {
    title: "Copy guide link",
    button: "Copy",
    copied: "Copied",
  },
};

export default guides;
