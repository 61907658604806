import { useState, useRef, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { DICTIONARY } from "../../utils";

// Mui
import {
  Box,
  Button,
  Typography,
  IconButton,
  Popover,
  Divider,
} from "@mui/material";

// Icons
import { Link as CopyIcon } from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";
import TextFieldWithInfo from "../inputs/TextFieldWithInfo";

interface Props {
  id?: string | null;
  name: string;
  email: string;
  password: string | null;
  onCreatePassword?: () => void;
}

export default function UserCredsDetails({
  id,
  name,
  email,
  password,
  onCreatePassword,
}: Props) {
  //#region Copy popover
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const handleClickCopy = () => {
    handlePopoverOpen(containerRef.current);
  };
  //#endregion

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 1,
        }}
      >
        {/* Name */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ color: grey[600], width: "60px" }}>
            {DICTIONARY.auth.userCredsDialog.text.name}:
          </Typography>
          <Typography>{name}</Typography>
        </Box>

        {/* Email */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ color: grey[600], width: "60px" }}>
            {DICTIONARY.auth.userCredsDialog.text.email}:
          </Typography>
          <Typography>{email}</Typography>
        </Box>

        {/* UserId */}
        {id && id !== "" && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: grey[600], width: "60px" }}>
              {DICTIONARY.auth.userCredsDialog.text.userId}:
            </Typography>
            <Typography>{id}</Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ my: 1, display: "flex", alignItems: "center" }}>
        {/* Create new password button */}
        {onCreatePassword && (
          <Fragment>
            <Divider sx={{ my: 2 }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={onCreatePassword}
            >
              {DICTIONARY.auth.userCredsDialog.buttons.createPassword}
            </Button>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          </Fragment>
        )}

        {/* Passwoard */}
        {password && password !== "" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
            }}
          >
            <TextFieldWithInfo
              label={DICTIONARY.auth.userCredsDialog.fields.password.label}
              value={password}
              id="password"
              info={DICTIONARY.auth.userCredsDialog.fields.password.info}
              disabled={true}
            />

            <CopyToClipboard text={password} onCopy={handleClickCopy}>
              <IconButton
                aria-label="copy"
                onMouseLeave={handlePopoverClose}
                ref={containerRef}
              >
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Box>
        )}
      </Box>

      {/* "Copied" message */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            p: 1,
          },
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{DICTIONARY.general.copyPwaLink.copied}</Typography>
      </Popover>
    </Fragment>
  );
}
