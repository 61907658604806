const promocodesManager = {
  title: "Tour promocodes",
  sections: {
    admin: {
      title: "Admin",
      info: '"Super" code that can be used multiple times.\n It will always be valid in order to start the tour.',
      buttons: {},
      emptyState: "Error loading Admin promocode.",
    },
    demo: {
      title: "Demo",
      info: `Demo code can be used multiple times for demonstration purposes.\n When passing it to others, please pay attention it has an Expiration date.`,
      buttons: {
        generate: "Generate",
      },
      emptyState: "No demo promocodes yet.",
      form: {
        title: "Generate demo promocode",
        fields: {
          description: {
            label: "Description",
            info: "Describe here the purpose of the promo code or who will be using it.",
          },
          expirationDate: {
            label: "Expiration date",
            info: "",
          },
          codeSourceType: {
            label: "Code type",
            info: "",
          },
          customCode: {
            label: "Custom code",
            info: "Must contain 8 characters. Can consists of digits and letters. Letters must be capital.",
            errors: {
              required: `Custom code is required.`,
              length: "Code length must be 8 chars.",
            },
          },
        },
      },
    },
    reusable: {
      title: "Reusable",
      info: "Reusable code can be used multiple times by customers.\n It is defined with unique discount properties.",
      buttons: {
        generate: "Generate",
      },
      emptyState: "No reusable promocodes yet.",
      form: {
        title: "Generate reusable promocode",
        fields: {
          description: {
            label: "Description",
            info: "Describe here the purpose of the promo code or who will be using it.",
          },
          expirationDate: {
            label: "Expiration date",
            info: "",
          },

          discountUnit: {
            label: "Discount unit",
            info: "",
          },
          discountValue: {
            label: "Discount value",
            info: "",
          },
        },
      },
    },
    singleuse: {
      title: "Single-use",
      info: "Single-use code can be used only one time.\n After submitted successfully by the client, the code will no longer be valid to re-submit.",
      buttons: {
        generate: "Generate",
        download: "Download CSV",
      },
      emptyState: "No single-use promocodes yet.",
      form: {
        title: "Generate single-use promocode",
        fields: {
          description: {
            label: "Description",
            info: "Describe here the purpose of the promo code or who will be using it.",
          },
          daysToExpiration: {
            label: "Days to expiration",
            info: "The number of days until the promo code gets expired since its first use.\n 0 = Endless (the promocode will never expire).",
          },
          discountUnit: {
            label: "Discount unit",
            info: "",
          },
          discountValue: {
            label: "Discount value",
            info: "",
          },
          amount: {
            label: "Amount",
            info: "Creating single-use promocodes is limited up to 500 codes in one batch.\n In order to create more than 500, repeat the creation process.",
            errors: {
              limit: `Can't create more than 500 single-use promocodes in one batch.`,
            },
          },
          promocodePattern: {
            label: "Promocode pattern",
            info: "",
          },
        },
      },
      errors: {
        downloadCsv: `Couldn't download CSV. Please try again or contact support.`,
      },
    },
  },
  buttons: {},
  table: {
    columns: {
      code: "Code",
      description: "Description",
      createdAt: "Created at",
      expirationDate: "Expiration date",
      daysToExpiration: "Days to expiration",
      discountUnit: "Discount unit",
      discountValue: "Discount value",
      count: "Used",
      total: "Total",
      used: "Used",
      remained: "Remained",
    },
  },
  dialogs: {
    demo: {},
  },
};

export default promocodesManager;
