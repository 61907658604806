import React, { useState, useEffect } from "react";

import { VALIDATORS } from "../../utils";
import { IMAGE_PLACEHOLDER, IMAGE_AVATAR_PLACEHOLDER } from "../../consts";

// App components
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import { Box, Typography, IconButton } from "@mui/material";

// Colors
import { red, grey } from "@mui/material/colors";

// Icons
import { Publish as UploadIcon } from "@mui/icons-material";

type ImageUploadrProps = {
  label: string;
  info: string;
  id: string;
  value: string;
  error: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageContainerStyle?: any;
  placeholderType?: string;
};

export default function ImageUploader({
  label,
  info,
  id,
  value,
  error,
  onInputChange,
  imageContainerStyle,
  placeholderType,
}: ImageUploadrProps) {
  /*
    Note: imagePlaceholder is like the url.
    value is the file itself.
  */

  //#region Image placeholcer
  const [imagePlaceholder, setImagePlaceholder] = useState(
    placeholderType === "avatar" ? IMAGE_AVATAR_PLACEHOLDER : IMAGE_PLACEHOLDER
  );

  useEffect(() => {
    // The component knows to handle image as a file or as a url

    if (value && VALIDATORS.filesUpload.isValidDataFile(value, "image")) {
      // Value is a file
      setImagePlaceholder(URL.createObjectURL(value));
    } else {
      // Value is a url
      const imagePlaceholder =
        placeholderType === "avatar"
          ? IMAGE_AVATAR_PLACEHOLDER
          : IMAGE_PLACEHOLDER;
      setImagePlaceholder(value || imagePlaceholder);
    }
  }, []);

  const handleImagePlaceholcer = (file: any) => {
    if (file && VALIDATORS.filesUpload.isValidDataFile(file, "image")) {
      setImagePlaceholder(URL.createObjectURL(file));
    }
  };
  //#endregion

  return (
    <Box
      sx={{
        marginTop: {
          xs: 0,
          sm: "-26px",
        },
        height: "calc(100% + 38px)",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {/* Title */}
          <Typography
            component="label"
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(14),
              color: grey[700],
            }}
          >
            {label}
          </Typography>

          {/* image Upload Instructions */}
          <InfoTooltip info={info} aria-label="Upload image instructions" />
        </Box>

        <div style={{ position: "relative" }}>
          <IconButton
            aria-label="upload"
            size="small"
            sx={{
              color: (theme) => theme.palette.primary.main,
              width: "30px",
              height: "30px",
            }}
          >
            <UploadIcon sx={{ width: "24px", height: "24px" }} />
            <label
              htmlFor={id}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
            ></label>
          </IconButton>
          <input
            id={id}
            type="file"
            accept="image/jpeg, image/png"
            style={{ display: "none" }}
            onChange={(event: any) => {
              onInputChange(event);
              handleImagePlaceholcer(event.target.files[0]);
            }}
          />
        </div>
      </Box>

      {/* Image container */}
      <img
        src={imagePlaceholder}
        alt={label}
        style={{
          display: "block",
          width: "100%",
          height: "calc(100% - 32px)",
          maxHeight: "146px",
          objectFit: "cover",
          border: !error ? `1px solid ${grey[400]}` : `1px solid ${red[500]}`,
          borderRadius: "10px",
          ...imageContainerStyle,
        }}
      />
    </Box>
  );
}
