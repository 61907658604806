import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { businessPartnerApi } from "../../api";

// Mui
import {
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

// App components
import ConfirmationDialog from "../dialog/ConfirmationDialog";
import UserCredsDialog from "../user/UserCredsDialog";

// Icons
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Password as PasswordIcon,
} from "@mui/icons-material";

type Props = {
  data: any;
  anchorEl: any;
  handleCloseMenu: () => void;
  onItemUpdate: () => Promise<void>;
};

export default function BusinessPartnerItemMenu({
  data,
  anchorEl,
  handleCloseMenu,
  onItemUpdate,
}: Props) {
  const history = useHistory();

  //#region Edit
  const handleEdit = () => {
    history.push(`${ROUTES.businessPartners.path}/${data.id}/edit`);
  };
  //#endregion

  //#region User creds
  const [openUserPasswordModal, setOpenUserPasswordModal] = useState(false);

  const handleUpdateUserPassword = () => {
    handleCloseMenu();
    setOpenUserPasswordModal(true);
  };

  const handleCloseUserPasswordModal = (data: any) => {
    setOpenUserPasswordModal(false);

    if (data.isSubmitedSuccessfully) {
      // Idaely - Reload the page
      // But no need here, because nothing gets changes on the view
    }
  };
  //#endregion

  //#region Delete guide
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onDeleteListItemClick = () => {
    handleCloseMenu();
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    let response = await businessPartnerApi.delete(data.id);
    return response;
  };

  const handleCloseDeleteModal = (isSubmitedSuccessfully: boolean) => {
    setOpenDeleteModal(false);

    // If submited successfully --> update tour data state in the UI (without fetching the updated data fron the DB)
    if (isSubmitedSuccessfully) {
      onItemUpdate();
    }
  };
  //#endregion

  return (
    <Fragment>
      {/* Tour item menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Edit */}
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.businessPartners.entityItem.actions.edit}
          </ListItemText>
        </MenuItem>

        {/* User Password */}
        <Divider flexItem />
        <MenuItem onClick={handleUpdateUserPassword}>
          <ListItemIcon>
            <PasswordIcon fontSize="small" />
          </ListItemIcon>
          <Divider />
          <ListItemText>
            {DICTIONARY.businessPartners.entityItem.actions.updateUserPassword}
          </ListItemText>
        </MenuItem>

        {/* NOTE: Currently delete is no available, because if we delete the entiity, need to take care of all its promocode as weel */}
        {/* Delete */}
        {/* <Divider />
        <MenuItem onClick={onDeleteListItemClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {DICTIONARY.businessPartners.entityItem.actions.delete}
          </ListItemText>
        </MenuItem> */}
      </Menu>

      {/* USer creds dialog */}
      {openUserPasswordModal && (
        <UserCredsDialog
          open={openUserPasswordModal}
          data={data}
          onClose={handleCloseUserPasswordModal}
        />
      )}

      {/* Delete dialog */}
      {openDeleteModal && (
        <ConfirmationDialog
          open={openDeleteModal}
          title={DICTIONARY.businessPartners.deleteConfirmationDialog.title}
          onSubmit={handleDelete}
          onClose={handleCloseDeleteModal}
          submitButtonText={
            DICTIONARY.businessPartners.deleteConfirmationDialog.button
          }
          submitButtonIcon={<DeleteIcon />}
          submitButtonColor="error"
        >
          <Typography variant="body2">
            {DICTIONARY.businessPartners.deleteConfirmationDialog.body}
          </Typography>
        </ConfirmationDialog>
      )}
    </Fragment>
  );
}
