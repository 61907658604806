import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";
import {
  businessPartnerApi,
  guideApi,
  sellerApi,
  adminApi,
} from "../../../api";
import { PROMOCODE_OWNER_TYPES } from "../../../consts";

// Mui
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";

// App components
import EmptyState from "../../shared/EmptyState";
import Loader from "../../loader/Loader";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import AutocompleteSelectEntity from "../../inputs/AutocompleteSelectEntity";
import NumberFieldWithInfo from "../../inputs/NumberFieldWithInfo";

// Icons
import {
  Delete as DeleteIcon,
  AccountCircle as OwnerIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

// Lodash
var cloneDeep = require("lodash.clonedeep");

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode: boolean;
}

const Owners = forwardRef(
  ({ entityData, clearFormError, isEditMode }: Props, ref) => {
    // #region States
    const entityTemplate: any = {
      type: {
        label: DICTIONARY.createPromocode.fields.owners.type.label,
        info: DICTIONARY.createPromocode.fields.owners.type.info,
        value: entityData.type ?? "",
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
      owner: {
        label: DICTIONARY.createPromocode.fields.owners.owner.label,
        info: DICTIONARY.createPromocode.fields.owners.owner.info,
        value: entityData.owner ?? { name: "", id: "" },
        isValid: true,
        isRequired: () => true,
        innerPropertiesToCheck: ["name", "id"],
        customValidators: [
          // No need to use it cause I use "innerPropertiesToCheck"
          // Leave it here for future need
          // {
          //   callback: (val: any, item: any) => {
          //     return val.name !== "";
          //   },
          //   errorMessage:
          //     DICTIONARY.createPromocode.fields.owners.owner.error.required,
          // },
        ],
      },
      profitDistribution: {
        label:
          DICTIONARY.createPromocode.fields.owners.profitDistribution.label,
        info: DICTIONARY.createPromocode.fields.owners.profitDistribution.info,
        value: "",
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
      limit: {
        label: DICTIONARY.createPromocode.fields.owners.limit.label,
        info: DICTIONARY.createPromocode.fields.owners.limit.info,
        value: "",
        isValid: true,
        isRequired: () => false,
        customValidators: [],
      },
    };

    const [formState, setFormState] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //#region Handle opertor of businessPartner if needed
    const handleDifferentBusinessPartnerOpertorIfNeeded = async (
      owners: any
    ) => {
      // NOTE: The assumption is that if the first owner is "businessPartner", hence the one after him is his operator.
      // We check here if the current operator is the same one that appear on the "businessPartner" data. if not --> replace it.
      try {
        let clonedOwners: any = cloneDeep(owners);
        let newOperatorOfBusinessPartner: any = {};

        // Get businessPartner details
        const response = await businessPartnerApi.get(
          entityData.owners[0].owner.id
        );

        if (
          response &&
          response.operatedBy.id !== entityData.owners[1].owner.id
        ) {
          newOperatorOfBusinessPartner = {
            owner: response.operatedBy,
            type: response.operatorType,
          };
        }

        // Update the owner[1] (the operator of owner[0] - the businessPartner)
        if (Object.keys(newOperatorOfBusinessPartner).length > 0) {
          clonedOwners[1].type.value = newOperatorOfBusinessPartner.type;
          clonedOwners[1].owner.value = newOperatorOfBusinessPartner.owner;
        }

        return clonedOwners;
      } catch (error) {
        // orignal owners
        return owners;
      }
    };
    //#endregion

    useEffect(() => {
      (async () => {
        if (entityData?.owners?.length > 0) {
          let parsedOwners: any = [];

          entityData.owners.forEach((item: any) => {
            let clonedTemplate: any = cloneDeep(entityTemplate);

            clonedTemplate.uniqueId = item.uniqueId;
            clonedTemplate.type.value = item.type;
            clonedTemplate.owner.value = item.owner;
            clonedTemplate.profitDistribution.value = item.profitDistribution;
            clonedTemplate.limit.value = item.limit;

            parsedOwners.push({
              ...clonedTemplate,
            });
          });

          if (
            entityData.owners[0].type ===
            PROMOCODE_OWNER_TYPES["businessPartner"].value
          ) {
            setIsLoading(true);
            const response =
              await handleDifferentBusinessPartnerOpertorIfNeeded(parsedOwners);

            setFormState(response);
            setIsLoading(false);
          } else {
            setFormState(parsedOwners);
          }
        }
      })();
    }, []);
    // #endregion

    //#region Functionality
    const handleInputChange = async (event: any, payload?: any) => {
      try {
        const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
        let key: string = "";
        let val: any;

        switch (type) {
          case "text":
          case "textarea": {
            const { id, value } = event.target;
            key = id;
            val = value;
            break;
          }
          case "number": {
            const { id, value } = event.target;
            key = id;
            val = Number(value);
            break;
          }
          case "select": {
            const { name, value } = event.target;
            key = name;
            val = value;
            break;
          }
          case "file": {
            const { id, files } = event.target;
            key = id;
            val = files != null && files[0];
            break;
          }
          case "richText": {
            key = "description";
            val = event;
            break;
          }
          case "chips":
          case "autocomplete": {
            const { id, value } = payload;
            key = id;
            val = value;
            break;
          }
          case "date": {
            key = payload.id;
            val = event;
            break;
          }
          default: {
            break;
          }
        }

        // Update field
        const [uniqueId, field] = key.split("-");

        const indexOfPoint = formState.findIndex(
          (item: any) => item.uniqueId === uniqueId
        );
        if (indexOfPoint === -1) return;

        let newState = [...formState];
        newState[indexOfPoint][field] = {
          ...newState[indexOfPoint][field],
          value: val,
          isValid: true,
        };

        // Only for businessPartner
        if (payload) {
          const { ownerType, index } = payload;
          if (ownerType === PROMOCODE_OWNER_TYPES.businessPartner.value) {
            const { id: businessPartnerId, name: businessPartnerName } = val;
            const response = await businessPartnerApi.get(businessPartnerId);
            const { operatorType, operatedBy } = response;

            if (operatorType !== "" && Object.keys(operatedBy).length > 0) {
              newState[index + 1].type.value =
                PROMOCODE_OWNER_TYPES[operatorType].value;
              newState[index + 1].owner.value = operatedBy;
            }
          }
        }

        setFormState(newState);

        // Clear errors
        clearFormError();
      } catch (error) {
        console.error(error);
      }
    };

    const handleAddOwner = (type: string) => {
      let updatedFormState: any = [...formState];
      let clonedTemplate: any = cloneDeep(entityTemplate);

      let item = {
        uniqueId: HELPERS.firebase.generateId(),
        ...clonedTemplate,
      };

      switch (type) {
        case PROMOCODE_OWNER_TYPES.businessPartner.value: {
          item.type.value = PROMOCODE_OWNER_TYPES[type].value;

          // Business partner always comes with an "operatedBy"
          let clonedTemplate: any = HELPERS.general.cloneObject(entityTemplate);
          let secondItem = {
            uniqueId: HELPERS.firebase.generateId(),
            ...clonedTemplate,
          };
          secondItem.type.value = PROMOCODE_OWNER_TYPES["unknown"].value;
          updatedFormState.push(item, secondItem);
          break;
        }
        case PROMOCODE_OWNER_TYPES.seller.value: {
          item.type.value = PROMOCODE_OWNER_TYPES[type].value;
          updatedFormState.push(item);
          break;
        }
        case PROMOCODE_OWNER_TYPES.guide.value: {
          item.type.value = PROMOCODE_OWNER_TYPES[type].value;
          updatedFormState.push(item);
          break;
        }
        case PROMOCODE_OWNER_TYPES.admin.value: {
          item.type.value = PROMOCODE_OWNER_TYPES[type].value;
          updatedFormState.push(item);
          break;
        }
        default: {
          break;
        }
      }

      handleClose(); // Close menu
      setFormState(updatedFormState);
      clearFormError(); // Clear errors
    };

    const handleDeleteOwner = (item: any) => {
      const { uniqueId, type } = item;

      let newState = [...formState];
      const indexOfPoint = newState.findIndex(
        (item: any) => item.uniqueId === uniqueId
      );
      if (indexOfPoint === -1) {
        return;
      }

      let spliceCount = 1;
      // Relevent only for Business partners
      if (type.value === PROMOCODE_OWNER_TYPES.businessPartner.value) {
        spliceCount = 2; // Delete 2 items (businessPartner and his operator)
      }

      newState.splice(indexOfPoint, spliceCount);

      // Update formState
      setFormState(newState);

      // Clear errors
      clearFormError();
    };

    const isInputDisabled = (index: number) => {
      try {
        return (
          formState[index].type.value === PROMOCODE_OWNER_TYPES.unknown.value
        );
      } catch (error) {
        return false;
      }
    };

    const isRelatedToBusinessPartner = (index: number) => {
      try {
        if (index > 0) {
          return (
            formState[index - 1].type.value ===
            PROMOCODE_OWNER_TYPES.businessPartner.value
          );
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const shouldShowPointIndex = true;
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.form.list.handleEmptyFields(
            [...formState],
            shouldShowPointIndex
          );

        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.form.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.form.list.handleInvalidFields(
          [...formState],
          shouldShowPointIndex
        );

        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createPromocode.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Owners menu
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleAddOwnerMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    //#endregion

    //#region Submit and
    const prepareDataToSave = () => {
      let files: any = [];

      let parsedItems: any = [];

      formState.forEach((item: any) => {
        let parsedItem = {
          uniqueId: item.uniqueId,
          type: item.type.value,
          owner: item.owner.value,
          profitDistribution: item.profitDistribution.value,
          limit: item.limit.value,
        };

        parsedItems.push(parsedItem);
      });

      return {
        data: {
          owners: parsedItems,
        },
        files: files,
      };
    };

    const onNext = () => {
      if (formState.length === 0) {
        return {
          error: "Please add the promocode owner.",
          isValid: false,
        };
      }

      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createPromocode.sections.owners.label}
          actions={[
            {
              label: DICTIONARY.createPromocode.buttons.addOwner,
              icon: <OwnerIcon />,
              color: "primary",
              onClick: handleAddOwnerMenuClick,
              disabled: formState.length > 0,
            },
          ]}
        />
        <Menu
          id="owners-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() =>
              handleAddOwner(PROMOCODE_OWNER_TYPES.businessPartner.value)
            }
          >
            {PROMOCODE_OWNER_TYPES.businessPartner.label}
          </MenuItem>
          <MenuItem
            onClick={() => handleAddOwner(PROMOCODE_OWNER_TYPES.seller.value)}
          >
            {PROMOCODE_OWNER_TYPES.seller.label}
          </MenuItem>
          <MenuItem
            onClick={() => handleAddOwner(PROMOCODE_OWNER_TYPES.guide.value)}
          >
            {PROMOCODE_OWNER_TYPES.guide.label}
          </MenuItem>
          <MenuItem
            onClick={() => handleAddOwner(PROMOCODE_OWNER_TYPES.admin.value)}
          >
            {PROMOCODE_OWNER_TYPES.admin.label}
          </MenuItem>
        </Menu>

        {/* Content */}
        {isLoading ? (
          <Loader py={12} />
        ) : (
          <Box
            sx={{
              mt: 3,
            }}
          >
            {formState.length > 0 ? (
              formState.map((item: any, index: number) => (
                <Box
                  key={item.uniqueId}
                  id={`${item.uniqueId}-entity-container`}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(12, 1fr)",
                      gap: 1,
                    }}
                  >
                    {/* Type */}
                    <Box
                      sx={{ gridColumn: "1 / 12", display: "flex", gap: 0.6 }}
                      mb={1}
                    >
                      <Typography sx={{ color: grey[500] }}>
                        {DICTIONARY.createPromocode.sections.owners.type}:{" "}
                      </Typography>
                      <Typography>
                        {PROMOCODE_OWNER_TYPES[item.type.value].label}
                      </Typography>
                    </Box>

                    {/* Actions */}
                    {!isRelatedToBusinessPartner(index) && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ gridColumn: "12 / 13" }}
                      >
                        {/* Delete item */}
                        <IconButton
                          aria-label="delete"
                          color="error"
                          size="small"
                          onClick={() => handleDeleteOwner(item)}
                          disabled={isEditMode}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )}

                    {/* Owner */}
                    <Box sx={{ gridColumn: "1 / 5" }} mb={1}>
                      <AutocompleteSelectEntity
                        label={formState[index].owner.label}
                        info={formState[index].owner.info}
                        id={`${item.uniqueId}-owner`}
                        value={formState[index].owner.value}
                        error={!formState[index].owner.isValid}
                        required={!formState[index].owner.isRequired()}
                        disabled={
                          isInputDisabled(index) ||
                          isRelatedToBusinessPartner(index) ||
                          isEditMode
                        }
                        onInputChange={async (
                          event: any,
                          newValue: any | null
                        ) => {
                          handleInputChange(event, {
                            ...newValue,
                            ownerType: item.type.value,
                            index: index,
                          });
                        }}
                        apiCallback={ownerApiCallback[item.type.value]}
                      />
                    </Box>

                    {/* Discount value */}
                    <Box sx={{ gridColumn: "5 / 9" }} mb={1}>
                      <NumberFieldWithInfo
                        label={formState[index].profitDistribution.label}
                        value={formState[index].profitDistribution.value}
                        id={`${item.uniqueId}-profitDistribution`}
                        onChange={handleInputChange}
                        required={formState[
                          index
                        ].profitDistribution.isRequired()}
                        error={!formState[index].profitDistribution.isValid}
                        info={formState[index].profitDistribution.info}
                        disabled={isInputDisabled(index) || isEditMode}
                      />
                    </Box>

                    {/* Limit */}
                    <Box sx={{ gridColumn: "9 / 13" }} mb={1}>
                      <NumberFieldWithInfo
                        label={formState[index].limit.label}
                        value={formState[index].limit.value}
                        id={`${item.uniqueId}-limit`}
                        onChange={handleInputChange}
                        required={formState[index].limit.isRequired()}
                        error={!formState[index].limit.isValid}
                        info={formState[index].limit.info}
                        disabled={isInputDisabled(index) || isEditMode}
                      />
                    </Box>
                  </Box>

                  {index < formState.length - 1 && <Divider sx={{ my: 3 }} />}
                </Box>
              ))
            ) : (
              <EmptyState
                title={DICTIONARY.createPromocode.sections.owners.emptyState}
                icon="person"
                py={8}
              />
            )}
          </Box>
        )}
      </Fragment>
    );
  }
);

export default Owners;

//#region Helpers
const ownerApiCallback: any = {
  businessPartner: businessPartnerApi.getAll,
  guide: guideApi.getAll,
  seller: sellerApi.getAll,
  admin: adminApi.getAll,
};
//#endregion
