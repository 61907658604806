import { Fragment, useState, useRef } from "react";
import { DICTIONARY } from "../../utils";

// Charts
import PromocodesChart from "../../components/charts/PromocodesChart";

// Cards
import TopPromocodeCard from "../../components/cards/TopPromocodeCard";
import TopTourCard from "../../components/cards/TopTourCard";
import TopGuideCard from "../../components/cards/TopGuideCard";

// Mui
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

function PromocodesAnalytics() {
  return (
    <Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.promocodesAnalytics.title}
              </Typography>
            </Box>
          </Box>

          {/* Content */}
          <Box sx={{ mt: 3 }}>
            <Typography>TBD</Typography>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}

export default PromocodesAnalytics;
