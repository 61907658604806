import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../../utils";
import { SHOULD_DISPLAY_ANSWER_FEEDBACK } from "../../../../consts";

// App components
import TextFieldWithInfo from "../../../inputs/TextFieldWithInfo";
import RadioButtonWithInfo from "../../../inputs/RadioButtonWithInfo";
import InfoTooltip from "../../../shared/InfoTooltip";
import RichTextEditor from "../../../richTextEditor/RichTextEditor";

// Mui
import {
  Box,
  Button,
  TextField,
  IconButton,
  FormLabel,
  Divider,
} from "@mui/material";

// Colors
import { blueGrey } from "@mui/material/colors";

// Icons
import { Close as CloseIcon } from "@mui/icons-material";

type Props = {
  data: any;
  tourPointIndex: number;
  clearFormError: () => void;
};

const ShortAnswer = forwardRef(
  ({ data, tourPointIndex, clearFormError }: Props, ref) => {
    //#region States
    const initalFormState: any = {
      questionContent: {
        label: DICTIONARY.createTour.fields.question.questionContent.label,
        info: DICTIONARY.createTour.fields.question.questionContent.info,
        value: data.questionContent ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      submitButtonLabel: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer.submitButtonLabel
            .label,
        info: DICTIONARY.createTour.fields.question.shortAnswer
          .submitButtonLabel.info,
        value: data.submitButtonLabel ?? "",
        isValid: true,
        required: true,
        validators: [],
      },
      possibleAnswers: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer.possibleAnswers
            .label,
        info: DICTIONARY.createTour.fields.question.shortAnswer.possibleAnswers
          .info,
        value: data.possibleAnswers ?? [
          {
            label: ANSWER_PLACEHOLDER,
          },
        ],
        isValid: true,
        required: false,
        validators: [],
      },
      shouldDispalyAnswerFeedback: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer
            .shouldDispalyAnswerFeedback.label,
        info: DICTIONARY.createTour.fields.question.shortAnswer
          .shouldDispalyAnswerFeedback.info,
        value: data.shouldDispalyAnswerFeedback
          ? data.shouldDispalyAnswerFeedback
          : SHOULD_DISPLAY_ANSWER_FEEDBACK.yes.value,
        isValid: true,
        required: true,
        validators: [],
      },
      correctAnswerMessage: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer.correctAnswerMessage
            .label,
        info: DICTIONARY.createTour.fields.question.shortAnswer
          .correctAnswerMessage.info,
        value: data.correctAnswerMessage ?? "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.shouldDispalyAnswerFeedback.value ===
                SHOULD_DISPLAY_ANSWER_FEEDBACK.yes.value
              ) {
                return value !== "";
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.question.shortAnswer
                .correctAnswerMessage.error.required,
          },
        ],
      },
      wrongAnswerMessage: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer.wrongAnswerMessage
            .label,
        info: DICTIONARY.createTour.fields.question.shortAnswer
          .wrongAnswerMessage.info,
        value: data.wrongAnswerMessage ?? "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              if (
                tourPoint.shouldDispalyAnswerFeedback.value ===
                SHOULD_DISPLAY_ANSWER_FEEDBACK.yes.value
              ) {
                return value !== "";
              } else {
                return true;
              }
            },
            errorMessage:
              DICTIONARY.createTour.fields.question.shortAnswer
                .wrongAnswerMessage.error.required,
          },
        ],
      },
      extraInformation: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer.extraInformation
            .label,
        info: DICTIONARY.createTour.fields.question.shortAnswer.extraInformation
          .info,
        value: HELPERS.richText.getRichTextFromDb(data.extraInformation),
        isValid: true,
        required: true,
        validators: [],
      },
      correctAnswerInformation: {
        label:
          DICTIONARY.createTour.fields.question.shortAnswer
            .correctAnswerInformation.label,
        info: DICTIONARY.createTour.fields.question.shortAnswer
          .correctAnswerInformation.info,
        value: HELPERS.richText.getRichTextFromDb(
          data.correctAnswerInformation
        ),
        isValid: true,
        required: true,
        validators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    //#endregion

    //#region Functionality
    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "radio": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "checkbox": {
          const { name, checked } = event.target;
          key = name;
          val = checked;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleOptionalAnswerTextChange = (event: any) => {
      const { id, value } = event.target;
      const key = id;
      const val = value;
      const [index, field] = key.split("-");

      setFormState((prevState: any) => {
        let updatedOptionalAnswers = [...formState.possibleAnswers.value];
        updatedOptionalAnswers[index] = {
          ...updatedOptionalAnswers[index],
          label: val,
        };
        return {
          ...prevState,
          possibleAnswers: {
            ...prevState["possibleAnswers"],
            value: updatedOptionalAnswers,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleAddAnotherOptionalAnswer = () => {
      setFormState((prevState: any) => {
        return {
          ...prevState,
          possibleAnswers: {
            ...prevState["possibleAnswers"],
            value: [
              ...prevState.possibleAnswers.value,
              {
                label: ANSWER_PLACEHOLDER,
              },
            ],
          },
        };
      });

      // Clear errors
      clearFormError();
    };

    const handleDeleteOptionalAnswer = (index: number) => {
      setFormState((prevState: any) => {
        let updatedOptionalAnswers = [...formState.possibleAnswers.value];

        if (index > -1) {
          updatedOptionalAnswers.splice(index, 1);
        }

        return {
          ...prevState,
          possibleAnswers: {
            ...prevState["possibleAnswers"],
            value: updatedOptionalAnswers,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourDetailsValidation.handleEmptyFields(
            { ...formState },
            tourPointIndex + 1
          );
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourDetailsValidation.handleInvalidFields(
          { ...formState },
          tourPointIndex + 1
        );
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit
    const prepareDataToSave = () => {
      let files: any = [];

      const parsedData = {
        questionContent: formState.questionContent.value,
        possibleAnswers: formState.possibleAnswers.value,
        submitButtonLabel: formState.submitButtonLabel.value,
        shouldDispalyAnswerFeedback:
          formState.shouldDispalyAnswerFeedback.value,
        correctAnswerMessage: formState.correctAnswerMessage.value,
        wrongAnswerMessage: formState.wrongAnswerMessage.value,
        extraInformation: HELPERS.richText.prepareRichTextToDbSave(
          formState.extraInformation.value
        ),
        correctAnswerInformation: HELPERS.richText.prepareRichTextToDbSave(
          formState.correctAnswerInformation.value
        ),
      };

      return parsedData;
    };

    const onQuestionValidate = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return parsedData;
    };

    useImperativeHandle(ref, () => ({
      onQuestionValidate,
    }));
    //#endregion

    return (
      <Fragment>
        <FormLabel
          component="legend"
          sx={{ fontSize: 14, mb: 0.5, gridColumn: "1 / 6" }}
        >
          {DICTIONARY.createTour.fields.question.shortAnswer.label}
        </FormLabel>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            backgroundColor: blueGrey[50],
            border: `1px solid ${blueGrey[400]}`,
            p: 2,
            borderRadius: "5px",
          }}
        >
          {/* Question content */}
          <Box sx={{ gridRow: "1", gridColumn: "span 12" }} mb={1}>
            <TextFieldWithInfo
              label={formState.questionContent.label}
              value={formState.questionContent.value}
              id="questionContent"
              onChange={handleInputChange}
              required={formState.questionContent.required}
              error={!formState.questionContent.isValid}
              info={formState.questionContent.info}
              // autoFocus
            />
          </Box>

          {/* Submit buttom label */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <TextFieldWithInfo
              label={formState.submitButtonLabel.label}
              value={formState.submitButtonLabel.value}
              id="submitButtonLabel"
              onChange={handleInputChange}
              required={formState.submitButtonLabel.required}
              error={!formState.submitButtonLabel.isValid}
              info={formState.submitButtonLabel.info}
            />
          </Box>

          {/* Section title */}
          <Box
            sx={{
              gridColumn: "span 12",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={1}
          >
            <FormLabel component="legend" sx={{ fontSize: 14 }}>
              {
                DICTIONARY.createTour.fields.question.shortAnswer
                  .possibleAnswers.label
              }
              <InfoTooltip
                info={
                  DICTIONARY.createTour.fields.question.shortAnswer
                    .possibleAnswers.info
                }
              />
            </FormLabel>
            {/* Add another answer button */}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleAddAnotherOptionalAnswer}
            >
              {DICTIONARY.createTour.fields.question.shortAnswer.buttons.add}
            </Button>
          </Box>

          {/* Possibale accaptable answers */}
          {formState.possibleAnswers.value.length > 0 &&
            formState.possibleAnswers.value.map((item: any, index: number) => (
              <Fragment key={index}>
                <Box sx={{ gridColumn: "1 / 11" }}>
                  <TextField
                    label={`Answer ${index + 1}`}
                    value={item.label}
                    id={`${index}-possibleAnswers`}
                    name={`${index}-possibleAnswers`}
                    onChange={handleOptionalAnswerTextChange}
                    margin="none"
                    size="small"
                    fullWidth
                    // variant="standard"
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </Box>

                {/* Actions */}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ gridColumn: "11 / 13" }}
                  mb={1}
                >
                  {/* Delete point button */}
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteOptionalAnswer(index)}
                    disabled={index === 0}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Fragment>
            ))}

          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <Divider flexItem />
          </Box>

          {/* Should display answer at this point */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <RadioButtonWithInfo
              label={formState.shouldDispalyAnswerFeedback.label}
              value={formState.shouldDispalyAnswerFeedback.value}
              id="shouldDispalyAnswerFeedback"
              onChange={(event: any) =>
                handleInputChange(event, { type: "radio" })
              }
              info={formState.shouldDispalyAnswerFeedback.info}
              options={shouldDisplayAnswerFeedbackArr}
            />
          </Box>

          {/* Correct answer message */}
          {formState.shouldDispalyAnswerFeedback.value ===
            SHOULD_DISPLAY_ANSWER_FEEDBACK.yes.value && (
            <Box sx={{ gridColumn: "span 12" }} mb={1}>
              <TextFieldWithInfo
                label={formState.correctAnswerMessage.label}
                value={formState.correctAnswerMessage.value}
                id="correctAnswerMessage"
                onChange={handleInputChange}
                required={formState.correctAnswerMessage.required}
                error={!formState.correctAnswerMessage.isValid}
                info={formState.correctAnswerMessage.info}
              />
            </Box>
          )}

          {/* Wrong answer message */}
          {formState.shouldDispalyAnswerFeedback.value ===
            SHOULD_DISPLAY_ANSWER_FEEDBACK.yes.value && (
            <Box sx={{ gridColumn: "span 12" }} mb={1}>
              <TextFieldWithInfo
                label={formState.wrongAnswerMessage.label}
                value={formState.wrongAnswerMessage.value}
                id="wrongAnswerMessage"
                onChange={handleInputChange}
                required={formState.wrongAnswerMessage.required}
                error={!formState.wrongAnswerMessage.isValid}
                info={formState.wrongAnswerMessage.info}
              />
            </Box>
          )}

          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <Divider flexItem />
          </Box>

          {/* Extra information */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <FormLabel component="legend" sx={{ fontSize: 14 }}>
              {formState.extraInformation.label}
            </FormLabel>
            <RichTextEditor
              /*
            // @ts-ignore */
              editorState={formState.extraInformation.value}
              onEditorStateChange={(editorState: any) =>
                handleInputChange(editorState, {
                  type: "richText",
                  id: "extraInformation",
                })
              }
            />
          </Box>

          {/* Correct answer information */}
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <FormLabel component="legend" sx={{ fontSize: 14 }}>
              {formState.correctAnswerInformation.label}
            </FormLabel>
            <RichTextEditor
              /*
            // @ts-ignore */
              editorState={formState.correctAnswerInformation.value}
              onEditorStateChange={(editorState: any) =>
                handleInputChange(editorState, {
                  type: "richText",
                  id: "correctAnswerInformation",
                })
              }
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
);

export default ShortAnswer;

//#region Helpers
const ANSWER_PLACEHOLDER = "Type your answer here..";

const shouldDisplayAnswerFeedbackArr = Object.keys(
  SHOULD_DISPLAY_ANSWER_FEEDBACK
).map((key: string) => ({
  ...SHOULD_DISPLAY_ANSWER_FEEDBACK[key],
}));
//#endregion
