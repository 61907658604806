import React, { useState } from "react";
import { VALIDATORS } from "../../utils";

// App components
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import { Box, Typography, IconButton } from "@mui/material";

// Colors
import { red, grey } from "@mui/material/colors";

// Icons
import { Publish as UploadIcon } from "@mui/icons-material";

type ImageUploadrProps = {
  label: string;
  info: string;
  id: string;
  value: string;
  error: boolean;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function VideoUploader({
  label,
  info,
  id,
  value,
  error,
  onInputChange,
}: ImageUploadrProps) {
  //#region Image placeholcer
  const [videoPlaceholder, setVideoPlaceholder] = useState<string>(value);

  const handleVideoPlaceholcer = (file: any) => {
    if (file && VALIDATORS.filesUpload.isValidDataFile(file, "video")) {
      setVideoPlaceholder(URL.createObjectURL(file));
    }
  };

  /*
    Note: videoPlaceholder acts as the url.
    value is the file itself.
  */
  //#endregion

  return (
    <Box
      sx={{
        marginTop: {
          xs: 0,
          sm: "-30px",
        },
        height: "calc(100% + 30px)",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {/* Title */}
          <Typography
            component="label"
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(14),
              color: grey[700],
            }}
          >
            {label}
          </Typography>

          {/* image Upload Instructions */}
          <InfoTooltip info={info} aria-label="Upload video instructions" />
        </Box>

        <div style={{ position: "relative" }}>
          <IconButton
            aria-label="upload"
            size="small"
            sx={{
              color: (theme) => theme.palette.primary.main,
              width: "30px",
              height: "30px",
            }}
          >
            <UploadIcon sx={{ width: "24px", height: "24px" }} />
            <label
              htmlFor={id}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
            ></label>
          </IconButton>
          <input
            id={id}
            type="file"
            accept="video/mp4"
            style={{ display: "none" }}
            onChange={(event: any) => {
              onInputChange(event);
              handleVideoPlaceholcer(event.target.files[0]);
            }}
          />
        </div>
      </Box>

      {/* Video container */}

      <video
        controls
        controlsList="nodownload"
        key={videoPlaceholder} // needed foe the src to be updated. better solution: https://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react
        style={{
          display: "block",
          width: "100%",
          height: "calc(100% - 32px)",
          maxHeight: "246px",
          objectFit: "cover",
          border: !error ? `1px solid ${grey[400]}` : `1px solid ${red[500]}`,
          borderRadius: "10px",
        }}
      >
        <source src={videoPlaceholder} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
