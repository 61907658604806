// Firebase
import { db } from "../config/firebase-config";
import {
  doc,
  setDoc,
  getDoc,
  Timestamp,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

// utils
import { HELPERS } from "../utils";

const admin = {
  get: async function (uid: string) {
    try {
      let retVal;
      const docRef = doc(db, "admins", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        retVal = docSnap.data();
      } else {
        retVal = {};
        HELPERS.localhost.isVerbose() &&
          console.log(`No such admin document with uid: ${uid}`);
      }

      return retVal;
    } catch (e) {
      const error = `Error getting admin data with uid: ${uid}`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  getAll: async function (queryFilter?: any) {
    try {
      let retVal: any = [];
      const docRef = collection(db, "admins");
      let querySnapshot;

      if (queryFilter) {
        const q = query(
          docRef,
          where(queryFilter.field, queryFilter.operator, queryFilter.value)
        );
        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(docRef);
      }

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();
        retVal.push({
          id: doc.id,
          ...data,
        });
      });

      return retVal;
    } catch (e) {
      const error = `Error getting admins data`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  create: async function (uid: string, data: any) {
    try {
      const { displayName, email, photoURL } = data;

      await setDoc(doc(db, "admins", uid), {
        name: displayName,
        email,
        avatar: photoURL,
        createdAt: Timestamp.fromDate(new Date()),
        timeZoneOffset: (new Date().getTimezoneOffset() / 60) * -1, // Should be used in date functionality in the server side. if offset equals -60 then the time zone offset is UTC+01
      });
    } catch (e) {
      const error = `Error creating admin`;
      HELPERS.localhost.isVerbose() && console.error(e);
      return {
        error,
      };
    }
  },
};

export default admin;
