import React from "react";

// Mui
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

// App components
import InfoTooltip from "../shared/InfoTooltip";

type Props = {
  label: string;
  value: string;
  id: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: boolean;
  info: string;
  disabled?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  hiddenLabel?: boolean;
};

export default function TextFieldWithInfo({
  label,
  value,
  id,
  onChange,
  required,
  error,
  info,
  disabled = false,
  autoFocus,
  multiline,
  rows,
  hiddenLabel,
}: Props) {
  return (
    <FormControl
      variant="outlined"
      size="small"
      margin="none"
      fullWidth
      error={error}
      required={required}
      disabled={disabled}
      hiddenLabel={hiddenLabel}
    >
      <InputLabel htmlFor="initialZoom">{label}</InputLabel>
      <OutlinedInput
        label={label}
        value={value}
        id={id}
        name={id}
        onChange={onChange}
        autoFocus={autoFocus}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        endAdornment={
          <InputAdornment position="end">
            <InfoTooltip info={info} />
          </InputAdornment>
        }
        sx={{
          paddingRight: 0.5,
          backgroundColor: "white",
        }}
      />
    </FormControl>
  );
}
