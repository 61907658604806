import React from "react";
import {
  TOUR_GUIDING_COLORS,
  // @ts-ignore
} from "tour-guide-shared-library";

// Mui
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

type Props = {
  label: string;
  value: string;
  id: string;
  name: string;
  onChange: (event: any) => any;
  required: boolean;
  error: boolean;
};

export default function SelectColor({
  label,
  value,
  id,
  name,
  onChange,
  required,
  error,
}: Props) {
  return (
    <FormControl
      fullWidth
      size="small"
      margin="none"
      required={required}
      error={error}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        name={name}
        label={label}
        value={value}
        onChange={(event) => onChange(event)}
        required={required}
        error={error}
        fullWidth
      >
        {colorsArr.map((item: any) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="span"
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                border: `1px solid ${grey[900]}`,
                borderRadius: "50%",
                marginRight: "8px",
                backgroundColor: item.hex,
              }}
            ></Box>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

//#region Helpers
const colorsArr = Object.keys(TOUR_GUIDING_COLORS).map((key: string) => ({
  ...TOUR_GUIDING_COLORS[key],
}));
//#endregion
