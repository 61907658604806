const createTour = {
  title: {
    create: "Create new tour",
    edit: "Edit tour",
  },
  sections: {
    details: {
      label: "Tour details",
      emptyState: "",
    },
    route: {
      label: "Map & route",
      emptyState: "",
    },
    points: {
      label: "Points",
      emptyState: "",
    },
    symbolPoints: {
      label: "Symbols",
      emptyState: {
        title: "No symbol points yet",
        subtitle: "Not mandatory",
      },
    },
    extraTourInfo: {
      label: "Extra info",
      info: "Optional content that can be added to the tour. \n It can be accessed by clicking an additional icon in the top-left corner of the screen",
      emptyState: {
        title: "No extra info yet",
        subtitle: "Not mandatory",
      },
    },
    summary: {
      label: "Summary",
      emptyState: "",
    },
  },
  buttons: {
    addTourPoint: "Add tour point",
    addSymbolPoint: "Add symbol point",
    addExtraInfoStep: "Add extra info",
    addCoordinates: "Add coordinate",
    next: "Next",
    back: "Back",
    save: "Save",
    preview: "Preview",
    introScreen: "Preview intro screen",
    route: "Preview",
  },
  fields: {
    name: {
      label: "Tour name",
      info: "",
      error: {
        length: "Tour name must contain between 3 and 50 characters.",
      },
    },
    description: {
      label: "Description",
      info: "",
      error: {},
    },
    guide: {
      label: "Guide",
      info: "",
      error: {},
    },
    tags: {
      label: "Tags",
      info: "Add tags that describe best your tour to help users discover it by searching the words in the app. \n You can add multiple tags and use any multiuple languages. \n Press 'Enter' To add the tag.",
      error: {},
    },
    country: {
      label: "Country",
      info: "",
      error: {},
    },
    location: {
      label: "Location",
      info: "",
      error: {},
    },
    tourType: {
      label: "Tour type",
      info: "",
      error: {},
    },
    language: {
      label: "Language",
      info: "",
      error: {},
    },
    durationHour: {
      label: "Duration",
      info: "Measured in hours. Not mandatory.",
      error: {},
    },
    lengthKm: {
      label: "Length",
      info: "Measured in km. Not mandatory.",
      error: {},
    },
    coverImage: {
      label: "Cover image",
      info: "",
      error: {},
    },
    route: {
      label: "Route coordinates",
      info: "",
      error: {
        invalid: "Route coordinates values are invalid.",
      },
    },
    routeColor: {
      label: "Route color",
      info: "",
      error: {},
    },
    initialZoom: {
      label: "Initial zoom",
      info: `"Initial zoom" value must be at least as the zoom minimum value that being generated by the map bounds after the 'Route coordinates' field is being submitted. \n Please check the zoom value on the preview map.`,
      error: {
        number: "Map initial zoom must be a number.",
      },
    },
    routeArrowDirection: {
      label: "Route direction arrow",
      info: "Option to show the walking direction.",
      error: {},
    },
    geolocationMode: {
      label: "Geolocation mode",
      info: "Default: Geolocation tracking is ON and geolocate indicators are visible (location dot, geolocate button and error messages). \n \n Background: Geolocation tracking is ON, but location dot and geolocate button are hidden. \n \n Disable: Geolocation tracking is OFF and all indicators are not shown.",
      error: {},
    },
    displayTourPointsOnMap: {
      label: "Display mode of route and tour points on map",
      info: "By default the route and the tour points will be displayed on the map. \n Hiding them means they won't show up on the map itself, but the points will still be accessible via the thumbnails footer.",
      error: {},
    },
    mapStyle: {
      label: "Map style",
      info: "",
      error: {},
    },
    mapStyleCustomImage: {
      label: "Custom image",
      info: "",
      error: {
        required: "'Map style custom image' file is required.",
      },
    },
    tourPoints: {
      title: {
        label: "Title",
        info: "",
        error: {
          length: "Title must contain a least 3 characters.",
        },
      },
      thumbnailImage: {
        label: "Thumbnail",
        info: "",
        error: {},
      },
      contentType: {
        label: "Content type",
        info: "",
        error: {},
      },
      displayTypeOnOpen: {
        label: "Display type",
        info: "Defines how the content should be displayed upon clicking on the point.",
        error: {
          required: "Display type is required.",
        },
      },
      videoContent: {
        label: "Video",
        info: "",
        error: {
          required: "Video file is required.",
        },
      },
      audioContent: {
        label: "Audio",
        info: "",
        error: {
          required: "Audio file is required.",
        },
      },
      externalWebpage: {
        label: "External webpage",
        info: "Paste your URL here",
        error: {
          required: "Please enter a URL of the external webpage.",
          invalid: "Invalid URL",
        },
      },
      moreDetails: {
        label: "Information",
        info: "",
        error: {},
      },
      coordinates: {
        label: "Coordinates",
        info: "",
        error: {
          invalid: "Coordinates value are invalid.",
        },
      },
      autoActivateTourPoint: {
        label: "Auto activate",
        info: "Should the point automatically open when entering to the specified radius?",
        error: {},
      },
      pointActivationRadius: {
        label: "Activation Radius (meters)",
        info: "If the user is inside the radius range, the point will open automatically. \n Radius must be at least 10 meters.",
        error: {
          number: "Point activation radius must be a number.",
          min: "Point activation radius must be no less than 10 meters.",
          required: "Please enter point activation radius.",
        },
      },
      questionType: {
        label: "Question type",
        info: "",
        error: {
          required: "Question type is required.",
        },
      },
      orientationType: {
        label: "Orientation type",
        info: "",
        error: {
          required: "Orientation type is required.",
        },
      },
      orientationPointValidRadiusRange: {
        label: "Orientation point valid radius range (meters)",
        info: "If the user is inside the radius range, the content of the point will be shown. \n Radius must be at least 5 meters. \n Leave empty to assign the default range (30 meters).",
        error: {
          min: "Orientation point valid radius range must be no less than 5 meters.",
        },
      },
      orientationPointDirections: {
        label: "Directions",
        info: "Explain how to arrive to the point.",
        error: {},
      },
      additionalImages: {
        label: "Additional images",
        info: "Possible additional images of the point, regardless of the thumbnail image.",
        image: {
          url: "Image",
          label: "Label",
          link: "Link",
        },
        button: {
          add: "Add",
        },
        emptyState: "No additional images yet (not mandatory).",
      },
    },
    symbolPoints: {
      icon: {
        label: "Icon",
        info: "",
        error: {},
      },
      iconColor: {
        label: "Icon color",
        info: "",
        error: {},
      },
      label: {
        label: "Label",
        info: "Not mandatory",
        error: {},
      },
      coordinates: {
        label: "Coordinates",
        info: "",
        error: {
          invalid: "Coordinates value are invalid.",
        },
      },
    },
    extraTourInfo: {
      icon: {
        label: "Icon",
        info: "",
        error: {},
      },
      iconColor: {
        label: "Icon color",
        info: "",
        error: {},
      },
      title: {
        label: "title",
        info: "The title of the the extra info",
        error: {},
      },
      steps: {
        extraInfoStepImage: {
          label: "Image",
          info: "",
          error: {},
        },
        information: {
          label: "Information",
          info: "",
          error: {},
        },
      },
    },
    publishStatus: {
      label: "Publish status",
      info: 'When choosing "Public" the tour will be shown on the Users App. \n "Draft" means the tour will be saved but will not be displayed on the Users app.',
      error: {},
    },

    price: {
      label: "Tour price ($)",
      info: "The currency unit is $.",
      error: {
        minPrice: "Tour minimum price must be at least $0", // TEMP If set to "0" --> it means FREE tour --> No need to enter promocode
      },
    },
    // Outdoor game
    outdoorGame_wrongAnswerPenaltyTime: {
      label: "Wrong answer penalty time (seconds)",
      info: "The time to wait until the user can try to answer the question again.",
      error: {},
    },
    outdoorGame_finishInformation: {
      label: "Finish game information",
      info: "",
      error: {},
    },
    question: {
      questionContent: {
        label: "Question",
        info: "Free text",
        error: {},
      },
      multipleChoice: {
        label: "Multiple choice",
        submitButtonLabel: {
          label: "Submit button label",
          info: "The text that will be shown on the button for submitting an answer.",
          error: {},
        },
        buttons: {
          add: "Add another answer",
          delete: "delete",
          defineCorrect: "define as correct answer",
        },
        optionalAnswers: {
          label: "Answers",
          info: "Don't forget to select the correct answer.",
          error: {},
        },
        shouldDispalyAnswerFeedback: {
          label: "Should show feedback for the answer?",
          info: "If Yes, after submitting the answer, the user will see if he answered correctly. \n If No, the user will not get feedback for his answer.",
          error: {},
        },
        correctAnswerMessage: {
          label: "Correct answer feedback",
          info: "The message that will be shown if the correct option was chosen.",
          error: {
            required: "Correct answer feedback is required.",
          },
        },
        wrongAnswerMessage: {
          label: "Wrong answer feedback",
          info: "The message that will be shown if a worng option was chosen.",
          error: {
            required: "Wrong answer feedback is required.",
          },
        },
        extraInformation: {
          label: "Extra information - appears below the question",
          info: "",
          error: {},
        },
        correctAnswerInformation: {
          label:
            "Correct answer information - appears in the correct response popup",
          info: "",
          error: {},
        },
      },
      shortAnswer: {
        label: "Short answer",
        submitButtonLabel: {
          label: "Submit button label",
          info: "The text that will be shown on the button for submitting an answer.",
          error: {},
        },
        buttons: {
          add: "Add another answer",
          delete: "delete",
          defineCorrect: "define as correct answer",
        },
        possibleAnswers: {
          label: "Possible acceptable answers",
          info: "Elaborate all the possible answers the user may answer.",
          error: {},
        },
        shouldDispalyAnswerFeedback: {
          label: "Should show feedback for the answer?",
          info: "If Yes, after submitting the answer, the user will see if he answered correctly. \n If No, the user will not get feedback for his answer.",
          error: {},
        },
        correctAnswerMessage: {
          label: "Correct answer feedback",
          info: "The message that will be shown if the correct option was chosen.",
          error: {
            required: "Correct answer feedback is required.",
          },
        },
        wrongAnswerMessage: {
          label: "Wrong answer feedback",
          info: "The message that will be shown if a worng option was chosen.",
          error: {
            required: "Wrong answer feedback is required.",
          },
        },
        extraInformation: {
          label: "Extra information - appears below the question",
          info: "",
          error: {},
        },
        correctAnswerInformation: {
          label:
            "Correct answer information - appears in the correct response popup",
          info: "",
          error: {},
        },
      },
    },
  },
  errors: {
    generalError: `Couldn't create tour. Please try again or contact support.`,
    generalErrorEdit: `Couldn't edit tour. Please try again or contact support.`,
    updateCoverImage: `Couldn't save tour cover image. Please try again or contact support.`,
    updateTourPointImages: `The tour save successfully, but we couldn't save tour points image(s). Please try again or contact support.`,
    updateTourPointVideos: `The tour save successfully, but we couldn't save tour points video(s). Please try again or contact support.`,
    updateTourPointAudios: `The tour save successfully, but we couldn't save tour points audio(s). Please try again or contact support.`,
  },
  emptyState: "The requested tour does not exist",
};

export default createTour;
