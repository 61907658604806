const businessPartners = {
  title: "Business partners",
  create: "Create new business partner",
  emptyState: "No business partners yet",
  emptyStateFiltered: "No matching business partners",
  entityItem: {
    actions: {
      view: "View",
      edit: "Edit business partner",
      updateUserPassword: "Update user password",
      delete: "Delete",
    },
  },
  table: {
    columns: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      operatedBy: "Operated by",
      countries: "Countries",
      location: "Location",
      createdBy: "Created by",
      createdAt: "Created at",
      updatedAt: "Updated at",
      actions: "Actions",
    },
  },
  deleteConfirmationDialog: {
    title: "Delete business partner",
    body: "After deleting the business partner you will no longer be able to edit it.",
    button: "Delete",
  },
};

export default businessPartners;
