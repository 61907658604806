import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DICTIONARY, ROUTES } from "../../utils";
import { promocodeApi } from "../../api";
import { COUNTRY_LIST } from "../../consts";

// Mui
import { Box, Container, Typography, Button } from "@mui/material";

// App components
import PromocodesTable from "../../components/promocode/PromocodesTable";

import Loader from "../../components/loader/Loader";
import EmptyState from "../../components/shared/EmptyState";
import SearchBox from "../../components/shared/SearchBox";

function Promocodes() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const originalData = useRef<any>([]);
  const [data, setData] = useState<any[]>([]);

  //#region Init
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const query = {
        field: "deletedAt",
        operator: "==",
        value: null,
      };
      const response = await promocodeApi.getAll(query);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      // Success
      originalData.current = sortEntities(response);
      setData(originalData.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, []);
  //#endregion

  //#region Search
  const [searchValue, setSearchValue] = useState<string | null>("");
  const handleSearchValueChange = (searchValue: string | null) => {
    setSearchValue(() => {
      let filteredTours: any = filterEntities(
        originalData.current,
        searchValue
      );
      filteredTours = sortEntities(filteredTours);

      setData(filteredTours);

      return searchValue;
    });
  };
  //#endregion

  const TableView = () => {
    return (
      <Box sx={{ mt: 3 }}>
        <PromocodesTable data={data} onItemUpdate={fetchData} />
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          sx={{
            marginTop: 3,
          }}
        >
          {/* Page header */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {/* Left side */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Title */}
              <Typography variant="h5" component="h1">
                {DICTIONARY.promocodes.title}
              </Typography>

              {/* Search */}
              <Box
                sx={{
                  width: "440px",
                }}
              >
                <SearchBox
                  searchValue={searchValue}
                  onChange={handleSearchValueChange}
                  placeholder="Search promocode"
                />
              </Box>
            </Box>

            {/* Right side */}
            <Box
              sx={{
                maxWidth: "600px",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {/* Action */}
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={ROUTES.promocodes.create.path}
              >
                {DICTIONARY.promocodes.create}
              </Button>
            </Box>
          </Box>

          {/* Content */}
          {isLoading ? (
            <Loader />
          ) : error ? (
            <EmptyState
              title={DICTIONARY.promocodes.emptyState}
              icon="tour"
              py={8}
            />
          ) : (
            <TableView />
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default Promocodes;

//#region Helpers
const sortEntities = (tours: any) => {
  function callback(a: any, b: any) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};

const filterEntities = (tours: any[], filteredValue: string | null) => {
  function callback(item: any, filteredValue: string) {
    try {
      const isIncludedInWord = (item: string) =>
        item.toLocaleLowerCase().includes(filteredValue);

      if (isIncludedInWord(item.code)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  try {
    if (filteredValue === undefined) {
      return false;
    }

    const parsedFilteredValue: string =
      filteredValue?.toLocaleLowerCase() || "";

    let filteredTours = tours.filter((item: any) =>
      callback(item, parsedFilteredValue)
    );

    return filteredTours;
  } catch (error) {
    return tours;
  }
};
//#endregion
