import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
} from "draft-js";
import Link from "../../components/richTextEditor/Link";

//#region Rich text
const prepareRichTextToDbSave = (richText: EditorState) => {
  const descriptionCurrentContent = richText.getCurrentContent();
  const stringifiedDescription = JSON.stringify(
    convertToRaw(descriptionCurrentContent)
  );

  return stringifiedDescription;
};

const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const strategyDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const getRichTextFromDb = (richTextFromDb: string | null) => {
  return richTextFromDb
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(richTextFromDb)),
        strategyDecorator
      )
    : EditorState.createEmpty();
};
//#endregion

const exported = {
  prepareRichTextToDbSave,
  getRichTextFromDb,
};

export default exported;
