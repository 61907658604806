const general = {
  isValidUrl: (str: string) => {
    // TODO: rewrite validation
    /*
      try {
        const url = new URL('http ://example.com');
      } catch (error) {
        error; // => TypeError, "Failed to construct URL: Invalid URL"
      }
    */
    return true;
  },
};

export default general;
