import { useState, useEffect } from "react";
import { DICTIONARY, HELPERS } from "../../utils";
import { logEventApi } from "../../api";
import { parse } from "date-fns";

// App components
import ChartContainer from "./ChartContainer";

// Mui
import { Paper, Box, Typography } from "@mui/material";

// Recharts
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";

// Colors
import { grey } from "@mui/material/colors";

// User agent parser
import { UAParser } from "ua-parser-js";
const parser = new UAParser();

const CHART_HEIGHT = 400;

type Props = {
  tour: any;
};

export default function GeolocationPermissionBlockedLogByDateChart({
  tour,
}: Props) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  //#region Init
  const fetchData = async (tourId: string) => {
    try {
      setIsLoading(true);
      const queryFilterArr = [
        {
          field: "tourId",
          operator: "==",
          value: tourId,
        },
        // {
        //   field: "event",
        //   operator: "==",
        //   value: "geolocation_permission_blocked",
        // },
      ];

      const response = await logEventApi.getAll(queryFilterArr);

      if (response.hasOwnProperty("error")) {
        setIsLoading(false);
        setError(DICTIONARY.general.error);
        return;
      }

      if (response.length === 0) {
        setIsLoading(false);
        setError(DICTIONARY.general.chart.errors.noData);
        return;
      }

      // Success
      const parsedData = convertToChartData(response);
      setData(parsedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(DICTIONARY.general.error);
    }
  };

  useEffect(() => {
    if (tour && "id" in tour) {
      setError("");
      (async () => {
        fetchData(tour.id);
      })();
    } else {
      setError(DICTIONARY.general.chart.errors.selectTour);
    }
  }, [tour]);
  //#endregion

  //#region Chart data
  const convertToChartData = (rawData: any) => {
    try {
      let retVal: any[] = [];
      let datesMap: any = {};

      if (rawData && rawData.length > 0) {
        rawData.forEach((item: any) => {
          const { timestamp_client, event, deviceUid, userAgent } = item;

          const date = HELPERS.date.formatDate(
            timestamp_client.toDate(),
            "dd/MM/yy"
          );

          // deviceUid is more unique than userAgent (started to collect deviceUid on April 19th)
          const uid = deviceUid || userAgent.replace(/\D+/g, "");

          parser.setUA(userAgent);
          let result = parser.getResult();
          let deviceKey = `${result.device.vendor || "Unknown"} \n ${
            result.os.name || "Unknown"
          } \n ${result.browser.name || "Unknown"}`;

          if (date in datesMap) {
            if (event in datesMap[date]) {
              if (uid in datesMap[date][event]) {
                datesMap[date][event][uid]["count"] += 1;
              } else {
                datesMap[date][event][uid] = {
                  count: 1,
                  device: deviceKey,
                };
              }
            } else {
              datesMap[date][event] = {};
              datesMap[date][event][uid] = {
                count: 1,
                device: deviceKey,
              };
            }
          } else {
            datesMap[date] = {};
            datesMap[date][event] = {};
            datesMap[date][event][uid] = {
              count: 1,
              device: deviceKey,
            };
          }
        });

        retVal = Object.keys(datesMap).map((key: any) => {
          return {
            date: key,

            // Unieqe ids count
            blockedCount: datesMap[key].geolocation_permission_blocked
              ? Object.keys(datesMap[key].geolocation_permission_blocked).length
              : 0,
            blockedDevices: datesMap[key].geolocation_permission_blocked
              ? Object.keys(datesMap[key].geolocation_permission_blocked).map(
                  (uidKey: any) =>
                    `${datesMap[key].geolocation_permission_blocked[uidKey].device}`
                  // `${datesMap[key].geolocation_permission_blocked[uidKey].device} (${datesMap[key].geolocation_permission_blocked[uidKey].count})`
                )
              : [],

            instructionsClickCount: datesMap[key]
              .geolocation_permission_show_instructions_click
              ? Object.keys(
                  datesMap[key].geolocation_permission_show_instructions_click
                ).length
              : 0,
            instructionsClickDevices: datesMap[key]
              .geolocation_permission_show_instructions_click
              ? Object.keys(
                  datesMap[key].geolocation_permission_show_instructions_click
                ).map(
                  (uidKey: any) =>
                    `${datesMap[key].geolocation_permission_show_instructions_click[uidKey].device}`
                  // `${datesMap[key].geolocation_permission_show_instructions_click[uidKey].device} (${datesMap[key].geolocation_permission_show_instructions_click[uidKey].count})`
                )
              : [],
          };
        });

        retVal = sortEntities(retVal, "date", {
          type: "timestamp",
          format: "dd/MM/yy",
        });
      }

      return retVal;
    } catch (error) {
      throw Error;
    }
  };
  //#endregion

  return (
    <ChartContainer
      title={
        DICTIONARY.toursAnalytics.charts.geolocationPermissionBlockedByDate
          .title
      }
      actions={[]}
      isLoading={isLoading}
      error={error}
      style={{
        height: `${CHART_HEIGHT + 80}px`,
        flex: 2,
      }}
    >
      <ResponsiveContainer height={CHART_HEIGHT} width="100%">
        <BarChart
          data={data}
          margin={{ right: 10, top: 5, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize={10} interval={0} />
          <YAxis
            fontSize={12}
            interval="preserveStartEnd"
            allowDecimals={false}
          />
          <Tooltip content={<CustomTooltip payload={data} />} />
          <Legend />
          <Bar
            dataKey="blockedCount"
            name="Display of 'Blocked permission' popup count"
            fill="#ef9a9a"
          />
          <Bar
            dataKey="instructionsClickCount"
            name="Instructions clicks count"
            fill="#bcaaa4"
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

//#region Helpers
const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload[0] && payload[0].payload) {
    return (
      <Paper sx={{ p: 1 }}>
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>Date:</Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.date}
          </Typography>
        </Box>

        {/* Blocked popup count */}
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            'Blocked permission' popup count:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.blockedCount}
          </Typography>
        </Box>

        {/* Blocked popup devices */}
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            'Blocked permission' popup devices:
          </Typography>
          <Box>
            {payload[0].payload.blockedDevices.map(
              (item: any, index: number) => (
                <Typography key={index} sx={{ fontSize: 12 }}>
                  {item}
                </Typography>
              )
            )}
          </Box>
        </Box>

        {/* Instructions clicks count */}
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Instructions clicks count:
          </Typography>
          <Typography sx={{ fontSize: 12 }}>
            {payload[0].payload.instructionsClickCount}
          </Typography>
        </Box>

        {/* Instructions clicks devices */}
        <Box sx={{ display: "flex", gap: 0.5 }}>
          <Typography sx={{ color: grey[600], fontSize: 12 }}>
            Instructions clicks devices:
          </Typography>
          <Box>
            {payload[0].payload.instructionsClickDevices.map(
              (item: any, index: number) => (
                <Typography key={index} sx={{ fontSize: 12 }}>
                  {item}
                </Typography>
              )
            )}
          </Box>
        </Box>
      </Paper>
    );
  }

  return null;
};

const sortEntities = (tours: any, key: string, options?: any) => {
  function callback(a: any, b: any) {
    // timestamp
    if (options && options.type === "timestamp") {
      a = parse(a[key], options.format, new Date());
      b = parse(b[key], options.format, new Date());

      return a - b;
    }

    // Other (number, string, ..)
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  }

  return tours.sort(callback);
};
//#endregion
