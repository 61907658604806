const tours = {
  title: "Tours",
  create: "Create new tour",
  emptyState: "No tours",
  emptyStateFiltered: "No matching tours",
  tourItem: {
    details: {
      country: "Country",
      location: "Location",
      description: "Description",
      guide: "Guide",
    },
    actions: {
      view: "View",
      edit: "Edit tour",
      analytics: "Tour analytics",
      promocode: "Manage promocodes",
      copyLink: "URL link",
      qrCode: "QR code",
      delete: "Delete",
      share: "Share",
      publish: "Update publish status",
    },
  },
  table: {
    columns: {
      coverImage: "Cover image",
      name: "Name",
      country: "Country",
      location: "Location",
      guide: "Guide",
      createdBy: "Created by",
      createdAt: "Created at",
      updatedAt: "Updated at",
      publishStatus: "Status",
      actions: "Actions",
    },
  },
  publishStatusDialog: {
    title: "Update publish status",
    button: "Update",
  },
  qrCodeDialog: {
    title: "QR code",
    button: "Download",
  },
  deleteConfirmationDialog: {
    title: "Delete tour",
    body: "Deleting the tour will remove it from the Back-office app and from the Users app. You will no longer be able to edit it.",
    button: "Delete",
  },
};

export default tours;
