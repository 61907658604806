const string = {
  isNotEmptyString: (str: string) => {
    return str.length > 0;
  },
  isLengthEqual: (str: string, len: number) => {
    // more then 'len' chats
    return str.length === len;
  },
  isMinLength: (str: string, len: number) => {
    // more then 'len' chats
    return str.length >= len;
  },
  isLengthInRange: (str: string, min: number, max: number) => {
    // between range of o and 'len'
    return str.length >= min && str.length <= max;
  },
};

export default string;
