import React, { useState } from "react";
import { DICTIONARY } from "../../utils";
import { PROMOCODE_STATUS } from "../../consts";

// App components
import RadioButtonWithInfo from "../inputs/RadioButtonWithInfo";

// Mui
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Icons
import { Check as ActiveIcon } from "@mui/icons-material";

interface StatusDialogProps {
  open: boolean;
  title: string;
  value: string;
  onSubmit: (data: any) => Promise<any>;
  onClose: (data: any) => void;
}

export default function PromocodeStatusDialog({
  open,
  title,
  value,
  onSubmit,
  onClose,
}: StatusDialogProps) {
  // #region States
  const [status, setStatus] = React.useState(
    value ? value : PROMOCODE_STATUS.active.value
  );
  //#endregion

  //#region Functionality
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus((event.target as HTMLInputElement).value);
  };
  //#endregion

  //#region Dialog
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);

    const response = await onSubmit({
      status,
    });

    setIsSubmitLoading(false);

    if (!response.hasOwnProperty("error")) {
      onClose({
        isSubmitedSuccessfully: true,
        status,
      });
    }
  };
  //#endregion

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        {/* Status radio buttons */}
        <Box sx={{ gridRow: "1", gridColumn: "1 / 8" }} mb={1}>
          <RadioButtonWithInfo
            label={DICTIONARY.createPromocode.fields.status.label}
            value={status}
            id="status"
            onChange={handleStatusChange}
            info={DICTIONARY.createPromocode.fields.status.info}
            options={promocodeStatusArr}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="inherit">
          {DICTIONARY.general.button.cancel}
        </Button>

        {/* Submit button */}
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitLoading}
          loadingPosition="start"
          startIcon={<ActiveIcon />}
          variant="contained"
          disabled={isSubmitLoading}
        >
          {DICTIONARY.promocodes.statusDialog.button}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

//#region Helpers
const promocodeStatusArr = Object.keys(PROMOCODE_STATUS).map((key: string) => ({
  ...PROMOCODE_STATUS[key],
}));
//#endregion
