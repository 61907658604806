import React, {
  Fragment,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { DICTIONARY, HELPERS } from "../../../utils";

// Mui
import { Box } from "@mui/material";

// App components
import SectionHeader from "../../shared/createWizards/SectionHeader";
import AutocompleteSelectTours from "../../inputs/AutocompleteSelectTours";
import RadioButtonWithInfo from "../../inputs/RadioButtonWithInfo";

interface Props {
  entityData: any;
  clearFormError: () => void;
  isEditMode?: boolean;
}

const Tours = forwardRef(
  ({ entityData, clearFormError, isEditMode }: Props, ref) => {
    // #region States
    const initalFormState = {
      eligibleTours: {
        label: DICTIONARY.createPromocode.fields.eligibleTours.label,
        info: DICTIONARY.createPromocode.fields.eligibleTours.info,
        value: entityData.eligibleTours ?? [],
        isValid: true,
        isRequired: () => true,
        customValidators: [],
      },
    };

    const [formState, setFormState] = useState(initalFormState);
    // #endregion

    //#region Functionality
    const handleAutocompleteChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "autocomplete": {
          const { id, value } = payload;
          key = id;
          val = value;
          break;
        }
        default: {
          break;
        }
      }

      setFormState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Radio buttons
    const [eligibleToursMode, setEligibleToursMode] = useState(
      entityData?.eligibleTours?.length > 0
        ? ELIGIBLE_TOURS_OPTIONS.selected.value
        : ELIGIBLE_TOURS_OPTIONS.all.value
    );

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target;
      setEligibleToursMode((val as HTMLInputElement).value);

      if (val.value === ELIGIBLE_TOURS_OPTIONS.all.value) {
        setFormState((prevState: any) => {
          return {
            ...prevState,
            eligibleTours: {
              ...prevState.eligibleTours,
              value: [],
              isValid: true,
            },
          };
        });
      }

      // Clear errors
      clearFormError();
    };
    //#endregion

    //#region Validation
    const isFormValid = (formState: any) => {
      // If "All tours" is chosen --> continue
      if (eligibleToursMode === ELIGIBLE_TOURS_OPTIONS.all.value) {
        return {
          isValid: true,
        };
      }

      try {
        // Empty fields
        const { formState: formStateResponseEmptyFields, emptyFields } =
          HELPERS.form.basic.handleEmptyFields({
            ...formState,
          });
        if (emptyFields.length > 0) {
          setFormState(formStateResponseEmptyFields);

          const error =
            HELPERS.form.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          formState: formStateResponseInvalidFields,
          formError: error,
        }: any = HELPERS.form.basic.handleInvalidFields({
          ...formState,
        });
        if (error) {
          setFormState(formStateResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createPromocode.errors.generalError,
        };
      }
    };
    //#endregion

    //#region Submit
    const prepareDataToSave = () => {
      let files: any = [];

      const parsedData = {
        eligibleTours: formState.eligibleTours.value,
      };

      return {
        data: parsedData,
        files: files,
      };
    };

    const onNext = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      const response = isFormValid(formState); // Validate fields

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave();

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createPromocode.sections.tours.label}
        />

        {/* Content */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            gap: 1,
            mt: 3,
          }}
        >
          <Box sx={{ gridColumn: "span 12" }} mb={1}>
            <RadioButtonWithInfo
              label={DICTIONARY.createPromocode.fields.eligibleToursMode.label}
              value={eligibleToursMode}
              id="eligibleToursMode"
              onChange={handleModeChange}
              info={DICTIONARY.createPromocode.fields.eligibleToursMode.info}
              options={eligibleToursOptions}
            />
          </Box>

          {/* Eligible tours  */}
          {eligibleToursMode === ELIGIBLE_TOURS_OPTIONS.selected.value && (
            <Box sx={{ gridColumn: "span 12" }} mb={1}>
              <AutocompleteSelectTours
                label={formState.eligibleTours.label}
                info={formState.eligibleTours.info}
                id="eligibleTours"
                value={formState.eligibleTours.value}
                error={!formState.eligibleTours.isValid}
                disabled={formState.eligibleTours.value === ""}
                onChange={handleAutocompleteChange}
              />
            </Box>
          )}
        </Box>
      </Fragment>
    );
  }
);

export default Tours;

//#region Helpers
const ELIGIBLE_TOURS_OPTIONS: any = {
  all: {
    label: "All tours",
    value: "all",
  },
  selected: {
    label: "Selected tours",
    value: "selected",
  },
};

const eligibleToursOptions = Object.keys(ELIGIBLE_TOURS_OPTIONS).map(
  (key: string) => ({
    ...ELIGIBLE_TOURS_OPTIONS[key],
  })
);
//#endregion
