const promocodes = {
  title: "Promocodes",
  create: "Create new promocode",
  emptyState: "No promocodes yet",
  emptyStateFiltered: "No matching promocodes",
  entityItem: {
    actions: {
      view: "View",
      edit: "Edit promocode",
      delete: "Delete",
      status: "Update status",
    },
  },
  table: {
    columns: {
      code: "Code",
      ownerType: "Owner type",
      ownerName: "Owner",
      totalCount: "Usages",
      createdBy: "Created by",
      createdAt: "Created at",
      updatedAt: "Updated at",
      status: "Status",
      actions: "Actions",
    },
  },
  statusDialog: {
    title: "Update status",
    button: "Update",
  },
};

export default promocodes;
