import React from "react";
import { VIEW_STRUCTURE_OPTIONS } from "../../consts";

// Mui
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

// Colors
import { blue } from "@mui/material/colors";

type Props = {
  viewStructure: string | null;
  onButtonClick: (newViewStructure: any) => void;
};

export default function ViewStructureToggle({
  viewStructure,
  onButtonClick,
}: Props) {
  //#region Functionality
  const handleChangeviewStructure = (
    event: React.MouseEvent<HTMLElement>,
    newViewStructure: string | null
  ) => {
    if (newViewStructure !== null) {
      onButtonClick(newViewStructure);
    }
  };
  //#endregion

  return (
    <ToggleButtonGroup
      value={viewStructure}
      exclusive
      onChange={handleChangeviewStructure}
      aria-label="view structure"
      size="small"
    >
      {viewStructureOptionsArr.map((item: any) => {
        const Icon = item.icon;
        return (
          <ToggleButton
            key={item.value}
            value={item.value}
            aria-label={item.label}
            size="small"
            sx={{
              "&.Mui-selected": {
                color: blue[700],
                "&:hover": {
                  color: blue[700],
                },
              },
            }}
          >
            <Icon />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

//#region Helpers
const viewStructureOptionsArr = Object.keys(VIEW_STRUCTURE_OPTIONS).map(
  (key: string) => ({
    ...VIEW_STRUCTURE_OPTIONS[key],
  })
);
//#endregion
