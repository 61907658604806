import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { DICTIONARY, VALIDATORS, HELPERS } from "../../../utils";

import {
  TOUR_GUIDING_ICONS,
  TOUR_GUIDING_COLORS,
  // @ts-ignore
} from "tour-guide-shared-library";

// Mui
import {
  Box,
  Typography,
  Alert,
  Divider,
  IconButton,
  FormLabel,
} from "@mui/material";

// App components
import EmptyState from "../../shared/EmptyState";
import SectionHeader from "../../shared/createWizards/SectionHeader";
import SelectColor from "../../inputs/SelectColor";
import SelectIcon from "../../inputs/SelectIcon";
import TextFieldWithInfo from "../../inputs/TextFieldWithInfo";
import ImageUploader from "../../inputs/ImageUploader";
import RichTextEditor from "../../richTextEditor/RichTextEditor";

// Icons
import {
  Delete as DeleteIcon,
  Info as InfoIcon,
  Preview as PreviewIcon,
} from "@mui/icons-material";

// Colors
import { grey } from "@mui/material/colors";

interface Props {
  tour: any;
  tourId: string;
  clearFormError: () => void;
  onPreviewExtraTourInfo: (response: any) => void;
  onDeleteTourPoint: (response: any) => void;
}

const ExtraInfo = forwardRef(
  (
    {
      tour,
      tourId,
      clearFormError,
      onPreviewExtraTourInfo,
      onDeleteTourPoint,
    }: Props,
    ref
  ) => {
    // #region Meta details states and functionality
    const initialExtraInfoMetaDetails = {
      icon: {
        label: DICTIONARY.createTour.fields.extraTourInfo.icon.label,
        info: DICTIONARY.createTour.fields.extraTourInfo.icon.info,
        value: tour?.extraTourInfo?.icon ?? TOUR_GUIDING_ICONS.book.value,
        isValid: true,
        required: true,
        validators: [],
      },
      iconColor: {
        label: DICTIONARY.createTour.fields.extraTourInfo.iconColor.label,
        info: DICTIONARY.createTour.fields.extraTourInfo.iconColor.info,
        value: tour?.extraTourInfo?.iconColor ?? TOUR_GUIDING_COLORS.blue.value,
        isValid: true,
        required: true,
        validators: [],
      },
      title: {
        label: DICTIONARY.createTour.fields.extraTourInfo.title.label,
        info: DICTIONARY.createTour.fields.extraTourInfo.title.info,
        value: tour?.extraTourInfo?.title ?? "",
        isValid: true,
        required: false,
        validators: [],
      },
    };

    const [extraInfoMetaDetailsState, setExtraInfoMetaDetailsState] =
      useState<any>(initialExtraInfoMetaDetails);

    const handleInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = "description";
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      setExtraInfoMetaDetailsState((prevState: any) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value: val,
            isValid: true,
          },
        };
      });

      // Clear errors
      clearFormError();
    };
    // #endregion

    // #region Steps States
    const containerRef = useRef<any>(null);

    const extraInfoStepsTemplate = {
      extraInfoStepImage: {
        label:
          DICTIONARY.createTour.fields.extraTourInfo.steps.extraInfoStepImage
            .label,
        info: DICTIONARY.general.filesUpload.imageUploadInstructions,
        value: "",
        isValid: true,
        required: false,
        validators: [
          {
            validate: (value: any, tourPoint: any) => {
              // Check if the value matches the value from the DB. if true --> the extraInfoStepImage wasnt change and its valid
              return (
                tourPoint.extraInfoStepImage.value === value ||
                VALIDATORS.filesUpload.isValidDataFile(value, "image")
              );
            },
            errorMessage: DICTIONARY.general.filesUpload.error.imageType,
          },
        ],
      },
      information: {
        label:
          DICTIONARY.createTour.fields.extraTourInfo.steps.information.label,
        info: DICTIONARY.createTour.fields.extraTourInfo.steps.information.info,
        value: HELPERS.richText.getRichTextFromDb(""),
        isValid: true,
        required: false,
        validators: [],
      },
    };

    const [tourPointsState, setTourPointsState] = useState<any>([]);
    const [previewTourPointError, setPreviewTourPointError] =
      useState<string>("");
    const [previewTourPointErrorIndex, setPreviewTourPointErrorIndex] =
      useState<number>(-1);
    // #endregion

    // #region Load data
    useEffect(() => {
      // Edit mode - load tour 'tourPointsState' and 'previewTour'
      if (tour?.extraTourInfo?.steps?.length > 0) {
        try {
          // Set tour points
          let tourPointsFromDB: any = [];

          tour.extraTourInfo.steps.forEach((item: any) => {
            let clonedExtraInfoStepsTemplate: any = HELPERS.general.cloneObject(
              extraInfoStepsTemplate
            );

            clonedExtraInfoStepsTemplate.extraInfoStepImage.value =
              item.extraInfoStepImage;
            clonedExtraInfoStepsTemplate.information.value =
              HELPERS.richText.getRichTextFromDb(item.information);

            clonedExtraInfoStepsTemplate.uniqueId = item.uniqueId;
            tourPointsFromDB.push(clonedExtraInfoStepsTemplate);
          });

          setTourPointsState(tourPointsFromDB);
        } catch (error) {}
      }
    }, [tour]);
    //#endregion

    //#region Steps functionality
    const handleTourPointInputChange = (event: any, payload?: any) => {
      const { type } = payload || event.target; // payload can be used for inputs like 'select' where they dont have a natural 'type' on their event.target
      let key: string = "";
      let val: any;

      switch (type) {
        case "text":
        case "textarea": {
          const { id, value } = event.target;
          key = id;
          val = value;
          break;
        }
        case "number": {
          const { id, value } = event.target;
          key = id;
          val = Number(value);
          break;
        }
        case "select": {
          const { name, value } = event.target;
          key = name;
          val = value;
          break;
        }
        case "file": {
          const { id, files } = event.target;
          key = id;
          val = files != null && files[0];
          break;
        }
        case "richText": {
          key = payload.id;
          val = event;
          break;
        }
        default: {
          break;
        }
      }

      const [uniqueId, field] = key.split("-");

      setTourPointsState((prevState: any) => {
        const indexOfPoint = prevState.findIndex(
          (item: any) => item.uniqueId === uniqueId
        );
        let newState = [...prevState];
        newState[indexOfPoint][field] = {
          ...newState[indexOfPoint][field],
          value: val,
          isValid: true,
        };

        return newState;
      });

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };

    const handleAddTourPoint = () => {
      let updatedTourPoints = [...tourPointsState];
      updatedTourPoints.push({
        uniqueId: HELPERS.firebase.generateId(),
        ...extraInfoStepsTemplate,
      });
      setTourPointsState(updatedTourPoints);

      setTimeout(() => {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          top: containerRef.current.offsetBottom,
        });
      }, 1000);
    };

    const handleExtraTourInfoPreview = () => {
      const response = isFormValid(tourPointsState); // Validate fields

      if ("error" in response) {
        onPreviewExtraTourInfo(response);
        return;
      }

      const parsedData = prepareDataToSave(
        tourPointsState,
        extraInfoMetaDetailsState
      );

      onPreviewExtraTourInfo({
        ...response, // isValid = true
        ...parsedData, // data and files
      });
    };

    const handleDeleteTourPoint = (uniqueId: string) => {
      let updatedTourPointsState = [...tourPointsState];
      const indexOfPoint = tourPointsState.findIndex(
        (item: any) => item.uniqueId === uniqueId
      );
      if (indexOfPoint === -1) {
        return;
      }

      updatedTourPointsState.splice(indexOfPoint, 1);

      // Update tour points state
      setTourPointsState(updatedTourPointsState);

      let updatedExtraInfoMetaDetailsState;
      if (updatedTourPointsState.length === 0) {
        updatedExtraInfoMetaDetailsState = {};
        setExtraInfoMetaDetailsState(initialExtraInfoMetaDetails);
      } else {
        updatedExtraInfoMetaDetailsState = extraInfoMetaDetailsState;
      }

      // Update preview tour
      const parsedData = prepareDataToSave(
        updatedTourPointsState,
        updatedExtraInfoMetaDetailsState
      );

      onDeleteTourPoint({
        ...parsedData, // data and files
      });

      // Clear errors
      clearFormError();
      setPreviewTourPointError("");
      setPreviewTourPointErrorIndex(-1);
    };
    // #endregion

    //#region Steps Validation
    const isFormValid = (tourPointsState: any) => {
      /* 
        title can be empty, and for sure icon and iconColor will have data
        So we can check only the steps content.
      */

      try {
        // Empty fields
        const shouldShowPointIndex = true;
        const { tourPoints: tourPointsResponseEmptyFields, emptyFields } =
          HELPERS.createTour.tourPointsValidation.handleEmptyFields(
            [...tourPointsState],
            shouldShowPointIndex
          );
        if (emptyFields.length > 0) {
          setTourPointsState(tourPointsResponseEmptyFields);

          const error =
            HELPERS.createTour.createEmptyFormFieldErrorMessage(emptyFields);

          return {
            isValid: false,
            error,
          };
        }

        // Invaid fields
        const {
          tourPoints: tourPointsResponseInvalidFields,
          formError: error,
        }: any = HELPERS.createTour.tourPointsValidation.handleInvalidFields(
          [...tourPointsState],
          shouldShowPointIndex
        );
        if (error) {
          setTourPointsState(tourPointsResponseInvalidFields);

          return {
            isValid: false,
            error,
          };
        }

        return {
          isValid: true,
        };
      } catch (error) {
        HELPERS.localhost.isVerbose() && console.error(error);

        return {
          isValid: false,
          error: DICTIONARY.createTour.errors.generalError,
        };
      }
    };
    // #endregion

    //#region Submit
    const prepareDataToSave = (
      tourPointsState: any,
      extraInfoMetaDetailsState: any
    ) => {
      //#region Meta details

      //#region Steps
      let parsedFormState: any = [];
      let files: any = [];

      tourPointsState.forEach((item: any) => {
        //#region Files
        // extraInfoStepImage file
        let thumbnailImageUrl: string = item.extraInfoStepImage.value;

        if (
          VALIDATORS.filesUpload.isValidDataFile(
            item.extraInfoStepImage.value,
            "image"
          )
        ) {
          files.push({
            type: "image",
            file: item.extraInfoStepImage.value,
            fileId: item.uniqueId,
            suffix: "extraInfoStepImage",
          });
          thumbnailImageUrl = URL.createObjectURL(
            item.extraInfoStepImage.value
          );
        }
        //#endregion

        parsedFormState.push({
          uniqueId: item.uniqueId,
          extraInfoStepImage: thumbnailImageUrl,
          information: HELPERS.richText.prepareRichTextToDbSave(
            item.information.value
          ),
        });
      });
      //#endregion

      let extraTourInfo = {};

      if (tourPointsState.length > 0) {
        extraTourInfo = {
          icon: extraInfoMetaDetailsState.icon.value,
          iconColor: extraInfoMetaDetailsState.iconColor.value,
          title: extraInfoMetaDetailsState.title.value,
          steps: parsedFormState,
        };
      } else {
        extraTourInfo = {};
      }

      return {
        data: {
          extraTourInfo,
        },
        files: files,
      };
    };

    const onNext = () => {
      // If no sympbol points were created at all
      if (tourPointsState.length === 0) {
        return {
          isValid: true,
          data: {
            extraTourInfo: {},
          },
          files: [],
        };
      }

      // Validate fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave(
        tourPointsState,
        extraInfoMetaDetailsState
      );

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    const onBack = () => {
      // If no sympbol points were created at all
      if (tourPointsState.length === 0) {
        return {
          isValid: true,
          data: {
            symbolPoints: [],
          },
          files: [],
        };
      }

      // Validate fields
      const response = isFormValid(tourPointsState);

      if ("error" in response) {
        return response;
      }

      const parsedData = prepareDataToSave(
        tourPointsState,
        extraInfoMetaDetailsState
      );

      return {
        ...response, // isValid = true
        ...parsedData, // data and files
      };
    };

    useImperativeHandle(ref, () => ({
      onNext,
      onBack,
    }));
    //#endregion

    //#region Components
    const MetaDetailsContent = () => (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: 1,
          pt: 2,
        }}
      >
        {/* Icon */}
        <Box sx={{ gridRow: "1", gridColumn: "1 / 4" }}>
          <SelectIcon
            label={extraInfoMetaDetailsState.icon.label}
            value={extraInfoMetaDetailsState.icon.value}
            id="icon"
            name="icon"
            onChange={(event: any) =>
              handleInputChange(event, { type: "select" })
            }
            required={extraInfoMetaDetailsState.icon.required}
            error={!extraInfoMetaDetailsState.icon.isValid}
          />
        </Box>

        {/* Icon color */}
        <Box sx={{ gridRow: "1", gridColumn: "4 / 7" }}>
          <SelectColor
            label={extraInfoMetaDetailsState.iconColor.label}
            value={extraInfoMetaDetailsState.iconColor.value}
            id="iconColor"
            name="iconColor"
            onChange={(event: any) =>
              handleInputChange(event, { type: "select" })
            }
            required={extraInfoMetaDetailsState.iconColor.required}
            error={!extraInfoMetaDetailsState.iconColor.isValid}
          />
        </Box>

        {/* Title  */}
        <Box sx={{ gridRow: "1", gridColumn: "7 / 13" }} mb={1}>
          <TextFieldWithInfo
            label={extraInfoMetaDetailsState.title.label}
            value={extraInfoMetaDetailsState.title.value}
            id="title"
            onChange={handleInputChange}
            required={extraInfoMetaDetailsState.title.required}
            error={!extraInfoMetaDetailsState.title.isValid}
            info={extraInfoMetaDetailsState.title.info}
          />
        </Box>
      </Box>
    );
    //#endregion

    return (
      <Fragment>
        {/* Header */}
        <SectionHeader
          title={DICTIONARY.createTour.sections.extraTourInfo.label}
          info={DICTIONARY.createTour.sections.extraTourInfo.info}
          actions={[
            {
              label: DICTIONARY.createTour.buttons.preview,
              icon: <PreviewIcon />,
              color: "secondary",
              onClick: handleExtraTourInfoPreview,
              disabled: tourPointsState.length === 0,
            },
            {
              label: DICTIONARY.createTour.buttons.addExtraInfoStep,
              icon: <InfoIcon />,
              color: "primary",
              onClick: handleAddTourPoint,
            },
          ]}
        />

        {/* Content */}
        {tourPointsState.length > 0 ? (
          <Box>
            {/* Meta details */}
            {MetaDetailsContent()}

            {/* Steps */}
            {tourPointsState.map((item: any, index: number) => (
              <React.Fragment key={item.uniqueId}>
                <Box
                  ref={containerRef}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(14, 1fr)",
                    gap: 1,
                    pt: 6,
                    pb: 4,
                  }}
                >
                  {/* Index */}
                  <Box sx={{ gridRow: "1", gridColumn: "span 1" }}>
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.5,
                        fontSize: 14,
                        height: "28px",
                        width: "28px",
                        lineHeight: "28px",
                        borderRadius: "50%",
                        border: `1px solid ${grey[400]}`,
                        textAlign: "center",
                      }}
                      component="h2"
                    >
                      {index + 1}
                    </Typography>
                  </Box>

                  {/* Information */}
                  <Box sx={{ gridRow: "1 / 4", gridColumn: "2 / 11" }} mb={1}>
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: (theme) => theme.typography.pxToRem(14),
                      }}
                      required={tourPointsState[index].information.required}
                    >
                      {tourPointsState[index].information.label}
                    </FormLabel>
                    <RichTextEditor
                      /*
                      // @ts-ignore */
                      editorState={tourPointsState[index].information.value}
                      onEditorStateChange={(editorState: any) =>
                        handleTourPointInputChange(editorState, {
                          type: "richText",
                          id: `${item.uniqueId}-information`,
                        })
                      }
                    />
                  </Box>

                  {/* Step image */}
                  <Box
                    sx={{
                      gridRow: "1 / 3",
                      gridColumn: "11 / 14",
                      maxHeight: "90px",
                    }}
                    mb={1}
                  >
                    <ImageUploader
                      label={tourPointsState[index].extraInfoStepImage.label}
                      info={tourPointsState[index].extraInfoStepImage.info}
                      id={`${item.uniqueId}-extraInfoStepImage`}
                      value={tourPointsState[index].extraInfoStepImage.value}
                      error={!tourPointsState[index].extraInfoStepImage.isValid}
                      onInputChange={handleTourPointInputChange}
                    />
                  </Box>

                  {/* Actions */}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ gridRow: "1", gridColumn: "14 / 15" }}
                    mb={1}
                  >
                    {/* Delete point button */}
                    <IconButton
                      aria-label="delete"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteTourPoint(item.uniqueId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>

                  {/* Preview tour point error message */}
                  {previewTourPointError &&
                    previewTourPointErrorIndex === index && (
                      <Box sx={{ gridColumn: "2 / 14" }}>
                        <Alert severity="error" variant="filled">
                          {previewTourPointError}
                        </Alert>
                      </Box>
                    )}
                </Box>

                {index < tourPointsState.length - 1 && <Divider sx={{}} />}
              </React.Fragment>
            ))}
          </Box>
        ) : (
          <EmptyState
            title={
              DICTIONARY.createTour.sections.extraTourInfo.emptyState.title
            }
            subtitle={
              DICTIONARY.createTour.sections.extraTourInfo.emptyState.subtitle
            }
            icon="symbol"
            py={4}
          />
        )}
      </Fragment>
    );
  }
);

export default ExtraInfo;
