import React from "react";

// App components
import InfoTooltip from "../shared/InfoTooltip";

// Mui
import { Box, Chip, TextField, Autocomplete } from "@mui/material";

type Props = {
  label: string;
  info: string;
  id: string;
  value: [];
  error: boolean;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    payload: any
  ) => void;
  placeholder: string;
};

// The important this here is: "freeSolo" prop of the Autocomplete
const ChipsInput = ({
  label,
  info,
  id,
  value,
  error,
  onInputChange,
  placeholder,
}: Props) => {
  // const [val, setValue] = React.useState<any>([]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Autocomplete
        multiple
        fullWidth
        id={id}
        options={[]}
        freeSolo
        value={value}
        onChange={(event: any, newValue: any | null) => {
          // setValue(newValue);
          onInputChange(event, { type: "chips", id: id, value: newValue });
        }}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            id="tags"
            margin="none"
            size="small"
            label={label}
            placeholder={placeholder}
            sx={{
              "& .MuiChip-root": {
                height: "22px",
                "& .MuiChip-deleteIcon": {
                  fontSize: 18,
                },
              },
            }}
          />
        )}
      />
      {/* image Upload Instructions */}
      <Box>
        <InfoTooltip info={info} aria-label="Upload audio instructions" />
      </Box>
    </Box>
  );
};

export default ChipsInput;
