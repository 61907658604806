import React, { ReactChild, ReactChildren } from "react";

// Mui
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";

// Icons
import { Close as CloseIcon } from "@mui/icons-material";

interface SimepleDialogProps {
  open: boolean;
  title: string;
  onClose: (isSubmited: boolean) => void;
  children: ReactChild | ReactChildren;
}

export default function SimpleDialog({
  open,
  title,
  onClose,
  children,
}: SimepleDialogProps) {
  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 535 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2, pr: 5 }}>
        {title}

        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 14,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ height: "auto" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
