// Firebase
import { db, storage, functions } from "../config/firebase-config";
import {
  collection,
  doc,
  query,
  where,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

// utils
import { HELPERS } from "../utils";

const logEvent = {
  get: async function (id: string) {
    try {
      const docRef = doc(db, "log_events", id);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw Error;
      }

      return docSnap.data();
    } catch (e) {
      const error = `Error getting log data with id: ${id}`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
  getAll: async function (queryFilterArr?: any[]) {
    try {
      let retVal: any = [];
      const toursRef = collection(db, "log_events");
      let querySnapshot;

      if (queryFilterArr && queryFilterArr.length > 0) {
        let conditions: any = [];

        queryFilterArr.forEach((q: any) => {
          conditions.push(where(q.field, q.operator, q.value));
        });

        const q = query(toursRef, ...conditions);
        querySnapshot = await getDocs(q);
      } else {
        querySnapshot = await getDocs(toursRef);
      }

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();
        retVal.push({
          id: doc.id,
          ...data,
        });
      });

      return retVal;
    } catch (e) {
      const error = `Error getting log_events data`;
      HELPERS.localhost.isVerbose() && console.error(error);
      return {
        error,
      };
    }
  },
};

export default logEvent;
