import React, { useState, ReactChild, ReactChildren } from "react";

// App components
import InfoTooltip from "../InfoTooltip";

// Mui
import { Box, Typography, Button } from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

interface Props {
  title: string;
  info?: string;
  actions?: any;
  children?: ReactChild | ReactChildren;
}

const SectionHeader = React.memo(
  ({ title, info, actions, children }: Props) => {
    return (
      <Box
        sx={{
          position: "sticky",
          top: "183px",
          pt: 2,
          pb: 1.5,
          px: 0,
          backgroundColor: "white",
          zIndex: 2,
          borderBottom: `1px solid ${grey[200]}`,
          mb: 0.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {/* Title */}
          <Typography
            sx={{
              fontSize: 20,
            }}
            component="h2"
          >
            {title}
            {info && (
              <InfoTooltip
                info={info}
                aria-label="Section header"
                style={{ mt: -0.2 }}
              />
            )}
          </Typography>

          <Box
            sx={{
              flex: 1,
              maxWidth: "450px",
            }}
          >
            {children}
          </Box>
        </Box>

        {/* Actions */}
        <Box>
          {actions &&
            actions.length > 0 &&
            actions.map((action: any, index: number) => (
              <Button
                key={index}
                variant="outlined"
                color={action.color || "secondary"}
                size="small"
                startIcon={action.icon}
                onClick={action.onClick}
                disabled={action.disabled || false}
                sx={{
                  ml: 1,
                }}
              >
                {action.label}
              </Button>
            ))}
        </Box>
      </Box>
    );
  }
);

export default SectionHeader;
