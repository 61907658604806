import { Fragment, useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { DICTIONARY } from "../../utils";
import { LOCALE_LIST } from "../../consts";

// App components
import AutocompleteSelectLocale from "../inputs/AutocompleteSelectLocale";

// Mui
import { Box, Button, Typography } from "@mui/material";

// Colors
import { grey } from "@mui/material/colors";

const BASE_URL = process.env.REACT_APP_PWA_BASE_URL;

type Props = {
  qrValue: string;
  qrFileName?: string;
};

export default function QrCode({ qrValue, qrFileName }: Props) {
  const [parsedQrValue, setParsedQrValue] = useState<string>(
    `${BASE_URL}/${qrValue}`
  );
  const [locale, setLocale] = useState<string>(LOCALE_LIST.en.value);

  //#region Init
  useEffect(() => {
    setParsedQrValue(`${BASE_URL}/${locale}/${qrValue}`);
  }, [qrValue]);
  //#endregion

  //#region Functionality
  const handleInputChange = (event: any, payload?: any) => {
    const { id, value } = payload;

    setLocale(value);

    let parsedValue = value || LOCALE_LIST.en.value; // Fallback to english
    setParsedQrValue(`${BASE_URL}/${parsedValue}/${qrValue}`);
  };

  //#endregion

  //#region QR code download
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas: any = document.getElementById("qr-gen");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `qr_code_${locale}_${
        qrFileName || new Date().toDateString()
      }.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  //#endregion

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
        }}
      >
        {/* Select locale */}
        <Box
          sx={{
            mb: 2,
            width: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <AutocompleteSelectLocale
            label={DICTIONARY.qrCodeGenerator.fields.locale.label}
            info={DICTIONARY.qrCodeGenerator.fields.locale.info}
            id="locale"
            value={locale}
            onInputChange={handleInputChange}
          />
          <Typography sx={{ fontSize: 12, color: grey[700] }}>
            {DICTIONARY.qrCodeGenerator.fields.locale.info}
          </Typography>
        </Box>

        <QRCode
          id="qr-gen"
          value={parsedQrValue}
          size={200}
          level={"M"}
          // includeMargin={true}
        />
      </Box>

      {/* Actions */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 1,
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={downloadQRCode}
        >
          {DICTIONARY.qrCodeGenerator.buttons.download}
        </Button>
      </Box>
    </Fragment>
  );
}
